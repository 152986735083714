import { Location } from 'history'
import { DateTime } from 'luxon'
import {
  storedInnerRedirectKey,
  storedOuterRedirectKey,
  StoredRedirect,
} from 'features/redirect-after-register'

export const REDIRECT_QUERY_PARAM_NAME = 'innerRedirect'

const getIsResetPasswordUri = (location: Location): boolean =>
  location.pathname === '/' &&
  location.hash.startsWith('#reset-password/password-reset-uuid')

const getIsRegisterVerifyUri = (location: Location): boolean =>
  location.pathname === '/' &&
  location.hash.startsWith('#verify/verification-code')

export const handleRequiresAuth = (location: Location): string => {
  if (getIsResetPasswordUri(location) || getIsRegisterVerifyUri(location)) {
    return `/${location.hash.slice(1)}`
  }

  const hostUrl = new URL(document.location.href)

  let innerRedirectUrl = hostUrl.searchParams.get(REDIRECT_QUERY_PARAM_NAME)
  const urlPath = hostUrl.pathname

  if (!innerRedirectUrl) {
    hostUrl.searchParams.set(REDIRECT_QUERY_PARAM_NAME, urlPath)
    innerRedirectUrl = urlPath
  }

  if (innerRedirectUrl) {
    const item: StoredRedirect = {
      url: innerRedirectUrl,
      createdAt: DateTime.utc().toISO(),
    }
    localStorage?.setItem(storedInnerRedirectKey, JSON.stringify(item))
    localStorage.removeItem(storedOuterRedirectKey)
  }

  if (hostUrl.search) {
    return `/login${hostUrl.search}`
  }

  return '/login'
}
