import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { TalkInstallsApi } from 'api/talkInstalls'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import {
  FullSubscription,
  useFullSubscriptions,
} from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { ProductName } from 'types/enums'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useTalkIntallsQuery = (
  api: TalkInstallsApi = new TalkInstallsApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const queryClient = useQueryClient()
  const { subscriptionGroups } = useFullSubscriptions()

  const hasTalkSubscriptions = subscriptionGroups.some(
    (group) => group === ProductName.UNIFI_TALK
  )

  const talkInstalls = useQuery({
    queryKey: [QueryKey.TALK_INSTALLS],
    queryFn: api.get,
    enabled: isDoneAuthenticating && hasTalkSubscriptions,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const resetTalkInstalls = () => {
    queryClient.removeQueries({ queryKey: [QueryKey.TALK_INSTALLS] })
  }

  const getInstall = (fullSubscription?: FullSubscription) =>
    talkInstalls.data?.find(
      ({ subscription }: { subscription: string }) =>
        subscription === fullSubscription?.id
    )

  return {
    talkInstalls: talkInstalls.data,
    areTalkInstallsLoading: talkInstalls.isFetching,
    talkInstallsErrors: talkInstalls.error,
    resetTalkInstalls,
    areTalkInstallsFetched: talkInstalls.isFetched,
    getInstall,
  }
}
