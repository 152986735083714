import { useEffect } from 'react'
import { History } from 'history'
import { DateTime } from 'luxon'
import { BrowserUtils } from 'utils/browserUtils'

// enforce checking that localStorage is available
// const localStorage: typeof window.localStorage | undefined = window.localStorage

export const storedOuterRedirectKey = 'storedOuterRedirect'
export const storedInnerRedirectKey = 'storedInnerRedirect'
export const storedTrustDeviceRedirectKey = 'storedTrustDeviceRedirect'

const {
  api: { sso },
} = __CONFIG__

export interface StoredRedirect {
  url: string
  createdAt: string | null
}

export function useOuterRedirectStore() {
  useEffect(() => {
    const hostUrl = new URL(document.location.href)
    const storedRedirectItem = localStorage?.getItem(storedOuterRedirectKey)
    const storedRedirect: StoredRedirect | undefined =
      storedRedirectItem && JSON.parse(storedRedirectItem)

    const isShopifyRedirect = hostUrl.searchParams.get('shopify') === 'true'

    const createdAt = DateTime.utc().toISO()
    const redirectUrl = hostUrl.searchParams.get('redirect')

    if (storedRedirect) {
      if (
        DateTime.fromISO(storedRedirect.createdAt || '').diffNow('minutes')
          .minutes >= 20
      ) {
        localStorage?.removeItem(storedOuterRedirectKey)
        // return
      }
    }

    const isInvalidRedirect =
      !isShopifyRedirect && !BrowserUtils.validateUrl(redirectUrl)

    const isInvalidShopifyRedirect = !redirectUrl && !isShopifyRedirect

    if (isInvalidRedirect || isInvalidShopifyRedirect) {
      return
    }

    const shopifyRegion = hostUrl.searchParams.get('region')

    const item: StoredRedirect = {
      url:
        redirectUrl ??
        `${sso.base}${sso.paths.shopify_login}${
          shopifyRegion ? `?region=${shopifyRegion}` : ''
        }`,
      createdAt,
    }

    localStorage?.setItem(storedOuterRedirectKey, JSON.stringify(item))
    localStorage?.removeItem(storedInnerRedirectKey)
  }, [])
}

export const trustDeviceRedirect = (history: History<unknown>) => {
  const storedTrustDeviceRedirect = localStorage?.getItem(
    storedTrustDeviceRedirectKey
  )

  const redirect: StoredRedirect | undefined =
    storedTrustDeviceRedirect && JSON.parse(storedTrustDeviceRedirect)

  if (!redirect) {
    return false
  }

  const redirectCreatedAt = redirect?.createdAt
    ? DateTime.fromISO(redirect.createdAt || '')
    : DateTime.fromMillis(Infinity)

  if (redirectCreatedAt.diffNow('minutes').minutes > 20) {
    localStorage.removeItem(storedInnerRedirectKey)
    return false
  }

  if (redirect?.url) {
    history.replace(redirect.url)
    return true
  }
}

const pathsToReplace = ['/login/trust-device']

export const innerRedirect = (history: History<unknown>) => {
  const storedInnerRedirectItem = localStorage?.getItem(storedInnerRedirectKey)
  const storedTrustDeviceRedirect = localStorage?.getItem(
    storedTrustDeviceRedirectKey
  )

  // Hacky solution to intercept the original redirect logic in other to be able to display the trust device page that follows an mfa login
  if (storedTrustDeviceRedirect) {
    return false
  }

  const innerRedirect: StoredRedirect | undefined =
    storedInnerRedirectItem && JSON.parse(storedInnerRedirectItem)

  const innerRedirectCreatedAt = innerRedirect?.createdAt
    ? DateTime.fromISO(innerRedirect.createdAt || '')
    : DateTime.fromMillis(Infinity)

  if (innerRedirectCreatedAt.diffNow('minutes').minutes > 20) {
    localStorage.removeItem(storedInnerRedirectKey)
    return false
  }

  if (innerRedirect?.url) {
    localStorage.removeItem(storedOuterRedirectKey)
    localStorage.removeItem(storedInnerRedirectKey)
    pathsToReplace.includes(history.location.pathname)
      ? history.replace(innerRedirect.url)
      : history.push(innerRedirect.url)
  }
}

export const outerRedirect = () => {
  const storedOuterRedirectItem = localStorage?.getItem(storedOuterRedirectKey)
  const storedTrustDeviceRedirect = localStorage?.getItem(
    storedTrustDeviceRedirectKey
  )

  // Hacky solution to intercept the original redirect logic in other to be able to display the trust device page that follows an mfa login
  if (storedTrustDeviceRedirect) {
    return false
  }

  const outerRedirect: StoredRedirect | undefined =
    storedOuterRedirectItem && JSON.parse(storedOuterRedirectItem)

  const isValidRedirect = BrowserUtils.validateUrl(outerRedirect?.url)

  if (!isValidRedirect) return

  const outerRedirectCreatedAt = outerRedirect?.createdAt
    ? DateTime.fromISO(outerRedirect.createdAt || '')
    : DateTime.fromMillis(Infinity)

  if (outerRedirectCreatedAt.diffNow('minutes').minutes > 20) {
    localStorage.removeItem(storedOuterRedirectKey)
    return false
  }

  if (outerRedirect?.url) {
    localStorage.removeItem(storedOuterRedirectKey)
    localStorage.removeItem(storedInnerRedirectKey)
    // history.replaceState and history.pushState are used here to add an entry for the account homepage
    // in the history object without actually navigating there, this means that if the user presses the
    // arrow back on the browser he will go into the account homepage instead of the login / trust device pages
    history.replaceState({}, '', '/')
    history.pushState({}, '', window.location.origin)
    window.location.replace(outerRedirect.url)
    return true
  }

  return false
}
