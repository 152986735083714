import React from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage } from 'react-intl'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import {
  CheckIcon,
  Columns,
  ColumnsWithGap,
  GridWrapper,
  InputLabel,
} from './Profile.styles'

export const MailingListSection = () => {
  const { profileData, isProfileDataFetching } = useSsoProfileQuery()
  const { updateProfile, isUpdateProfileLoading } = useUpdateProfile()

  const toggleMailingList = () => {
    //INFO: We toggle all three lists for now until we update the UI to allow multiple selections
    const newsletter = {
      announcements: !profileData?.mailinglist,
      unifi: !profileData?.mailinglist,
      uisp: !profileData?.mailinglist,
    }

    updateProfile({ newsletter })
  }

  const isButtonLoading = isUpdateProfileLoading || isProfileDataFetching

  return (
    <GridWrapper>
      <InputLabel>
        <FormattedMessage id="COMMON_AUTH_REGISTER_LABEL_NEWSLETTER_PROFILE" />
      </InputLabel>
      <Columns>
        {profileData?.mailinglist && <CheckIcon variant="twoTone" isActive />}
        <ColumnsWithGap>
          {profileData?.mailinglist && (
            <FormattedMessage id="SETTINGS_NEWSLETTER_SUBSCRIBED" />
          )}
          <Button
            loader={isButtonLoading ? 'dots' : undefined}
            variant="inline"
            onClick={toggleMailingList}
          >
            <FormattedMessage
              id={
                profileData?.mailinglist
                  ? 'COMMON_ACTION_UNSUBSCRIBE'
                  : 'COMMON_ACTION_SUBSCRIBE'
              }
            />
          </Button>
        </ColumnsWithGap>
      </Columns>
    </GridWrapper>
  )
}
