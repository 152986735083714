import React from 'react'
import { FormFooter, FormFooterButton } from '@ubnt/ui-components/FormFooter'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'

interface Props {
  isActive: boolean
  isDisabled?: boolean
  isLoading: boolean
  onSubmit: () => void
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const FooterButtons: React.FC<Props> = ({
  isActive,
  isDisabled = false,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  return (
    <FooterContainer>
      <FormFooter active={isActive} align="left" paddingLeft="l" paddingTop="l">
        <FormFooterButton
          variant="primary"
          disabled={isDisabled || isLoading}
          loader={isLoading ? 'loading' : undefined}
          onClick={() => onSubmit()}
        >
          <FormattedMessage id="COMMON_ACTION_APPLY_CHANGES" />
        </FormFooterButton>
        <FormFooterButton
          variant="tertiary"
          onClick={onCancel}
          disabled={isDisabled || isLoading}
        >
          <FormattedMessage id="COMMON_ACTION_CANCEL" />
        </FormFooterButton>
      </FormFooter>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 600;
`
