import React, { useEffect, useMemo, useState } from 'react'
import { SidePanel } from '@ubnt/ui-components'
import { useDispatch } from 'react-redux'
import { setVisibleModal } from 'modules/modals'
import { useNcaArchivedBackupsQuery } from 'store/queries/useNcaArchivedBackupsQuery'
import { useNcaBackupsQuery } from 'store/queries/useNcaBackupsQuery'
import styled from 'theme/styled'
import usePrevious from 'utils/usePrevious'
import { BackupsView } from './BackupsView'
import { DeviceView } from './DeviceView'
import { Footer } from './Footer'
import { ParsedBackup } from './types'
import { prepareData } from './utils'
import { DELETE_BACKUP_MODAL_ID } from '../DeleteBackupModal'
import { TabState } from '../types'

type BackupsSidePanelProps = {
  selectedDeviceId: string | null
  onClose: () => void
  selectedTab: TabState
}

export const BackupsSidePanel: React.FC<BackupsSidePanelProps> = ({
  selectedDeviceId,
  onClose,
  selectedTab,
}) => {
  const dispatch = useDispatch()
  const { backupsFromAllDevices, areNcaBackupsRefetching } =
    useNcaBackupsQuery()
  const { parsedNcaArchivedBackups, areNcaArchivedBackupsRefetching } =
    useNcaArchivedBackupsQuery()

  const [selectedBackups, setSelectedBackups] = useState<ParsedBackup[]>([])
  const [isManageStateActive, setIsManageStateActive] = useState<boolean>(false)

  const prevSelectedDeviceId = usePrevious(selectedDeviceId)

  useEffect(() => {
    if (
      (areNcaBackupsRefetching || areNcaArchivedBackupsRefetching) &&
      selectedBackups.length
    ) {
      setSelectedBackups([])
    }
  }, [
    areNcaArchivedBackupsRefetching,
    areNcaBackupsRefetching,
    selectedBackups.length,
  ])

  useEffect(() => {
    if (selectedDeviceId !== prevSelectedDeviceId) {
      setSelectedBackups([])
      setIsManageStateActive(false)
    }
  }, [prevSelectedDeviceId, selectedDeviceId])

  const device = useMemo(() => {
    if (selectedTab === TabState.ACTIVE) {
      return backupsFromAllDevices.find(
        ({ deviceId }) => deviceId === selectedDeviceId
      )
    }

    return parsedNcaArchivedBackups.find(
      ({ deviceId }) => deviceId === selectedDeviceId
    )
  }, [
    backupsFromAllDevices,
    parsedNcaArchivedBackups,
    selectedDeviceId,
    selectedTab,
  ])

  const handleClickFooterButton = (isActive: boolean) => {
    setIsManageStateActive(isActive)
  }

  const handleClickCheckbox = (backup: ParsedBackup) => {
    setSelectedBackups((prev) => {
      if (prev.some(({ id }) => id === backup.id)) {
        return prev.filter(({ id }) => id !== backup.id)
      }
      return [...prev, backup]
    })
  }

  const handleClickHeaderCheckbox = () => {
    if (selectedBackups.length) {
      setSelectedBackups([])
    }
    if (!selectedBackups.length) {
      const parsedBackups = device?.backups.map((backup) => {
        return prepareData({ ...backup, deviceId: device.deviceId })
      })
      setSelectedBackups(parsedBackups || [])
    }
  }

  const handleClickRemove = () =>
    dispatch(
      setVisibleModal(DELETE_BACKUP_MODAL_ID, {
        backups: selectedBackups,
      })
    )

  if (!device) {
    return null
  }

  return (
    <StyledSidePanel
      in={!!device}
      position="fixed"
      header={device.name}
      height="calc(100% - 50px)"
      footer={
        <Footer
          onClick={handleClickFooterButton}
          onClickRemove={handleClickRemove}
          isManageStateActive={isManageStateActive}
          hasSelectedBackups={!!selectedBackups.length}
        />
      }
      onClose={onClose}
    >
      <Body>
        <DeviceView device={device} />
        <BackupsView
          backups={device.backups}
          deviceId={device.deviceId}
          isManageStateActive={isManageStateActive}
          onClickCheckbox={handleClickCheckbox}
          onClickHeaderCheckbox={handleClickHeaderCheckbox}
          selectedBackups={selectedBackups}
        />
      </Body>
    </StyledSidePanel>
  )
}

const StyledSidePanel = styled(SidePanel)`
  top: 50px;

  z-index: 500;
`

const Body = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
