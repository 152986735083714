import React, { useContext } from 'react'
import { AgentMessage } from './AgentMessage'
import { ChatContext } from './ChatContext'
import { AGENT_MESSAGE_PREFIX } from './constants'
import { ExtraInfo } from './ExtraInfo'
import { MessagesContainer, OverflowWrapper } from './styles'
import { isTextMessageType } from './types'
import { UserMessage } from './UserMessage'

export const Messages: React.FC = () => {
  const { messages } = useContext(ChatContext)

  return (
    <MessagesContainer>
      <OverflowWrapper>
        {messages.map((msg, i, allMessages) => {
          const isAgentMessage = msg.nick.startsWith(AGENT_MESSAGE_PREFIX)
          const shouldScroll = i + 1 === allMessages.length
          const shouldHideAvatar = i > 0 && allMessages[i - 1].nick === msg.nick

          const isTextMessage = isTextMessageType(msg)

          const text = isTextMessage ? msg.msg : undefined

          const attachment = isTextMessage ? undefined : msg.attachment

          return isAgentMessage ? (
            <AgentMessage
              key={i}
              text={text}
              username={msg.display_name}
              shouldScroll={shouldScroll}
              shouldHideAvatar={shouldHideAvatar}
              attachment={attachment}
            />
          ) : (
            <UserMessage
              key={i}
              text={text}
              shouldScroll={shouldScroll}
              shouldHideAvatar={shouldHideAvatar}
              isInitialMessage={i === 0}
            />
          )
        })}
        <ExtraInfo />
      </OverflowWrapper>
    </MessagesContainer>
  )
}
