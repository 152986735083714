import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { ZendeskUserApi } from 'api/zendesk/user'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useZendeskUserQuery = (
  api: ZendeskUserApi = new ZendeskUserApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const userData = useQuery({
    queryKey: [QueryKey.ZENDESK_USER],
    queryFn: api.get,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    hasZendeskUser: !!userData.data?.zendeskUser,
    isZendeskUserLoading: userData.isFetching,
    isZendeskUserFetched: userData.isFetched,
    isZendeskUserError: userData.isError,
  }
}
