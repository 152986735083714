import React, { useMemo } from 'react'
import { SearchIcon } from '@ubnt/icons'
import { useDebouncedCallback } from 'use-debounce'
import { EmptySearchResults } from 'components/EmptySearchResults'
import { BackupGridRow } from './BackupGridRow'
import { SearchField, Wrapper } from './Backups.styles'
import { BackupsGridHeaderRow } from './BackupsGridHeaderRow'
import { MixedDeviceBackups, SortState } from './types'

type BackupsGridProps = {
  devices: MixedDeviceBackups[]
  clickedDevice: string | null
  onClickRow: (device: string) => void
  onClickSortIcon: () => void
  sort: SortState
  onSearchChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => void
}

export const BackupsGrid: React.FC<BackupsGridProps> = ({
  devices,
  clickedDevice,
  onClickRow,
  onClickSortIcon,
  sort,
  onSearchChange,
}) => {
  const handleDebouncedChange = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string | number) => {
      onSearchChange(e, value)
    },
    300
  )

  const renderContent = useMemo(() => {
    if (!devices.length) {
      return <EmptySearchResults />
    }

    return devices.map((device) => (
      <BackupGridRow
        key={device.deviceId}
        device={device}
        selected={device.deviceId === clickedDevice}
        onClick={onClickRow}
      />
    ))
  }, [clickedDevice, devices, onClickRow])

  return (
    <Wrapper>
      <SearchField
        variant="secondary"
        placeholder="Search"
        iconBefore={<SearchIcon />}
        onChange={handleDebouncedChange}
        full
      />
      <BackupsGridHeaderRow onClick={onClickSortIcon} sort={sort} />
      {renderContent}
    </Wrapper>
  )
}
