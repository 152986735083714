import React from 'react'
import { StripeError } from '@stripe/stripe-js'
import { api } from 'api'
import { AxiosError } from 'axios'
import { omit, pick } from 'lodash'
import { FormattedMessage, IntlShape } from 'react-intl'
import Stripe from 'stripe'
import { AddressToValidate } from 'api/api'
import {
  TAX_ID_ERROR_CODES_SET,
  TaxIdErrorCode,
} from 'api/billing/billingTaxId'
import { regionsThatAllowDifferentCardCountries } from 'features/stripe/config'
import { validStripeCountryMap } from 'features/stripe/ui/regions'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'
import { getStripeMutationErrorMessage } from 'store/mutations/utils'
import { isAxiosError } from 'utils/isAxiosError'
import {
  PAST_EXPIRY,
  STRIPE_VALUE_INCOMPLETE,
  STRIPE_VALUE_INVALID,
} from './common'
import {
  AddCardAddress,
  AddCardValues,
  BrazilCardMetadata,
  EditCardValues,
} from './forms/types'
import { PaymentMethodErrors } from './types'
import {
  brazilValidationSchema,
  brazilValidationSchemaWithCardInformation,
  brazilValidationSchemaWithCardInformationAndTaxId,
  defaultValidationSchema,
  defaultValidationSchemaWithCardInformation,
} from './validationSchemas'
import { CardType, UpdateCardData } from '../modules/types'

const getAddressLabel = (field: string) => {
  switch (field) {
    case 'street':
      return 'COMMON_LABEL_STREET_ADDRESS'
    case 'region':
      return 'COMMON_LABEL_STATE'
    case 'city':
      return 'COMMON_LABEL_CITY'
    case 'postal_code':
      return 'COMMON_LABEL_POSTAL_CODE'
    case 'country_area':
      return 'COMMON_LABEL_STATE'
    default:
      return null
  }
}

/* Stripe elements cannot use the theme since Stripe does not support 
hsla colour values and the UIC palette is built using them */
export const stripeStyles = {
  base: {
    fontSize: '13px',
    color: '#000000d9',
    fontFamily: 'Lato, sans-serif',
    '::placeholder': {
      color: '#00000033',
    },
  },
  invalid: {
    color: '#f03a3e',
  },
}

export const stripeStylesDark = {
  base: {
    fontSize: '13px',
    color: '#ffffffd9',
    fontFamily: 'Lato, sans-serif',
    '::placeholder': {
      color: '#ffffff33',
    },
  },
  invalid: {
    color: '#cd3237',
  },
}

type SSOAddressError = {
  country?: string[] | string
  city?: string[] | string
  postal_code?: string[] | string
  region?: string[] | string
  street?: string[] | string
  street2?: string[] | string
  country_area?: string[] | string
}

const transformSsoAddressToBillingAddress = (data: SSOAddressError) => ({
  ...omit(data, ['street', 'country_area']),
  line1: data.street,
  state: data.country_area,
})

export const handleCaughtAddressError = (
  unknownError: unknown,
  intl: IntlShape,
  setAddressError: (state: Record<string, string> | null) => void
) => {
  if (!isAxiosError(unknownError)) return
  const error = unknownError as AxiosError<SSOAddressError>

  if (!error.response?.data) return
  const data = transformSsoAddressToBillingAddress(error.response.data)
  const parsedData: Record<string, string> = {}
  Object.entries(data).forEach(([key, value]) => {
    if (!Array.isArray(value)) return

    const addressLabel = getAddressLabel(key)

    if (!addressLabel) return

    if ((value[0] as string).includes('invalid')) {
      parsedData[key] = intl.formatMessage(
        { id: 'COMMON_VALIDATION_INVALID' },
        { name: intl.formatMessage({ id: addressLabel }) }
      )
    }

    const match = (value[0] as string).match(
      /Ensure this field has no more than (\d+)/
    )

    if (match && match.length > 1) {
      const maxValue = match[1]
      parsedData[key] = intl.formatMessage(
        { id: 'COMMON_VALIDATION_MAX_CHARS' },
        { name: intl.formatMessage({ id: addressLabel }), max: maxValue }
      )
    }

    if (value[0] === 'required') {
      parsedData[key] = intl.formatMessage(
        { id: 'COMMON_VALIDATION_REQUIRED' },
        { name: intl.formatMessage({ id: addressLabel }) }
      )
    }
  })

  setAddressError(parsedData)
}

export const checkAddress = async (values: {
  name: string
  country: string
  line1: string
  line2: string
  city: string
  state: string
  postal_code: string
}) => {
  const addressValues: AddressToValidate = pick(values, [
    'city',
    'country',
    'postal_code',
  ])

  addressValues.street = values.line1
  addressValues.street2 = values.line2
  if (['CA', 'US'].includes(values.country)) {
    addressValues.country_area = values.state
  }

  return await api.checkAddress(addressValues)
}

export const cardNumberErrorMessage = (cardNumber: string) => {
  if (cardNumber === STRIPE_VALUE_INCOMPLETE)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INCOMPLETE_CARD_NUMBER" />
  if (cardNumber === STRIPE_VALUE_INVALID)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_NUMBER" />
  if (cardNumber === '')
    return <FormattedMessage id="SETTINGS_PAYMENTS_EMPTY_CARD_NUMBER" />
  return null
}

export const cardExpiryErrorMessage = (cardExpiry: string) => {
  if (cardExpiry === STRIPE_VALUE_INCOMPLETE)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INCOMPLETE_CARD_EXPIRY" />
  if (cardExpiry === STRIPE_VALUE_INVALID)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_EXPIRY" />
  if (cardExpiry === PAST_EXPIRY)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_EXPIRY_PAST" />
  if (cardExpiry === '')
    return <FormattedMessage id="SETTINGS_PAYMENTS_EMPTY_CARD_EXPIRY" />
  return null
}

export const cardCvcErrorMessage = (cardCvc: string) => {
  if (cardCvc === STRIPE_VALUE_INCOMPLETE)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INCOMPLETE_CARD_CVC" />
  if (cardCvc === STRIPE_VALUE_INVALID)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_CVC" />
  if (cardCvc === '')
    return <FormattedMessage id="SETTINGS_PAYMENTS_EMPTY_CARD_CVC" />
  return null
}

export const parseMonth = (exp_month?: number | string): string => {
  if (!exp_month) {
    return ''
  }

  const parsedMonth =
    typeof exp_month === 'string' ? parseInt(exp_month) : exp_month

  if (parsedMonth < 10) {
    return `0${parsedMonth}`
  }
  return `${parsedMonth}`
}

export const parseYear = (exp_year?: number | string): string => {
  if (!exp_year) {
    return ''
  }

  const parsedYear =
    typeof exp_year === 'string' ? parseInt(exp_year) : exp_year

  return `${parsedYear % 100}`
}

export const getIsSourceCountryValid = (
  userStripeRegion: StripeRegionCode,
  sourceCountry: StripeCountryCode,
  userCountry: StripeCountryCode
) => {
  if (sourceCountry === userCountry) {
    return true
  }

  if (
    regionsThatAllowDifferentCardCountries.includes(userStripeRegion) &&
    userCountry
  ) {
    const userCountryRegion = validStripeCountryMap.get(userCountry)
    const sourceCountryRegion = validStripeCountryMap.get(sourceCountry)

    return userCountryRegion?.region === sourceCountryRegion?.region
  }
}

export const getErrorMessage = (
  mutationError: Error | null,
  intl: IntlShape,
  regionName?: string
) => {
  const error = getStripeMutationErrorMessage(mutationError)
  if (error?.apiError === PaymentMethodErrors.DUPLICATE_CARD) {
    return intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_DUPLICATED_CARD' })
  }
  if (error?.apiError === PaymentMethodErrors.INVALID_COUNTRY) {
    return intl.formatMessage(
      { id: 'STRIPE_PAYMENT_ERROR_UNSUPORTED_CARD' },
      {
        region: regionName,
      }
    )
  }
  if (error?.apiError?.includes(PaymentMethodErrors.SANCTIONED)) {
    return intl.formatMessage(
      { id: 'STRIPE_PAYMENT_ERROR_SANCTIONED' },
      {
        region: regionName,
      }
    )
  }
  if (error?.stripeError?.includes(PaymentMethodErrors.POSTAL_CODE)) {
    return intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_POSTAL_CODE' })
  }
  if (error?.stripeError) {
    return error.stripeError
  }

  if (
    error?.apiErrorCode === TaxIdErrorCode.VALIDATION_SERVER_DOWN &&
    error?.service
  ) {
    return intl.formatMessage(
      {
        id: 'SETTINGS_PAYMENTS_TAX_ID_VALIDATION_DOWN',
      },
      { service: error.service }
    )
  }

  if (error?.apiErrorCode && TAX_ID_ERROR_CODES_SET.has(error.apiErrorCode)) {
    return intl.formatMessage({
      id: 'SETTINGS_PAYMENTS_TAX_ID_VALIDATION_ERROR',
    })
  }

  if (error?.apiError) {
    return intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' })
  }
  return null
}

export const getValidationSchema = (
  type: 'add' | 'edit',
  country?: string | null,
  hideTaxId?: boolean
) => {
  const cardCountry = country?.toLowerCase()
  if (cardCountry === 'br' && type === 'add' && hideTaxId) {
    return brazilValidationSchemaWithCardInformation
  }
  if (cardCountry === 'br' && type === 'add') {
    return brazilValidationSchemaWithCardInformationAndTaxId
  }

  if (type === 'add') {
    return defaultValidationSchemaWithCardInformation
  }

  if (type === 'edit' && cardCountry === 'br') {
    return brazilValidationSchema
  }

  return defaultValidationSchema
}

export const getInitialValues = (
  name?: string | null,
  addressData?: Stripe.Address | null,
  cardData?: Stripe.PaymentMethod.Card,
  metadata?: Stripe.Source['metadata']
) => {
  return {
    name: name || '',
    country: addressData?.country || 'us',
    line1: addressData?.line1 || '',
    line2: addressData?.line2 || '',
    state: metadata?.bill_addr_state_abbr || addressData?.state || '',
    exp_month: parseMonth(cardData?.exp_month.toString()), // month requires parsing on initial value only
    exp_year: cardData?.exp_year.toString(),
    postal_code:
      metadata?.bill_addr_postal_code || addressData?.postal_code || '',
    bill_addr_street: metadata?.bill_addr_street || '',
    number: metadata?.bill_addr_number || '',
    neighborhood: metadata?.bill_addr_neighborhood || '',
    city: metadata?.bill_addr_city || addressData?.city || '',
  }
}

export const getAddressAndMetadata = ({
  country,
  line1,
  line2,
  city,
  state,
  postal_code,
  number,
  neighborhood,
  state_abbr,
  bill_addr_street,
}: AddCardValues | EditCardValues) => {
  const defaultInfo: {
    address: AddCardAddress
    metadata?: BrazilCardMetadata
  } = {
    address: {
      country,
      line1,
      line2,
      city,
      state,
      postal_code,
    },
  }
  if (country === 'BR') {
    defaultInfo.metadata = {
      bill_addr_postal_code: postal_code,
      bill_addr_street: bill_addr_street || 'N/A',
      bill_addr_number: number || 'N/A',
      bill_addr_neighborhood: neighborhood || 'N/A',
      bill_addr_city: city,
      bill_addr_state_abbr: state_abbr || state || 'N/A',
    }

    defaultInfo.address.line1 = `${line1} ${number}`
  }

  return defaultInfo
}

export const getUpdateCardData = (values: EditCardValues, card: CardType) => {
  const { address, metadata } = getAddressAndMetadata(values)

  const defaultData: UpdateCardData = {
    id: card.id,
    region: card.region,
    metadata,
    card: {
      exp_month: values.exp_month,
      exp_year: values.exp_year,
    },
    owner: {
      name: values.name,
    },
  }

  if (values.country !== 'BR') {
    defaultData.owner.address = address
  }

  return defaultData
}

export const handleSourceErrors = (
  setError: (
    value: React.SetStateAction<{
      message?: string
      show: boolean
    } | null>
  ) => void,
  intl: IntlShape,
  sourceCountry?: string | null,
  userStripeRegion?: StripeRegionCode,
  error?: StripeError
) => {
  if (
    sourceCountry &&
    !validStripeCountryMap.has(sourceCountry.toLowerCase() as StripeCountryCode)
  ) {
    setError({
      message: intl.formatMessage({
        id: 'STRIPE_PAYMENT_ERROR_INVALID_REGION',
      }),
      show: true,
    })
  }
  if (sourceCountry && sourceCountry.toLowerCase() !== userStripeRegion) {
    setError({
      message: intl.formatMessage({
        id: 'STRIPE_PAYMENT_ERROR_WRONG_REGION',
      }),
      show: true,
    })
  }
  if (error) {
    setError(error.message ? { message: error.message, show: true } : null)
  }
  return
}
