import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useCancelExternalSubscription } from 'pages/subscriptions/hooks/useCancelExternalSubscription'
import { ProductName } from 'types/enums'

interface Props extends ModalProps {
  productName?: ProductName
}

const ExternalCancelSubscriptionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  productName,
}) => {
  const { title, steps } = useCancelExternalSubscription(productName)

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION" />
      }
      size="small"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
      ]}
    >
      <Text>{title}</Text>
      <ol>
        {steps.map((step, i) => (
          <li key={`external-cancel-step-${i}`}>{step}</li>
        ))}
      </ol>
    </GenericModal>
  )
}

export const EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID =
  'EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID'

export const WrappedExternalCancelSubscriptionModal = () => (
  <ModalWrapper modalId={EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID}>
    <ExternalCancelSubscriptionModal />
  </ModalWrapper>
)
