import { ParsedNCAConsole } from 'features/support/unifi/types'
import { parseDescription } from 'features/support/utils'
import { billingSpecificService as billingSpecificServiceOptions } from '../config/ConfigOptions'
import { Console } from '../config/types'
import { SupportFormState } from '../types'

const parseConsole = (console?: ParsedNCAConsole) => {
  if (!console || console.id === Console.umrUnlisted) {
    return 'Offline UMR'
  }

  return console.deviceName ?? console.label
}

export const createSubject = (values: SupportFormState) => {
  const parsedConsole = parseConsole(values.console)
  const parsedService = billingSpecificServiceOptions.find(
    ({ id }) => id === values.billingSpecificService
  )?.label

  const parsedDescription = parseDescription(values.description || '')

  if (parsedService) {
    return `${parsedConsole} | ${parsedService}: ${parsedDescription}...`
  }

  return `${parsedConsole}: ${parsedDescription}...`
}
