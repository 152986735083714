import React, { useContext, useState } from 'react'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'
import { InvoiceContext } from './InvoiceContext'
import { InvoicesGrid } from './invoices/InvoicesGrid'
import { MonthView } from './invoices/monthView/MonthView'
import { UnpaidInvoicesAccordion } from './invoices/UnpaidInvoicesAccordion/UnpaidInvoicesAccordion'

type Props = {
  onUpdateDisplayTabs: (displayTabs: boolean) => void
}

export const InvoicesList: React.FC<Props> = ({ onUpdateDisplayTabs }) => {
  const { selectedYear } = useContext(InvoiceContext)

  const [selectedMonth, setSelectedMonth] = useState<string | null>(null)

  useBillingInvoicesQuery(selectedYear)

  const handleSelectMonth = (value: string) => {
    setSelectedMonth(value)
    onUpdateDisplayTabs(false)
  }

  const handleResetMonth = () => {
    onUpdateDisplayTabs(true)
    setSelectedMonth(null)
  }

  if (selectedMonth) {
    return (
      <MonthView
        selectedMonth={selectedMonth}
        onResetSelectedMonth={handleResetMonth}
      />
    )
  }

  return (
    <>
      <UnpaidInvoicesAccordion />
      <InvoicesGrid onMonthClick={handleSelectMonth} />
    </>
  )
}
