import { useQueryClient } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { MFAAuthenticatorStatus } from 'enums'
import { useDispatch } from 'react-redux'
import { MfaApi } from 'api/sso/mfa'
import { MFAType } from 'modules/types'
import { closeVisibleModal } from 'modules/modals'
import { useMfaToasts } from 'pages/MFAToasts'
import { QueryKey } from 'store/types'
import { parseSsoErrors } from 'store/utils'
import { InitiateData, VerifyData, UpdateData } from 'types/mfa'
import { SsoApiError } from 'types/ssoErrors'

type SsoInitiateTotpResponse = {
  provisioning_uri: string
  status: MFAAuthenticatorStatus
  last_success: string | null
  id: string
  created: string
  type: MFAType.totp
}

const mfaApi = new MfaApi()

export const useMfaTotp = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { createMfaSuccessToast } = useMfaToasts()

  const addTotp = useMutation<
    SsoInitiateTotpResponse,
    AxiosError<SsoApiError>,
    InitiateData
  >({
    mutationFn: (data: InitiateData) =>
      mfaApi.initiate({ type: MFAType.totp, data }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      queryClient.setQueryData([QueryKey.MFA_INITIATED_TOTP], {
        provisioning_uri: data.provisioning_uri,
        id: data.id,
      })
    },
  })

  const verifyTotp = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    { id: string; data: VerifyData }
  >({
    mutationFn: ({ id, data }) =>
      mfaApi.verify({ type: MFAType.totp, id, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      dispatch(closeVisibleModal())
      createMfaSuccessToast(MFAType.totp)
    },
  })

  const updateTotp = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    { id: string; data: UpdateData }
  >({
    mutationFn: ({ id, data }) =>
      mfaApi.update({ type: MFAType.totp, id, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
    },
  })

  const removeTotp = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    string
  >({
    mutationFn: (id) => mfaApi.remove({ type: MFAType.totp, id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      dispatch(closeVisibleModal())
    },
  })

  return {
    addTotp: addTotp.mutate,
    addTotpError: parseSsoErrors(addTotp.error),
    isAddTotpLoading: addTotp.isPending,
    resetAddTotp: addTotp.reset,
    isAddTotpSuccess: addTotp.isSuccess,
    addTotpData: queryClient.getQueryData<SsoInitiateTotpResponse>([
      QueryKey.MFA_INITIATED_TOTP,
    ]),

    verifyTotp: verifyTotp.mutate,
    verifyTotpError: parseSsoErrors(verifyTotp.error),
    isVerifyTotpLoading: verifyTotp.isPending,
    resetVerifyTotp: verifyTotp.reset,
    isVerifyTotpSuccess: verifyTotp.isSuccess,

    updateTotp: updateTotp.mutate,
    updateTotpError: parseSsoErrors(updateTotp.error),
    isUpdateTotpLoading: updateTotp.isPending,
    resetUpdateTotp: updateTotp.reset,
    isUpdateTotpSuccess: updateTotp.isSuccess,

    removeTotp: removeTotp.mutate,
    removeTotpError: parseSsoErrors(removeTotp.error),
    isRemoveTotpLoading: removeTotp.isPending,
    resetRemoveTotp: removeTotp.reset,
    isRemoveTotpSuccess: removeTotp.isSuccess,
  }
}
