import React, { useContext } from 'react'
import { TextInput_Default } from '@ubnt/ui-components/aria/experimental'
import SearchIcon from '@ubnt/icons/SearchIcon'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { StyledLoader } from 'pages/request/styles'
import { RequestsContext } from './RequestsContext'

export const RequestsSearch: React.FC = () => {
  const { isMaxMobileXLarge } = useContext(MediaSizingContext)
  const { handleSearch, userSearchValue, isSearchLoading } =
    useContext(RequestsContext)

  return (
    <TextInput_Default
      onChange={handleSearch}
      value={userSearchValue}
      width={isMaxMobileXLarge ? 'full' : 220}
      placeholder="Search"
      symbol={<SearchIcon />}
      control={
        isSearchLoading ? (
          <StyledLoader variant="primary" type="spinner" />
        ) : null
      }
    />
  )
}
