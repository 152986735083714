import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import { GridRow } from './Invoices.styles'

export const GridHeaderRow = () => {
  return (
    <StyledGridRow $noBorder>
      <Text variant="heading-medium" color="text-1">
        <FormattedMessage id="COMMON_LABEL_DATE_TIME" />
      </Text>
    </StyledGridRow>
  )
}

const StyledGridRow = styled(GridRow)`
  :hover {
    background-color: ${({ theme }) => theme.neutral00};
  }
`
