import { UAParser } from 'ua-parser-js'

export const getIsIOS = (useragent: string) => {
  return (
    UAParser(useragent).os.name === 'iOS' ||
    UAParser(useragent).os.name === 'macOS'
  )
}

export const getIsAndroid = (useragent: string) => {
  return UAParser(useragent).os.name === 'Android'
}
