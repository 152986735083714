import React, { useContext } from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { StripeStatus } from 'sharedConstants'
import { CountryFlag } from 'components/CountryFlag'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'
import { StripeInvoice } from 'features/subscriptions/module/types'
import styled from 'theme/styled'
import { formatCurrencyNumberOptions } from 'utils/formatCurrencyNumberOptions'
import { InvoicePill } from './InvoicePill'
import { ValueCell } from '../Invoices.styles'
import { GridRow } from './InvoicesTable.styles'
import { getAmount } from './utils'
import { InvoiceContext } from '../../InvoiceContext'
import { ParsedInvoice } from '../types'

type Props = ParsedInvoice & {
  invoice: StripeInvoice
}

export const InvoicesTableRow: React.FC<Props> = ({
  name,
  date,
  billedCard,
  subscriptionName,
  status,
  total,
  currency,
  invoice,
}) => {
  const intl = useIntl()
  const { handleOpenInvoice } = useContext(InvoiceContext)
  const { orgToFetch } = useContext(OrganizationContext)

  return (
    <GridRow $isAdmin={!!orgToFetch}>
      <InvoicePill variant={status} />
      <ValueCell>{subscriptionName}</ValueCell>
      <ValueCell className="extra-data">{name}</ValueCell>
      <ValueCell className="extra-data">{date}</ValueCell>
      {!orgToFetch && (
        <ValueCell
          className="extra-data"
          color={status === StripeStatus.OPEN ? 'red-06' : 'inherit'}
        >
          {billedCard}
        </ValueCell>
      )}
      <AmountCell>
        <CountryFlag countryCode={invoice.region} noMargin />
        {intl.formatNumber(
          getAmount(total, currency),
          formatCurrencyNumberOptions(currency)
        )}
      </AmountCell>
      {!orgToFetch && (
        <StyledButton
          variant="link"
          onClick={() => {
            handleOpenInvoice?.(invoice)
          }}
        >
          <FormattedMessage id="COMMON_ACTION_VIEW" />
        </StyledButton>
      )}
    </GridRow>
  )
}

const AmountCell = styled(ValueCell)`
  display: flex;
  gap: 4px;
`

const StyledButton = styled(Button)`
  padding: 0;
`
