import React from 'react'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { Amount } from './tableCells/Amount'
import { BillingDate } from './tableCells/BillingDate'
import { PaymentMethod } from './tableCells/PaymentMethod'
import { SubscriptionServicePopover } from './tableCells/SubscriptionServicePopover'

interface Props {
  subscription: FullSubscription
  group: string
}

export const CommonColumns: React.FC<Props> = ({ subscription, group }) => {
  const {
    isSuspended,
    current_period_end,
    upcomingInvoice,
    region,
    upcomingAmount,
    latestInvoice,
    currency,
    cancelAtPeriodEnd,
    status,
  } = subscription

  return (
    <>
      <BillingDate
        isSuspended={isSuspended}
        periodEnd={current_period_end}
        upcomingInvoice={upcomingInvoice}
      />
      <PaymentMethod subscription={subscription} group={group} />
      <Amount
        region={region}
        amount={upcomingAmount}
        currency={latestInvoice?.currency ?? currency}
        isCanceled={cancelAtPeriodEnd || status === 'canceled'}
      />
      <SubscriptionServicePopover group={group} subscription={subscription} />
    </>
  )
}
