import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StatusBar } from '@ubnt/ui-components'
import styled from 'theme/styled'
import useFakeStatus from 'utils/fakeStatusHook'

interface Props {
  fileName?: string
  isUploadingPicture?: boolean
  onClose?: () => void
}

const ProfilePictureUpload: React.FC<Props> = ({
  fileName,
  isUploadingPicture,
}) => {
  const [progress] = useFakeStatus(isUploadingPicture)

  return (
    <Container>
      <StatusContainer>
        <FormattedMessage
          id="SETTINGS_PICTURE_UPLOAD_MODAL_PROGRESS_UPLOADING"
          values={{ fileName }}
          tagName="div"
        />
        <Progress>{`${progress}%`}</Progress>
      </StatusContainer>
      <StatusBar value={progress} />
    </Container>
  )
}

export default ProfilePictureUpload

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  border: 1px dashed ${(p) => p.theme.neutral08};
  border-radius: 4px;
  color: ${(p) => p.theme.neutral08};
`

const StatusContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  > :first-child {
    overflow-wrap: anywhere;
  }
`

const Progress = styled.div`
  margin-left: auto;
`
