import React from 'react'
import styled from '@emotion/styled'
import { Text } from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'

export const MfaFeedbackInfo: React.FC<{
  titleMessageId: string
  descriptionMessageId: string
}> = ({ titleMessageId, descriptionMessageId }) => {
  return (
    <Wrapper>
      <Text variant="heading-large">
        <FormattedMessage id={titleMessageId} />
      </Text>
      <Text>
        <FormattedMessage id={descriptionMessageId} />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
