import { api } from 'api'
import { handle } from 'redux-pack'
import { ApiUpdateError, ReduxAction, RootState } from 'types/types'

export const dataKey = 'authReset'

export interface AuthResetState {
  errors: ApiUpdateError
  isLoading: boolean
  isEmailSent: boolean
  isVerified: boolean
  isPasswordChanged: boolean
}

const initialState: AuthResetState = {
  errors: {},
  isLoading: false,
  isEmailSent: false,
  isVerified: false,
  isPasswordChanged: false,
}

interface ResetEmailPayload {
  email: string
  captcha: string
  site_key: string
}

const SEND_RESET_PASSWORD = `${dataKey}/SEND_RESET_PASSWORD`
export const sendResetPassword = (data: ResetEmailPayload) => ({
  type: SEND_RESET_PASSWORD,
  promise: api.sendResetPasswordEmail(data),
})

const VERIFY_RESET_PASSWORD = `${dataKey}/VERIFY_RESET_PASSWORD`
export const verifyResetPassword = (uuid: string) => ({
  type: VERIFY_RESET_PASSWORD,
  promise: api.verifyResetRequest(uuid),
})

const RESET_PASSWORD = `${dataKey}/RESET_PASSWORD`
export const resetPassword = (uuid: string, newPassword: string) => ({
  type: RESET_PASSWORD,
  promise: api.resetPassword(uuid, newPassword),
})

const RESET_API_ERRORS = `${dataKey}/RESET_API_ERRORS`
export const resetApiErrors = () => ({
  type: RESET_API_ERRORS,
})

export const selectIsEmailSent = (state: RootState) =>
  state[dataKey].isEmailSent

export const selectIsLoading = (state: RootState) => state[dataKey].isLoading

export const selectErrors = (state: RootState) => state[dataKey].errors

export const selectIsPasswordChanged = (state: RootState) =>
  state[dataKey].isPasswordChanged

export const authResetReducer = (state = initialState, action: ReduxAction) => {
  const { type, payload } = action
  switch (type) {
    case SEND_RESET_PASSWORD: {
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, errors: {}, isLoading: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => ({ ...prevState, isEmailSent: true }),
        failure: (prevState) => ({
          ...prevState,
          errors: payload?.response?.data,
        }),
      })
    }
    case VERIFY_RESET_PASSWORD: {
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, errors: {}, isLoading: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => ({ ...prevState, isVerified: true }),
        failure: (prevState) => ({
          ...prevState,
          errors: payload?.response?.data,
        }),
      })
    }
    case RESET_PASSWORD: {
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, errors: {}, isLoading: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => ({ ...prevState, isPasswordChanged: true }),
        failure: (prevState) => {
          if (payload.response.status === 404) {
            return {
              ...prevState,
              errors: { detail: 'Request not found or expired' },
            }
          }
          return {
            ...prevState,
            errors: payload?.response?.data,
          }
        },
      })
    }
    case RESET_API_ERRORS:
      return {
        ...state,
        errors: {},
      }
    default:
      return state
  }
}
