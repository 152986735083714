import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { MobileNavigation } from 'components/navigation/MobileNavigation'
import { UserPopover } from 'features/user-popover/ui/UserPopover'
import styled from 'theme/styled'
import { SettingsLink } from 'types/types'
import { Hamburger, HamburgerWrapper } from './Hamburger'
import {
  MobileDivider,
  MobileItems,
  MobileMenuWrap,
  NavShadow,
  NavWrapper,
} from './styles'
import './transition.css'

export const Mobile: React.FC<{
  items?: SettingsLink[]
  setMobileNavOpen?: (value: boolean) => void
  isAuthenticated?: boolean
  mobileNavOpen?: boolean
}> = ({ setMobileNavOpen, isAuthenticated, mobileNavOpen }) => {
  const toggleMenu = () => {
    setMobileNavOpen!(!mobileNavOpen)
  }

  return (
    <MobileMenuWrap>
      {isAuthenticated && <UserPopover />}

      <MobileDivider />

      <HamburgerCursor>
        <HamburgerWrapper onClick={toggleMenu}>
          <Hamburger data-role="hamburger" active={mobileNavOpen} />
        </HamburgerWrapper>
      </HamburgerCursor>

      <CSSTransition
        in={mobileNavOpen}
        timeout={200}
        classNames="mobileNav"
        unmountOnExit
      >
        <>
          <MobileItems>
            <NavWrapper>
              <MobileNavigation />
            </NavWrapper>
          </MobileItems>
          <NavShadow onClick={toggleMenu} />
        </>
      </CSSTransition>
    </MobileMenuWrap>
  )
}

const HamburgerCursor = styled('div')`
  cursor: pointer;
  z-index: 9999;
`
