import React, { SetStateAction, useCallback, useContext, useState } from 'react'
import Button from '@ubnt/ui-components/Button/Button'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ZendeskTicketWithComments,
  TicketStatus,
  TicketType,
  HeaderTab,
} from 'api/zendesk/types'
import { FlexWrapper } from 'components/SharedComponents'
import { RequestSubject } from 'pages/requests/RequestSubject'
import { RequestsContext } from 'pages/requests/RequestsContext'
import { useClosedTicketsQuery } from 'store/queries/useClosedTicketsQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import styled from 'theme/styled'
import { getFormattedTime } from '../requests/utils'
import { SecondaryText } from './styles'

const {
  api: { accountBE },
} = __CONFIG__

export const RequestTitle: React.FC<
  ZendeskTicketWithComments & {
    setTicket: React.Dispatch<
      SetStateAction<{
        loading: boolean
        ticket?: ZendeskTicketWithComments
      }>
    >
  }
> = ({ status, type, id, created_at, subject, setTicket }) => {
  const intl = useIntl()
  const { createNotification } = useContext(ToastContext)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const { refetchClosedTickets } = useClosedTicketsQuery()
  const { statusToShow } = useContext(RequestsContext)

  const createGenericErrorNotfication = useCallback(() => {
    createNotification(
      <BasicToast
        stateIndicator="danger"
        title={<FormattedMessage id="SUPPORT_SUBMIT_FAILED_TOAST_TITLE" />}
        details={<FormattedMessage id="REQUEST_COMMENT_ERROR" />}
      />
    )
  }, [createNotification])

  const handleMarkAsClosed = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setIsSubmitLoading(true)
      const parsedPath = accountBE.paths.zendeskMarkTicketAsClosed.replace(
        '{ticketId}',
        id
      )
      const url = new URL(parsedPath, accountBE.base)

      await axios
        .put(url.href, {}, { withCredentials: true })
        .then(({ data }) => {
          setTicket(() => ({
            loading: false,
            ticket: data?.ticket,
          }))
          refetchOpenTickets()
          refetchClosedTickets()
          setIsSubmitLoading(false)
        })
        .catch(() => {
          setIsSubmitLoading(false)
          createGenericErrorNotfication()
        })
    },
    [
      id,
      setTicket,
      refetchOpenTickets,
      refetchClosedTickets,
      createGenericErrorNotfication,
    ]
  )

  const canCloseTicket =
    status !== TicketStatus.Closed && type !== TicketType.Problem

  const isCCd = statusToShow === HeaderTab.CCD

  return (
    <FlexWrapper
      justifyContent="space-between"
      gap={20}
      alignItems="flex-start"
    >
      <FlexWrapper column>
        <TicketTitle title={subject}>
          <RequestSubject subject={subject} id={id} />
        </TicketTitle>
        <SecondaryText>
          <span className="ticket-id">#{id}</span>
          <span className="created-at">
            <FormattedMessage
              id="REQUEST_CREATED_AT"
              values={{ date: getFormattedTime(created_at, intl, true) }}
            />
          </span>
          {isCCd && status !== TicketStatus.Closed && (
            <span className="active">
              <FormattedMessage id="SUPPORT_MY_REQUESTS_TAB_ACTIVE" />
            </span>
          )}
          {isCCd && status === TicketStatus.Closed && (
            <span className="closed">
              <FormattedMessage id="SUPPORT_MY_REQUESTS_TAB_CLOSED" />
            </span>
          )}
        </SecondaryText>
      </FlexWrapper>

      {canCloseTicket && (
        <Button
          variant="secondary"
          size="medium"
          onClick={handleMarkAsClosed}
          loader={isSubmitLoading ? 'dots' : undefined}
        >
          <FormattedMessage id="REQUEST_CLOSE_NOW" />
        </Button>
      )}
    </FlexWrapper>
  )
}

const TicketTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
