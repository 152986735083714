import React from 'react'
import { KeyholeSecurityIcon } from '@ubnt/icons'
import { motifPalette } from 'theme'
import ProfilePicture from 'features/profile-picture/ui/ProfilePicture'
import { useMotif } from 'motif/useMotif'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import Card from './Card'
import CardSkeleton from './CardSkeleton'

const Cards: React.FC = () => {
  const { profileData } = useSsoProfileQuery()

  const motif = useMotif()

  if (!profileData)
    return (
      <TwoEqualColumns>
        {[...Array(2)].map((_, index) => (
          <CardSkeleton key={index.toString()} />
        ))}
      </TwoEqualColumns>
    )

  const isProtected = profileData.twofa_enabled

  const cards = [
    {
      headingId: 'HOME_CARD_PERSONALIZE_HEADER',
      bodyId: 'HOME_CARD_PERSONALIZE_TEXT',
      action: {
        textId: 'HOME_CARD_PERSONALIZE_ACTION',
        url: '/profile',
      },
      icon: <StyledProfilePicture isClickable={false} dimensions={42} />,
    },
    {
      headingId: isProtected
        ? 'HOME_CARD_PROTECTED_HEADER'
        : 'HOME_CARD_UNPROTECTED_HEADER',
      bodyId: 'HOME_CARD_PROTECTED_TEXT',
      action: {
        textId: isProtected
          ? 'HOME_CARD_PROTECTED_ACTION'
          : 'HOME_CARD_UNPROTECTED_ACTION',
        url: isProtected ? '/security' : '/security?addmfa=true',
      },
      icon: isProtected ? (
        <KeyholeSecurityIcon
          variant="fill"
          color={motifPalette[motif].green06}
          size={45}
        />
      ) : (
        <KeyholeSecurityIcon
          variant="fill"
          color={motifPalette[motif].red06}
          size={45}
        />
      ),
    },
  ]

  return (
    <TwoEqualColumns>
      {cards.map((card) => (
        <Card key={card.headingId} cardInfo={card} />
      ))}
    </TwoEqualColumns>
  )
}

export default Cards

const TwoEqualColumns = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${({ theme }) => theme.media.large}) {
    grid-template-columns: 1fr;
  }
`

const StyledProfilePicture = styled(ProfilePicture)`
  margin-right: 0;
`
