import { regionsWithTaxIdSupport } from '../config'
import { auStripeCountries } from './countries/auStripeCountries'
import { caStripeCountries } from './countries/caStripeCountries'
import { euStripeCountries } from './countries/euStripeCountries'
import { gbStripeCountries } from './countries/gbStripeCountries'
import { rowStripeCountries } from './countries/rowStripeCountries'
import { usStripeCountries } from './countries/usStripeCountries'
import {
  StripeCountry,
  StripeCountryCode,
  StripeRegion,
  StripeRegionCode,
} from './types'

const {
  featureFlags: { areExtraStripeRegionsEnabled },
} = __CONFIG__

const extraStripeRegions = [{ code: StripeRegionCode.BR, name: 'Brazil' }]

const validStripeRegions: Readonly<StripeRegion[]> = [
  {
    code: StripeRegionCode.US,
    name: 'United States',
  },
  {
    code: StripeRegionCode.CA,
    name: 'Canada',
  },
  {
    code: StripeRegionCode.GB,
    name: 'United Kingdom',
  },
  {
    code: StripeRegionCode.EU,
    name: 'Europe',
  },
  {
    code: StripeRegionCode.AU,
    name: 'Australia',
  },
  {
    code: StripeRegionCode.SG,
    name: 'Singapore',
  },
  {
    code: StripeRegionCode.AE,
    name: 'United Arab Emirates',
  },
  {
    code: StripeRegionCode.ROW,
    name: 'Global',
  },
  {
    code: StripeRegionCode.JP,
    name: 'Japan',
  },
  { code: StripeRegionCode.MX, name: 'Mexico' },

  ...(areExtraStripeRegionsEnabled ? extraStripeRegions : []),
]

export const validStripeRegionMap = new Map<StripeRegionCode, StripeRegion>(
  validStripeRegions.map((x) => [x.code, x])
)

const extraStripeCountries = [
  {
    code: 'br',
    name: 'Brazil',
    region: StripeRegionCode.BR,
    taxId: [
      {
        value: 'br_cnpj',
        taxFormat: /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/,
        taxPlaceholder: '00.123.456/7890-12',
      },
      {
        value: 'br_cpf',
        taxFormat: /\d{3}\.\d{3}\.\d{3}-\d{2}/,
        taxPlaceholder: '123.456.789-09',
      },
    ] as StripeCountry['taxId'],
  },
]

export const validStripeCountries: Readonly<StripeCountry[]> = [
  ...euStripeCountries,
  ...gbStripeCountries,
  ...auStripeCountries,
  ...usStripeCountries,
  ...caStripeCountries,
  ...rowStripeCountries,
  {
    code: 'sg',
    name: 'Singapore',
    region: StripeRegionCode.SG,
  },
  {
    code: 'ae',
    name: 'United Arab Emirates',
    region: StripeRegionCode.AE,
  },
  {
    code: 'jp',
    name: 'Japan',
    region: StripeRegionCode.JP,
  },
  {
    code: 'mx',
    name: 'Mexico',
    region: StripeRegionCode.MX,
  },
  ...(areExtraStripeRegionsEnabled ? extraStripeCountries : []),
].sort((a, b) => a.name.localeCompare(b.name)) as StripeCountry[]

export const validStripeTaxIdCountries = validStripeCountries.filter(
  ({ region, taxId }) =>
    regionsWithTaxIdSupport.includes(region) && taxId?.length // no taxId length means that Stripe does not currently supports this country's tax id
)

export const validStripeCountryMap = new Map<StripeCountryCode, StripeCountry>(
  validStripeCountries.map((x) => [x.code, x])
)
