import React from 'react'
import styled from '@emotion/styled'
import type Stripe from 'stripe'
import { TruncatedText } from 'components/TruncatedText'
import { getSubscriptionProductLine } from 'features/subscriptions/fullSubscriptions/utils'
import { getSubscriptionIcon } from 'pages/subscriptions/components/subscriptions/utils'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'

interface Props {
  productId: string
  invoice: Stripe.Invoice
  overrideMediaQueries?: boolean
}

export const SubscriptionNameCell: React.FC<Props> = ({
  productId,
  invoice,
  overrideMediaQueries = false,
}) => {
  const { getProduct } = useBillingProductsQuery()
  const product = getProduct(productId)

  const metadataName =
    invoice.metadata?.ui_product_line ||
    invoice.lines?.data?.[0]?.metadata.ui_product_line

  const subscriptionName = getSubscriptionProductLine(
    product?.name,
    metadataName
  )

  const subscriptionIcon = getSubscriptionIcon(
    subscriptionName,
    '32px',
    'neutral',
    '0'
  )

  if (overrideMediaQueries) {
    return (
      <div>
        <TruncatedText text={subscriptionName} truncateAt={22} />
      </div>
    )
  }

  return (
    <>
      <MobileWrapper>{subscriptionIcon}</MobileWrapper>
      <DesktopWrapper>
        <TruncatedText text={subscriptionName} truncateAt={22} />
      </DesktopWrapper>
    </>
  )
}

const MobileWrapper = styled.div`
  @media (min-width: 440px) {
    display: none;
  }
`

const DesktopWrapper = styled.div`
  white-space: nowrap;
  @media (max-width: 439px) {
    display: none;
  }
`
