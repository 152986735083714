import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { DropdownOption } from '@ubnt/ui-components/Dropdown'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import { UmrDevice } from 'api/umrDevices'
import { Bubble } from 'components/support/Bubble'
import { DeviceDropdown } from 'components/support/DeviceDropdown'
import { DeviceTile } from 'components/support/DeviceTile'
import { TilesLoader } from 'components/support/TilesLoader'
import { BubbleWrapper, TilesWrapper } from 'components/support/Wrappers'
import { useUmrDevicesQuery } from 'store/queries/useUmrDevicesQuery'
import { formatMac } from 'utils/formatMac'
import { DROPDOWN_BREAKPOINT, ELEMENT_COUNT } from '../constants'
import { console as device } from './config/ConfigOptions'
import { config } from './config/formConfig'
import { Console, ZDFormValueType } from './config/types'
import { BubbleProps, SupportConsole } from './types'

const umrImg =
  'https://static.ui.com/fingerprint/ui/icons/1606c40f-e9b7-46d9-aeaf-e9afe52416e5_101x101.png'

interface UmrDeviceToRender extends ZDFormValueType {
  mac?: string
  deviceId?: string
}

export const ConsoleBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.umrSpecificIssue])
  const { umrDevices, isUmrDevicesLoading } = useUmrDevicesQuery()

  const userConsoles: UmrDeviceToRender[] = useMemo(
    () =>
      umrDevices.map((console: UmrDevice) => ({
        id: Console.umr,
        mac: console.mac,
        label: console.name ? console.name : `UMR - ${formatMac(console.mac)}`,
        deviceId: console.id,
        icon: <img src={umrImg} style={{ width: '60px', height: '60px' }} />,
      })),
    [umrDevices]
  )

  const onChange = (option: DropdownOption) => {
    const selectedConsole = userConsoles.find(
      ({ mac }) => mac === option.additionalInfo
    )
    if (selectedConsole) {
      return handleClick(selectedConsole)
    }
    return handleClick({ id: Console.umrUnlisted, ...option })
  }

  const getNextSection = (): string | undefined =>
    config.console.nextSection?.section

  const handleClick = useCallback(
    (value?: string | boolean | SupportConsole) => {
      const section = getNextSection()
      handleChange('console', value, section)
    },
    [handleChange]
  )

  useEffect(() => {
    if (!isUmrDevicesLoading && !userConsoles.length && !values.console?.id) {
      const unlistedOption = device[device.length - 1]
      handleClick(unlistedOption)
    }
  }, [
    handleClick,
    isUmrDevicesLoading,
    userConsoles.length,
    values.console?.id,
  ])

  if (isUmrDevicesLoading) {
    return (
      <BubbleWrapper $isVisible ref={ref}>
        <SkeletonLoader>
          <Text width={220} />
        </SkeletonLoader>
        <TilesLoader numberOfTiles={ELEMENT_COUNT} />
      </BubbleWrapper>
    )
  }

  const renderDevices = () => {
    const unlistedOption = device[device.length - 1]
    const allConsoleOptions: UmrDeviceToRender[] = [
      ...userConsoles,
      unlistedOption,
    ]
    if (allConsoleOptions.length > DROPDOWN_BREAKPOINT) {
      return (
        <DeviceDropdown
          userConsoles={userConsoles}
          otherOption={unlistedOption}
          handleChange={onChange}
          value={values.console}
        />
      )
    }

    return (
      <TilesWrapper>
        {allConsoleOptions.map((el) => (
          <DeviceTile
            key={`${el.id}-${el.mac}`}
            icon={el.icon}
            label={el.label}
            onClick={() => handleClick(el)}
            isActive={
              el.id === Console.umrUnlisted
                ? el.id === values.console?.id
                : el.mac === values.console?.mac
            }
          />
        ))}
      </TilesWrapper>
    )
  }

  if (!isUmrDevicesLoading && !userConsoles.length && values.console?.id) {
    return null
  }

  return (
    <Bubble question={config.console.fieldTitle} ref={ref}>
      {renderDevices()}
    </Bubble>
  )
}
