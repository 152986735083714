import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { MultiSelectionIcon } from '@ubnt/icons'
import { isAxiosError } from 'axios'
import { typography } from 'theme'
import { ErrCode, ErrMessage, HeaderTab, TicketStatus } from 'api/zendesk/types'
import { FlexWrapper } from 'components/SharedComponents'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import styled from 'theme/styled'
import { getFormattedTime } from './utils'
import { RequestsContext } from './RequestsContext'
import { RequestsError } from './RequestsError'
import { EmptyResults } from './EmptyResults'
import { RequestsLoader } from './SkeletonLoaders/RequestsLoader'
import { RequestSubject } from './RequestSubject'

export const RequestsGrid: React.FC = () => {
  const intl = useIntl()
  const {
    paginatedTickets,
    areTicketsLoading,
    hasMultiplePages,
    ticketsError,
    searchData,
    statusToShow,
  } = useContext(RequestsContext)
  const { openTicketsError } = useOpenTicketsQuery()

  if (areTicketsLoading) {
    return <RequestsLoader />
  }

  if (
    ticketsError &&
    isAxiosError(openTicketsError) &&
    openTicketsError.response?.data.code !== ErrCode.NOT_FOUND &&
    openTicketsError.response?.data.message !==
      ErrMessage.USER_NOT_FOUND_IN_ZENDESK
  ) {
    return <RequestsError />
  }

  if (!!searchData.error || (!paginatedTickets.length && !areTicketsLoading)) {
    return <EmptyResults />
  }

  const isCCd = statusToShow === HeaderTab.CCD

  return (
    <Fragment>
      <TicketsGridRow>
        <ColumnTitle>
          <FormattedMessage id="SUPPORT_MY_REQUESTS_DESCRIPTION_AND_ID" />
        </ColumnTitle>
        <ColumnTitle className="date">
          <FormattedMessage id="SUPPORT_MY_REQUESTS_CREATED" />
        </ColumnTitle>
        <ColumnTitle className="date">
          <FormattedMessage id="SUPPORT_MY_REQUESTS_LATEST_ACTIVITY" />
        </ColumnTitle>
      </TicketsGridRow>
      {paginatedTickets.map((ticket, i) => {
        return (
          <HoverDiv key={ticket.id}>
            <Link to={`/requests/${ticket.id}`}>
              <TicketsGridRow
                $omitBorder={
                  !hasMultiplePages && i === paginatedTickets.length - 1
                }
                $extraPadding
              >
                <TicketTitle>
                  <div className="title">
                    {isCCd && (
                      <FlexWrapper alignItems="center" gap={4} height={20}>
                        <MultiSelectionIcon variant="fill" />
                        <span className="cc">
                          <FormattedMessage id="SUPPORT_MY_REQUESTS_CC" />
                        </span>
                      </FlexWrapper>
                    )}
                    <RequestSubject subject={ticket.subject} id={ticket.id} />
                  </div>
                  <TicketId className="id" $isCC={isCCd}>
                    #{ticket.id}
                    {isCCd && ticket.status !== TicketStatus.Closed && (
                      <span className="active">
                        <FormattedMessage id="SUPPORT_MY_REQUESTS_TAB_ACTIVE" />
                      </span>
                    )}
                    {isCCd && ticket.status === TicketStatus.Closed && (
                      <span className="closed">
                        <FormattedMessage id="SUPPORT_MY_REQUESTS_TAB_CLOSED" />
                      </span>
                    )}
                  </TicketId>
                  <div className="date-mobile">
                    {getFormattedTime(ticket.updated_at, intl, true)}
                  </div>
                </TicketTitle>
                <span className="date">
                  {getFormattedTime(ticket.created_at, intl, true)}
                </span>
                <span className="date">
                  {getFormattedTime(ticket.updated_at, intl, true)}
                </span>
              </TicketsGridRow>
            </Link>
          </HoverDiv>
        )
      })}
    </Fragment>
  )
}

const ColumnTitle = styled.div`
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text1};

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    &.date {
      display: none;
    }
  }
`

const TicketsGridRow = styled.div<{
  $omitBorder?: boolean
  $extraPadding?: boolean
}>`
  width: 100%;
  position: relative;
  max-width: 920px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) 120px 120px;
  column-gap: 16px;
  padding: ${({ $extraPadding }) => ($extraPadding ? '8px 16px' : '8px 0')};
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral03}`};

  ${({ $omitBorder }) =>
    $omitBorder &&
    `
      border-bottom: none;
  `}

  span.date {
    font: ${typography['desktop-body']};
    color: ${({ theme }) => theme.text3};
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    grid-template-columns: minmax(0, 1fr) 90px;

    span.date {
      display: none;
    }
  }
`

const TicketTitle = styled.div`
  display: flex;
  flex-direction: column;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};

  .date-mobile {
    display: none;
  }

  .title {
    width: 100%;
    display: flex;
    .cc {
      font: ${typography['desktop-typography-heading-small']};
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 4px;
      font: ${typography['desktop-typography-body']};
    }
  }

  .id {
    display: flex;
    gap: 8px;
    font: ${typography['desktop-caption']};
    color: ${({ theme }) => theme.text3};

    .active {
      color: ${({ theme }) => theme.green06};
    }

    .closed {
      color: ${({ theme }) => theme.text3};
    }

    .active,
    .closed {
      &:before {
        content: '·';
        margin-right: 8px;
        color: ${({ theme }) => theme.text3};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    .date-mobile {
      display: flex;
      font: ${typography['desktop-caption']};
      color: ${({ theme }) => theme.text3};
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    .title {
      span {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
`

const HoverDiv = styled.div`
  margin: 0 -16px;

  div:hover {
    background-color: ${({ theme }) => theme.neutral01};
  }
`

const TicketId = styled.div<{ $isCC?: boolean }>`
  ${({ $isCC }) => $isCC && 'margin-left: 45px;'}
`
