import React, { useEffect, useMemo, useRef } from 'react'
import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'
import { orderIssue } from './config/ConfigOptions'
import { config } from './config/formConfig'
import { Country, FormValue, OrderIssue } from './config/types'
import { BubbleProps, SupportFormState } from './types'

export const OrderIssueBubble: React.FC<BubbleProps> = ({
  handleChange,
  shouldScroll,
  values,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.country])

  const getNextSection = (value: FormValue): string | undefined => {
    const nextSectionData = config.orderIssue.nextSection

    if (nextSectionData && nextSectionData.length > 1) {
      const section = nextSectionData.find((nextSection) => {
        if (nextSection.condition?.field === 'orderIssue') {
          return nextSection.condition?.value.includes(value)
        }
        return nextSection.condition?.value.includes(
          values[nextSection.condition?.field as keyof SupportFormState]
        )
      })?.section
      return section
    }
    return nextSectionData?.[0].section
  }

  const handleClick = (value?: string) => {
    const section = getNextSection(value)
    handleChange('orderIssue', value, section)
  }

  const filteredIssues = useMemo(() => {
    if (values.country === Country.usa) {
      return orderIssue
    }
    return orderIssue.filter(({ id }) => id !== OrderIssue.changeOrder)
  }, [values.country])

  return (
    <Bubble question={config.orderIssue.fieldTitle} ref={ref}>
      <TilesWrapper>
        {filteredIssues.map(({ label, id }) => (
          <QuestionTile
            key={id}
            label={label}
            onClick={() => handleClick(id)}
            isActive={values.orderIssue === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
