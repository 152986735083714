import React, { useEffect, useRef } from 'react'
import { Bubble } from 'components/support/Bubble'
import { Description } from 'components/support/Description'
import { BubbleProps, SupportConsole } from './types'

export const DescriptionBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldTouched,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.userType])

  const onChange = (value?: string | boolean | SupportConsole) => {
    handleChange('description', value)
  }

  return (
    <Bubble question={config.description.fieldTitle} ref={ref}>
      <Description
        handleChange={onChange}
        value={values.description || ''}
        error={touched?.description && errors?.description}
        onBlur={() => setFieldTouched?.('description', true)}
      />
    </Bubble>
  )
}
