import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { SessionsApi } from 'api/sessions'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useSessionsQuery = (api: SessionsApi = new SessionsApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const sessions = useQuery({
    queryKey: [QueryKey.SESSIONS],
    queryFn: api.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const currentSession = useMemo(
    () => sessions.data?.find((session) => session.is_current_session),
    [sessions.data]
  )

  const nonCurrentSessions = useMemo(
    () => sessions.data?.filter((session) => !session.is_current_session),
    [sessions.data]
  )

  const currentSessionLocation =
    currentSession?.location?.countryCode?.toLowerCase() ?? null

  const hasMultipleSessions = (sessions.data ?? []).length > 1

  return {
    sessions: sessions.data,
    currentSession,
    nonCurrentSessions,
    currentSessionLocation,
    hasMultipleSessions,
    isSessionsLoading: sessions.isFetching,
  }
}
