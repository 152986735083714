import React from 'react'
import { Button } from '@ubnt/ui-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import authorizedRedirect from 'components/authorizedRedirect'
import PublicPage from 'pages/PublicPage'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { RootState } from 'types/types'
import { getIsDevOrStg } from 'utils/getIsDevOrStg'
import { ButtonContainer, Description } from './styles'
import { selectIsAuthenticated } from '../modules/auth'

interface Props {
  isAuthenticated: boolean
}

const RegisterSuccess: React.FC<Props> = ({ isAuthenticated }) => {
  const intl = useIntl()
  const isDevOrStg = getIsDevOrStg(window.location.origin)
  const { profileData } = useSsoProfileQuery()

  if (isAuthenticated) return <Redirect to="/" />

  const email = profileData?.email

  return (
    <PublicPage
      title={intl.formatMessage({ id: 'COMMON_AUTH_REGISTER_SUCCESS_HEADER' })}
    >
      <Wrapper>
        <StyledDescription>
          {email ? (
            <FormattedMessage
              id="COMMON_AUTH_REGISTER_SUCCESS_DESCRIPTION_WITH_EMAIL"
              values={{
                b: (email) => <strong>{email}</strong>,
                email: email,
              }}
            />
          ) : (
            <FormattedMessage id="COMMON_AUTH_REGISTER_SUCCESS_DESCRIPTION" />
          )}
        </StyledDescription>
        {isDevOrStg && (
          <StyledDescription>
            {`This is a testing environment so verification emails are only sent to @ui.com accounts.`}
          </StyledDescription>
        )}
        <ButtonContainer>
          <Link to="/login">
            <Button variant="primary" full>
              <FormattedMessage id="COMMON_AUTH_ACTION_SIGN_IN" />
            </Button>
          </Link>
        </ButtonContainer>
      </Wrapper>
    </PublicPage>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: selectIsAuthenticated(state),
})

export default authorizedRedirect(connect(mapStateToProps)(RegisterSuccess))

const Wrapper = styled.div`
  padding: 20px 0;
`

const StyledDescription = styled(Description)`
  color: ${({ theme }) => theme.text2};
  line-height: 20px;
  word-break: break-word;
`
