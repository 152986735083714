import { parseDescription } from 'features/support/utils'
import { category, productLine } from '../config/ConfigOptions'
import { SupportFormState } from '../types'

const parseField = (type: 'productLine' | 'category', valueId?: string) => {
  if (type === 'productLine') {
    return productLine.find(({ id }) => id === valueId)?.label
  }

  return category.find(({ id }) => id === valueId)?.label
}

export const createSubject = ({
  productLine,
  category,
  description,
}: SupportFormState) => {
  const parsedProductLine = parseField('productLine', productLine)
  const parsedCategory = parseField('category', category)

  const parsedDescription = parseDescription(description || '')

  if (parsedProductLine && parsedCategory) {
    return `${parsedProductLine} | ${parsedCategory}: ${parsedDescription}`
  }

  if (parsedProductLine && !parsedCategory) {
    return `${parsedProductLine}: ${parsedDescription}`
  }

  if (!parsedProductLine && parsedCategory) {
    return `${parsedCategory}: ${parsedDescription}`
  }

  return parsedDescription
}
