import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  BackupIcon,
  KeyholeSecurityIcon,
  PaymentIcon,
  QuestionMarkIcon,
  UserAccountIcon,
  MarketplaceIcon,
} from '@ubnt/icons'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'
import { useOrganizationsQuery } from 'store/queries/useOrganizationsQuery'
import { INavigationItem, ROUTES, Route } from 'types/routes'
import {
  Container,
  IconContainer,
  StyledLink,
  Wrapper,
} from './Navigation.styles'
import { hideNavigationItem } from './utils'

export const NAVIGATION_CONFIG: INavigationItem[] = [
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_PROFILE" />,
    link: ROUTES[Route.Profile],
    id: Route.Profile,
    Icon: UserAccountIcon,
    hiddenForAdmins: true,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_SECURITY" />,
    link: ROUTES[Route.Security],
    id: Route.Security,
    Icon: KeyholeSecurityIcon,
    hiddenForAdmins: true,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_PAYMENT_OPTIONS" />,
    link: ROUTES[Route.Payments],
    id: Route.Payments,
    Icon: PaymentIcon,
    hiddenForAdmins: true,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_SUBSCRIPTIONS" />,
    link: ROUTES[Route.Subscriptions],
    id: Route.Subscriptions,
    Icon: MarketplaceIcon,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_CLOUD_BACKUPS" />,
    link: ROUTES[Route.Backups],
    id: Route.Backups,
    Icon: BackupIcon,
    hiddenForAdmins: true,
  },
  {
    title: <FormattedMessage id="COMMON_SIDE_NAV_MYSUPPORT" />,
    link: ROUTES[Route.Support],
    id: Route.Support,
    Icon: QuestionMarkIcon,
    hiddenForAdmins: true,
  },
]

export const SidebarNavigation = () => {
  const { accountViewId } = useContext(OrganizationContext)
  const { accountType, isOwnerAndAdmin } = useOrganizationsQuery()

  return (
    <Container>
      <Wrapper>
        {NAVIGATION_CONFIG.map(({ link, id, Icon, title, hiddenForAdmins }) => {
          if (
            hideNavigationItem(
              accountViewId,
              accountType,
              isOwnerAndAdmin,
              hiddenForAdmins
            )
          ) {
            return null
          }
          return (
            <StyledLink to={link} key={id} activeClassName="active">
              <IconContainer>{Icon && <Icon />}</IconContainer>
              {title}
            </StyledLink>
          )
        })}
      </Wrapper>
    </Container>
  )
}
