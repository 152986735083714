import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import type Stripe from 'stripe'
import { media, radius, size, space, typography } from 'theme'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { getPaymentMethodIcon } from 'pages/payments/utils'
import styled from 'theme/styled'
import { formatUTCtoHumanReadableWithTime } from 'utils/dateTime'

interface IInvoicePaymentsSectionProps {
  invoice: StripeInvoice
}

export const InvoicePaymentsSection: React.FC<IInvoicePaymentsSectionProps> = ({
  invoice,
}) => {
  const chargeDate = useMemo(() => {
    const { charge } = invoice || {}

    if (!charge || typeof charge === 'string') {
      return null
    }

    return formatUTCtoHumanReadableWithTime(
      (invoice.charge as Stripe.Charge)?.created
    )
  }, [invoice])

  const paymentCardDetails = useMemo(() => {
    const { charge } = invoice || {}

    if (!charge || typeof charge === 'string') {
      return null
    }

    const { payment_method_details } = charge

    if (!(payment_method_details as Stripe.Charge.PaymentMethodDetails)?.card) {
      return null
    }

    const { last4, brand } =
      (payment_method_details as Stripe.Charge.PaymentMethodDetails)?.card || {}

    return {
      last4: last4 ? `•••• ${last4}` : null,
      brand: brand ?? null,
      icon: getPaymentMethodIcon(brand),
    }
  }, [invoice])

  if (!paymentCardDetails) {
    return null
  }

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="SETTINGS_PAYMENTS_PAYMENT" />
      </Title>

      <MainContainer>
        {paymentCardDetails?.icon && (
          <CardIcon src={paymentCardDetails?.icon} />
        )}

        <Container>
          <BaselineContainer>
            <CardTitle>{paymentCardDetails?.brand}</CardTitle>
            <CardDetails>{paymentCardDetails?.last4}</CardDetails>
          </BaselineContainer>
          {chargeDate && (
            <BaselineContainer>
              <Description>{chargeDate}</Description>
            </BaselineContainer>
          )}
        </Container>
      </MainContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: ${space['desktop-spacing-base-08']};

  @media (max-width: ${media.small}) {
    margin-top: ${space['desktop-spacing-base-03']};
  }
`

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`

const BaselineContainer = styled.div`
  align-items: baseline;
  display: flex;
  margin-bottom: ${space['desktop-spacing-base-01']};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${space['desktop-spacing-base-05']};

  @media (max-width: ${media.small}) {
    gap: ${space['desktop-spacing-base-03']};
  }
`

const Title = styled.h6`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-typography-heading-medium']};
  margin: 0 0 ${space['desktop-spacing-base-04']};

  @media (max-width: ${media.small}) {
    margin-bottom: ${space['desktop-spacing-base-04']};
  }
`

const CardTitle = styled(Title)`
  color: ${({ theme }) => theme.text1};
  margin: 0;
  text-transform: capitalize;

  @media (max-width: ${media.small}) {
    margin: 0 !important;
  }
`

const Description = styled.span`
  font: ${typography['desktop-caption']};
`

const CardDetails = styled(Description)`
  margin-left: ${space['desktop-spacing-base-01']};
`

const CardIcon = styled.img`
  border-radius: ${radius['desktop-radius-base']};
  height: ${size['desktop-sizing-base-10']};
  width: ${size['desktop-sizing-base-16']};
`
