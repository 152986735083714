import { useEffect, useMemo, useState } from 'react'
import isMobile from 'is-mobile'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAssuranceLevelDone,
  selectAssuranceLevel,
} from 'features/auth/modules/assuranceLevel'
import { setVisibleModal } from 'modules/modals'
import { EMAIL_INPUT_EMAIL_MODAL_ID } from 'pages/security/components/Email'
import { SETUP_MFA_MODAL_ID } from 'pages/security/components/SetupMFAModal'
import {
  TOTP_QR_CODE_MODAL_ID,
  TOTP_SECRET_CODE_MODAL_ID,
} from 'pages/security/components/TOTP'
import { SUBMIT_PASSWORD_MODAL_ID } from 'pages/security/SubmitPasswordModal'
import { useMfaPush } from 'store/mutations/mfa/useMfaPush'
import { useMfaPasskey } from 'store/mutations/mfa/useMfaPasskey'
import { useMfaTotp } from 'store/mutations/mfa/useMfaTotp'
import { BrowserUtils } from './browserUtils'

export const useAddMfaQuickFlow = () => {
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const dispatch = useDispatch()
  const { addTotp } = useMfaTotp()
  const isAssuranceLevelDone = useSelector(getAssuranceLevelDone)
  const [isAssuranceLevelInitialized, setIsAssuranceLevelInitialized] =
    useState(false)
  const isMobileDevice = isMobile()
  const { addPush } = useMfaPush()
  const { addPasskey } = useMfaPasskey()

  useEffect(() => {
    setIsAssuranceLevelInitialized(true)
  }, [assuranceLevel, isAssuranceLevelDone])

  const [addMfaState, setAddMfaState] = useState<{
    addMfa: string | null
    unlockModalShown: boolean
    mfaModalShown: boolean
  }>({
    addMfa: null,
    unlockModalShown: false,
    mfaModalShown: false,
  })

  const addMfaQuery = BrowserUtils.getQueryValue(
    window.location.toString(),
    'addmfa'
  )

  const mfaActions: Record<string, () => void> = useMemo(
    () => ({
      true: () => dispatch(setVisibleModal(SETUP_MFA_MODAL_ID)),
      push: () => addPush({}),
      totp: () => {
        addTotp({})
        dispatch(
          setVisibleModal(
            isMobileDevice ? TOTP_SECRET_CODE_MODAL_ID : TOTP_QR_CODE_MODAL_ID
          )
        )
      },
      email: () => dispatch(setVisibleModal(EMAIL_INPUT_EMAIL_MODAL_ID)),
      webAuthn: () => addPasskey({}),
    }),
    [dispatch, addPush, isMobileDevice, addPasskey, addTotp]
  )

  if (addMfaQuery) {
    setAddMfaState({ ...addMfaState, addMfa: addMfaQuery || '' })
    history.replaceState(null, '', '/security')
  }

  useEffect(() => {
    const { addMfa, unlockModalShown, mfaModalShown } = addMfaState

    if (!isAssuranceLevelInitialized) return
    if (!isAssuranceLevelDone || assuranceLevel.isLoading) return
    if (!addMfa) return
    if (mfaModalShown) return
    if (mfaActions[addMfa] === undefined) return

    if (assuranceLevel.data.level === 2 && !mfaModalShown) {
      mfaActions[addMfa]()
      setAddMfaState({
        ...addMfaState,
        mfaModalShown: true,
        unlockModalShown: true,
      })
      return
    }

    if (!unlockModalShown) {
      dispatch(setVisibleModal(SUBMIT_PASSWORD_MODAL_ID))
      setAddMfaState({ ...addMfaState, unlockModalShown: true })
      return
    }
  }, [
    addMfaState,
    assuranceLevel,
    isAssuranceLevelDone,
    dispatch,
    isAssuranceLevelInitialized,
    mfaActions,
    addTotp,
  ])
}
