import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@ubnt/ui-components/Accordion'
import { ZendeskTicketWithComments } from 'api/zendesk/types'
import { SendSafelyDropzone } from 'components/support/SendSafelyDropzone'
import { useSendSafely } from 'features/support/useSendSafely'
import { Dropzone } from 'pages/supportForm/types'
import { CreateComment } from './CreateComment'
import { UploadDocumentsLabel } from './UploadDocumentsLabel'

export const UPLOAD_ATTACHMENTS_ID = 'upload-attachments'

const RequestComment: FC<{
  ticketId: string
  setTicketWithComments: React.Dispatch<
    React.SetStateAction<{
      loading: boolean
      ticket?: ZendeskTicketWithComments | undefined
    }>
  >
}> = ({ ticketId, setTicketWithComments }) => {
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false)
  const { handleSendSafelyUpload, handleReinitialize } = useSendSafely(
    Dropzone.REQUEST,
    setIsAttachmentUploading,
    100
  )

  return (
    <>
      <StyledAccordion
        items={[
          {
            id: UPLOAD_ATTACHMENTS_ID,
            openByDefault: false,
            renderLabel: (itemExpanded, togglePanel) => (
              <UploadDocumentsLabel
                togglePanel={togglePanel}
                itemExpanded={itemExpanded}
              />
            ),
            renderContent: () => (
              <DropzoneWrapper>
                <SendSafelyDropzone isRequestDropzone id={Dropzone.REQUEST} />
              </DropzoneWrapper>
            ),
          },
        ]}
        variant="tertiary"
      />
      <CreateComment
        ticketId={ticketId}
        setTicketWithComments={setTicketWithComments}
        handleSendSafelyUpload={handleSendSafelyUpload}
        handleDropzoneReinitialize={handleReinitialize}
        isAttachmentUploading={isAttachmentUploading}
      />
    </>
  )
}

export default RequestComment

const StyledAccordion = styled(Accordion)`
  & > li {
    border-bottom: none !important;
  }
`

const DropzoneWrapper = styled.div`
  width: 100%;
`
