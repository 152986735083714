import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { BillingUiApi } from 'api/billing/billingUi'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'

export const useBillingUiBusinessProfileQuery = (
  api: BillingUiApi = new BillingUiApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const businessProfiles = useQuery({
    queryKey: [QueryKey.UI_BUSINESS_PROFILE],
    queryFn: api.getAllBusinessProfiles,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const uiBusinessProfiles = useMemo(() => {
    if (!businessProfiles.data) {
      return []
    }

    const { data } = businessProfiles

    return Object.entries(data).map(([region, value]) => ({ region, value }))
  }, [businessProfiles])

  return {
    uiBusinessProfiles,
    areBusinessProfilesLoading: businessProfiles.isLoading,
  }
}
