import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  dataKey as assuranceLevelDataKey,
  assuranceLevelReducer,
} from 'features/auth/modules/assuranceLevel'
import { dataKey as authDataKey, authReducer } from 'features/auth/modules/auth'
import {
  dataKey as authResendVerificationDataKey,
  authResendVerificationReducer,
} from 'features/auth/modules/resendVerification'
import {
  dataKey as authResetDataKey,
  authResetReducer,
} from 'features/auth/modules/resetPassword'
import {
  dataKey as deleteAccountDataKey,
  deleteAccountReducer,
} from 'features/delete-account/module/deleteAccount'
import {
  dataKey as rolesDataKey,
  rolesReducer,
} from 'features/early-access/module/roles'
import {
  dataKey as changeEmailDataKey,
  requestEmailVerificationReducer,
} from 'pages/security/changeEmail'
import { ReduxAction } from 'types/types'
import { dataKey as modalsDataKey, modalsReducer } from './modals'

export const createRootReducer = () =>
  combineReducers({
    [authDataKey]: persistReducer<ReturnType<typeof authReducer>, ReduxAction>(
      { key: authDataKey, whitelist: ['isAuthenticated'], storage },
      authReducer
    ),
    [modalsDataKey]: modalsReducer,
    [authResetDataKey]: authResetReducer,
    [authResendVerificationDataKey]: authResendVerificationReducer,
    [rolesDataKey]: rolesReducer,
    [assuranceLevelDataKey]: assuranceLevelReducer,
    [deleteAccountDataKey]: deleteAccountReducer,
    [changeEmailDataKey]: requestEmailVerificationReducer,
  })
