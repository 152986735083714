import React from 'react'
import AvatarPlaceholder from 'assets/svgs/userPlaceholder.svg'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'

interface Props {
  dimensions?: number
  onClick?(): void
}

export const UserImage: React.FC<Props> = ({ dimensions = 38 }) => {
  const { profileData } = useSsoProfileQuery()

  return (
    <StyledImage
      dimensions={dimensions}
      src={profileData?.picture ?? AvatarPlaceholder}
      alt="Avatar"
    />
  )
}

const StyledImage = styled.img<{ dimensions?: number }>`
  ${({ dimensions = '28' }) => `
    height: ${dimensions}px;
    width: ${dimensions}px;
  `}
  border-radius: 50%;
  object-fit: cover;
  display: 'block';
`
