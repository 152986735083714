import React, { KeyboardEvent, useEffect, useState } from 'react'
import { api } from 'api'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { BrowserAutofillBuster } from 'components/BrowserAutofillBuster'
import { InputGroup } from 'components/form'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { Input, ValidationError } from 'components/Input'
import { ModalForm } from 'components/ModalForm'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import Yup from 'validators/yupLocaleConfig'

interface FormValues {
  password?: string
  username?: string
}

const ChangeUsernameModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl()
  const { isProfileDataFetching, profileDataError, profileData } =
    useSsoProfileQuery()
  const { updateProfile } = useUpdateProfile()

  const { values, handleSubmit, handleBlur, handleChange, touched, errors } =
    useFormik<FormValues>({
      initialValues: {
        username: profileData?.username,
        password: '',
      },
      onSubmit: (values) =>
        updateProfile({
          username: values.username,
          old_password: values.password,
        }),
      validationSchema: Yup.object().shape({
        password: Yup.string().required().label('COMMON_LABEL_PASSWORD'),
        username: Yup.string()
          .required()
          .label('COMMON_LABEL_USERNAME')
          .min(1)
          .max(30)
          .username(),
      }),
      enableReinitialize: true,
    })

  const [usernameChecking, setUsernameChecking] = useState(false)
  const [usernameError, setUsernameError] = useState<string | null>(null)
  const hasErrors: boolean =
    !!usernameError || !!errors.password || !!errors.username
  const isSubmitButtonDisabled: boolean =
    isProfileDataFetching ||
    usernameChecking ||
    hasErrors ||
    !values.password ||
    !values.username

  const checkUsername = useDebouncedCallback(async () => {
    if (!values.username) return
    if (values.username === profileData?.username) {
      return setUsernameError(
        intl.formatMessage({
          id: 'SETTINGS_PROFILE_CHANGE_USERNAME_DUPLICATE',
        })
      )
    }
    setUsernameChecking(true)
    try {
      await api.checkUsername(values.username)
      /* on successfull responses the username was found on our db,
      therefore it is not available and we display a error message */
      setUsernameError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_USERNAME_TAKEN',
        })
      )
    } catch (e) {
      // on error responses the username is available
      setUsernameError(null)
    }
    setUsernameChecking(false)
  }, 200)

  useEffect(() => {
    checkUsername()
  }, [checkUsername, values?.username])

  const handleOnSubmit = (e: KeyboardEvent<HTMLFormElement>) => {
    if (isSubmitButtonDisabled) {
      return e.preventDefault()
    } else {
      handleSubmit()
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_LABEL_CHANGE_USERNAME" />}
      size="small"
      onRequestClose={() => onClose?.()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          disabled: isProfileDataFetching || usernameChecking,
          onClick: onClose,
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_CHANGE" />,
          variant: 'primary',
          disabled: isSubmitButtonDisabled,
          type: 'submit',
          onClick: handleSubmit as any,
          loader: isProfileDataFetching ? 'spinner' : undefined,
        },
      ]}
    >
      <ModalForm onSubmit={handleOnSubmit}>
        <InputGroupStyled full>
          <Input
            type="password"
            name="password"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_PASSWORD',
            })}
            value={values.password}
            disabled={isProfileDataFetching}
            invalid={errors.password || profileDataError.password}
            onChange={handleChange}
            onBlur={handleBlur}
            passwordToggle
            focus
            full
          />
        </InputGroupStyled>
        <InputGroupStyled full topSpace>
          <BrowserAutofillBuster />
          <Input
            name="username"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_NEW_USERNAME',
            })}
            value={values.username}
            invalid={
              (touched.username && errors.username) ||
              (!usernameChecking && usernameError) ||
              profileDataError.username ||
              undefined
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isProfileDataFetching}
            full
          />
        </InputGroupStyled>
      </ModalForm>
      {profileDataError && (
        <ValidationError>{profileDataError.detail}</ValidationError>
      )}
    </GenericModal>
  )
}

export const CHANGE_USERNAME_MODAL_ID = 'CHANGE_USERNAME_MODAL_ID'

export const WrappedChangeUsernameModal = () => (
  <ModalWrapper modalId={CHANGE_USERNAME_MODAL_ID}>
    <ChangeUsernameModal />
  </ModalWrapper>
)

const InputGroupStyled = styled(InputGroup)<{ topSpace?: boolean }>`
  ${(p) => (p.topSpace ? 'margin-top: 40px' : 'margin-bottom: 40px')};
  span {
    top: 4px;
  }
`
