import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { PersonalBillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { closeVisibleModal } from 'modules/modals'
import { QueryKey } from 'store/types'

export const useCancelCyberSecureSubscription = (
  api: PersonalBillingSubscriptionsApi = new PersonalBillingSubscriptionsApi()
) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const cancelCyberSecureSubscription = useMutation({
    mutationFn: api.cancelCyberSecure,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      dispatch(closeVisibleModal())
    },
  })

  const cancelCyberSecureSubscriptionImediately = useMutation({
    mutationFn: api.cancelCyberSecureImmediately,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      dispatch(closeVisibleModal())
    },
  })

  return {
    cancelCyberSecureSubscription: cancelCyberSecureSubscription.mutate,
    isCancelCyberSecureSubscriptionLoading:
      cancelCyberSecureSubscription.isPending,
    cancelCyberSecureSubscriptionErrors: cancelCyberSecureSubscription.error,
    resetCancelCyberSecureSubscriptionErrors:
      cancelCyberSecureSubscription.reset,

    cancelCyberSecureSubscriptionImediately:
      cancelCyberSecureSubscriptionImediately.mutate,
    isCancelCyberSecureSubscriptionImediatelyLoading:
      cancelCyberSecureSubscriptionImediately.isPending,
    cancelCyberSecureSubscriptionImediatelyErrors:
      cancelCyberSecureSubscriptionImediately.error,
    resetCancelCyberSecureSubscriptionImediatelyErrors:
      cancelCyberSecureSubscriptionImediately.reset,
  }
}
