import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { TrustedDevicesApi } from 'api/trustedDevices'
import { checkAuth } from 'features/auth/modules/auth'
import { closeVisibleModal } from 'modules/modals'
import { QueryKey } from 'store/types'

export const useRemoveTrustedDevices = (
  api: TrustedDevicesApi = new TrustedDevicesApi()
) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const removeMultipleTrustedDevices = useMutation({
    mutationFn: api.deleteMany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.TRUSTED_DEVICES],
      })
      dispatch(checkAuth())
      dispatch(closeVisibleModal())
    },
  })
  return {
    removeMultipleTrustedDevices: removeMultipleTrustedDevices.mutate,
    isRemoveLoading: removeMultipleTrustedDevices.isPending,
  }
}
