import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { Input } from '@ubnt/ui-components/Input'
import { closeVisibleModal } from 'modules/modals'
import { useMfaEmail } from 'store/mutations/mfa/useMfaEmail'
import styled from 'theme/styled'
import { getErrorMessage } from 'utils/mfa'
import Yup from 'validators/yupLocaleConfig'
import { GenericMFAModal } from '../GenericMFAModal'

export const EMAIL_INPUT_EMAIL_MODAL_ID = 'EMAIL_INPUT_EMAIL_MODAL_ID'

interface FormValues {
  email: string
}

export const EmailInputEmailModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { addEmail, isAddEmailLoading, addEmailError } = useMfaEmail()

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      addEmail({ email })
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required().label('SETTINGS_MFA_EMAIL_EMAIL'),
    }),
  })

  const apiError = useMemo(() => {
    return getErrorMessage({
      apiError: addEmailError,
      fields: ['email', 'detail'],
      defaultMessage: intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_TITLE',
      }),
    })
  }, [addEmailError, intl])

  return (
    <GenericMFAModal
      modalId={EMAIL_INPUT_EMAIL_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_EMAIL_MODAL_TITLE' })}
      actions={[
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CANCEL',
          }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({
            id: 'SETTINGS_MFA_SEND_CODE_ACTION',
          }),
          onClick: () => formik.handleSubmit(),
          variant: 'primary',
          loader: isAddEmailLoading ? 'dots' : undefined,
        },
      ]}
      isLoading={isAddEmailLoading}
      onAfterClose={() => {
        formik.resetForm()
      }}
      overrideFullScreen
      size="small"
    >
      <Wrapper>
        <InputWrapper>
          <Input
            {...formik.getFieldProps('email')}
            label={intl.formatMessage({
              id: 'SETTINGS_MFA_EMAIL_EMAIL',
            })}
            variant="secondary"
            invalid={formik.touched.email && (formik.errors.email || apiError)}
            full
          />
        </InputWrapper>
      </Wrapper>
    </GenericMFAModal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`
