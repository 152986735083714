import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { ButtonLink } from '@ubnt/ui-components/Button'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { selectCanChangeEmail } from 'features/auth/modules/auth'
import PublicPage from 'pages/PublicPage'
import styled from 'theme/styled'
import { ChangeEmailForm } from './ChangeEmailForm'
import { ChangeEmailHeader } from './ChangeEmailHeader'

export const ChangeEmail = () => {
  const intl = useIntl()
  const history = useHistory<{ email: string }>()
  const canChangeEmail = useSelector(selectCanChangeEmail)

  if (!canChangeEmail) {
    history.push('/login')
  }

  return (
    <PublicPage showLogo={false}>
      <ChangeEmailHeader
        title={intl.formatMessage({ id: 'COMMON_LABEL_CHANGE_EMAIL' })}
        subtitle={intl.formatMessage({
          id: 'LOGIN_CHANGE_EMAIL_DESCRIPTION',
        })}
      />
      <TextWrapper>
        <Text variant="caption">
          {intl.formatMessage({ id: 'COMMON_LABEL_CURRENT_EMAIL' })}
        </Text>
        <Text variant="heading-medium">{history.location.state?.email}</Text>
      </TextWrapper>
      <ChangeEmailForm />
      <Link to="/login">
        <StyledButtonLink>
          {intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' })}
        </StyledButtonLink>
      </Link>
    </PublicPage>
  )
}

const TextWrapper = styled.div`
  margin: 32px 0 24px;
  display: flex;
  flex-direction: column;
`

const StyledButtonLink = styled(ButtonLink)`
  color: ${({ theme }) => theme.neutral09};

  &:hover {
    color: ${({ theme }) => theme.neutral08};
  }
`
