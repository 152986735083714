import { MFAType } from 'modules/types'
import { parseSSOAPIError } from './parseSSOAPIError'
import { SSOAPIError } from '../types/types'

interface GetErrorMessage {
  apiError: SSOAPIError
  defaultMessage: string
  fields?: string[]
}

export const getErrorMessage = ({
  apiError,
  defaultMessage,
  fields = [],
}: GetErrorMessage): string | undefined => {
  if (!Object.keys(apiError).length) {
    return undefined
  }

  const matchingKeys = Object.keys(apiError).filter((key) =>
    fields.includes(key)
  )

  if (matchingKeys.length === 0) {
    return defaultMessage
  }

  const errorMessages = fields
    .map((key) => parseSSOAPIError(apiError, key))
    .filter((value) => value)

  const found = errorMessages.find(() => true)

  if (found === '2fa token error') {
    return 'Invalid token'
  }

  return found
}

export const methodTitle = (method: MFAType) => {
  switch (method) {
    case MFAType.push:
      return 'UI Verify'
    case MFAType.sms:
      return 'Phone Number (SMS)'
    case MFAType.email:
      return 'Email'
    case MFAType.totp:
      return 'Authenticator app'
    default:
      return 'Authenticator method'
  }
}
