import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { BasicToast, ToastContext } from '@ubnt/ui-components'
import { useFormik } from 'formik'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { TaxIdArgs } from 'api/billing/billingTaxId'
import { ValidationError } from 'components/Input'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import { usStripeCountries } from 'features/stripe/ui/countries/usStripeCountries'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import { StripeElements } from 'features/stripe/ui/StripeElements'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'
import { useCreateCard } from 'store/mutations/billingCards/useCreateCard'
import { useCreateCustomer } from 'store/mutations/billingCustomer/useCreateCustomer'
import { useCreateTaxId } from 'store/mutations/billingTaxId/useCreateTaxId'
import { useUpdateTaxId } from 'store/mutations/billingTaxId/useUpdateTaxId'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { useBillingTaxIdQuery } from 'store/queries/useBillingTaxIdQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { sendWebKitMessage } from 'utils/sendWebKitMessage'
import { StyledModal, Wrapper } from './Components'
import { CardAddressForm } from './forms/cardAddress/CardAddressForm'
import { CardInfoForm } from './forms/CardInfoForm'
import { TaxIdForm } from './forms/TaxIdForm'
import { AddCardValues } from './forms/types'
import {
  checkAddress,
  getIsSourceCountryValid,
  handleCaughtAddressError,
  getErrorMessage,
  getValidationSchema,
  getAddressAndMetadata,
  handleSourceErrors,
} from './utils'
import { StripeRegionContext } from '../../stripe/ui/Region'

interface Props {
  addPaymentView?: boolean
  subscriptionRegion?: StripeRegionCode
}

const AddPaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ addPaymentView = false, isOpen, onClose, intl, subscriptionRegion }) => {
  const {
    isCreateCardLoading,
    createCardError,
    createCard,
    resetCreateCardErrors,
  } = useCreateCard()
  const { createTaxIdAsync, isCreateTaxIdError } =
    useCreateTaxId('paymentMethod')
  const { updateTaxIdAsync, isUpdateTaxIdError } =
    useUpdateTaxId('paymentMethod')
  const { isBillingCustomerLoading, billingCustomer } =
    useBillingCustomerQuery()
  const { isTaxIdFromBrazil, taxId } = useBillingTaxIdQuery()
  const { createBillingCustomerAsync, isCreateCustomerError } =
    useCreateCustomer()
  const isLoading = isCreateCardLoading || isBillingCustomerLoading

  const [createError, setCreateError] = useState<{
    message?: string
    show: boolean
  } | null>(null)
  const [addressError, setAddressError] = useState<Record<
    string,
    string
  > | null>(null)

  const stripe = useStripe()
  const elements = useElements()
  const { country, region } = useContext(StripeRegionContext)
  const { isEmployeeAccount } = useSsoProfileQuery()
  const { createNotification, removeNotification } = useContext(ToastContext)

  const cardRegion = subscriptionRegion || region

  const selectedRegion = validStripeRegionMap.get(cardRegion)

  const userCountry = useMemo(() => {
    if (!selectedRegion?.code) {
      return null
    }
    // Since we default the region to be US when the user is in an unsupported stripe region we also need to confirm that the country the user
    // is in belongs to the US region. In cases where it does not, it means it's actually our default value and we should also default the country
    // to be the same as the region (the US)
    if (
      selectedRegion.code === StripeRegionCode.US &&
      !usStripeCountries.find((stripeCountry) => stripeCountry.code === country)
    ) {
      return selectedRegion.code
    }
    if (regionsWithMultipleCountries.includes(selectedRegion.code)) {
      return country
    }

    return selectedRegion.code
  }, [selectedRegion, country])

  const {
    values,
    setSubmitting,
    touched,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    handleBlur,
    setValues,
    handleChange,
    errors,
  } = useFormik<AddCardValues>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      country: userCountry?.toUpperCase() || 'US',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      cardCountry: '',
    },
    onSubmit: async (values) => {
      try {
        await checkAddress(values)
      } catch (error) {
        handleCaughtAddressError(error, intl, setAddressError)
        return
      }
      if (!stripe || !elements) return
      const card = elements.getElement(CardNumberElement)

      if (!card) return
      const { address, metadata } = getAddressAndMetadata(values)
      const { source, error } = await stripe.createSource(card, {
        type: 'card',
        metadata,
        owner: {
          name: values.name,
          address,
        },
      })

      const userStripeRegion = selectedRegion?.code || StripeRegionCode.US

      const isSourceCountryValid =
        getIsSourceCountryValid(
          userStripeRegion,
          source?.card?.country?.toLowerCase() as StripeCountryCode,
          userCountry as StripeCountryCode
        ) || isEmployeeAccount

      if (!source || !isSourceCountryValid) {
        handleSourceErrors(
          setCreateError,
          intl,
          source?.card?.country,
          userStripeRegion,
          error
        )
      }

      if (source && isSourceCountryValid) {
        try {
          const hasStripeCustomer = !!billingCustomer?.[userStripeRegion]

          if (!hasStripeCustomer) {
            await createBillingCustomerAsync(userStripeRegion)
          }

          if (values.taxIdType && values.taxIdValue) {
            const taxIdArgs: TaxIdArgs = {
              taxData: {
                type: values.taxIdType,
                value: values.taxIdValue,
              },
              hasCustomer: true,
              region: cardRegion,
            }
            if (taxId) {
              await updateTaxIdAsync(taxIdArgs)
            } else {
              await createTaxIdAsync(taxIdArgs)
            }
          }

          createCard({
            cardData: {
              source: source.id,
              region: cardRegion,
            },
            hasCustomer: true,
          })
        } catch (error) {
          console.error(error)
        }
      }

      setSubmitting(false)
    },
    validationSchema: getValidationSchema('add', country, isTaxIdFromBrazil),
  })

  useEffect(() => {
    if (isUpdateTaxIdError || isCreateTaxIdError) {
      setCreateError({
        message: intl.formatMessage({
          id: 'SETTINGS_PAYMENTS_TAX_ID_VALIDATION_ERROR',
        }),
        show: false,
      })
    }
    if (isCreateCustomerError) {
      setCreateError({
        message: intl.formatMessage({
          id: 'GENERIC_ERROR_DETAILS',
        }),
        show: true,
      })
    }
  }, [intl, isCreateCustomerError, isCreateTaxIdError, isUpdateTaxIdError])

  useEffect(() => {
    setCreateError(null)
  }, [values])

  useEffect(() => {
    const errorMessage = getErrorMessage(
      createCardError,
      intl,
      selectedRegion?.name
    )
    setCreateError(errorMessage ? { message: errorMessage, show: true } : null)
  }, [createCardError, selectedRegion?.name, intl])

  const handleClose = useCallback(() => {
    if (addPaymentView) {
      sendWebKitMessage('cancel')
    } else {
      onClose?.()
      resetCreateCardErrors()
    }
  }, [addPaymentView, onClose, resetCreateCardErrors])

  useEffect(() => {
    if (createError && createError.show) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
          details={createError.message}
        />
      )
    }
  }, [createError, createNotification, removeNotification])

  const isButtonDisabled = useMemo(() => {
    const hasAddressError =
      !!addressError && !!Object.values(addressError).length
    const hasFormikError = !!Object.values(errors).length
    const hasAnyError = hasAddressError || hasFormikError

    if (isLoading || isSubmitting || hasAnyError) {
      return true
    }
    return false
  }, [addressError, errors, isSubmitting, isLoading])

  return (
    <StyledModal
      size="small"
      isOpen={isOpen}
      onRequestClose={() => handleClose()}
      appView={addPaymentView ? true : false}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_ADD"
          tagName="div"
        />
      }
      actions={[
        {
          text: addPaymentView
            ? intl.formatMessage({ id: 'COMMON_ACTION_BACK' })
            : intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: () => handleClose(),
          variant: 'default',
          disabled: isLoading || isSubmitting,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_ADD' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleSubmit as any,
          disabled: isButtonDisabled,
          loader: isOpen && (isLoading || isSubmitting) ? 'spinner' : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      <Wrapper>
        <CardInfoForm
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          cardRegion={cardRegion}
          subscriptionRegion={subscriptionRegion}
          createError={createError?.message}
          resetCreateError={() => setCreateError(null)}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
        <CardAddressForm
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setValues={setValues as any}
          touched={touched}
          errors={errors}
          setFieldTouched={setFieldTouched}
          submitError={createError?.message || null}
          addressError={addressError}
          setAddressError={setAddressError}
          country={userCountry}
          cardRegion={cardRegion}
          setFieldValue={setFieldValue}
        />
        <TaxIdForm
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          taxIdError={isCreateTaxIdError || isUpdateTaxIdError}
        />
      </Wrapper>
      {addressError?.['detail'] ? (
        <ValidationError>
          <FormattedMessage id="SETTINGS_PAYMENTS_ADDRESS_VALIDATION_FAILED" />
        </ValidationError>
      ) : null}
    </StyledModal>
  )
}

const AddPaymentMethodModalConnected = injectIntl(AddPaymentMethodForm)

export const ADD_PAYMENT_METHOD_MODAL_ID = 'ADD_PAYMENT_METHOD_MODAL_ID'

const StripeWrappedModal: React.FC<Props & ModalProps> = (props) => {
  const { region } = useContext(StripeRegionContext)
  const selectedRegion = props.subscriptionRegion
    ? props.subscriptionRegion
    : region
  return (
    <StripeElements region={selectedRegion}>
      <AddPaymentMethodModalConnected {...props} />
    </StripeElements>
  )
}

export default () => (
  <ModalWrapper modalId={ADD_PAYMENT_METHOD_MODAL_ID}>
    <StripeWrappedModal />
  </ModalWrapper>
)
