import React, { Fragment } from 'react'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { getProfileNameToDisplay } from 'utils/getProfileNameToDisplay'
import {
  ProfileWrapper,
  StyledAvatar,
  Subtitle,
  TextContainer,
  Title,
} from './ProfileCard.styles'

export const ProfileCard = () => {
  const { profileData } = useSsoProfileQuery()

  const displayName = getProfileNameToDisplay(profileData)

  return (
    <ProfileWrapper>
      {profileData && (
        <Fragment>
          <StyledAvatar isClickable={false} />
          <TextContainer>
            <Title data-testid="displayName" title={displayName}>
              {displayName}
            </Title>
            <Subtitle>{profileData?.email || ''}</Subtitle>
          </TextContainer>
        </Fragment>
      )}
    </ProfileWrapper>
  )
}
