import React from 'react'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { Notification } from '@ubnt/ui-components/Notification'
import { FormattedMessage } from 'react-intl'
import { size, space, typography } from 'theme'
import styled from 'theme/styled'

interface Props {
  margin?: string
}

const ChangePasswordNotification: React.FC<Props> = ({ margin }) => {
  return (
    <StyledNotification
      variant="secondary"
      icon={<WarningIcon width={20} height={20} variant="fill" />}
      margin={margin}
    >
      <Message>
        <FormattedMessage id="COMMON_LABEL_CHANGE_PASSWORD_MODAL_NOTIFICATION" />
      </Message>
    </StyledNotification>
  )
}

export default ChangePasswordNotification

const StyledNotification = styled(Notification)<{ margin?: string }>`
  padding: ${size['desktop-sizing-base-04']};
  display: flex;
  align-items: flex-start;
  margin: ${({ margin }) => margin ?? space['desktop-spacing-base-04']} 0;
`

const Message = styled.div`
  color: ${({ theme }) => theme.text2};
  text-align: left;
  font: ${typography['desktop-caption']};
`

const WarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.orange06};
`
