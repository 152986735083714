import React, { useContext } from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { EmptyResult } from '@ubnt/ui-components/EmptyResult'
import { FormattedMessage } from 'react-intl'
import { useInvoices } from 'pages/subscriptions/hooks/useInvoices'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'
import { GridHeaderRow } from './InvoiceGridHeaderRow'
import { InvoiceGridRow } from './InvoiceGridRow'
import { GridWrapper, Wrapper } from './Invoices.styles'
import { InvoiceYearSelector } from './InvoiceYearSelector'
import { InvoiceContext } from '../InvoiceContext'
import { SkeletonLoaders } from '../subscriptions/SkeletonLoaders'

type InvoiceYearSelectorProps = {
  onMonthClick: (value: string) => void
}

export const InvoicesGrid: React.FC<InvoiceYearSelectorProps> = ({
  onMonthClick,
}) => {
  const { selectedYear } = useContext(InvoiceContext)

  const { fetchNextInvoicesPage, areInvoicesRefetching } =
    useBillingInvoicesQuery(selectedYear)
  const { dataByYear } = useInvoices()

  const renderContent = () => {
    if (dataByYear[selectedYear]?.invoiceTableItems.length) {
      return (
        <GridWrapper>
          <GridHeaderRow />
          {dataByYear[selectedYear].invoiceTableItems.map((invoice) => (
            <InvoiceGridRow
              key={invoice.id}
              invoice={invoice}
              onClick={onMonthClick}
            />
          ))}
          {dataByYear[selectedYear]?.shouldInvoicesPaginate && (
            <Button
              onClick={() => fetchNextInvoicesPage()}
              loader={areInvoicesRefetching ? 'spinner' : undefined}
              variant="inline"
              size="medium"
              style={{ width: 'max-content' }}
            >
              <FormattedMessage id="COMMON_SHOW_MORE" />
            </Button>
          )}
        </GridWrapper>
      )
    }

    if (areInvoicesRefetching) {
      return <SkeletonLoaders />
    }

    return (
      <EmptyResult
        title={<FormattedMessage id={'SETTINGS_INVOICES_EMPTY_YEAR_TITLE'} />}
        description={
          <FormattedMessage id={'SETTINGS_INVOICES_EMPTY_MESSAGE'} />
        }
        size="large"
      />
    )
  }

  return (
    <Wrapper>
      <InvoiceYearSelector />
      {renderContent()}
    </Wrapper>
  )
}
