import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { InvoiceData, StripeInvoice } from 'features/subscriptions/module/types'
import { useBillingInvoiceYearsQuery } from 'store/queries/useBillingInvoiceYearsQuery'
import { QueryKey } from 'store/types'
import {
  getPaginationInfo,
  InvoiceYearInfo,
  InvoiceYearsInfo,
  parseInvoices,
  getSortedInvoices,
  InvoiceItem,
  mergeInvoicePages,
} from './utils'

export const useInvoices = () => {
  const { invoiceYears } = useBillingInvoiceYearsQuery()

  const queryClient = useQueryClient()

  const allInvoices: StripeInvoice[] = []
  const allTableInvoices: InvoiceItem[] = []

  const dataByYear = invoiceYears?.reduce<InvoiceYearsInfo>((result, year) => {
    const queryData = queryClient.getQueryData<
      InfiniteData<InvoiceData, unknown>
    >([QueryKey.BILLING_INVOICES, year])

    const mergedInvoices = mergeInvoicePages(queryData)
    const paginationInfo = getPaginationInfo(mergedInvoices)
    const sortedInvoices = getSortedInvoices(mergedInvoices)
    const parsedInvoices = parseInvoices(sortedInvoices)
    const shouldInvoicesPaginate = paginationInfo.some(
      (region) => region.hasMore
    )

    const yearResult: InvoiceYearInfo = {
      paginationInfo,
      invoices: sortedInvoices,
      invoiceTableItems: parsedInvoices,
      shouldInvoicesPaginate,
    }

    result[year] = yearResult

    allInvoices.push(...sortedInvoices)
    allTableInvoices.push(...parsedInvoices)
    return result
  }, {})

  return {
    dataByYear,
    allInvoices,
    allTableInvoices,
  }
}
