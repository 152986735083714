import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Bubble } from 'components/support/Bubble'
import { FormInput } from 'components/support/FormInput'
import { TilesWrapper } from 'components/support/Wrappers'
import { config } from './config/formConfig'
import { BubbleProps } from './types'

export const OrderNumberBubble: React.FC<BubbleProps> = ({
  handleChange,
  shouldScroll,
  touched,
  setFieldTouched,
  errors,
  values,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.orderIssue])

  return (
    <Bubble question={config.orderNumber.fieldTitle} ref={ref}>
      <TilesWrapper>
        <FormInput
          size="body"
          variant="secondary"
          placeholder={intl.formatMessage({
            id: 'SUPPORT_DESCRIPTION_PLACEHOLDER',
          })}
          onChange={(_, value) =>
            handleChange('orderNumber', value?.toString(), 'description')
          }
          invalid={touched?.orderNumber && errors?.orderNumber}
          onBlur={() => setFieldTouched?.('orderNumber', true)}
          value={values.orderNumber}
        />
      </TilesWrapper>
    </Bubble>
  )
}
