import React, { useEffect, useMemo, useRef } from 'react'
import AttachmentIcon from '@ubnt/icons/AttachmentIcon'
import { Link } from '@ubnt/ui-components/aria/experimental'
import { UserImage } from 'components/avatar/UserImage'
import {
  Message,
  MessageContent,
  MessageWrapper,
  UserTextWrapper,
} from './styles'
import { MessageProps } from './types'
import { parseInitialMessage } from './utils'

export const UserMessage: React.FC<MessageProps> = ({
  key,
  text,
  shouldScroll,
  shouldHideAvatar,
  isInitialMessage,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  const message = useMemo(() => {
    if (!text) return ''
    if (isInitialMessage) {
      return parseInitialMessage(text)
    }

    const hasAttachments = text.includes(
      'Attachments: https://secure-uploads.ui.com'
    )

    if (!hasAttachments) {
      return <MessageContent>{text}</MessageContent>
    }

    const url = text.split('Attachments: ')[1]

    return (
      <MessageContent>
        <AttachmentIcon isActive size={20} style={{ marginRight: '4px' }} />
        <Link href={url} target="blank">
          Download Attachments
        </Link>
      </MessageContent>
    )
  }, [isInitialMessage, text])

  return (
    <Message key={key} alignRight ref={ref}>
      <MessageWrapper>
        <UserTextWrapper
          $extraMargin={shouldHideAvatar ? '0 32px 0 0' : undefined}
        >
          {message}
        </UserTextWrapper>
        {!shouldHideAvatar && <UserImage dimensions={24} />}
      </MessageWrapper>
    </Message>
  )
}
