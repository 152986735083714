import React, { useContext } from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import styled from 'theme/styled'

export const RequestsLoader = () => {
  const { isMaxXSmall } = useContext(MediaSizingContext)

  return (
    <SkeletonLoader>
      <Wrapper>
        <Table colCounts={isMaxXSmall ? 2 : 4} rowCounts={5} rowHeight={52} />
      </Wrapper>
    </SkeletonLoader>
  )
}

const Wrapper = styled.div`
  td,
  th {
    width: 120px !important;
    max-width: 120px !important;
  }
  td:first-of-type,
  th:first-of-type {
    width: auto !important;
    max-width: unset !important;
  }
  td:last-of-type,
  th:last-of-type {
    width: 60px !important;
    max-width: 60px !important;
  }
  table {
    border-bottom: none !important;
  }
`
