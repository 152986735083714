import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectVisibleModal } from 'modules/modals'
import { useMfaEmail } from 'store/mutations/mfa/useMfaEmail'
import styled from 'theme/styled'
import { getErrorMessage } from 'utils/mfa'
import { GenericVerifyTokenModal } from '../GenericVerifyTokenModal'

export const EMAIL_VERIFY_CODE_MODAL_ID = 'EMAIL_VERIFY_CODE_MODAL_ID'

interface FormValues {
  token: string
}

export const EmailVerifyCodeModal: React.FC = () => {
  const intl = useIntl()

  const {
    sendEmail,
    addEmailData,
    isSendEmailLoading,
    verifyEmail,
    isVerifyEmailLoading,
    verifyEmailError,
    resetVerifyEmail,
  } = useMfaEmail()

  const visibleModal = useSelector(selectVisibleModal)

  const [apiError, setApiError] = useState<string>('')

  useEffect(() => {
    setApiError(
      getErrorMessage({
        apiError: verifyEmailError,
        defaultMessage: intl.formatMessage({
          id: 'GENERIC_ERROR_BOUNDARY_TITLE',
        }),
        fields: ['detail'],
      }) || ''
    )
  }, [verifyEmailError, intl])

  useEffect(() => setApiError(''), [visibleModal])

  const handleOnSubmit = useCallback(
    ({ token }: FormValues) => {
      if (!token) {
        console.error('missing token')
        return
      } else if (!addEmailData?.id) {
        console.error('missing id')
        return
      }

      verifyEmail({
        id: addEmailData.id,
        data: { oneTimePassword: token },
      })
    },
    [addEmailData?.id, verifyEmail]
  )

  const handleResendCode = () => {
    if (!addEmailData?.id) {
      return null
    }

    sendEmail(addEmailData.id)
  }

  const description = (
    <div>
      <Description>
        {intl.formatMessage(
          {
            id: 'SETTINGS_MFA_EMAIL_VERIFY_CODE_DESCRIPTION',
          },
          {
            email: <b>{addEmailData?.email}</b>,
          }
        )}
      </Description>
      <ResendCode>
        {intl.formatMessage(
          {
            id: 'SETTINGS_MFA_VERIFY_CODE_RESEND_ACTION',
          },
          {
            link: (
              <Button
                variant="inline"
                onClick={handleResendCode}
                loader={isSendEmailLoading ? 'dots' : undefined}
              >
                {intl.formatMessage({
                  id: 'SETTINGS_MFA_VERIFY_CODE_RESEND',
                })}
              </Button>
            ),
          }
        )}
      </ResendCode>
    </div>
  )

  return (
    <GenericVerifyTokenModal
      modalId={EMAIL_VERIFY_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_EMAIL_MODAL_TITLE' })}
      description={description}
      handleOnSubmit={handleOnSubmit}
      isLoading={isVerifyEmailLoading}
      errorMessage={apiError}
      onAfterClose={resetVerifyEmail}
    />
  )
}

const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.text3};
  word-break: break-word;
`

const ResendCode = styled.div`
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text3};
  button {
    outline: none;
  }
`
