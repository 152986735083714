import React, { useEffect, useMemo, useState } from 'react'
import { CheckmarkIcon } from '@ubnt/icons'
import { api } from 'api'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import theme, { size, typography } from 'theme'
import { useDebouncedCallback } from 'use-debounce'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { Input } from 'components/Input'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { logout } from 'features/auth/modules/auth'
import { PASSWORD_MIN_LENGTH } from 'features/auth/ui/checkPasswprdRequirements'
import PasswordFeedback, {
  PasswordFeedbackData,
  passwordScoreBarWidth,
  passwordScoreColor,
  passwordScoreText,
} from 'features/auth/ui/PasswordFeedback'
import { PasswordRequirements } from 'features/auth/ui/PasswordRequirements'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { isAxiosError } from 'utils/isAxiosError'
import Yup from 'validators/yupLocaleConfig'

interface FormValues {
  oldPassword?: string
  password?: string
  confirmNewPassword?: string
}

const ChangePasswordModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl()
  const { profileDataError, isProfileDataFetching, profileData } =
    useSsoProfileQuery()
  const { updateProfile, isProfileUpdated, isUpdateProfileLoading } =
    useUpdateProfile()
  const dispatch = useDispatch()

  const isLoading = isUpdateProfileLoading || isProfileDataFetching

  const { values, handleSubmit, handleBlur, handleChange, touched, errors } =
    useFormik<FormValues>({
      initialValues: {
        oldPassword: '',
        password: '',
        confirmNewPassword: '',
      },
      onSubmit: (values) =>
        updateProfile({
          password: values.password,
          old_password: values.oldPassword,
        }),
      validationSchema: Yup.object().shape({
        oldPassword: Yup.string()
          .required()
          .label('COMMON_LABEL_CURRENT_PASSWORD'),
        password: Yup.string()
          .required()
          .min(PASSWORD_MIN_LENGTH)
          .password()
          .label('SETTINGS_SECURITY_NEW_PASSWORD_LABEL'),
        confirmNewPassword: Yup.string()
          .required()
          .oneOf([Yup.ref('password')], 'Passwords must match')
          .label('SETTINGS_SECURITY_CONFIRM_NEW_PASSWORD_LABEL'),
      }),
    })
  const [passwordData, setPasswordData] = useState<PasswordFeedbackData | null>(
    null
  )
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [passwordCheckLoading, setPasswordCheckLoading] =
    useState<boolean>(false)
  const [samePassword, setSamePassword] = useState<boolean>(false)

  const passwordLongEnough = useMemo(() => {
    if (values.password) {
      if (values.password?.length >= PASSWORD_MIN_LENGTH) return true
      return false
    }
  }, [values.password])

  const checkPassword = useDebouncedCallback(async () => {
    const validator = Yup.string()
    if (
      !values.password ||
      !validator.isValidSync(values.password) ||
      errors.password
    )
      return
    try {
      const userInputs: string[] = [
        profileData?.username,
        profileData?.first_name,
        profileData?.last_name,
        profileData?.email,
        values?.oldPassword,
      ].filter((userInput): userInput is string => !!userInput)
      const { is_acceptable_password, suggestions, warning, score } =
        await api.checkPassword(values.password!, userInputs)
      setPasswordCheckLoading(false)

      warning && suggestions.unshift(warning)

      score !== undefined &&
        setPasswordData({
          is_acceptable_password: is_acceptable_password ?? null,
          suggestions: suggestions ?? null,
          score: score,
          barWidth: passwordScoreBarWidth(score),
          feedbackColor: passwordScoreColor(score),
          text: (
            <FormattedMessage
              id={passwordScoreText(score)}
              values={{
                b: (text) => <b className="intl-message">{text}</b>,
              }}
            />
          ),
          error: null,
        })
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<any>
        setPasswordCheckLoading(false)
        setPasswordData({
          is_acceptable_password: null,
          suggestions: null,
          score: null,
          barWidth: null,
          feedbackColor: null,
          text: null,
          error: error.response?.data?.detail || '',
        })
      }
      return
    }
  }, 200)

  useEffect(() => {
    if (values?.password && values?.password?.length >= PASSWORD_MIN_LENGTH) {
      setSamePassword(values.password === values.oldPassword)
    }
  }, [values?.password, values?.oldPassword, setSamePassword])

  useEffect(() => {
    if (passwordFocused) {
      checkPassword()
    }
  }, [checkPassword, passwordFocused, values.password])

  useEffect(() => {
    if (values?.password && values?.password.length >= PASSWORD_MIN_LENGTH) {
      setPasswordCheckLoading(true)
    }
  }, [checkPassword, values?.password])

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(logout())
    }
  }, [dispatch, isProfileUpdated])

  const invalidMessage = samePassword
    ? intl.formatMessage({
        id: 'SETTINGS_SECURITY_NEW_PASSWORD_SAME_PASSWORD',
      })
    : touched.password && errors.password

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_LABEL_CHANGE_PASSWORD" />}
      size="small"
      overrideFullScreen
      onRequestClose={() => onClose?.()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          disabled: isLoading,
          onClick: onClose,
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_CHANGE" />,
          variant: 'primary',
          disabled:
            isLoading ||
            passwordCheckLoading ||
            !passwordData?.score ||
            passwordData.score < 3 ||
            samePassword ||
            !!errors?.confirmNewPassword,
          type: 'submit',
          onClick: handleSubmit as any,
          loader: isLoading
            ? 'spinner'
            : isProfileUpdated
              ? 'success'
              : undefined,
        },
      ]}
    >
      <>
        <Subtitle>
          <FormattedMessage id="COMMON_LABEL_CHANGE_PASSWORD_MODAL_SUBTITLE" />
        </Subtitle>
        <StyledForm>
          <StyledInput
            type="password"
            name="oldPassword"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_CURRENT_PASSWORD',
            })}
            value={values.oldPassword}
            disabled={isLoading}
            invalid={touched.oldPassword && errors.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            passwordToggle
            focus
            full
          />
          <Wrapper>
            <StyledInput
              type="password"
              name="password"
              variant="secondary"
              label={intl.formatMessage({
                id: 'SETTINGS_SECURITY_NEW_PASSWORD_LABEL',
              })}
              value={values.password}
              disabled={isLoading}
              invalid={!passwordFocused && invalidMessage}
              helperMessage={
                passwordLongEnough &&
                !errors.password &&
                !passwordFocused && (
                  <PasswordFeedback
                    passwordLongEnough={passwordLongEnough ?? false}
                    passwordCheckLoading={passwordCheckLoading}
                    passwordData={passwordData}
                    apiErrors={profileDataError}
                  />
                )
              }
              onFocus={() => setPasswordFocused(true)}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e)
                setPasswordFocused(false)
              }}
              passwordToggle
              full
            />
            {passwordFocused && (
              <PasswordRequirements
                passwordFocused={passwordFocused}
                password={values.password}
              />
            )}
          </Wrapper>
          <StyledInput
            type="password"
            name="confirmNewPassword"
            variant="secondary"
            label={intl.formatMessage({
              id: 'SETTINGS_SECURITY_CONFIRM_NEW_PASSWORD_LABEL',
            })}
            value={values.confirmNewPassword}
            disabled={isLoading}
            invalid={touched.confirmNewPassword && errors.confirmNewPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            contentAfter={
              values?.confirmNewPassword &&
              values.confirmNewPassword?.length >= PASSWORD_MIN_LENGTH &&
              !errors.confirmNewPassword && (
                <StyledCheckIcon variant="twoTone" isActive />
              )
            }
            passwordToggle
            full
            last
          />
        </StyledForm>
      </>
    </GenericModal>
  )
}

export const CHANGE_PASSWORD_MODAL_ID = 'CHANGE_PASSWORD_MODAL_ID'

export const WrappedChangePasswordModal = () => (
  <ModalWrapper modalId={CHANGE_PASSWORD_MODAL_ID}>
    <ChangePasswordModal />
  </ModalWrapper>
)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  > div {
    margin: 0;
  }
`

const Subtitle = styled.span`
  font: ${typography['desktop-typography-body']};
  text-align: left;
  color: ${({ theme }) => theme.text2};
`

const StyledInput = styled(Input)<{ last?: boolean }>`
  height: ${({ last }) =>
    last ? size['desktop-sizing-base-13'] : size['desktop-sizing-base-15']};
`

const StyledCheckIcon = styled(CheckmarkIcon)`
  position: absolute;
  transform: translate(-30%, -5%);
`

const StyledForm = styled.form`
  > div {
    margin: 0;
  }
  margin-bottom: 112px;

  @media (max-width: ${theme.media.mobileLarge}) {
    margin-bottom: 0;
    margin-top: 40px;
  }
`
