import { omit, reduce } from 'lodash-es'
import { FIELD_FALLBACK_VALUE_NA } from 'features/support/constants'
import { ZDCustomFields } from 'features/support/types'
import { getRequester } from 'features/support/unifi/utils/requestUtils'
import { getTags } from 'features/support/utils'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { CustomFields, SupportFormState } from '../types'
import { createSubject } from './createSubject'
import { config } from '../config/formConfig'

const { ZENDESK_UISP_FORM_KEY } = __CONFIG__

export const useFormatTicket = () => {
  const { profileData } = useSsoProfileQuery()

  const { accountType } = useOrganizationsQuery()

  const formatRequestedTicket = (
    values: SupportFormState,
    sendsafelyAttachment?: string,
    followup_source_id?: string
  ) => {
    if (!profileData) return
    const requester = getRequester(profileData)

    const initialValues: CustomFields = {
      productLine: values.productLine,
      category: values.category,
      cloudHostname: values.cloudHostname,
      attachments: sendsafelyAttachment,
      rawData: JSON.stringify(
        {
          product_line: values.productLine,
          category: values.category,
          cloud_host_name: values.cloudHostname,
          sendsafelyAttachment,
        },
        null,
        2
      ),
    }

    const ticketFields = omit(config, 'description')

    const custom_fields = reduce<typeof ticketFields, ZDCustomFields>(
      ticketFields,
      (result, field, key) => {
        const id: number = field.fieldId
        const value = initialValues[key as keyof CustomFields]
        if (!value) return result
        return [
          ...result,
          {
            id,
            value,
          },
        ]
      },
      []
    )

    return {
      followup_source_id: followup_source_id || FIELD_FALLBACK_VALUE_NA,
      ticket_form_id: ZENDESK_UISP_FORM_KEY,
      requester,
      tags: getTags({
        isFollowUp: !!followup_source_id,
        isOrgAccount: accountType === AccountType.ORG_OWNER,
      }),
      subject: createSubject(values),
      comment: { body: values.description },
      custom_fields,
    }
  }

  return { formatRequestedTicket }
}
