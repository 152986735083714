import { ShadowData } from 'api/nca/types'
import { SupportFormState } from 'features/support/unifi/types'
import { Tech } from '../config/types'

const getVersion = (
  deviceApps: ShadowData['state']['reported']['controllers'],
  app: string
) => {
  const deviceApp = deviceApps.find((item) => item.name === app)
  return deviceApp?.version ?? undefined
}

const getAppVersion = (
  deviceApps: ShadowData['state']['reported']['controllers'],
  selectedTech?: Tech
): string | undefined => {
  switch (selectedTech) {
    case Tech.wifi:
    case Tech.routingAndSwitching:
    case Tech.securityAndVPN:
      return getVersion(deviceApps, 'network')
    case Tech.protect:
      return getVersion(deviceApps, 'protect')
    case Tech.talk:
      return getVersion(deviceApps, 'talk')
    case Tech.access:
      return getVersion(deviceApps, 'access')
    case Tech.connect:
      return getVersion(deviceApps, 'connect')
    case Tech.identity:
      return getVersion(deviceApps, 'uid-agent')
    default:
      return
  }
}

const getAppVersions = (
  deviceApps: ShadowData['state']['reported']['controllers']
) => {
  let apps = ''
  const installedApps = deviceApps.filter((app) => app.isInstalled)
  installedApps.forEach((app, i) => {
    apps += `${app.name.toUpperCase()} -> installed version: ${
      app.version
    } - available update: ${
      !app.updateAvailable ? 'app up to date' : app.updateAvailable
    }`
    if (i !== installedApps.length - 1) {
      apps += ' || '
    }
  })
  return apps
}

export const getConsoleValues = (
  formState: SupportFormState,
  shadow?: ShadowData['state']['reported']
) => {
  return {
    version: shadow?.version,
    fwVersion: shadow?.hardware.firmwareVersion,
    releaseChannel: shadow?.releaseChannel,
    appVersion: getAppVersion(shadow?.controllers ?? [], formState.tech),
    appVersions: getAppVersions(shadow?.controllers ?? []),
  }
}
