import React, { ForwardRefRenderFunction, forwardRef } from 'react'
import ExternalIcon from '@ubnt/icons/ExternalIcon'
import { FormattedMessage } from 'react-intl'
import theme, { typography } from 'theme'
import styled from 'theme/styled'
import { BubbleWrapper } from './Wrappers'

type BubbleProps = React.PropsWithChildren<{
  question: string | null
  isVisible?: boolean
  error?: React.ReactNode
  extraText?: boolean
  ref?: React.RefObject<HTMLDivElement>
}>

const BubbleWithRef: ForwardRefRenderFunction<HTMLDivElement, BubbleProps> = (
  { question, children, isVisible = true, extraText },
  ref
) => {
  return (
    <BubbleWrapper $isVisible={isVisible} ref={ref}>
      <QuestionRow>
        <Question>{question && <FormattedMessage id={question} />}</Question>
        {extraText && (
          <ExtraInfo
            href="https://help.ui.com/hc/en-us/articles/360049956374-UniFi-Getting-Support-Files-and-Logs"
            target="_blank"
          >
            <FormattedMessage id="SUPPORT_ATTACHMENT_EXTRA_INFO" />
            <ExternalIcon size="original" />
          </ExtraInfo>
        )}
      </QuestionRow>
      {children}
    </BubbleWrapper>
  )
}

const QuestionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${theme.media.tabletLarge}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  @media (max-width: ${theme.media.mobileXLarge}) {
    align-items: center;
    text-align: center;
  }
`

const Question = styled.span`
  font: ${typography['header-label']};
  color: ${({ theme }) => theme.neutral10};
`
const ExtraInfo = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.ublue06};
`

export const Bubble = forwardRef(BubbleWithRef)
