import {
  fetchRequestWithCookies,
  postRequestWithCookies,
  putRequestWithCookies,
} from '../axios'

export interface ProfileData {
  username?: string
  email?: string
  first_name?: string
  last_name?: string
  twofa_enabled?: boolean
  session_ttl_seconds?: number
  picture?: null | string
  password?: string
  old_password?: string
  default_mfa?: string | null
  last_login?: string
  default_address?: string
  trusted_session_ttl_seconds?: number | null
  email_change_verification_code?: string
  mailinglist?: boolean
  newsletter?: {
    announcements: boolean
    unifi: boolean
    uisp: boolean
  }
}

export interface SelfData extends ProfileData {
  fields_missing: string[]
  is_verified: boolean
  time_created: string
  curr_privacy_rev: string
  curr_terms_rev: string
  accounts: {
    lithium?: string
    magento?: string
    lte?: string
  }
  ivr: string
  uuid: string
}

const {
  api: { ssoUi, sso },
} = __CONFIG__

export class SsoProfileApi {
  private getBaseUrl = (): string => {
    if (window.location.host.match(/\.ui\.com/)) {
      return ssoUi.base
    }
    return sso.base
  }

  public get = async () => {
    const url = new URL(sso.paths.self, this.getBaseUrl())

    const { data } = await fetchRequestWithCookies<SelfData>(url.href)

    return data
  }

  public update = async (updateData: Partial<SelfData>) => {
    const url = new URL(sso.paths.self, this.getBaseUrl())

    const { data } = await putRequestWithCookies<Partial<SelfData>, SelfData>(
      url.href,
      updateData
    )

    return data
  }

  public updatePicture = async (file: File) => {
    const url = new URL(sso.paths.picture, this.getBaseUrl())

    const { data } = await postRequestWithCookies<File, { picture: string }>(
      url.href,
      file
    )

    return data
  }
}
