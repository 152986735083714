import { DateTime } from 'luxon'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { getSubscriptionProductLine } from 'features/subscriptions/fullSubscriptions/utils'
import { showSubscriptionFailedPaymentButtons } from 'features/subscriptions/utils'
import { SubscriptionUrl, ProductName } from 'types/enums'
import { CANCEL_SUBSCRIPTION_MODAL_ID } from './CancelSubscriptionModal'
import { EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID } from './ExternalCancelSubscriptionModal'
import { SITE_NAME_TO_HIDE } from '../subscriptions/utils'

// All subscription services share the columns for billing date
// payment, amount and the more popover
const commonColumnTitles = [
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLING_DATE',
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_PAYMENT',
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_AMOUNT',
  undefined,
]
const commonColumnSizes = '100px 134px 100px 24px'

const commonColumnTitlesOrgAdmin = [
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLING_DATE',
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_AMOUNT',
]
const commonColumnSizesOrgAdmin = '100px 100px'

export const getSubscriptionServiceFromUrl = (url: string) => {
  switch (url) {
    case SubscriptionUrl.UNIFI_TALK: {
      return ProductName.UNIFI_TALK
    }
    case SubscriptionUrl.SITE_SUPPORT: {
      return ProductName.SITE_SUPPORT
    }
    case SubscriptionUrl.MSP_SITE_SUPPORT: {
      return ProductName.MSP_SITE_SUPPORT
    }
    case SubscriptionUrl.ULTE: {
      return ProductName.ULTE
    }
    case SubscriptionUrl.HOSTING: {
      return ProductName.HOSTING
    }
    case SubscriptionUrl.CYBERSECURE: {
      return ProductName.CYBERSECURE
    }
    case SubscriptionUrl.MOBILITY: {
      return ProductName.MOBILITY
    }
    case SubscriptionUrl.UISP_CLOUD: {
      return ProductName.UISP_CLOUD
    }
    case SubscriptionUrl.OTHERS: {
      return ProductName.OTHERS
    }
    default:
      return null
  }
}

export const getSubscriptionCancelModalId = (productLine: string) => {
  switch (productLine) {
    case ProductName.UNIFI_TALK:
    case ProductName.CYBERSECURE:
    case ProductName.ULTE:
    case ProductName.SITE_SUPPORT:
    case ProductName.MSP_SITE_SUPPORT: {
      return CANCEL_SUBSCRIPTION_MODAL_ID
    }
    case ProductName.MOBILITY:
    case ProductName.HOSTING:
    case ProductName.UISP_CLOUD: {
      return EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID
    }
    default:
      return null
  }
}

export const getSubscriptionColumns = (
  productLine: string,
  isAdmin: boolean
) => {
  const extraColumns = isAdmin ? commonColumnTitlesOrgAdmin : commonColumnTitles
  const extraColumnSizes = isAdmin
    ? commonColumnSizesOrgAdmin
    : commonColumnSizes

  switch (productLine) {
    case ProductName.UNIFI_TALK: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_SITE_NAME',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_QUANTITY',
          ...extraColumns,
        ],
        template: `1fr 80px ${extraColumnSizes}`,
      }
    }
    case ProductName.OTHERS:
    case ProductName.MSP_SITE_SUPPORT: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_PRODUCT',
          ...commonColumnTitles,
        ],
        template: `1fr ${commonColumnSizes}`,
      }
    }
    case ProductName.SITE_SUPPORT: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE_NAME',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE',
          ...extraColumns,
        ],
        template: `1fr 120px ${extraColumnSizes}`,
      }
    }
    case ProductName.ULTE: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_UNIFI_CONSOLE',
          ...extraColumns,
        ],
        template: `1fr ${extraColumnSizes}`,
      }
    }
    case ProductName.HOSTING: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_UNIFI_DEVICES',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_QUANTITY',
          ...extraColumns,
        ],
        template: `1fr 80px ${extraColumnSizes}`,
      }
    }
    case ProductName.CYBERSECURE: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE_NAME',
          ...extraColumns,
        ],
        template: `1fr ${extraColumnSizes}`,
      }
    }
    case ProductName.MOBILITY: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_NAME_LOC',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DATA_LIMIT',
          ...extraColumns,
        ],
        template: `1fr 120px ${extraColumnSizes}`,
      }
    }
    case ProductName.UISP_CLOUD: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_PLAN',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_QUANTITY',
          ...extraColumns,
        ],
        template: `1fr 80px ${extraColumnSizes}`,
      }
    }
    default:
      return null
  }
}

export const getFormattedDate = (date: number) => {
  return DateTime.fromSeconds(date).toFormat('MMM d, yyyy')
}

export const getSearchedSubscriptions = (
  subscriptions: FullSubscription[],
  group: string,
  searchTerm: string
) => {
  const searchValue = searchTerm.toLowerCase()
  return subscriptions.filter((subscription) => {
    if (group === ProductName.CYBERSECURE) {
      return (
        (subscription.metadata?.site_name.toLowerCase().includes(searchValue) &&
          subscription.metadata?.site_name !== SITE_NAME_TO_HIDE) ||
        subscription.metadata?.device_name
          .toLowerCase()
          .includes(searchValue) ||
        subscription.metadata?.device_shortname
          .toLowerCase()
          .includes(searchValue)
      )
    }
    if (group === ProductName.UNIFI_TALK) {
      return (
        subscription.metadata?.ui_device_name
          .toLowerCase()
          .includes(searchValue) ||
        subscription.name.toLowerCase().includes(searchValue)
      )
    }
    if (group === ProductName.SITE_SUPPORT) {
      return subscription.metadata?.deviceName
        .toLowerCase()
        .includes(searchValue)
    }
    if (group === ProductName.ULTE) {
      return (
        (subscription.metadata?.site.toLowerCase().includes(searchValue) &&
          subscription.metadata?.site !== SITE_NAME_TO_HIDE) ||
        subscription.metadata?.controller.toLowerCase().includes(searchValue) ||
        subscription.name.toLowerCase().includes(searchValue)
      )
    }
    if (group === ProductName.MOBILITY) {
      const umrData = subscription.deviceUmrInfo?.find(
        (umr) => umr.stripe_id === subscription.id
      )
      return (
        umrData?.device.name.toLowerCase().includes(searchValue) ||
        subscription.metadata?.ui_device_name
          .toLowerCase()
          .includes(searchValue) ||
        subscription.name.toLowerCase().includes(searchValue)
      )
    }
    // this is just the fallback
    return []
  })
}

export const shouldPopoverDisplay = (
  subscription: FullSubscription,
  orgToFetch: string | null
) => {
  if (orgToFetch) {
    return false
  }

  const { cancelAtPeriodEnd, status, upcomingInvoice } = subscription
  const isSubscriptionCancelled = status === 'canceled' || cancelAtPeriodEnd
  const isCyberSecure =
    getSubscriptionProductLine(subscription.product?.name) ===
    ProductName.CYBERSECURE
  const subHasDefaultSource =
    !subscription.isSuspended &&
    (subscription.default_source || subscription.default_payment_method)
  const showPayInvoice =
    showSubscriptionFailedPaymentButtons(subscription) && subHasDefaultSource

  const hasExtraOptions = upcomingInvoice || showPayInvoice

  if (isSubscriptionCancelled && !isCyberSecure && !hasExtraOptions) {
    return false
  }

  if (isCyberSecure && status === 'canceled' && !hasExtraOptions) {
    return false
  }

  return true
}
