import React, { Key, useContext, useEffect } from 'react'
import SitesIcon from '@ubnt/icons/SitesIcon'
import { Dropdown, type DropdownOption } from '@ubnt/ui-components/Dropdown'
import { useHistory } from 'react-router-dom'
import { MY_ORG, MY_SITES } from 'sharedConstants'
import { OrganizationRole } from 'api/organization'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'
import { useOrganizationsQuery } from 'store/queries/useOrganizationsQuery'
import styled from 'theme/styled'
import { getOrgImage } from './utils'

export const OrganizationManagerDropdown = () => {
  const { organizations, isOwnerAndAdmin } = useOrganizationsQuery()
  const { accountViewId, handleAccountViewIdChange } =
    useContext(OrganizationContext)
  const history = useHistory()

  const options: DropdownOption[] = isOwnerAndAdmin
    ? []
    : [
        {
          label: 'My Sites',
          value: MY_SITES,
          image: <SitesIcon size="original" />,
        },
      ]

  organizations?.forEach(({ name, orgId, logoUrl, role }) => {
    options.push({
      label: name,
      value: role === OrganizationRole.ORG_OWNER ? MY_ORG : orgId,
      image: getOrgImage(logoUrl),
    })
  })

  useEffect(() => {
    if (isOwnerAndAdmin && accountViewId === MY_SITES) {
      handleAccountViewIdChange(MY_ORG)
    }
  }, [accountViewId, handleAccountViewIdChange, isOwnerAndAdmin])

  const handleOnChange = (_options: DropdownOption, value: Key) => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.get('org')) {
      queryParams.delete('org')
      history.replace({
        search: queryParams.toString(),
      })
    }
    handleAccountViewIdChange(value.toString())
  }

  return (
    <DropdownWrapper>
      <Dropdown
        options={options}
        variant="secondary"
        width="100%"
        value={accountViewId}
        onChange={handleOnChange}
        imageHeight={20}
        imageWidth={20}
      />
    </DropdownWrapper>
  )
}

const DropdownWrapper = styled.div`
  margin-bottom: 16px;
`
