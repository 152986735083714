import React from 'react'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { media, typography } from 'theme'
import styled from 'theme/styled'

type Props = {
  icon?: JSX.Element
  activeIcon?: JSX.Element
  label: string
  onClick: () => void
  isActive?: boolean
  deviceName?: string
}

export const DeviceTile: React.FC<Props> = ({
  icon,
  label,
  onClick,
  isActive,
  deviceName,
  activeIcon,
}) => {
  const displayActiveIcon = !!activeIcon && isActive
  return (
    <Wrapper
      $isActive={isActive}
      $hasIcon={!!icon}
      onClick={onClick}
      type="button"
    >
      {displayActiveIcon ? activeIcon : icon}
      <TextWrapper $hasIcon={!!icon}>
        {deviceName && (
          <Tooltip noWrap>
            <DeviceName $isActive={isActive}>{deviceName}</DeviceName>
          </Tooltip>
        )}
        <Device $isActive={isActive}>{label}</Device>
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.button<{
  $isActive?: boolean
  $hasIcon?: boolean
}>`
  min-height: 100%;
  height: ${({ $hasIcon }) => (!$hasIcon ? '90px' : '120px')};
  padding: ${({ $hasIcon }) => ($hasIcon ? '6px 16px 16px' : '8px 16px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.ublue06 : theme.neutral02};
  border: none;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, $isActive }) =>
      !$isActive ? theme.neutral03 : theme.ublue06};
  }

  @media (max-width: ${media.mobileLarge}) {
    height: ${({ $hasIcon }) => (!$hasIcon ? '50px' : '76px')};
    padding: 8px 16px;
    flex-direction: row;
    justify-content: ${({ $hasIcon }) => ($hasIcon ? 'flex-start' : 'center')};
    gap: 8px;
  }
`

const TextWrapper = styled.div<{
  $hasIcon?: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: clip;

  @media (max-width: ${media.mobileLarge}) {
    width: ${({ $hasIcon }) => ($hasIcon ? 'calc(100% - 60px)' : '100%')};
  }
`

const DeviceName = styled.span<{ $isActive?: boolean }>`
  display: block;
  font: ${typography['device-name']};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.neutral00 : theme.text2};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Device = styled.span<{ $isActive?: boolean; $hasDeviceName?: boolean }>`
  display: block;
  font: ${typography['header-label']};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.neutral00 : theme.text2};
  text-align: center;

  ${({ $hasDeviceName }) =>
    $hasDeviceName &&
    `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  `}
`
