import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { ADD_MFA_PROMPT_MODAL_ID } from 'components/addMfaPrompt/AddMFAPrompt'
import { setVisibleModal } from 'modules/modals'

const {
  api: { accountBE },
} = __CONFIG__

export const useShowAddMFAPrompt = () => {
  const dispatch = useDispatch()
  const [showPopup, setShowPopup] = useState<boolean | undefined>()

  useEffect(() => {
    const getMFAPopupStatus = async () => {
      try {
        const { href } = new URL(accountBE.paths.userMFAPopup, accountBE.base)
        const { data } = await axios.get(href, {
          withCredentials: true,
        })
        setShowPopup(data.show_mfa_popup)
      } catch (error) {
        // fail silently
      }
    }

    if (showPopup === undefined) {
      getMFAPopupStatus()
    }

    if (showPopup) {
      dispatch(setVisibleModal(ADD_MFA_PROMPT_MODAL_ID))
    }
  }, [dispatch, showPopup])
}
