import React, { useEffect, useMemo } from 'react'
import { Input } from '@ubnt/ui-components/Input'
import { FormattedMessage } from 'react-intl'
import { useBrazilPostalCodeQuery } from 'store/queries/useBrazilPostalCodeQuery'
import { InputWrapper, MultipleInputsWrapper, Title } from '../../Components'
import { FormProps } from '../types'
import { parsePostalCode } from './utils'

export const BrazilCardAddressForm: React.FC<
  FormProps & {
    setAddressFocused: React.Dispatch<React.SetStateAction<boolean>>
  }
> = ({
  handleBlur,
  values,
  handleChange,
  touched,
  errors,
  addressError,
  submitError,
  setAddressFocused,
  setFieldValue,
  isEditPaymentMethod,
}) => {
  const validPostalCode = useMemo(() => {
    if (values.postal_code && !errors.postal_code && !addressError) {
      return parsePostalCode(values.postal_code)
    }
    return ''
  }, [addressError, errors.postal_code, values.postal_code])

  const { addressInfo, isAddressInfoFetched } =
    useBrazilPostalCodeQuery(validPostalCode)

  useEffect(() => {
    if (
      !isEditPaymentMethod &&
      isAddressInfoFetched &&
      addressInfo?.state_abbr
    ) {
      setFieldValue('state', addressInfo.state_abbr)
      setFieldValue('neighborhood', addressInfo.neighborhood)
      setFieldValue('city', addressInfo.city)
    }
  }, [
    setFieldValue,
    isAddressInfoFetched,
    isEditPaymentMethod,
    addressInfo?.state_abbr,
    addressInfo?.state,
    addressInfo?.neighborhood,
    addressInfo?.city,
  ])

  return (
    <>
      <Title>
        <FormattedMessage id="SETTINGS_PAYMENTS_BILLING_ADDRESS" />
      </Title>
      <InputWrapper>
        <Input
          name="postal_code"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_POSTAL_CODE" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={
            (touched.postal_code &&
              (errors.postal_code || addressError?.['postal_code'])) ||
            !!submitError
          }
          value={values.postal_code}
          autoComplete="new-postal-code"
          onFocus={() => setAddressFocused(true)}
          full
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="bill_addr_street"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STREET_ADDRESS" />}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          invalid={
            touched.bill_addr_street &&
            (errors.bill_addr_street || addressError?.['bill_addr_street'])
          }
          value={values.bill_addr_street}
          autoComplete="new-street"
          full
        />
      </InputWrapper>
      <MultipleInputsWrapper>
        <Input
          name="number"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STREET_NUMBER" />}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          invalid={
            touched.number && (errors.number || addressError?.['number'])
          }
          value={values.number}
          autoComplete="new-number"
        />
        <Input
          name="neighborhood"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_NEIGHBORHOOD" />}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          invalid={
            touched.neighborhood &&
            (errors.neighborhood || addressError?.['neighborhood'])
          }
          value={values.neighborhood}
          autoComplete="new-neighborhood"
        />
      </MultipleInputsWrapper>
      <InputWrapper>
        <Input
          name="city"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_CITY" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={touched.city && (errors.city || addressError?.['city'])}
          value={values.city}
          autoComplete="new-city"
          full
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="state"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STATE" />}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          invalid={touched.state && (errors.state || addressError?.['state'])}
          value={values.state}
          autoComplete="new-state"
          onFocus={() => setAddressFocused(true)}
          full
        />
      </InputWrapper>
    </>
  )
}
