import React from 'react'
import ArrowLeftSecondaryIcon from '@ubnt/icons/ArrowLeftSecondaryIcon'
import Button from '@ubnt/ui-components/Button/Button'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'theme/styled'

export const BackButton: React.FC<{ to?: string; messageId?: string }> = ({
  to,
  messageId,
}) => {
  const history = useHistory()

  if (!to) {
    return (
      <StyledButton
        onClick={() => {
          history.goBack()
        }}
        Icon={ArrowLeftSecondaryIcon}
      >
        <FormattedMessage id={messageId || 'COMMON_ACTION_BACK'} />
      </StyledButton>
    )
  }

  return (
    <StyledButton
      onClick={() => {
        history.push(to)
      }}
      Icon={ArrowLeftSecondaryIcon}
    >
      <FormattedMessage id={messageId || 'COMMON_ACTION_BACK'} />
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  width: max-content;
  padding-left: 8px;
  border: ${({ theme }) => `1px solid ${theme.neutral03}`};
  background-color: ${({ theme }) => theme.neutral00};
`
