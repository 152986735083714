import React, { useCallback, useEffect, useRef } from 'react'
import { DropdownOption } from '@ubnt/ui-components/Dropdown'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import { Bubble } from 'components/support/Bubble'
import { DeviceDropdown } from 'components/support/DeviceDropdown'
import { DeviceTile } from 'components/support/DeviceTile'
import { TilesLoader } from 'components/support/TilesLoader'
import { BubbleWrapper, TilesWrapper } from 'components/support/Wrappers'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { BubbleProps, SupportConsole } from './types'
import { DROPDOWN_BREAKPOINT, ELEMENT_COUNT } from '../constants'
import { ParsedNCAConsole } from '../unifi/types'
import { console as device } from './config/ConfigOptions'
import { BillingSpecificService, Console } from './config/types'
import { UmrConsoleBubble } from './UmrConsole'
import { getNextSection } from './utils/getNextSection'
import { parseNCAdata } from '../unifi/utils/parseNCAdata'

export const ConsoleBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
  errors,
  touched,
  setFieldTouched,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.billingSpecificService])

  const { selectValidUcoreDevices: consoles, areNcaDevicesLoading } =
    useNcaDevicesQuery()

  const userConsoles = parseNCAdata(consoles, device)

  const onChange = (option: DropdownOption) => {
    const selectedConsole = userConsoles.find(
      ({ mac }) => mac === option.additionalInfo
    )
    if (selectedConsole) {
      return handleClick(selectedConsole)
    }
    return handleClick({ id: Console.other, ...option })
  }

  const handleClick = useCallback(
    (value?: string | boolean | SupportConsole) => {
      const section = getNextSection(config, value, 'console', values)
      handleChange('console', value, section)
    },
    [config, handleChange, values]
  )

  useEffect(() => {
    if (
      !areNcaDevicesLoading &&
      !consoles.length &&
      !values.console?.id &&
      values.billingSpecificService !== BillingSpecificService.umr
    ) {
      const undetectedOption = {
        id: Console.undetected,
        label: 'No device was detected',
      }
      handleClick(undetectedOption)
    }
  }, [
    areNcaDevicesLoading,
    handleClick,
    consoles.length,
    values.billingSpecificService,
    values.console?.id,
  ])

  if (values.billingSpecificService === BillingSpecificService.umr) {
    return (
      <UmrConsoleBubble
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldTouched={setFieldTouched}
        config={config}
      />
    )
  }

  if (areNcaDevicesLoading) {
    return (
      <BubbleWrapper $isVisible ref={ref}>
        <SkeletonLoader>
          <Text width={220} />
        </SkeletonLoader>
        <TilesLoader numberOfTiles={ELEMENT_COUNT} />
      </BubbleWrapper>
    )
  }

  if (!consoles.length && !areNcaDevicesLoading && values.console?.id) {
    return null
  }

  const renderDevices = () => {
    const otherOption = device[device.length - 1]
    const allConsoleOptions: ParsedNCAConsole[] = [...userConsoles, otherOption]
    if (allConsoleOptions.length > DROPDOWN_BREAKPOINT) {
      return (
        <DeviceDropdown
          userConsoles={userConsoles}
          otherOption={otherOption}
          handleChange={onChange}
          value={values.console}
        />
      )
    }

    return (
      <TilesWrapper>
        {allConsoleOptions.map((el) => (
          <DeviceTile
            key={`${el.id}-${el.mac}`}
            icon={el.icon}
            label={el.label}
            onClick={() => handleClick(el)}
            deviceName={el.deviceName}
            isActive={
              el.id === Console.other
                ? el.id === values.console?.id
                : el.mac === values.console?.mac
            }
          />
        ))}
      </TilesWrapper>
    )
  }

  return (
    <Bubble question={config.console.fieldTitle} ref={ref}>
      {renderDevices()}
    </Bubble>
  )
}
