import { ChatFileChatEventData, ChatMsgChatEventData } from 'zchat-browser'

type WidgetState = {
  isMinimised: boolean
  isInitialised: boolean
  isConnected: boolean
  isEnded: boolean
}

type Review = {
  rating: 'good' | 'bad' | null
  comment?: string
  isOpen: boolean
  isConfirm: boolean
}

type ExtraInfo = {
  isAgentTyping: boolean
  agentName?: string
  queuePosition?: number
}

type Settings = {
  departmentId: number
  tagsToRemove: string[]
  tagsToAdd: string[]
  sandboxDepartmentId: number
}

type ChatTextMessage = Omit<ChatMsgChatEventData, 'options' | 'structured_msg'>

export type ChatContextType = {
  messages: (ChatTextMessage | ChatFileChatEventData)[]
  setMessages: React.Dispatch<
    React.SetStateAction<(ChatTextMessage | ChatFileChatEventData)[]>
  >
  sendUserMessage: (message: string) => void
  widgetState: WidgetState
  setWidgetState: React.Dispatch<React.SetStateAction<WidgetState>>
  extraInfo: ExtraInfo
  setExtraInfo: React.Dispatch<React.SetStateAction<ExtraInfo>>
  initialMessage?: string
  setInitialMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  review: Review
  setReview: React.Dispatch<React.SetStateAction<Review>>
  settings: Settings
  setSettings: React.Dispatch<React.SetStateAction<Settings>>
  endChat: () => void
  isChatOpen: boolean | undefined
  setIsChatOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export type MessageProps = {
  key: number
  text?: string
  shouldScroll: boolean
  username?: string
  shouldHideAvatar: boolean
  isInitialMessage?: boolean
  attachment?: ChatFileChatEventData['attachment']
}

export const isTextMessageType = (
  data: ChatTextMessage | ChatFileChatEventData
): data is ChatTextMessage => data.type === 'chat.msg'
