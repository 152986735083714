import React from 'react'
import { ArrowLineDownPrimaryIcon, ArrowLineUpPrimaryIcon } from '@ubnt/icons'
import { useIntl } from 'react-intl'
import styled from 'theme/styled'
import { GridRow, HeaderCell } from './Backups.styles'
import { SortState } from './types'

type BackupsGridHeaderRowProps = {
  onClick: () => void
  sort: SortState
}

export const BackupsGridHeaderRow: React.FC<BackupsGridHeaderRowProps> = ({
  onClick,
  sort,
}) => {
  const intl = useIntl()
  return (
    <GridRow>
      <SitesWrapper>
        <HeaderCell variant="heading-medium">
          {intl.formatMessage({ id: 'SETTINGS_BACKUPS_GRID_HEADER_SITES' })}
        </HeaderCell>
        {sort === SortState.ASC_NAME ? (
          <ArrowLineDownPrimaryIcon size={14} onClick={onClick} />
        ) : (
          <ArrowLineUpPrimaryIcon size={14} onClick={onClick} />
        )}
      </SitesWrapper>
      <HeaderCell variant="heading-medium" className="extraData">
        {intl.formatMessage({ id: 'SETTINGS_BACKUPS_GRID_HEADER_MAC' })}
      </HeaderCell>
      <HeaderCell variant="heading-medium">
        {intl.formatMessage({ id: 'SETTINGS_BACKUPS_GRID_HEADER_TYPE' })}
      </HeaderCell>
    </GridRow>
  )
}

const SitesWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
`
