import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { SelfData, SsoProfileApi } from 'api/sso/profile'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { parseSsoProfileErrors } from 'store/utils'
import { SsoApiError } from 'types/ssoErrors'
import { QueryKey } from '../types'

export const useSsoProfileQuery = (
  api: SsoProfileApi = new SsoProfileApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const profileData = useQuery<SelfData, AxiosError<SsoApiError>>({
    queryKey: [QueryKey.SSO_PROFILE],
    queryFn: api.get,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const isEmployeeAccount = useMemo(() => {
    const userEmail = profileData.data?.email
    const isVerified = profileData.data?.is_verified
    return (
      isVerified &&
      (userEmail?.endsWith('@ui.com') || userEmail?.endsWith('@ubnt.com'))
    )
  }, [profileData.data?.email, profileData.data?.is_verified])

  return {
    profileData: profileData.data,
    isProfileDataFetching: profileData.isFetching,
    isProfileDataFetched: profileData.isFetched,
    profileDataError: parseSsoProfileErrors(profileData.error),
    isEmployeeAccount,
  }
}
