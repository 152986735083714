import { ExclamationMarkIcon } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components/aria'
import { Input } from '@ubnt/ui-components/Input'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { media } from 'theme'
import styled from 'theme/styled'

export const Wrapper = styled.div`
  padding: 16px;
  display: grid;
  border: 1px solid ${({ theme }) => theme.neutral03};
  border-radius: 6px;
`

export const SearchField = styled(Input)`
  max-width: 248px;
  margin-bottom: 16px;
`

export const GridRow = styled.div<{ $selected?: boolean }>`
  padding: 6px 4px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-top: 1px solid ${({ theme }) => theme.neutral03};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, $selected }) =>
      !$selected && theme.neutral01};
  }

  ${({ theme, $selected }) =>
    $selected && `background-color: ${theme.ublue01}`};

  @media (max-width: ${media.mobileXLarge}) {
    grid-template-columns: 1fr 1fr 1fr;

    .extraData {
      display: none;
    }
  }
`

export const HeaderCell = styled(Text)`
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
`

export const ValueCell = styled(Text)`
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.text2};
`

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    align-items: start;
    justify-content: start;
    flex-direction: column;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    padding-left: 5px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ModalMessage = styled.div`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
`

const EmptyStateContainer = styled.div`
  height: 524px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon)`
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.orange06};
`

const EmptyStateContent = styled.p`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-body']};
  margin: 0;
  padding: 16px 0;
  text-align: center;
`

const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export {
  EmptyStateContainer,
  EmptyStateContent,
  HeaderWrapper,
  ModalMessage,
  PageHeader,
  StyledExclamationMarkIcon,
  WarningIconContainer,
}
