import React from 'react'
import { ArrowDownTertiaryIcon, AttachmentIcon } from '@ubnt/icons'
import { FormattedMessage } from 'react-intl'
import { typography } from 'theme'
import styled from 'theme/styled'
import { UPLOAD_ATTACHMENTS_ID } from './RequestComment'

type Props = {
  togglePanel: (id: string) => void
  itemExpanded: boolean
}

export const UploadDocumentsLabel: React.FC<Props> = ({
  togglePanel,
  itemExpanded,
}) => {
  const handleClick = () => {
    togglePanel(UPLOAD_ATTACHMENTS_ID)
  }

  return (
    <Wrapper onClick={handleClick}>
      <AttachmentIcon isActive variant="outline" size={20} />
      <Label>
        <FormattedMessage id="REQUEST_UPLOAD_ATTACHMENT_BUTTON" />
      </Label>
      <AccordionArrow
        isActive
        variant="twoTone"
        size={20}
        isExpanded={itemExpanded}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
  cursor: pointer;
`

const Label = styled.span`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.ublue06};
`

const AccordionArrow = styled(ArrowDownTertiaryIcon, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>`
  transition: transform 0.2s;
  ${({ isExpanded }) => isExpanded && 'transform: rotate(180deg);'};
`
