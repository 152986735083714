import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { BillingTaxIdApi } from 'api/billing/billingTaxId'
import { closeVisibleModal } from 'modules/modals'
import { useToasts } from 'pages/payments/components/useToasts'
import { QueryKey } from 'store/types'
import { getTaxIdMutationErrorMessage } from './utils'

export const useUpdateTaxId = (
  source?: 'paymentMethod',
  api: BillingTaxIdApi = new BillingTaxIdApi()
) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { taxIdSuccessToast, taxIdErrorToast } = useToasts()

  const updateTaxId = useMutation({
    mutationFn: api.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_TAX_ID] })
      queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_CUSTOMER] })
      if (source !== 'paymentMethod') {
        dispatch(closeVisibleModal())
      }
      taxIdSuccessToast('updated')
    },
    onError: (error) => {
      const errorMessage = getTaxIdMutationErrorMessage(error, intl)

      taxIdErrorToast(errorMessage)
    },
  })

  return {
    updateTaxId: updateTaxId.mutate,
    updateTaxIdAsync: updateTaxId.mutateAsync,
    isUpdateTaxIdLoading: updateTaxId.isPending,
    isUpdateTaxIdError: updateTaxId.isError,
  }
}
