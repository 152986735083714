import { HeaderFadeIn } from 'components/animations'
import styled from 'theme/styled'

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
  padding-top: 16px;
`

export const BubbleWrapper = styled.div<{ $isVisible: boolean }>`
  padding: 24px;
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.neutral00};
  border-radius: 8px;
  box-shadow: 0px 0px 30px 0px rgba(49, 98, 125, 0.1);
  animation: ${HeaderFadeIn} 0.2s linear;
`

export const TilesWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-auto-rows: 1fr;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    grid-template-columns: minmax(0, 1fr);
  }
`
