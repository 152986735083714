import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { BillingUiApi } from 'api/billing/billingUi'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'

export const useBillingUiTaxIdQuery = (
  api: BillingUiApi = new BillingUiApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const taxIds = useQuery({
    queryKey: [QueryKey.UI_TAX_ID],
    queryFn: api.getAllTaxIds,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const uiTaxIds = useMemo(() => {
    if (!taxIds.data) {
      return []
    }

    const { data } = taxIds

    return Object.entries(data).map(([region, data]) => {
      const ids = data.data.map(({ value }) => value)
      return { ids, region }
    })
  }, [taxIds])

  return { uiTaxIds, areUiTaxIdsLoading: taxIds.isLoading }
}
