import React from 'react'
import { useIntl } from 'react-intl'
import { useMfaBackupCodes } from 'store/mutations/mfa/useMfaBackupCodes'
import { GenericMFAModal } from '../GenericMFAModal'

export const BACKUP_CODES_PROMPT_MODAL_ID = 'BACKUP_CODES_PROMPT_MODAL_ID'

export const BackupCodesPromptModal: React.FC = () => {
  const intl = useIntl()
  const { generateBackupCodes, isGeneratingBackupCodes } = useMfaBackupCodes()

  return (
    <GenericMFAModal
      modalId={BACKUP_CODES_PROMPT_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_TITLE',
      })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_BACKUP_CODE_PROMPT_DESCRIPTION',
      })}
      primaryButton={{
        text: intl.formatMessage({
          id: 'COMMON_ACTION_REGENERATE',
        }),
        variant: 'danger',
        onClick: () => {
          generateBackupCodes()
        },
      }}
      isLoading={isGeneratingBackupCodes}
    />
  )
}
