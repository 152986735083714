import React, { useCallback } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { BackupItem } from 'api/nca/types'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useDeleteBackups } from 'store/mutations/nca/useDeleteBackups'
import { useNcaArchivedBackupsQuery } from 'store/queries/useNcaArchivedBackupsQuery'
import { useNcaBackupsQuery } from 'store/queries/useNcaBackupsQuery'
import { ModalMessage } from './Backups.styles'

interface Props {
  backups?: BackupItem[]
}

const DeleteBackupModal: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, backups }) => {
  const { areNcaBackupsLoading } = useNcaBackupsQuery()
  const { areNcaArchivedBackupsLoading } = useNcaArchivedBackupsQuery()
  const { areDeleteBackupsLoading, deleteBackups } = useDeleteBackups()

  const isFetchLoading = areNcaBackupsLoading || areNcaArchivedBackupsLoading
  const isLoading = isFetchLoading || areDeleteBackupsLoading

  const handleDeleteBackups = useCallback(() => {
    if (backups?.length) {
      deleteBackups({ backups })
    }
  }, [backups, deleteBackups])

  if (!backups?.length) return <></>

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage id="SETTINGS_BACKUPS_DELETE_BACKUP" tagName="div" />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: onClose,
          variant: 'default',
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_DELETE' }),
          type: 'submit',
          variant: 'primary',
          color: 'danger',
          onClick: handleDeleteBackups,
          style: { outline: 'none' },
          loader: isLoading ? 'dots' : undefined,
        },
      ]}
    >
      <ModalMessage>
        <FormattedMessage
          id="SETTINGS_BACKUPS_DELETE_BACKUP_MESSAGE"
          values={{
            backup: backups.length < 2 ? 'this backup' : 'these backups',
          }}
          tagName="div"
        />
      </ModalMessage>
    </GenericModal>
  )
}

const DeleteBackupModalConnected = injectIntl(DeleteBackupModal)

export const DELETE_BACKUP_MODAL_ID = 'DELETE_BACKUP_MODAL_ID'

export default () => (
  <ModalWrapper modalId={DELETE_BACKUP_MODAL_ID}>
    <DeleteBackupModalConnected />
  </ModalWrapper>
)
