import { omit, reduce } from 'lodash-es'
import { FIELD_FALLBACK_VALUE_NA } from 'features/support/constants'
import { ZDCustomFields } from 'features/support/types'
import { getRequester } from 'features/support/unifi/utils/requestUtils'
import { getTags } from 'features/support/utils'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { createSubject } from './createSubject'
import { config } from '../config/formConfig'
import { OrderIssue } from '../config/types'
import { CustomFields, SupportFormState } from '../types'

const { ZENDESK_STORE_FORM_KEY } = __CONFIG__

export const useFormatTicket = () => {
  const { profileData } = useSsoProfileQuery()
  const { accountType } = useOrganizationsQuery()

  const formatRequestedTicket = (
    values: SupportFormState,
    sendsafelyAttachment?: string,
    followup_source_id?: string
  ) => {
    if (!profileData) return
    const requester = getRequester(profileData)

    const initialValues: CustomFields = {
      country: values.country,
      orderIssue: values.orderIssue,
      orderNumber: values.orderNumber,
      attachments: sendsafelyAttachment,
    }

    const ticketFields = omit(config, 'description', 'info')

    const custom_fields = reduce<typeof ticketFields, ZDCustomFields>(
      ticketFields,
      (result, field, key) => {
        const id: number = field.fieldId
        const value = initialValues[key as keyof CustomFields]
        if (!value) return result
        return [
          ...result,
          {
            id,
            value,
          },
        ]
      },
      []
    )

    // TODO: This is here to bypass a current broken ZD field. It should be improved ASAP
    const isTaxExemption = values.orderIssue === OrderIssue.taxExemption
    if (isTaxExemption) {
      const missingValueId = 360036980953
      const isFoundMissingValue = !!custom_fields.find(
        (field) => field.id === missingValueId
      )
      if (!isFoundMissingValue) {
        custom_fields.push({
          id: missingValueId,
          value: FIELD_FALLBACK_VALUE_NA,
        })
      }
    }

    if (isTaxExemption) {
      const missingValueId = 360036980233
      const isFoundMissingValue = !!custom_fields.find(
        (field) => field.id === missingValueId
      )
      if (!isFoundMissingValue) {
        custom_fields.push({
          id: missingValueId,
          value: FIELD_FALLBACK_VALUE_NA,
        })
      }
    }

    return {
      followup_source_id: followup_source_id || FIELD_FALLBACK_VALUE_NA,
      ticket_form_id: ZENDESK_STORE_FORM_KEY,
      requester,
      tags: getTags({
        isFollowUp: !!followup_source_id,
        isOrgAccount: accountType === AccountType.ORG_OWNER,
      }),
      subject: createSubject(values),
      comment: { body: values.description },
      custom_fields,
    }
  }

  return { formatRequestedTicket }
}
