import React from 'react'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import SkeletonLoader from '@ubnt/ui-components/SkeletonLoader/SkeletonLoader'
import styled from 'theme/styled'

export const RequestLoader = () => {
  return (
    <SkeletonLoader>
      <Wrapper>
        <Text size="header-l" width="85%" />
        <Text size="header-l" width="30%" />
        <Block height={300} width="100%" />
        <Text size="header-xl" width="100%" />
      </Wrapper>
    </SkeletonLoader>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
