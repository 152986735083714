import { fetchRequest } from 'api/axios'

type PostalCodeResponse = {
  uf: string // state_abbr
  estado: string // state
  bairro: string // neighborhood
  localidade: string // city
}

export type BrazilAutofillData = {
  city?: string
  neighborhood?: string
  state?: string
  state_abbr?: string
}

const {
  api: { brAutocomplete },
} = __CONFIG__

export class BrazilPostalCodeApi {
  get = async (postalCode: string) => {
    const url = new URL(`ws/${postalCode}/json/`, brAutocomplete.base)

    const { data } = await fetchRequest<PostalCodeResponse>(url.href)

    return data
  }
}
