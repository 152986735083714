import React, { useContext } from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'
import styled from 'theme/styled'
import { GridHeaderRow } from './InvoicesTable.styles'

export const InvoicesTableHeader = () => {
  const { orgToFetch } = useContext(OrganizationContext)

  return (
    <GridHeaderRow $noBorder $isAdmin={!!orgToFetch}>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_STATUS" />
      </HeaderCell>
      <HeaderCell variant="heading-medium" color="text-1">
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_SUBSCRIPTION" />
      </HeaderCell>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_NAME" />
      </HeaderCell>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_BILLING_DATE" />
      </HeaderCell>
      {!orgToFetch && (
        <HeaderCell
          variant="heading-medium"
          color="text-1"
          className="extra-data"
        >
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLED_TO" />
        </HeaderCell>
      )}
      <HeaderCell variant="heading-medium" color="text-1">
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_AMOUNT" />
      </HeaderCell>
      {!orgToFetch && <Placeholder />}
    </GridHeaderRow>
  )
}

const HeaderCell = styled(Text)`
  padding: 0 4px;
`

const Placeholder = styled.div``
