import React, { useEffect, useRef } from 'react'
import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'
import { trouble } from './config/ConfigOptions'
import { UniFiStateValues } from './config/types'
import { setNextSection } from './handleClicks'
import { BubbleProps } from './types'

export const TroubleBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.assistance, values.device])

  return (
    <Bubble question={config.trouble.fieldTitle} ref={ref}>
      <TilesWrapper>
        {trouble.map(({ id, label, condition }) => {
          if (condition?.id !== values.assistance) {
            return null
          }
          return (
            <QuestionTile
              key={id}
              label={label}
              onClick={() =>
                setNextSection(
                  config,
                  id,
                  UniFiStateValues.TROUBLE,
                  values,
                  handleChange
                )
              }
              isActive={values.trouble === id}
            />
          )
        })}
      </TilesWrapper>
    </Bubble>
  )
}
