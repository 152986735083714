import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { TrustedDevicesApi } from 'api/trustedDevices'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useTrustedDevicesQuery = (
  api: TrustedDevicesApi = new TrustedDevicesApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const trustedDevices = useQuery({
    queryKey: [QueryKey.TRUSTED_DEVICES],
    queryFn: api.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const hasTrustedDevices = useMemo(
    () => !!trustedDevices.data?.length,
    [trustedDevices.data?.length]
  )

  const currentTrustedDevice = useMemo(
    () =>
      trustedDevices.data?.find(({ is_current_device }) => is_current_device),
    [trustedDevices.data]
  )

  return {
    trustedDevices: trustedDevices.data ?? [],
    hasTrustedDevices,
    currentTrustedDevice,
  }
}
