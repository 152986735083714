import React, { useContext } from 'react'
import { WrappedAddMFAPrompt } from 'components/addMfaPrompt/AddMFAPrompt'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { AddNewMethodBanner } from 'components/mfaBanners/AddNewMethodBanner'
import { AddPasskeyBanner } from 'components/mfaBanners/AddPasskeyBanner'
import { MobileNavigation } from 'components/navigation/MobileNavigation'
import { ProfileCard } from 'components/profileCard/ProfileCard'
import { Activities } from 'pages/activity/ui/Activities'
import { ProtectedPage } from 'pages/ProtectedPage'
import { useAppView } from 'utils/useAppView'
import Cards from './components/Cards'
import Hero from './components/Hero'
import { useShowAddMFAPrompt } from './useShowAddMFAPrompt'
import { useShowMfaBanner } from './useShowMfaBanner'

const Home: React.FC = () => {
  const [isAppView] = useAppView()
  const { isMaxMediumV2 } = useContext(MediaSizingContext)

  useShowAddMFAPrompt()

  const { displayPasskeyBanner, handleCloseBanner, onlyHasOneEmailMfa } =
    useShowMfaBanner()

  if (isAppView && isMaxMediumV2) {
    return (
      <ProtectedPage>
        {onlyHasOneEmailMfa && <AddNewMethodBanner />}
        {displayPasskeyBanner && (
          <AddPasskeyBanner onClose={handleCloseBanner} />
        )}
        <ProfileCard />
        <MobileNavigation />
        <Activities numberOfItems={5} />
        <WrappedAddMFAPrompt />
      </ProtectedPage>
    )
  }

  return (
    <ProtectedPage>
      {onlyHasOneEmailMfa && <AddNewMethodBanner />}
      {displayPasskeyBanner && <AddPasskeyBanner onClose={handleCloseBanner} />}
      <Hero />
      <Cards />
      <Activities numberOfItems={5} />
      <WrappedAddMFAPrompt />
    </ProtectedPage>
  )
}

export default Home
