import { IntlShape } from 'react-intl'
import {
  TAX_ID_VALIDATION_ERROR_CODES_SET,
  TaxIdErrorCode,
} from 'api/billing/billingTaxId'
import { getMutationErrorData } from '../utils'

export const getTaxIdMutationErrorMessage = (
  error: Error,
  intl: IntlShape
): string => {
  const errorData = getMutationErrorData(error)
  let errorMessage = intl.formatMessage({
    id: 'SETTINGS_PAYMENTS_TAX_ID_FAILURE',
  })

  if (TAX_ID_VALIDATION_ERROR_CODES_SET.has(errorData?.metaData.code)) {
    errorMessage = intl.formatMessage({
      id: 'SETTINGS_PAYMENTS_TAX_ID_VALIDATION_ERROR',
    })
  }

  if (
    errorData?.metaData.service &&
    errorData?.metaData.code === TaxIdErrorCode.VALIDATION_SERVER_DOWN
  ) {
    errorMessage = intl.formatMessage(
      {
        id: 'SETTINGS_PAYMENTS_TAX_ID_VALIDATION_DOWN',
      },
      { service: errorData?.metaData.service }
    )
  }

  if (
    errorData?.metaData.code === TaxIdErrorCode.CANNOT_DELETE_OR_UPDATE_REGION
  ) {
    errorMessage = intl.formatMessage({
      id: 'SETTINGS_PAYMENTS_TAX_ID_CANNOT_UPDATE',
    })
  }

  return errorMessage
}
