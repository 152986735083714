import { FormikErrors, FormikTouched } from 'formik'
import { SupportFormState } from '../types'

export enum ProductLine {
  airMax = 'acc_brand_airmax',
  airFiber = 'acc_brand_airfiber',
  ltu = 'acc_brand_ltu',
  edgemax = 'acc_brand_edgemax',
  UFiber = 'acc_brand_ufiber',
  uispCloud = 'acc_brand_uisp-cloud',
  uispConsole = 'acc_brand_uisp-console',
  uispSelfHosted = 'acc_brand_self-uisp',
  uispMobile = 'acc_brand_mobile-uisp',
  other = 'acc_brand_other-parts',
}

export enum Category {
  setup = 'acc_issue_setup',
  updates = 'acc_issue_updates',
  performance = 'acc_issue_performance',
  hardware = 'acc_issue_hw',
  other = 'acc_issue_other-features',
}

export interface ZDFormValueType {
  id: string
  label: string
  image?: string
}

type NextSection = {
  section: string
  condition?: { field: string; value: any }
}

type ConfigElement = {
  fieldId: number
  fieldTitle: string | null
  options: ZDFormValueType[] | null
  nextSection: NextSection[] | null
  hide?: boolean
}

export type FormValue = string | undefined

export interface BubbleProps {
  handleChange: (
    section: string,
    value: FormValue,
    sectionToRender?: string
  ) => void
  values: SupportFormState
  errors?: FormikErrors<SupportFormState>
  touched?: FormikTouched<SupportFormState>
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<SupportFormState>> | Promise<void>
  shouldScroll?: boolean
}

type AllData = SupportFormState & {
  attachment?: string
  rawData?: string
}

export type FormConfig = Record<keyof AllData, ConfigElement>
