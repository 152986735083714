import React from 'react'
import { useIntl } from 'react-intl'
import { GenericCompletedMFAModal } from '../GenericCompletedMFAModal'

const EMAIL_COMPLETED_MODAL_ID = 'EMAIL_COMPLETED_MODAL_ID'

export const EmailCompletedModal: React.FC = () => {
  const intl = useIntl()

  return (
    <GenericCompletedMFAModal
      modalId={EMAIL_COMPLETED_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_EMAIL_MODAL_TITLE' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_EMAIL_COMPLETED_DESCRIPTION',
      })}
    />
  )
}
