import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import IconChecked from 'assets/svgs/checked.svg?react'
import { ModalProps } from 'components/ModalWrapper'
import { checkAuth } from 'features/auth/modules/auth'
import { closeVisibleModal } from 'modules/modals'
import styled from 'theme/styled'
import { GenericMFAModal } from '../GenericMFAModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  svg {
    width: 30px;
    height: 30px;
  }
`

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(p) => p.theme.neutral08};
  margin: 16px 0;
`

interface Props extends ModalProps {
  modalId: string
  title: string
  description: string
  isLoading?: boolean
}
export const GenericCompletedMFAModal: React.FC<Props> = ({
  modalId,
  title,
  description,
  isLoading,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <GenericMFAModal
      modalId={modalId}
      title={title}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'secondary',
          disabled: isLoading,
          onClick: () => {
            dispatch(closeVisibleModal())
          },
        },
      ]}
      isLoading={isLoading}
      onAfterClose={() => dispatch(checkAuth())}
      overrideFullScreen
      size="auto"
    >
      <Wrapper>
        <IconChecked />
        <Description>{description}</Description>
      </Wrapper>
    </GenericMFAModal>
  )
}
