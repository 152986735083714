import React, { useContext } from 'react'
import { Tabs } from '@ubnt/ui-components/Tabs'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage } from 'react-intl'
import { HeaderTab } from 'api/zendesk/types'
import { FlexWrapper } from 'components/SharedComponents'
import { useCCdTicketsQuery } from 'store/queries/useCCdTicketsQuery'
import { useClosedTicketsQuery } from 'store/queries/useClosedTicketsQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { useZendeskUserQuery } from 'store/queries/useZendeskUser'
import styled from 'theme/styled'
import { useNavigate } from 'utils/useNavigate'
import { HeaderTabLabel } from './HeaderTabLabel'
import { RequestsContext } from './RequestsContext'
import { RequestsGrid } from './RequestsGrid'
import { RequestsPagination } from './RequestsPagination'
import { RequestsSearch } from './RequestsSearch'

export const RequestsContainer: React.FC = () => {
  const navigate = useNavigate()
  const {
    statusToShow,
    handleChangeStatusToShow,
    hasMultiplePages,
    ticketsError,
    searchData,
    areTicketsLoading,
  } = useContext(RequestsContext)

  const { totalOpenTickets, areOpenTicketsFetched } = useOpenTicketsQuery()
  const { totalClosedTickets, areClosedTicketsFetched } =
    useClosedTicketsQuery()
  const { totalCcdTickets, areCcdTicketsFetched } = useCCdTicketsQuery()
  const { hasZendeskUser, isZendeskUserFetched } = useZendeskUserQuery()

  if (
    (!areTicketsLoading &&
      areOpenTicketsFetched &&
      areClosedTicketsFetched &&
      areCcdTicketsFetched &&
      !totalOpenTickets &&
      !totalClosedTickets &&
      !totalCcdTickets) ||
    (!hasZendeskUser && isZendeskUserFetched)
  ) {
    navigate('/supportform', { replace: true })
    return null
  }

  const isSearchActive = !!searchData.value.length

  const renderSearch = (hasMultiplePages && !ticketsError) || isSearchActive

  return (
    <>
      <Wrapper>
        <StyledTabs
          tabs={[
            {
              id: HeaderTab.OPEN,
              label: (
                <HeaderTabLabel
                  label="SUPPORT_MY_REQUESTS_TAB_ACTIVE"
                  totalTickets={totalOpenTickets}
                />
              ),
            },
            {
              id: HeaderTab.CLOSED,
              label: (
                <HeaderTabLabel
                  label="SUPPORT_MY_REQUESTS_TAB_CLOSED"
                  totalTickets={totalClosedTickets}
                />
              ),
            },
            {
              id: HeaderTab.CCD,
              label: (
                <HeaderTabLabel
                  label="SUPPORT_MY_REQUESTS_TAB_CCD"
                  totalTickets={totalCcdTickets}
                />
              ),
            },
          ]}
          selectedTabBold
          fullHeightTabs
          tabClassName="requests-tab"
          onSelectTab={({ id }) => handleChangeStatusToShow?.(id as HeaderTab)}
          selectedTab={statusToShow}
          variant="capsule"
        />
        <Button
          onClick={() => navigate('/supportform')}
          variant="primary"
          size="small"
        >
          <FormattedMessage id="COMMON_SIDE_NAV_SUPPORT_FORM" />
        </Button>
      </Wrapper>
      <Container>
        {renderSearch && (
          <Header $omitPaddingBottom={hasMultiplePages || isSearchActive}>
            <RequestsSearch />
          </Header>
        )}
        <FlexWrapper column width="100%">
          <RequestsGrid />
        </FlexWrapper>
        {hasMultiplePages && !ticketsError && <RequestsPagination />}
      </Container>
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
    gap: 12px;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.neutral03}`};
  border-radius: 8px;
  margin-bottom: 100px;
`

const Header = styled.div<{ $omitPaddingBottom: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`

const StyledTabs = styled(Tabs)`
  justify-content: flex-start;

  div {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    div {
      width: 100%;
    }
  }
`
