import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingCustomerApi } from 'api/billing/billingCustomer'
import { QueryKey } from 'store/types'

export const useCreateCustomer = (
  api: BillingCustomerApi = new BillingCustomerApi()
) => {
  const queryClient = useQueryClient()

  const createBillingCustomer = useMutation({
    mutationFn: api.create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_CUSTOMER] })
    },
  })

  return {
    createBillingCustomer: createBillingCustomer.mutate,
    createBillingCustomerAsync: createBillingCustomer.mutateAsync,
    isCreateCustomerError: createBillingCustomer.isError,
  }
}
