import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { useCancelCyberSecureSubscription } from 'store/mutations/billingSubscriptions/useCancelCyberSecureSubscription'
import { useCancelLteSubscription } from 'store/mutations/billingSubscriptions/useCancelLteSubscription'
import { useCancelMspSiteSupportSubscription } from 'store/mutations/billingSubscriptions/useCancelMspSiteSupport'
import { useCancelSiteSupportSubscription } from 'store/mutations/billingSubscriptions/useCancelSiteSupportSubscription'
import { useCancelTalkSubscription } from 'store/mutations/billingSubscriptions/useCancelTalkSubscription'
import { useTalkIntallsQuery } from 'store/queries/useTalkInstallsQuery'
import { ProductName } from 'types/enums'

type CancelSubscriptionData = {
  cancelFn: () => void
  isCancelLoading: boolean
  cancelError: React.ReactNode
  resetCancelErrors: () => void
}

export const useCancelSubscription = (
  productName?: ProductName,
  fullSubscription?: FullSubscription,
  cancelImmediately?: boolean
) => {
  const {
    cancelMspSiteSupportSubscription,
    isCancelMspSiteSupportSubscriptionLoading,
    cancelMspSiteSupportSubscriptionErrors,
    resetCancelMspSiteSupportSubscriptionErrors,
  } = useCancelMspSiteSupportSubscription()
  const {
    cancelTalkSubscription,
    isCancelTalkSubscriptionLoading,
    cancelTalkSubscriptionErrors,
    resetCancelTalkSubscriptionErrors,
  } = useCancelTalkSubscription()
  const {
    talkInstallsErrors,
    areTalkInstallsLoading,
    resetTalkInstalls,
    areTalkInstallsFetched,
    getInstall,
  } = useTalkIntallsQuery()
  const {
    cancelSiteSupportSubscription,
    isCancelSiteSupportSubscriptionLoading,
    cancelSiteSupportSubscriptionErrors,
    resetCancelSiteSupportSubscriptionErrors,
  } = useCancelSiteSupportSubscription()
  const {
    cancelCyberSecureSubscription,
    isCancelCyberSecureSubscriptionLoading,
    cancelCyberSecureSubscriptionErrors,
    resetCancelCyberSecureSubscriptionErrors,
    cancelCyberSecureSubscriptionImediately,
    isCancelCyberSecureSubscriptionImediatelyLoading,
    cancelCyberSecureSubscriptionImediatelyErrors,
    resetCancelCyberSecureSubscriptionImediatelyErrors,
  } = useCancelCyberSecureSubscription()
  const {
    cancelLteSubscription,
    isCancelLteSubscriptionLoading,
    cancelLteSubscriptionErrors,
    resetCancelLteSubscriptionErrors,
  } = useCancelLteSubscription()

  const talkInstall = getInstall(fullSubscription)

  const error = useMemo(() => {
    if (productName === ProductName.UNIFI_TALK) {
      if (talkInstallsErrors || (areTalkInstallsFetched && !talkInstall)) {
        return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
      }
    }
    if (!fullSubscription) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    }
    if (
      cancelCyberSecureSubscriptionErrors ||
      cancelCyberSecureSubscriptionImediatelyErrors ||
      cancelTalkSubscriptionErrors ||
      cancelLteSubscriptionErrors ||
      cancelSiteSupportSubscriptionErrors ||
      cancelMspSiteSupportSubscriptionErrors
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    }
    return null
  }, [
    areTalkInstallsFetched,
    cancelCyberSecureSubscriptionErrors,
    cancelCyberSecureSubscriptionImediatelyErrors,
    cancelLteSubscriptionErrors,
    cancelMspSiteSupportSubscriptionErrors,
    cancelSiteSupportSubscriptionErrors,
    cancelTalkSubscriptionErrors,
    fullSubscription,
    productName,
    talkInstall,
    talkInstallsErrors,
  ])

  const getData = (): CancelSubscriptionData | undefined => {
    if (productName === ProductName.UNIFI_TALK) {
      const cancelFn = () => {
        if (talkInstall) {
          cancelTalkSubscription({
            install: talkInstall.id,
          })
        }
      }

      return {
        cancelFn,
        isCancelLoading:
          (!error && areTalkInstallsLoading) || isCancelTalkSubscriptionLoading,
        cancelError: error,
        resetCancelErrors: () => {
          resetCancelTalkSubscriptionErrors()
          resetTalkInstalls()
        },
      }
    }

    if (productName === ProductName.CYBERSECURE) {
      const cancelFn = () => {
        if (fullSubscription && cancelImmediately) {
          cancelCyberSecureSubscriptionImediately({
            id: fullSubscription.id,
            region: fullSubscription.region,
          })
        } else if (fullSubscription) {
          cancelCyberSecureSubscription({
            id: fullSubscription.id,
            region: fullSubscription.region,
          })
        }
      }

      return {
        cancelFn,
        isCancelLoading:
          isCancelCyberSecureSubscriptionLoading ||
          isCancelCyberSecureSubscriptionImediatelyLoading,
        cancelError: error,
        resetCancelErrors:
          resetCancelCyberSecureSubscriptionErrors ||
          resetCancelCyberSecureSubscriptionImediatelyErrors,
      }
    }

    if (productName === ProductName.ULTE) {
      const cancelFn = () => {
        if (fullSubscription) {
          cancelLteSubscription({
            id: fullSubscription.id,
          })
        }
      }

      return {
        cancelFn,
        isCancelLoading: isCancelLteSubscriptionLoading,
        cancelError: error,
        resetCancelErrors: resetCancelLteSubscriptionErrors,
      }
    }

    if (productName === ProductName.SITE_SUPPORT) {
      const cancelFn = () => {
        if (fullSubscription) {
          cancelSiteSupportSubscription({
            id: fullSubscription.id,
          })
        }
      }

      return {
        cancelFn,
        isCancelLoading: isCancelSiteSupportSubscriptionLoading,
        cancelError: error,
        resetCancelErrors: resetCancelSiteSupportSubscriptionErrors,
      }
    }

    if (productName === ProductName.MSP_SITE_SUPPORT) {
      const cancelFn = () => {
        if (fullSubscription) {
          cancelMspSiteSupportSubscription({
            id: fullSubscription.id,
          })
        }
      }

      return {
        cancelFn,
        isCancelLoading: isCancelMspSiteSupportSubscriptionLoading,
        cancelError: error,
        resetCancelErrors: resetCancelMspSiteSupportSubscriptionErrors,
      }
    }
  }

  return getData()
}
