import React, { useEffect, useRef } from 'react'
import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'
import { userType } from './config/ConfigOptions'
import { UniFiStateValues } from './config/types'
import { setNextSection } from './handleClicks'
import { BubbleProps } from './types'

export const UserTypeBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.tech, values.model])

  return (
    <Bubble question={config.userType.fieldTitle} ref={ref}>
      <TilesWrapper>
        {userType.map(({ id, label, icon }) => (
          <QuestionTile
            key={id}
            label={label}
            icon={icon}
            onClick={() =>
              setNextSection(
                config,
                id,
                UniFiStateValues.USER_TYPE,
                values,
                handleChange
              )
            }
            isActive={values.userType === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
