import React, { ReactElement, useCallback, useContext } from 'react'
import { QuoteIcon } from '@ubnt/icons'
import EntityToast from '@ubnt/ui-components/Toast/EntityToast'
import { ToastContext } from '@ubnt/ui-components/Toast/ToastContext'
import { FormattedMessage } from 'react-intl'
import {
  stripeErrorCodesToHide,
  stripeErrorMessagesToHide,
} from 'store/mutations/utils'

type ErrorData = {
  message?: string
  metaData?: {
    dependencyMessage?: string
    dependencyErrorCode?: string
  }
}

export const useToasts = () => {
  const toast = useContext(ToastContext)

  const handleCloseToast = useCallback(
    (_e: React.MouseEvent, id?: string) => {
      if (id) {
        toast.removeNotification(id)
      }
    },
    [toast]
  )

  const payInvoiceSuccessToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon={<QuoteIcon />}
        stateIndicator="success"
        title={<FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_PAID" />}
        details={
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_PAID_TOAST" />
        }
        onClose={handleCloseToast}
      />
    )
  }, [handleCloseToast, toast])

  const payInvoiceErrorToast = useCallback(
    (data: ErrorData = {}) => {
      let errorMessage: ReactElement | string
      let detailsMessage: ReactElement | string = ''
      if (
        data.message === 'Something happened on the external API.' &&
        data.metaData?.dependencyMessage &&
        !stripeErrorCodesToHide.some(
          (code) => code === data.metaData?.dependencyErrorCode
        ) &&
        !stripeErrorMessagesToHide.some((message) =>
          data.metaData?.dependencyMessage?.includes(message)
        )
      ) {
        errorMessage = data.metaData.dependencyMessage
      } else {
        errorMessage = (
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COULD_NOT_PAY_INVOICE" />
        )
        detailsMessage =
          data?.message &&
          data.message !== 'Something happened on the external API.'
            ? data.message
            : ''
      }
      toast.createNotification(
        <EntityToast
          icon={<QuoteIcon />}
          stateIndicator="danger"
          title={errorMessage}
          duration={5000}
          details={detailsMessage}
          onClose={handleCloseToast}
        />
      )
    },
    [handleCloseToast, toast]
  )

  const updateSubscriptionSuccessToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon={<QuoteIcon />}
        stateIndicator="success"
        title={<FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CARD_UPDATED" />}
        onClose={handleCloseToast}
      />
    )
  }, [handleCloseToast, toast])

  const updateSubscriptionErrorToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon={<QuoteIcon />}
        stateIndicator="danger"
        title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
        details={
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COULD_NOT_UPDATE_CARD" />
        }
        onClose={handleCloseToast}
      />
    )
  }, [handleCloseToast, toast])

  return {
    payInvoiceSuccessToast,
    payInvoiceErrorToast,
    updateSubscriptionSuccessToast,
    updateSubscriptionErrorToast,
  }
}
