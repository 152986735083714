import React, { useContext } from 'react'
import { ChatIcon } from '@ubnt/icons'
import { ChatContext } from './ChatContext'
import { Footer } from './Footer'
import { Header } from './Header'
import { Messages } from './Messages'
import { ReviewChat } from './ReviewChat'
import { ChatButton, ChatButtonContainer, ChatPopover } from './styles'

export const ChatWidget: React.FC = () => {
  const { widgetState, setWidgetState, review } = useContext(ChatContext)

  if (widgetState.isMinimised) {
    return (
      <ChatButtonContainer>
        <ChatButton
          Icon={ChatIcon({ variant: 'fill', size: 32, color: 'white' })}
          onClick={() => {
            setWidgetState((prev) => ({ ...prev, isMinimised: false }))
          }}
          variant="primary"
        />
      </ChatButtonContainer>
    )
  }

  return (
    <ChatPopover open>
      <Header />
      {review.isOpen ? (
        <ReviewChat />
      ) : (
        <>
          <Messages />
          <Footer />
        </>
      )}
    </ChatPopover>
  )
}
