import { object, string } from 'yup'
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
} from 'features/support/constants'
import { OrderIssue } from './types'

export const StoreFormValidationSchema = object({
  country: string().label('SETTINGS_STORE_FORM_COUNTRY_LABEL'),
  orderIssue: string().label('SETTINGS_STORE_FORM_ISSUE_LABEL'),
  orderNumber: string().when('orderIssue', {
    is: (orderIssue: OrderIssue) =>
      [
        OrderIssue.trackOrder,
        OrderIssue.cancelOrder,
        OrderIssue.returnOrder,
        OrderIssue.other,
      ].includes(orderIssue),
    then: (schema) =>
      schema.required().label('SETTINGS_STORE_FORM_NUMBER_LABEL'),
    otherwise: (schema) => schema.label('SETTINGS_STORE_FORM_NUMBER_LABEL'),
  }),
  description: string()
    .required()
    .min(DESCRIPTION_MIN_LENGTH)
    .max(DESCRIPTION_MAX_LENGTH)
    .label('SETTINGS_SUPPORT_FORM_LABEL_DESCRIPTION'),
})
