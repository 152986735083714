import React from 'react'
import MediaQuery from 'react-responsive'
import theme from 'theme'
import { UserPopover } from 'features/user-popover/ui/UserPopover'
import styled from 'theme/styled'
import { INavigationItem } from 'types/routes'

interface DesktopItemsProps {
  alignRight?: boolean
}

interface Props {
  otherItems?: INavigationItem[]
  isAuthenticated?: boolean
}

export const Desktop: React.FC<Props> = ({
  otherItems = [],
  isAuthenticated,
}) => {
  return (
    <MediaQuery query={`(min-width: ${theme.media.mobileLarge})`}>
      <DesktopItems>
        {otherItems.map(({ title, link, newTab }, idx) => {
          if (!title || !link) return null
          return (
            <DesktopItem
              key={`${idx}-d`}
              href={link}
              target={newTab ? '_blank' : undefined}
            >
              {title}
            </DesktopItem>
          )
        })}
      </DesktopItems>
      <DesktopItems alignRight>
        {isAuthenticated && <UserPopover />}
      </DesktopItems>
    </MediaQuery>
  )
}

const DesktopItems = styled.div<DesktopItemsProps>`
  display: flex;
  ${({ alignRight }) =>
    alignRight &&
    `
    margin-left: auto;
    margin-right: 20px;
  `}
`

const DesktopItem = styled('a')`
  display: flex;
  margin-right: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral08};
`
