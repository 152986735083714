import { ParsedNCAConsole } from 'features/support/unifi/types'
import { parseDescription } from 'features/support/utils'
import { umrSpecificIssue as umrSpecificIssueOptions } from '../config/ConfigOptions'
import { Console } from '../config/types'
import { SupportFormState } from '../types'

const parseConsole = (console?: ParsedNCAConsole) => {
  if (!console || console.id === Console.umrUnlisted) {
    return 'Offline UMR'
  }

  return console.label
}
export const createSubject = ({
  console,
  umrSpecificIssue,
  description,
}: SupportFormState) => {
  const parsedConsole = parseConsole(console)
  const parsedIssue = umrSpecificIssueOptions.find(
    ({ id }) => id === umrSpecificIssue
  )?.label

  const parsedDescription = parseDescription(description || '')

  return `${parsedConsole} | ${parsedIssue}: ${parsedDescription}...`
}
