import React, { useCallback, useContext } from 'react'
import QuoteIcon from '@ubnt/icons/QuoteIcon'
import EntityToast from '@ubnt/ui-components/Toast/EntityToast'
import { ToastContext } from '@ubnt/ui-components/Toast/ToastContext'
import { FormattedMessage } from 'react-intl'

type ToastType = 'added' | 'updated' | 'removed'

export const useToasts = () => {
  const toast = useContext(ToastContext)

  const handleCloseToast = useCallback(
    (_e: React.MouseEvent, id?: string) => {
      if (id) {
        toast.removeNotification(id)
      }
    },
    [toast]
  )

  const updateCustomerSuccessToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon="success"
        title={
          <FormattedMessage id="SETTINGS_PAYMENTS_UPDATE_METHOD_SUCCESS" />
        }
        onClose={handleCloseToast}
      />
    )
  }, [handleCloseToast, toast])

  const updateCustomerErrorToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon="danger"
        title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
        onClose={handleCloseToast}
      />
    )
  }, [handleCloseToast, toast])

  const taxIdSuccessToast = useCallback(
    (type: ToastType) => {
      toast.createNotification(
        <EntityToast
          icon={<QuoteIcon />}
          stateIndicator="success"
          onClose={handleCloseToast}
          title={
            <FormattedMessage
              id="SETTINGS_PAYMENTS_TAX_ID_SUCCESS"
              values={{ action: type }}
            />
          }
        />
      )
    },
    [handleCloseToast, toast]
  )

  const taxIdErrorToast = useCallback(
    (errorMessage: string) => {
      toast.createNotification(
        <EntityToast
          icon={<QuoteIcon />}
          stateIndicator="danger"
          onClose={handleCloseToast}
          title={errorMessage}
        />
      )
    },
    [handleCloseToast, toast]
  )

  const paymentMethodSuccessToast = useCallback(
    (type: ToastType) => {
      toast.createNotification(
        <EntityToast
          icon="success"
          onClose={handleCloseToast}
          title={
            <FormattedMessage
              id="SETTINGS_PAYMENTS_METHODS_SUCCESS_TOAST"
              values={{ action: type }}
            />
          }
        />
      )
    },
    [handleCloseToast, toast]
  )

  return {
    updateCustomerSuccessToast,
    updateCustomerErrorToast,
    taxIdSuccessToast,
    taxIdErrorToast,
    paymentMethodSuccessToast,
  }
}
