import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Dropdown, Input } from '@ubnt/ui-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { CountryFlag } from 'components/CountryFlag'
import {
  validStripeCountries,
  validStripeCountryMap,
  validStripeRegionMap,
} from 'features/stripe/ui/regions'
import { StripeCountryCode } from 'features/stripe/ui/types'
import { getFormattedAddress } from 'utils/getFormattedAddress'
import { useAutocomplete } from 'utils/useAutocomplete'
import { useOutsideClick } from 'utils/useOutsideClick'
import {
  AddressResult,
  AddressResultsContainer,
  AddressResultsInnerContainer,
  InputWrapper,
  Title,
} from '../../Components'
import { checkAddress, handleCaughtAddressError } from '../../utils'
import { FormProps } from '../types'
import { StatesDropdown } from './StatesDropdown'

export const UsCardAddressForm: React.FC<FormProps> = (props) => {
  const {
    handleBlur,
    values,
    setValues,
    handleChange,
    touched,
    errors,
    submitError,
    addressError,
    setFieldValue,
    setAddressError,
    cardRegion,
    country,
    isEditPaymentMethod = false,
  } = props
  const [addressResultsVisible, setAddressResultsVisible] = useState(false)
  const [active, setActive] = useState<number>(0)
  const [cardCountry, setCardCountry] = useState<string | null | undefined>(
    country
  )
  const [addressFocused, setAddressFocused] = useState(false)
  const intl = useIntl()
  const wrapperRef = useRef(null)

  const selectedRegion = cardRegion
    ? validStripeRegionMap.get(cardRegion)
    : undefined

  const selectedCountry = validStripeCountryMap.get(
    values.country.toLowerCase() as StripeCountryCode
  )

  const selectedCountryCode = isEditPaymentMethod
    ? selectedCountry?.code
    : cardCountry

  useOutsideClick(wrapperRef, () => {
    setAddressResultsVisible(false)
    setActive(0)
  })

  const { placesService, placePredictions, getPlacePredictions } =
    useAutocomplete()

  useEffect(() => {
    if (placePredictions?.length && values.country === 'US') {
      setAddressResultsVisible(true)
    } else {
      setAddressResultsVisible(false)
      setActive(0)
    }
  }, [values.country, placePredictions])

  const handleAddressSelected = useCallback(
    (addressDetails: google.maps.places.PlaceResult) => {
      setValues({
        ...values,
        ...getFormattedAddress(addressDetails),
      })
    },
    [setValues, values]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!event?.key) {
        return
      } else if (event.key === 'Tab') {
        setAddressResultsVisible(false)
        setActive(0)
      } else if (event.key === 'Enter') {
        event.preventDefault()
        setAddressResultsVisible(false)
        setActive(0)
        placesService?.getDetails(
          {
            placeId: placePredictions[active]?.place_id,
            fields: ['address_component'],
          },
          (addressDetails) => {
            addressDetails && handleAddressSelected(addressDetails)
          }
        )
      } else if (event.key === 'ArrowDown') {
        setActive(
          active < placePredictions.length - 1
            ? active + 1
            : placePredictions.length - 1
        )
      } else if (event.key === 'ArrowUp') {
        setActive(active > 0 ? active - 1 : 0)
      }
    },
    [active, handleAddressSelected, placePredictions, placesService]
  )

  const checkAddressDebounced = useDebouncedCallback(async () => {
    try {
      await checkAddress(values)
      setAddressError(null)
    } catch (error) {
      handleCaughtAddressError(error, intl, setAddressError)
    }
  }, 200)

  useEffect(() => {
    if (addressFocused) {
      checkAddressDebounced()
    }
  }, [checkAddressDebounced, addressFocused, values])

  return (
    <>
      <Title>
        <FormattedMessage id="SETTINGS_PAYMENTS_BILLING_ADDRESS" />
      </Title>
      {selectedCountryCode && (
        <InputWrapper>
          <Dropdown
            label="Country"
            value={selectedCountryCode}
            onChange={(option) => {
              !isEditPaymentMethod && setCardCountry(option.value.toString())
              setValues({
                ...values,
                country: option.value.toUpperCase(),
              })
            }}
            options={validStripeCountries
              .filter((country) => country.region === selectedRegion?.code)
              .map((country) => ({
                label: country.name,
                value: country.code,
                image: <CountryFlag countryCode={country.code} />,
              }))}
            searchable
            variant="secondary"
            minHeight={214}
            width="100%"
            optionContainerClassName="change-region__dropdown"
            disabled={isEditPaymentMethod}
          />
        </InputWrapper>
      )}
      <InputWrapper>
        <Input
          onKeyDown={handleKeyDown}
          name="line1"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STREET_ADDRESS" />}
          onBlur={handleBlur}
          onChange={(e) => {
            values.country === 'US' &&
              getPlacePredictions({ input: e.target.value })
            handleChange(e)
          }}
          invalid={touched.line1 && (errors.line1 || addressError?.['line1'])}
          value={values.line1}
          autoComplete="new-line1"
          full
        />
        <AddressResultsContainer
          ref={wrapperRef}
          visible={addressResultsVisible}
        >
          <AddressResultsInnerContainer>
            {placePredictions?.length &&
              placePredictions.map(
                (
                  place: google.maps.places.AutocompletePrediction,
                  i: number
                ) => (
                  <AddressResult
                    active={active === i}
                    key={i.toString()}
                    onClick={() => {
                      setAddressResultsVisible(false)
                      setActive(0)
                      placesService?.getDetails(
                        {
                          placeId: place?.place_id,
                          fields: ['address_component'],
                        },
                        (addressDetails) => {
                          addressDetails &&
                            handleAddressSelected(addressDetails)
                        }
                      )
                    }}
                  >
                    {place?.description}
                  </AddressResult>
                )
              )}
          </AddressResultsInnerContainer>
        </AddressResultsContainer>
      </InputWrapper>
      <InputWrapper>
        <Input
          name="city"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_CITY" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={touched.city && (errors.city || addressError?.['city'])}
          value={values.city}
          autoComplete="new-city"
          full
        />
      </InputWrapper>
      <InputWrapper>
        <StatesDropdown
          state={values.state}
          handleBlur={handleBlur}
          handleChange={setFieldValue}
          country="US"
          invalid={touched.state && (errors.state || addressError?.['state'])}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="postal_code"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_POSTAL_CODE" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={
            (touched.postal_code &&
              (errors.postal_code || addressError?.['postal_code'])) ||
            !!submitError
          }
          value={values.postal_code}
          autoComplete="new-postal-code"
          onFocus={() => setAddressFocused(true)}
          full
        />
      </InputWrapper>
    </>
  )
}
