import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ArrowDownPrimaryIcon, ArrowUpPrimaryIcon } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { MFAAuthenticatorStatus } from 'enums'
import { HideOptions } from 'components/hideOptions/HideOptions'
import { MFAAuthenticator, MFAType } from 'modules/types'
import { LockTooltip } from 'pages/security/LockTooltip'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import styled from 'theme/styled'
import { MultiFactorMethodSection } from './MultiFactorMethodSection'

interface Props {
  activeMFAAuthenticators: MFAAuthenticator[]
}

export const MultiFactorMethods: React.FC<Props> = ({
  activeMFAAuthenticators,
}) => {
  const { profileData } = useSsoProfileQuery()
  const { allMfaAuthenticators, getPendingMfaAuthenticators } =
    useMFAAuthenticators()
  const defaultMFAId = profileData?.default_mfa

  const pendingMethods = getPendingMfaAuthenticators()

  const [isOtherMethodsOpen, setIsOtherMethodsOpen] = useState(false)

  const primaryIsTOTP = useMemo(() => {
    const primary = allMfaAuthenticators.find(
      (auth) => auth.id === defaultMFAId
    )
    return primary?.type === MFAType.totp
  }, [allMfaAuthenticators, defaultMFAId])

  const showButton = useMemo(
    () => activeMFAAuthenticators.some(({ id }) => id !== defaultMFAId),
    [activeMFAAuthenticators, defaultMFAId]
  )

  const renderOtherMethodsButton = () => {
    return isOtherMethodsOpen ? (
      <>
        <ArrowUpPrimaryIcon size="medium" />
        <OtherMethodsText>
          <FormattedMessage id="COMMON_SHOW_LESS" />
        </OtherMethodsText>
      </>
    ) : (
      <>
        <ArrowDownPrimaryIcon size="medium" />
        <OtherMethodsText>
          <FormattedMessage id="COMMON_SHOW_ALL" />
        </OtherMethodsText>
      </>
    )
  }

  const primaryMethod = useMemo(() => {
    const authenticator = allMfaAuthenticators.find(
      (authenticator) => authenticator.id === defaultMFAId
    )
    return authenticator ? [authenticator] : []
  }, [allMfaAuthenticators, defaultMFAId])

  const backupMethods = useMemo(
    () =>
      allMfaAuthenticators
        .filter(
          (method) =>
            // Filter out: Primary MFA, Pending, Recovery Code
            method.id !== defaultMFAId &&
            method.status !== MFAAuthenticatorStatus.PENDING &&
            method.status !== MFAAuthenticatorStatus.DEACTIVATED &&
            method.type !== MFAType.backupCodes
        )
        .sort(
          (a, b) =>
            new Date(a.created).getTime() - new Date(b.created).getTime()
        ),
    [allMfaAuthenticators, defaultMFAId]
  )

  const deactivatedMethods = useMemo(
    () =>
      allMfaAuthenticators
        .filter(
          (method) =>
            method.id !== defaultMFAId &&
            method.status === MFAAuthenticatorStatus.DEACTIVATED
        )
        .sort(
          (a, b) =>
            new Date(a.created).getTime() - new Date(b.created).getTime()
        ),
    [allMfaAuthenticators, defaultMFAId]
  )

  if (allMfaAuthenticators.length === 0) {
    return <></>
  }

  //need at least 2 active MFA methods
  const isDeleteDisabled =
    allMfaAuthenticators.filter(
      (auth: MFAAuthenticator) => auth.status === 'active'
    ).length < 2

  return (
    <Wrapper>
      <PrimaryWrapper
        showBottomBorder={
          isOtherMethodsOpen ||
          !!pendingMethods.length ||
          !!deactivatedMethods.length
        }
      >
        <MultiFactorMethodSection
          data={primaryMethod}
          isPrimary
          isDeleteDisabled
        />
      </PrimaryWrapper>
      <MultiFactorMethodSection data={pendingMethods} />
      <MultiFactorMethodSection data={deactivatedMethods} />
      <HideOptions maxHeight={1500} isShown={isOtherMethodsOpen}>
        <MultiFactorMethodSection
          data={backupMethods}
          isDeleteDisabled={isDeleteDisabled}
          isPrimaryTOTP={primaryIsTOTP}
        />
      </HideOptions>

      {showButton ? (
        <ButtonWrapper>
          <LockTooltip inline>
            <ExtendButton
              variant="inline"
              size="medium"
              className="viewMoreButton"
              onClick={() => setIsOtherMethodsOpen(!isOtherMethodsOpen)}
            >
              {renderOtherMethodsButton()}
            </ExtendButton>
          </LockTooltip>
        </ButtonWrapper>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: ${({ theme }) => `1px solid ${theme.neutral03}`};
  padding: 0 16px;
  border-radius: 10px;
`
const PrimaryWrapper = styled.div<{ showBottomBorder: boolean }>`
  > * {
    &:last-child {
      border-bottom: ${({ theme, showBottomBorder }) =>
        showBottomBorder ? `1px solid ${theme.neutral03}` : `none`};
    }
  }
`

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    padding: 0;
  }
`

const ExtendButton = styled(Button)`
  text-decoration: none !important;
  &:active,
  &:focus {
    box-shadow: none !important;
  }
`
const OtherMethodsText = styled.span`
  margin-left: 3px;
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.ublue06};
`
