import React, { useEffect, useRef } from 'react'
import { FlexWrapper } from 'components/SharedComponents'
import {
  Agent,
  ImageMessage,
  Message,
  MessageContent,
  MessageWrapper,
  TextWrapper,
  UIAvatar,
} from './styles'
import { MessageProps } from './types'

export const AgentMessage: React.FC<MessageProps> = ({
  key,
  text,
  username,
  shouldScroll,
  shouldHideAvatar,
  attachment,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  const handleAttachment = () => {
    if (!attachment) {
      return null
    }
    if (attachment?.mime_type.includes('image')) {
      return (
        <a href={attachment.url} download target="_blank">
          <ImageMessage src={attachment.url} />
        </a>
      )
    }
    return (
      <FlexWrapper gap={4}>
        <a href={attachment.url} download target="_blank">
          {attachment.name}
        </a>
        - {Math.ceil(attachment.size / 1000)}KB
      </FlexWrapper>
    )
  }

  return (
    <Message key={key} ref={ref}>
      <MessageWrapper>
        {!shouldHideAvatar && <UIAvatar size={24} />}
        <TextWrapper $extraMargin={shouldHideAvatar ? '0 0 0 32px' : undefined}>
          {!shouldHideAvatar && <Agent>{username}</Agent>}
          {text && <MessageContent>{text}</MessageContent>}
          {attachment && handleAttachment()}
        </TextWrapper>
      </MessageWrapper>
    </Message>
  )
}
