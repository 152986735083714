import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { getSubscriptionProductLine } from 'features/subscriptions/fullSubscriptions/utils'
import { setVisibleModal } from 'modules/modals'
import { PopoverButton } from 'pages/payments/components/Components.styles'
import { ProductName } from 'types/enums'
import { EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID } from '../ExternalCancelSubscriptionModal'
import { getSubscriptionCancelModalId } from '../utils'

interface Props {
  subscription: FullSubscription
  group: string
}

export const CancelSubscriptionButton: React.FC<Props> = ({
  group,
  subscription,
}) => {
  const dispatch = useDispatch()
  const { cancelAtPeriodEnd, status } = subscription

  const cancelSubscriptionModalId = getSubscriptionCancelModalId(group)

  const isSubscriptionCancelled = status === 'canceled' || cancelAtPeriodEnd

  const isCyberSecure =
    getSubscriptionProductLine(subscription.product?.name) ===
    ProductName.CYBERSECURE

  const isCyberSecureCanceledImmediately =
    isCyberSecure && status === 'canceled'

  if (
    (!isCyberSecure && isSubscriptionCancelled) ||
    isCyberSecureCanceledImmediately ||
    !cancelSubscriptionModalId
  ) {
    return null
  }

  return (
    <PopoverButton
      variant="tertiary"
      onClick={() => {
        const data =
          cancelSubscriptionModalId === EXTERNAL_CANCEL_SUBSCRIPTION_MODAL_ID
            ? { productName: group }
            : { fullSubscription: subscription, productName: group }
        dispatch(setVisibleModal(cancelSubscriptionModalId, data))
      }}
    >
      {isCyberSecure && cancelAtPeriodEnd ? (
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_CYBERSECURE_IMMEDIATELY" />
      ) : (
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_CANCEL" />
      )}
    </PopoverButton>
  )
}
