import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { MY_ORG, MY_SITES } from 'sharedConstants'
import { OrganizationRole } from 'api/organization'
import { useOrganizationsQuery } from 'store/queries/useOrganizationsQuery'
import { QueryKey } from 'store/types'

type OrganizationContextType = {
  accountViewId: string
  handleAccountViewIdChange: (value: string) => void
  orgToFetch: string | null
}

export const OrganizationContext = React.createContext<OrganizationContextType>(
  {
    accountViewId: MY_SITES,
    handleAccountViewIdChange: () => {},
    orgToFetch: null,
  }
)

const personalAccountIds = [MY_SITES, MY_ORG]

export const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accountViewId, setAccountView] = useState<string>(MY_SITES)
  const queryClient = useQueryClient()
  const { organizations, isOrganizationsLoading } = useOrganizationsQuery()

  const handleAccountViewIdChange = (value: string) => {
    setAccountView(value)
  }

  const orgToFetch = personalAccountIds.some((id) => id === accountViewId)
    ? null
    : accountViewId

  const queryParams = useMemo(() => new URLSearchParams(location.search), [])
  const orgViewQuery = queryParams.get('org')

  useEffect(() => {
    const isNotOrgOwner = organizations.some(
      ({ orgId, role }) =>
        orgId === orgViewQuery && role !== OrganizationRole.ORG_OWNER
    )
    if (orgViewQuery && isNotOrgOwner && !isOrganizationsLoading) {
      handleAccountViewIdChange(orgViewQuery)
    }
  }, [
    isOrganizationsLoading,
    orgViewQuery,
    organizations,
    queryClient,
    queryParams,
  ])

  useEffect(() => {
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_INVOICES],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_INVOICE_YEARS],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_UPCOMING_INVOICES],
    })
    queryClient.resetQueries({
      queryKey: [QueryKey.BILLING_OPEN_INVOICES],
    })
  }, [orgToFetch, queryClient])

  return (
    <OrganizationContext.Provider
      value={{ accountViewId, handleAccountViewIdChange, orgToFetch }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
