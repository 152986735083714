import React from 'react'
import CheckmarkIcon from '@ubnt/icons/CheckmarkIcon'
import { FormattedMessage } from 'react-intl'
import { typography } from 'theme'
import styled from 'theme/styled'

export const PasswordRequirement: React.FC<{
  copy: string
  hasBeenMeet: boolean
}> = ({ copy, hasBeenMeet }) => {
  return (
    <Requirement>
      <StyledCheckmarkIcon
        $hasBeenMeet={hasBeenMeet}
        variant={hasBeenMeet ? 'fill' : 'outline'}
        size="original"
      />
      <FormattedMessage id={copy} />
    </Requirement>
  )
}

const Requirement = styled.div<{ requirementPassed?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text3};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)<{ $hasBeenMeet?: boolean }>`
  color: ${({ theme, $hasBeenMeet }) =>
    $hasBeenMeet ? theme.green06 : theme.text4};
`
