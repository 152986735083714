import type Stripe from 'stripe'
import { StripeTaxId } from 'features/payment-methods/modules/types'
import { StripeRegionCode, TaxIdType } from 'features/stripe/ui/types'
import { StripeData } from 'types/types'
import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  postRequestWithCookies,
  putRequestWithCookies,
} from '../axios'
import { BillingCustomerApi } from './billingCustomer'

type TaxIdData = {
  type: TaxIdType
  value: string
}

export type TaxIdArgs = {
  taxData: TaxIdData
  hasCustomer: boolean
  region: StripeRegionCode
}

export enum TaxIdErrorCode {
  INVALID_REGION = 6001,
  ALREADY_EXISTS = 6002,
  USER_NOT_EXISTS = 6003,
  INVALID_VALUE = 6004,
  VALIDATION_FAILED = 6005,
  VALIDATION_SERVER_DOWN = 6006,
  TAX_AUTORITY_VALIDATION_FAILED = 6007,
  CANNOT_DELETE_OR_UPDATE_REGION = 6008,
}

export const TAX_ID_ERROR_CODES_SET = new Set([
  TaxIdErrorCode.INVALID_REGION,
  TaxIdErrorCode.ALREADY_EXISTS,
  TaxIdErrorCode.USER_NOT_EXISTS,
  TaxIdErrorCode.INVALID_VALUE,
])

export const TAX_ID_VALIDATION_ERROR_CODES_SET = new Set([
  TaxIdErrorCode.VALIDATION_FAILED,
  TaxIdErrorCode.TAX_AUTORITY_VALIDATION_FAILED,
])

const {
  api: { billing },
} = __CONFIG__

const billingCustomerApi = new BillingCustomerApi()

export class BillingTaxIdApi {
  public get = async () => {
    const url = new URL(billing.paths.taxId, billing.base)

    const { data } = await fetchRequestWithCookies<StripeTaxId>(url.href)
    return data
  }

  public create = async (data: TaxIdArgs) => {
    if (!data.hasCustomer) {
      await billingCustomerApi.create(data.region)
    }

    const url = new URL(billing.paths.taxId, billing.base)

    const { taxData } = data
    await postRequestWithCookies<TaxIdData, StripeData<Stripe.TaxId>>(
      url.href,
      taxData
    )
  }

  public update = async (data: TaxIdArgs) => {
    if (!data.hasCustomer) {
      await billingCustomerApi.create(data.region)
    }
    const url = new URL(billing.paths.taxId, billing.base)
    await putRequestWithCookies<TaxIdData, StripeData<Stripe.TaxId>>(
      url.href,
      data.taxData
    )
  }

  public delete = async () => {
    const url = new URL(billing.paths.taxId, billing.base)

    await deleteRequestWithCookies(url.href)
  }
}
