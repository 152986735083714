import React from 'react'
import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'
import { billingSpecificService } from './config/ConfigOptions'
import { BubbleProps, SupportConsole } from './types'
import { getNextSection } from './utils/getNextSection'

export const BillingServiceBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  config,
}) => {
  const handleClick = (value?: string | boolean | SupportConsole) => {
    const section = getNextSection(
      config,
      value,
      'billingSpecificService',
      values
    )
    handleChange('billingSpecificService', value, section)
  }

  return (
    <Bubble question={config.billingSpecificService.fieldTitle}>
      <TilesWrapper>
        {billingSpecificService.map(({ icon, label, id }) => (
          <QuestionTile
            key={id}
            icon={icon}
            label={label}
            onClick={() => handleClick(id)}
            isActive={values.billingSpecificService === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
