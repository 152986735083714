import React, { useContext } from 'react'
import ThumbDownIcon from '@ubnt/icons/ThumbDownIcon'
import ThumbUpIcon from '@ubnt/icons/ThumbUpIcon'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/aria/experimental/Button'
import { FormattedMessage } from 'react-intl'
import { ChatContext } from './ChatContext'
import {
  HelperMessage,
  ReviewButtonWrapper,
  ReviewTextArea,
  ScoreWrapper,
  Wrapper,
} from './styles'

export const ReviewChat = () => {
  const { review, setReview, endChat } = useContext(ChatContext)

  const handleReview = () => {
    if (review.rating) {
      window.zChat.sendChatRating(review.rating)
    }

    if (review.comment) {
      window.zChat.sendChatComment(review.comment)
    }

    endChat()
  }

  const isReviewCommentInvalid =
    !!review.comment?.length && review.comment.length > 500

  return (
    <Wrapper>
      <Text variant="heading-xlarge-1">
        Has this conversation been helpful?
      </Text>
      <ScoreWrapper>
        <Button
          onPress={() => setReview((prev) => ({ ...prev, rating: 'good' }))}
          variant={
            review?.rating === 'good'
              ? 'secondary-emphasis'
              : 'secondary-neutral'
          }
          shape="rounded"
        >
          <ThumbUpIcon size={24} />
          <FormattedMessage id="SUPPORT_CHAT_REVIEW_GOOD" />
        </Button>
        <Button
          onPress={() => setReview((prev) => ({ ...prev, rating: 'bad' }))}
          variant={
            review?.rating === 'bad'
              ? 'secondary-emphasis'
              : 'secondary-neutral'
          }
          shape="rounded"
        >
          <ThumbDownIcon size={24} />
          <FormattedMessage id="SUPPORT_CHAT_REVIEW_BAD" />
        </Button>
      </ScoreWrapper>
      <ReviewTextArea
        variant="secondary"
        full
        placeholder="Leave a comment"
        onChange={(_e, value) =>
          setReview((prev) => ({ ...prev, comment: value }))
        }
        invalid={isReviewCommentInvalid}
      />
      <HelperMessage $isInvalid={isReviewCommentInvalid}>
        <FormattedMessage id="SUPPORT_CHAT_REVIEW_INFO" />
      </HelperMessage>
      <ReviewButtonWrapper>
        <Button
          type="button"
          variant="tertiary-neutral"
          style={{ alignSelf: 'flex-end' }}
          onPress={endChat}
        >
          <FormattedMessage id="COMMON_ACTION_SKIP" />
        </Button>
        <Button
          type="button"
          variant="emphasis"
          style={{ alignSelf: 'flex-end' }}
          onPress={handleReview}
          isDisabled={!review.rating || isReviewCommentInvalid}
        >
          <FormattedMessage id="COMMON_ACTION_SEND" />
        </Button>
      </ReviewButtonWrapper>
    </Wrapper>
  )
}
