import React from 'react'
import { Alert } from '@ubnt/ui-components/Alert'
import { DateTime } from 'luxon'
import { FormattedMessage } from 'react-intl'
import { size, space, typography } from 'theme'
import { HARDCODED_MAINTENANCE } from 'api/constants'
import ExternalLink from 'components/ExternalLink'
import { FlexWrapper, boldText } from 'components/SharedComponents'
import { uiLinks } from 'redirectLinks/uiLinks'
import { useStatusQuery } from 'store/queries/useStatusQuery'
import styled from 'theme/styled'

type Props = {
  loginPage?: boolean
  omitMarginBottom?: boolean
}

const HARDCODED_MAINTENANCE_START_DATE = '2024-04-15T08:00:00+0000'
const HARDCODED_MAINTENANCE_END_DATE = '2024-04-15T08:30:00+0000'

const getDuration = (end: DateTime, start: DateTime): string => {
  const durationHour = end.hour - start.hour
  const durationMinutes = Math.abs(end.minute - start.minute)
  const singularOrPluralHour = durationHour === 1 ? 'hour' : 'hours'

  if (durationHour > 0 && durationMinutes > 0) {
    return `${durationHour} ${singularOrPluralHour} and ${durationMinutes} minutes`
  }

  if (durationHour > 0) {
    return `${durationHour} ${singularOrPluralHour}`
  }

  return `${durationMinutes} minutes`
}

const getFormatedDate = (date: string): DateTime =>
  DateTime.fromISO(date, { zone: 'utc' })

export const MaintenanceInfo: React.FC<Props> = ({
  loginPage,
  omitMarginBottom = false,
}) => {
  const { upcomingMaintenance, uiIncidents } = useStatusQuery()

  const message = React.useMemo(() => {
    if (upcomingMaintenance) {
      const { start, end } = upcomingMaintenance

      const startDateTime = getFormatedDate(start)
      const endDateTime = getFormatedDate(end)

      const duration = getDuration(endDateTime, startDateTime)

      return (
        <StyledSpan>
          <FormattedMessage
            id="COMMON_LABEL_UPCOMING_MAINTENANCE"
            values={{
              b: (text) => <strong>{text}</strong>,
              startDate: `${startDateTime.toLocaleString({
                month: 'long',
                day: 'numeric',
              })}`,
              startTime: `${startDateTime.toLocaleString({
                hour: 'numeric',
                minute: 'numeric',
              })}`,
              duration,
            }}
          />
        </StyledSpan>
      )
    }

    if (uiIncidents) {
      return (
        <StyledSpan>
          <FormattedMessage
            id="COMMON_LABEL_INCIDENT_STATUS"
            values={{
              incident: uiIncidents,
              a: (text) => (
                <ExternalLink href={uiLinks.statusUi}>{text}</ExternalLink>
              ),
              b: boldText,
            }}
          />
        </StyledSpan>
      )
    }

    if (HARDCODED_MAINTENANCE) {
      const startDateTime = getFormatedDate(HARDCODED_MAINTENANCE_START_DATE)
      const endDateTime = getFormatedDate(HARDCODED_MAINTENANCE_END_DATE)
      const duration = getDuration(endDateTime, startDateTime)
      return (
        <StyledSpan>
          <FormattedMessage
            id="COMMON_LABEL_ACTIVE_MAINTENANCE_BILLING"
            values={{
              b: (text) => <strong>{text}</strong>,
              duration,
            }}
          />
        </StyledSpan>
      )
    }

    return null
  }, [upcomingMaintenance, uiIncidents])

  return message ? (
    <StyledAlert
      $loginPage={loginPage}
      type="warning"
      text={<FlexWrapper alignItems="center">{message}</FlexWrapper>}
      $omitMarginBottom={omitMarginBottom}
    />
  ) : null
}

const StyledAlert = styled(Alert)<{
  $loginPage?: boolean
  $omitMarginBottom: boolean
}>`
  z-index: 100;
  margin-bottom: ${({ $omitMarginBottom }) =>
    $omitMarginBottom ? '0' : space['desktop-spacing-base-06']};
  left: 0;
  right: 0;
  ${({ $loginPage, theme }) =>
    $loginPage &&
    `
    background: ${theme.neutral02};
    width:fit-content;    
    `};
  span {
    padding-right: ${size['desktop-sizing-base-05']};
  }

  > div:nth-child(2) {
    width: 100%;
    justify-content: center;
  }
`

const StyledSpan = styled.span`
  font: ${typography['desktop-caption']};
`
