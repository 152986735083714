import { AwsV4Signer } from 'aws4fetch'
import axios from 'axios'

const {
  api: { nca, config },
} = __CONFIG__

const ax = axios.create({
  withCredentials: true,
  baseURL: nca.base,
})

const EXPIRATION_BUFFER = 60 * 1000

interface Credentials {
  identityId: string
  accessKeyId: string
  secretKey: string
  sessionToken: string
  expiration: number
  region: string
  roles: string[]
}
interface ConnectionDetails {
  unifiCloudAccess: {
    region: string
    apiGatewayUI: { url: string }
    iot: { host: string }
  }
}

const credsFactory = () =>
  Promise.resolve({
    identityId: '',
    accessKeyId: '',
    secretKey: '',
    sessionToken: '',
    expiration: 0,
    region: '',
    roles: [],
  })

class Aws {
  expiration = 0

  credentialsRequest: Promise<Credentials>
  connectionDetails?: ConnectionDetails

  constructor() {
    this.credentialsRequest = credsFactory()
  }

  createSigner = async ({
    method,
    path,
    body,
    headers,
  }: {
    method: 'GET' | 'POST' | 'DELETE'
    path: string
    body: unknown
    headers?: HeadersInit
  }): Promise<AwsV4Signer> => {
    const {
      accessKeyId,
      secretKey: secretAccessKey,
      sessionToken,
    } = await this.getCredentials()

    const {
      unifiCloudAccess: { region, apiGatewayUI },
    } = await this.getConnectionDetails()

    return new AwsV4Signer({
      method,
      url: `${apiGatewayUI.url}${path}`,
      body: JSON.stringify(body),
      accessKeyId,
      secretAccessKey,
      sessionToken,
      service: 'execute-api',
      region,
      headers,
    })
  }

  getExpiration = () => this.expiration

  getCredentials = async () => {
    if (this.credentialsRequest) {
      const credentials = await this.credentialsRequest
      this.expiration = credentials.expiration - EXPIRATION_BUFFER
      if (this.expiration > Date.now()) {
        return credentials
      }
      this.credentialsRequest = credsFactory()
    }

    this.credentialsRequest = await ax
      .post(`${nca.paths.auth}`)
      .then((res) => res.data)
    return this.credentialsRequest
  }

  getConnectionDetails = async () => {
    if (this.connectionDetails) return this.connectionDetails

    const response = await ax.get(
      `${config.base}${config.paths.nca}?t=${Date.now()}`
    )

    this.connectionDetails = response.data
    return this.connectionDetails!
  }

  async fetch(
    path: string,
    {
      method,
      body,
      headers,
    }: {
      method: 'GET' | 'POST' | 'DELETE'
      body?: unknown
      headers?: HeadersInit
    }
  ) {
    const signer = await this.createSigner({ method, path, body, headers })
    const url = __DEV__ ? `${nca.base}${path}` : signer.url.href

    const init = await signer.sign()

    return fetch(url, init)
  }
}

export default new Aws()
