import React, { useContext, useEffect, useState } from 'react'
import DesignCenterTextLogo from '@ubnt/icons/DesignCenterTextLogo'
import { Button } from '@ubnt/ui-components/Button'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { FormattedMessage } from 'react-intl'
import DesignCenterImage from 'assets/images/designCenterBackground.png'
import DesignCenterImageLow from 'assets/images/designCenterBackgroundLow.png'
import DesignCenterImageSmall from 'assets/images/designCenterBackgroundSmall.png'
import DesignCenterImageSmallLow from 'assets/images/designCenterBackgroundSmallLow.png'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import styled from 'theme/styled'

export const DesignCenterTile = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { isMaxTabletLarge } = useContext(MediaSizingContext)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = isMaxTabletLarge
      ? DesignCenterImageSmall
      : DesignCenterImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [isMaxTabletLarge])

  return (
    <Container>
      <Wrapper>
        <StyledDesignCenterTextLogo size={20} motif="light" />
        <SubTitle>
          <FormattedMessage id="SUPPORT_UNIFI_INFO_DESIGN_CENTER_DESCRIPTION" />
        </SubTitle>
        <Button<'a'>
          component="a"
          href="https://design.ui.com"
          target="_blank"
          variant="primary"
        >
          <FormattedMessage id="SUPPORT_UNIFI_INFO_DESIGN_CENTER_BUILD" />
        </Button>
      </Wrapper>
      <StyledBackground $imageLoaded={imageLoaded} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.neutral01};
  border-radius: 8px;

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    flex-direction: column;
  }
`

const StyledBackground = styled.div<{
  $imageLoaded: boolean
}>`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-image: ${({ $imageLoaded }) =>
    $imageLoaded
      ? `url(${DesignCenterImage})`
      : `url(${DesignCenterImageLow})`};

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    background-image: ${({ $imageLoaded }) =>
      $imageLoaded
        ? `url(${DesignCenterImageSmall})`
        : `url(${DesignCenterImageSmallLow})`};
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    height: 198px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 26px 0 0 26px;

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    padding: 30px 34px;
    align-items: center;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    padding-bottom: 0;
  }
`

const StyledDesignCenterTextLogo = styled(DesignCenterTextLogo)`
  color: ${({ theme }) => theme.neutral08};
`

const SubTitle = styled.span`
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};
`
