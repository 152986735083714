import React, { useContext } from 'react'
import { Dropdown } from '@ubnt/ui-components/Dropdown'
import { Tabs } from '@ubnt/ui-components/Tabs'
import { useBillingInvoiceYearsQuery } from 'store/queries/useBillingInvoiceYearsQuery'
import styled from 'theme/styled'
import { InvoiceContext } from '../InvoiceContext'

const MIN_TAB_THRESHOLD = 1
const MAX_TAB_THRESHOLD = 5

export const InvoiceYearSelector: React.FC = () => {
  const { selectedYear, updateSelectedYear } = useContext(InvoiceContext)

  const { invoiceYears } = useBillingInvoiceYearsQuery()

  const noOfYears = invoiceYears?.length

  if (noOfYears <= MIN_TAB_THRESHOLD) {
    return null
  }

  const renderContent = () => {
    if (noOfYears > MAX_TAB_THRESHOLD) {
      const options = invoiceYears?.map((year) => ({
        value: year,
        label: year,
      }))

      return (
        <Dropdown
          variant="secondary"
          options={options}
          width={76}
          value={selectedYear}
          onChange={(option) => updateSelectedYear(option.value)}
        />
      )
    }

    const tabs = invoiceYears?.map((year) => ({
      id: year,
      label: year,
    }))

    return (
      <Tabs
        selectedTab={selectedYear}
        tabs={tabs}
        variant="filter"
        onSelectTab={(tab) => updateSelectedYear(tab.id)}
      />
    )
  }

  return <Wrapper>{renderContent()}</Wrapper>
}

const Wrapper = styled.div`
  width: max-content;
  margin-bottom: 16px;
`
