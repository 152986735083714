import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { TrustedDevicesApi } from 'api/trustedDevices'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from '../types'

export const useTrustDeviceQuery = (
  api: TrustedDevicesApi = new TrustedDevicesApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const trustDeviceInfo = useQuery({
    queryKey: [QueryKey.TRUST_DEVICE],
    queryFn: api.getDeviceData,
    enabled: isDoneAuthenticating,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    trustDeviceInfo: trustDeviceInfo.data,
    hasFetchedDeviceInfoOnce: trustDeviceInfo.isFetched,
    trustDeviceInfoErrors: trustDeviceInfo.error,
    isTrustDeviceInfoLoading: trustDeviceInfo.isFetching,
  }
}
