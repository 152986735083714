import React, { useContext } from 'react'
import ClosePrimaryIcon from '@ubnt/icons/ClosePrimaryIcon'
import MinusPrimaryIcon from '@ubnt/icons/MinusPrimaryIcon'
import UbiquitiLogo from '@ubnt/icons/UbiquitiLogo'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/aria/experimental'
import { FormattedMessage } from 'react-intl'
import { FlexWrapper } from 'components/SharedComponents'
import { ChatContext } from './ChatContext'
import {
  HeaderContainer,
  HeaderButtonWrapper,
  IconButton,
  HeaderUI,
  ConfirmationPopup,
} from './styles'

export const Header: React.FC = () => {
  const { setWidgetState, endChat, setReview, review } = useContext(ChatContext)

  const minimiseChat = () => {
    setWidgetState((prev) => ({ ...prev, isMinimised: true }))
  }

  return (
    <HeaderContainer>
      <div />
      <HeaderUI>
        <UbiquitiLogo variant="flat" size={32} />
        <FormattedMessage id="SUPPORT_CHAT_UI" />
      </HeaderUI>
      <HeaderButtonWrapper>
        <IconButton
          Icon={MinusPrimaryIcon({ size: 20 })}
          onClick={minimiseChat}
          type="button"
        />
        <IconButton
          Icon={ClosePrimaryIcon({ size: 20 })}
          onClick={endChat}
          type="button"
        />
      </HeaderButtonWrapper>
      {review.isConfirm && (
        <ConfirmationPopup>
          <Text variant="heading-large">
            <FormattedMessage id="SUPPORT_CHAT_CLOSE_CONFIRM" />
          </Text>
          <FlexWrapper gap={8}>
            <Button
              onPress={() =>
                setReview((prev) => ({ ...prev, isConfirm: false }))
              }
              variant="tertiary-neutral"
            >
              <FormattedMessage id="COMMON_ACTION_CANCEL" />
            </Button>
            <Button onPress={endChat} variant="destructive">
              <FormattedMessage id="SUPPORT_CHAT_END" />
            </Button>
          </FlexWrapper>
        </ConfirmationPopup>
      )}
    </HeaderContainer>
  )
}
