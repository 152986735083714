import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { MfaApi } from 'api/sso/mfa'
import { doDoubleLogin } from 'features/auth/modules/auth'
import {
  closeVisibleModal,
  selectVisibleModal,
  setVisibleModal,
} from 'modules/modals'
import { MFAType } from 'modules/types'
import { PUSH_QR_CODE_MODAL_ID } from 'pages/security/components/Push/QRCodeModal'
import { useMfaToasts } from 'pages/MFAToasts'
import { PUSH_REMOVE_MODAL_ID } from 'pages/security/components/Push/RemoveModal'
import { QueryKey } from 'store/types'
import { parseSsoErrors } from 'store/utils'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { SsoApiError } from 'types/ssoErrors'
import { InitiateData } from 'types/mfa'

type SsoInitiatePushResponse = {
  setup_uri: string
  id: string
}

const setupController = new AbortController()
let loginController = new AbortController()

const mfaApi = new MfaApi()

export const useMfaPush = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { getDeactivatedMfaAuthenticators } = useMFAAuthenticators()
  const { createMfaSuccessToast } = useMfaToasts()
  const { visibleModal } = useSelector(selectVisibleModal)

  const removePush = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    string
  >({
    mutationFn: (id) => mfaApi.remove({ type: MFAType.push, id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.MFA_AUTHENTICATORS],
      })
      if (visibleModal === PUSH_REMOVE_MODAL_ID) {
        dispatch(closeVisibleModal())
      }
    },
  })

  const verifyPush = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    string
  >({
    mutationFn: (id) =>
      mfaApi.pollSetupPush({ id, signal: setupController.signal }),
    onSuccess: (response, id) => {
      if (response.status === 202) {
        verifyPush.mutate(id)
      } else {
        const deactivatedMfas = getDeactivatedMfaAuthenticators()
        const deactivatedPush = deactivatedMfas.filter(
          (mfa) => mfa.type === MFAType.push
        )

        if (deactivatedPush.length) {
          deactivatedPush.forEach((push) => {
            removePush.mutate(push.id)
          })
        }

        queryClient.invalidateQueries({
          queryKey: [QueryKey.MFA_AUTHENTICATORS],
        })
        dispatch(closeVisibleModal())
        createMfaSuccessToast(MFAType.push)
      }
    },
    onError: () => {
      addPush.reset()
    },
  })

  const verifyPushCancel = useCallback(() => {
    setupController.abort()
  }, [])

  const addPush = useMutation<
    SsoInitiatePushResponse,
    AxiosError<SsoApiError>,
    InitiateData
  >({
    mutationFn: (data: InitiateData) =>
      mfaApi.initiate({ type: MFAType.push, data }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      queryClient.setQueryData([QueryKey.MFA_INITIATED_PUSH], {
        setup_uri: data.setup_uri,
        id: data.id,
      })
      verifyPush.mutate(data.id)
      dispatch(setVisibleModal(PUSH_QR_CODE_MODAL_ID))
    },
  })

  const loginPush = useMutation<AxiosResponse, AxiosError<SsoApiError>, void>({
    mutationFn: () => mfaApi.pollLoginPush(loginController.signal),
    onSuccess: (response) => {
      if (response.status === 202) {
        loginPush.mutate()
      } else {
        dispatch(doDoubleLogin())
      }
    },
  })

  const loginPushCancel = useCallback(() => {
    loginController.abort()
  }, [])

  const sendPush = useMutation<AxiosResponse, AxiosError<SsoApiError>, void>({
    mutationFn: () => mfaApi.send({ type: MFAType.push }),
    onSuccess: () => {
      if (loginController.signal.aborted) {
        loginController = new AbortController()
      }
      loginPush.mutate()
    },
  })

  return {
    addPush: addPush.mutate,
    addPushError: parseSsoErrors(addPush.error),
    isAddPushLoading: addPush.isPending,
    resetAddPush: addPush.reset,
    isAddPushSuccess: addPush.isSuccess,
    addPushData: queryClient.getQueryData<SsoInitiatePushResponse>([
      QueryKey.MFA_INITIATED_PUSH,
    ]),

    isVerifyPushLoading: verifyPush.isPending,
    resetVerifyPush: verifyPush.reset,
    isVerifyPushSuccess: verifyPush.isSuccess,
    verifyPushError: parseSsoErrors(verifyPush.error),
    verifyPushCancel,

    removePush: removePush.mutate,
    removePushError: parseSsoErrors(removePush.error),
    isRemovePushLoading: removePush.isPending,
    resetRemovePush: removePush.reset,
    isRemovePushSuccess: removePush.isSuccess,

    sendPush: sendPush.mutate,
    sendPushError: parseSsoErrors(sendPush.error),
    isSendPushLoading: sendPush.isPending,
    resetSendPush: sendPush.reset,
    isSendPushSuccess: sendPush.isSuccess,

    loginPush: loginPush.mutate,
    loginPushError: parseSsoErrors(loginPush.error),
    isLoginPushLoading: loginPush.isPending,
    resetLoginPush: loginPush.reset,
    isLoginPushSuccess: loginPush.isSuccess,
    loginPushCancel,
  }
}
