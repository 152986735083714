import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { KeyholeSecurityIcon } from '@ubnt/icons'
import { FormattedMessage } from 'react-intl'
import { motifPalette, typography } from 'theme'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { useMotif } from 'motif/useMotif'

export const ModalHeader = () => {
  const motif = useMotif()
  const { isMaxMobileLarge } = useContext(MediaSizingContext)

  return (
    <HeaderWrapper>
      <KeyholeSecurityIcon
        variant="fill"
        color={motifPalette[motif].green06}
        size={isMaxMobileLarge ? 65 : 32}
      />
      <Title>
        <FormattedMessage id="ADD_MFA_PROMPT_TITLE" />
      </Title>
    </HeaderWrapper>
  )
}

const Title = styled.span`
  font: ${typography['desktop-heading-xlarge-2']};
  color: ${({ theme }) => theme.text1};
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    text-align: center;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    flex-direction: column;
    gap: 8px;
  }
`
