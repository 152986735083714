import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { setVisibleModal } from 'modules/modals'
import { useBillingOpenInvoicesQuery } from 'store/queries/useBillingOpenInvoicesQuery'
import { useBillingSubscriptionsQuery } from 'store/queries/useBillingSubscriptionsQuery'
import { CONFIRM_DELETE_ACCOUNT_MODAL } from './ConfirmDeleteAccountModal'
import { DeleteAccountModalBody } from './DeleteAccountModalBody'
import { hasPendingSubscriptions } from '../utils'

const DeleteAccountModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const { areOpenInvoicesLoading, openInvoices } = useBillingOpenInvoicesQuery()
  const { fullSubscriptions } = useFullSubscriptions()
  const { areSubscriptionsLoading } = useBillingSubscriptionsQuery()

  const isLoading = useMemo(() => {
    const invoicesAreLoading = areOpenInvoicesLoading
    const subscriptionsAreLoading = areSubscriptionsLoading

    return invoicesAreLoading || subscriptionsAreLoading
  }, [areOpenInvoicesLoading, areSubscriptionsLoading])

  const hasOpenInvoices = !!openInvoices.length
  const hasActiveSubscriptions = hasPendingSubscriptions(fullSubscriptions)

  const pendingItems = hasOpenInvoices || hasActiveSubscriptions

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_SECURITY_DELETE" />}
      size="small"
      actions={
        pendingItems
          ? []
          : [
              {
                text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
                onClick: onClose,
                variant: 'tertiary',
              },
              {
                text: <FormattedMessage id="SETTINGS_SECURITY_DELETE_BUTTON" />,
                variant: 'primary',
                color: 'danger',
                onClick: () =>
                  dispatch(setVisibleModal(CONFIRM_DELETE_ACCOUNT_MODAL)),
                disabled: isLoading || !!pendingItems,
                loader: isLoading ? 'dots' : undefined,
                type: 'submit',
              },
            ]
      }
      footer={pendingItems ? <></> : undefined}
    >
      <DeleteAccountModalBody
        isLoading={isLoading}
        actionNotAllowed={pendingItems}
      />
    </GenericModal>
  )
}

export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL'

export const WrappedDeleteAccountModal = () => (
  <ModalWrapper modalId={DELETE_ACCOUNT_MODAL}>
    <DeleteAccountModal />
  </ModalWrapper>
)
