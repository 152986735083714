import React, { CSSProperties } from 'react'
import { Tooltip, TooltipPosition } from '@ubnt/ui-components/Tooltip'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import styled from 'theme/styled'

interface Props {
  locked?: boolean
  message?: string
  inline?: boolean
  width?: number
  position?: TooltipPosition
  style?: CSSProperties
  children: React.ReactNode
}

export const LockTooltip: React.FC<Props> = ({
  locked,
  message,
  width = 300,
  inline = false,
  position = 'top',
  style = {},
  children,
}) => {
  const intl = useIntl()
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const assuranceLocked = assuranceLevel.data.level === 1
  const check = locked ?? assuranceLocked

  return (
    <>
      {check ? (
        <StyledTooltip
          description={
            message ||
            intl.formatMessage({ id: 'SETTINGS_LOCK_TOOLTIP_DESCRIPTION' })
          }
          width={width}
          inline={inline}
          portal={true}
          position={position}
          style={style}
        >
          {children}
        </StyledTooltip>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

const StyledTooltip = styled(Tooltip)<{ inline: boolean }>`
  cursor: not-allowed;
  ${({ inline }) => inline && 'display: inline;'};
  .tooltip {
    color: ${(props) => props.theme.neutral08};
    font-size: 13px;
    transform: translate(-16px, calc(100% + 10px)) !important;
    z-index: 999999;
    opacity: 1 !important;
  }
  button,
  svg,
  .editSelector {
    pointer-events: none;
  }
  a,
  svg,
  div {
    cursor: not-allowed;
    pointer-events: none;
  }
`
