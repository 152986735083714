import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@ubnt/ui-components'
import {
  AccountMenu,
  AccountMenu_WithMessageList,
  Avatar_Announcement,
  useAnnouncementAnimation,
} from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { sessionStorageChatKey } from 'features/support/unifi/UniFiForm'
import {
  GreyButton,
  PopoverOption,
  SignOutButton,
} from 'features/user-popover/ui/UserPopover.styles'
import { useMarkAnnouncementAsRead } from 'store/mutations/announcements/useMarkAnnouncementAsRead'
import { useAnnouncementsQuery } from 'store/queries/useAnnouncementsQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { ROUTES, Route } from 'types/routes'
import { getProfileNameToDisplay } from 'utils/getProfileNameToDisplay'
import { useExternalLink } from 'utils/useExternalLink'

interface BasicAnnouncement {
  id: string
  title: string
  description: string
  externalLink: string
}

const {
  api: { account },
} = __CONFIG__

export const UserPopover: React.FC = () => {
  const history = useHistory()
  const { profileData } = useSsoProfileQuery()
  const { unreadAnnouncements, isAnnouncementsFetched } =
    useAnnouncementsQuery()
  const { markAnnouncementAsRead } = useMarkAnnouncementAsRead()
  const goToExternalLink = useExternalLink()
  const [shouldAnimateIn] = useState(!isAnnouncementsFetched) // Set once and shouldnt change

  const { animate, display, animateIn, animateOut } = useAnnouncementAnimation(
    undefined,
    unreadAnnouncements.length && isAnnouncementsFetched
      ? 'announcement'
      : 'userAvatar'
  )

  useEffect(() => {
    if (unreadAnnouncements.length > 0 && shouldAnimateIn) animateIn()
    if (unreadAnnouncements.length === 0) animateOut()
  }, [unreadAnnouncements.length, shouldAnimateIn, animateIn, animateOut])

  const announcementsToShow = useMemo(() => {
    const markAsRead = (selectedId: string) => {
      markAnnouncementAsRead(selectedId)
    }
    const actionButtons = (announcement: BasicAnnouncement) => [
      <GreyButton
        key={`clear-${announcement.id.toString()}`}
        variant="inline"
        type="button"
        onClick={() => markAsRead(announcement.id)}
      >
        <FormattedMessage id="COMMON_DESIGN_CLEAR" />
      </GreyButton>,
      <Button
        key={`show-${announcement.id.toString()}`}
        variant="inline"
        onClick={() => {
          markAsRead(announcement.id)
          goToExternalLink(announcement.externalLink)
          // TODO: Check if we want to keep this tracking
          window.gtag?.('event', 'announcement_click')
        }}
      >
        <FormattedMessage id="COMMON_DESIGN_SHOW_ME" />
      </Button>,
    ]
    return (
      unreadAnnouncements?.map((announcement, index) => {
        let headerImg = undefined

        if (announcement.media.image && index === 0) {
          headerImg = (
            <img
              style={{ width: '100%', objectFit: 'cover' }}
              src={announcement.media.image}
            />
          )
        }
        return {
          ...announcement,
          showIndicator: true,
          actionButtons: actionButtons(announcement),
          headerImg,
        }
      }) || []
    )
  }, [unreadAnnouncements, markAnnouncementAsRead, goToExternalLink])

  const signOut = () => {
    sessionStorage.removeItem(sessionStorageChatKey)
    history.push(account.paths.logout)
  }

  return (
    <AccountMenu.Root>
      <AccountMenu.Trigger>
        <Avatar_Announcement
          image={profileData?.picture}
          alt="User avatar"
          animate={animate}
          display={display}
          notices={unreadAnnouncements.length ?? 0}
        />
      </AccountMenu.Trigger>
      <AccountMenu_WithMessageList
        image={profileData?.picture}
        alt="User avatar"
        actionButtons={[
          <SignOutButton
            id="signOut"
            key="signOut"
            size="medium"
            onClick={signOut}
          >
            <FormattedMessage id="COMMON_AUTH_ACTION_SIGN_OUT" />
          </SignOutButton>,
        ]}
        emailAddress={profileData?.email}
        messages={announcementsToShow}
        footerLinks={[
          <PopoverOption href={ROUTES[Route.TermsAndConditions]}>
            <FormattedMessage id="COMMON_LABEL_TERMS_AND_CONDITIONS" />
          </PopoverOption>,
          <PopoverOption href={ROUTES[Route.PrivacyPolicy]}>
            <FormattedMessage id="COMMON_LABEL_PRIVACY_POLICY" />
          </PopoverOption>,
        ]}
        name={getProfileNameToDisplay(profileData)}
      />
    </AccountMenu.Root>
  )
}
