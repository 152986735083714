import React, { ReactNode, useEffect } from 'react'
import { ButtonVariants, DropdownRef } from '@ubnt/ui-components'
import { FooterAction } from '@ubnt/ui-components/Footer/Footer'
import isMobile from 'is-mobile'
import { useIntl } from 'react-intl'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'

const DescriptionWrapper = styled.div<{ alignLeft?: boolean }>`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text2};
  @media (max-width: 600px) {
    padding: 0;
  }
  ${(props) =>
    props.alignLeft &&
    `
      text-align: left;
    `}
`

interface GenericModalBasicProps extends ModalProps {
  title: string
  description?: ReactNode
  isLoading?: boolean
  onAfterClose?(): void
  onRequestClose?(): void
  onAfterOpen?(): void
  isMobileDevice?: boolean
  buttonTabIndex?: number
  focusElement?: HTMLInputElement | DropdownRef | null
  footerHeight?: number
  alignLeft?: boolean
  children?: React.ReactNode
  size?: 'small' | 'full' | 'medium' | 'large' | 'auto'
  overrideFullScreen?: boolean
}

interface GenericModalPrimaryButtonProps {
  primaryButton?: {
    text: string
    onClick(): void
    disabled?: boolean
    variant?: ButtonVariants
    type?: 'submit'
    buttonMargin?: boolean
  }
  actions?: never
}

interface GenericModalActionsProps {
  actions: FooterAction[]
  primaryButton?: never
}

type GenericModalProps = GenericModalBasicProps &
  (GenericModalPrimaryButtonProps | GenericModalActionsProps)

const MFAModal: React.FC<GenericModalProps> = ({
  title,
  description,
  primaryButton,
  isLoading,
  isOpen,
  onClose,
  children,
  actions,
  buttonTabIndex,
  focusElement,
  footerHeight,
  alignLeft = false,
  ...restprops
}) => {
  const intl = useIntl()

  const isMobileDevice = isMobile()

  const getActions = () => {
    if (actions) return actions

    const defaultActions: FooterAction[] = [
      {
        text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
        variant: 'tertiary',
        onClick: onClose,
        tabIndex: buttonTabIndex ?? 1,
      },
    ]

    if (primaryButton) {
      defaultActions.push({
        text: primaryButton.text,
        variant: primaryButton.variant || 'primary',
        type: primaryButton.type,
        disabled: primaryButton.disabled || isLoading,
        onClick: primaryButton.onClick,
        loader: isLoading ? 'dots' : undefined,
        tabIndex: buttonTabIndex ? buttonTabIndex + 1 : 2,
        style: {
          marginBottom: primaryButton.buttonMargin ? '10px' : 'initial',
          boxShadow: 'none',
        },
      })
    }

    return defaultActions
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      primaryButton?.onClick()
    }
  }

  useEffect(() => {
    if (focusElement) return focusElement.focus()
  }, [focusElement])

  return (
    <GenericModal
      isMobileDevice={isMobileDevice}
      isOpen={isOpen}
      onRequestClose={onClose}
      actions={getActions()}
      title={title}
      {...restprops}
    >
      {description && (
        <DescriptionWrapper alignLeft={alignLeft}>
          {description}
        </DescriptionWrapper>
      )}
      <div onKeyDown={handleOnKeyDown}>{children}</div>
    </GenericModal>
  )
}

type GenericMFAModalProps = GenericModalProps & {
  modalId: string
}

export const GenericMFAModal: React.FC<GenericMFAModalProps> = ({
  modalId,
  children,
  ...restProps
}) => {
  return (
    <ModalWrapper modalId={modalId}>
      <MFAModal {...restProps}>{children}</MFAModal>
    </ModalWrapper>
  )
}
