import { parseDescription } from 'features/support/utils'
import {
  extraConsoles,
  device,
  trouble,
  assistance,
  otherConsole,
} from '../config/ConfigOptions'
import { SupportFormState } from '../types'

const parseField = (
  type: 'console' | 'device' | 'trouble' | 'assistance',
  valueId?: string
) => {
  if (type === 'console') {
    return [...extraConsoles, otherConsole].find(({ id }) => id === valueId)
      ?.label
  }

  if (type === 'device') {
    return device.find(({ id }) => id === valueId)?.label
  }
  if (type === 'trouble') {
    return trouble.find(({ id }) => id === valueId)?.label
  }

  return assistance.find(({ id }) => id === valueId)?.label
}

export const createSubject = (
  { trouble, console: t, assistance, device }: SupportFormState,
  description: string = ''
) => {
  const parsedConsole = t?.deviceName || parseField('console', t?.id)
  const parsedDevice = parseField('device', device)
  const parsedTrouble = parseField('trouble', trouble)

  let dynamicText = ''

  const parsedDescription = parseDescription(description)

  if (parsedTrouble && (parsedDevice || parsedConsole)) {
    dynamicText = `${parsedDevice || parsedConsole} | ${parsedTrouble}: `
  }

  if (parsedTrouble && !parsedDevice && !parsedConsole) {
    dynamicText = `${parsedTrouble}: `
  }

  const parsedAssistance = parseField('assistance', assistance)

  if (!parsedTrouble && parsedAssistance && (parsedDevice || parsedConsole)) {
    dynamicText = `${parsedDevice || parsedConsole} | ${parsedAssistance}: `
  }

  if (!parsedTrouble && parsedAssistance && !parsedDevice && !parsedConsole) {
    dynamicText = `${parsedAssistance}: `
  }

  return `${dynamicText}${parsedDescription}...`
}
