import { MFAAuthenticatorStatus } from 'enums'

export enum MFAType {
  email = 'email',
  totp = 'totp',
  sms = 'sms',
  webAuthn = 'webauthn',
  backupCodes = 'backup_code',
  push = 'push',
}

export enum ProviderName {
  ICLOUD_KEYCHAIN = 'iCloud Keychain',
  ICLOUD_KEYCHAIN_MANAGED = 'iCloud Keychain (Managed)',
  GOOGLE_PASSWORD_MANAGER = 'Google Password Manager',
  ONE_PASSWORD = '1Password',
  WINDOWS_HELLO = 'Windows Hello',
  SAMSUNG_PASS = 'Samsung Pass',
  TOOTHPICK_PASSKEY_PROVIDER = 'ToothPic Passkey Provider',
  THALES_BIO_IOS = 'Thales Bio iOS SDK',
  THALES_BIO_ANDROID = 'Thales Bio Android SDK',
  THALES_PIN_IOS = 'Thales PIN iOS SDK',
  THALES_PIN_ANDROID = 'Thales PIN Android SDK',
  CHROME_ON_MAC = 'Chrome on Mac',
  YUBIKEY = 'YubiKey',
}

interface MFAAuthenticatorBase {
  id: string
  created: string
  last_success: string
  verified: boolean
  status: MFAAuthenticatorStatus
}

export interface MFAAuthenticatorPush extends MFAAuthenticatorBase {
  type: MFAType.push
  name: string
}
export interface MFAAuthenticatorEmail extends MFAAuthenticatorBase {
  type: MFAType.email
  email: string
}

export interface MFAAuthenticatorSMS extends MFAAuthenticatorBase {
  type: MFAType.sms
  phone_number: string
}

export interface MFAAuthenticatorTOTP extends MFAAuthenticatorBase {
  type: MFAType.totp
  name: string
}

export interface MFAAuthenticatorWebAuthn extends MFAAuthenticatorBase {
  type: MFAType.webAuthn
  name?: string
  provider_friendly_name: string
}

export interface MFAAuthenticatorBackupCodes extends MFAAuthenticatorBase {
  type: MFAType.backupCodes
  codes_left: number
}

export type MFAAuthenticator =
  | MFAAuthenticatorPush
  | MFAAuthenticatorEmail
  | MFAAuthenticatorSMS
  | MFAAuthenticatorTOTP
  | MFAAuthenticatorWebAuthn
  | MFAAuthenticatorBackupCodes
