import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { PersonalBillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { closeVisibleModal } from 'modules/modals'
import { QueryKey } from 'store/types'

export const useCancelSiteSupportSubscription = (
  api: PersonalBillingSubscriptionsApi = new PersonalBillingSubscriptionsApi()
) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const cancelSiteSupportSubscription = useMutation({
    mutationFn: api.cancelSiteSupport,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      dispatch(closeVisibleModal())
    },
  })

  return {
    cancelSiteSupportSubscription: cancelSiteSupportSubscription.mutate,
    isCancelSiteSupportSubscriptionLoading:
      cancelSiteSupportSubscription.isPending,
    cancelSiteSupportSubscriptionErrors: cancelSiteSupportSubscription.error,
    resetCancelSiteSupportSubscriptionErrors:
      cancelSiteSupportSubscription.reset,
  }
}
