import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { useIntl } from 'react-intl'
import { BillingTaxIdApi, TaxIdErrorCode } from 'api/billing/billingTaxId'
import { useToasts } from 'pages/payments/components/useToasts'
import { QueryKey } from 'store/types'

export const useDeleteTaxId = (
  api: BillingTaxIdApi = new BillingTaxIdApi()
) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const { taxIdSuccessToast, taxIdErrorToast } = useToasts()

  const deleteTaxId = useMutation({
    mutationFn: api.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_TAX_ID],
      })
      taxIdSuccessToast('removed')
    },
    onError: (error) => {
      let errorMessageId = 'SETTINGS_PAYMENTS_TAX_ID_DELETE_FAILURE'
      if (
        isAxiosError(error) &&
        error.response?.data.metaData.code ===
          TaxIdErrorCode.CANNOT_DELETE_OR_UPDATE_REGION
      ) {
        errorMessageId = 'SETTINGS_PAYMENTS_TAX_ID_CANNOT_DELETE'
      }

      const errorMessage = intl.formatMessage({
        id: errorMessageId,
      })

      taxIdErrorToast(errorMessage)
    },
  })

  return {
    deleteTaxId: deleteTaxId.mutate,
    isDeleteTaxIdLoading: deleteTaxId.isPending,
  }
}
