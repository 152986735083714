import React, { useEffect, useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { ValidationError } from 'components/Input'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useDeleteCard } from 'store/mutations/billingCards/useDeleteCard'
import { getMutationErrorMessage } from 'store/mutations/utils'
import styled from 'theme/styled'
import { StripeErrors } from './Components'
import { CardType } from '../modules/types'

interface Props {
  card?: CardType
}

const RemovePaymentMethodModal: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, card }) => {
  const {
    deleteCardError,
    resetDeleteCardError,
    deleteCard,
    isDeleteCardLoading,
  } = useDeleteCard()
  const [deleteError, setDeleteError] = useState<string | null>(null)

  useEffect(() => {
    const apiError = getMutationErrorMessage(deleteCardError)

    if (
      apiError === 'Cannot delete the payment-method with active subscription.'
    ) {
      return setDeleteError(
        intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_CANNOT_DELETE' })
      )
    }
    setDeleteError(apiError)
  }, [deleteCardError, intl])

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={() => {
        deleteError && resetDeleteCardError()
        onClose && onClose()
      }}
      title={
        <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_REMOVE" tagName="div" />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: () => {
            deleteError && resetDeleteCardError()
            onClose && onClose()
          },
          variant: 'default',
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_REMOVE' }),
          type: 'submit',
          variant: 'primary',
          onClick: () => {
            deleteError && resetDeleteCardError()
            if (card?.id) deleteCard({ id: card.id, region: card.region })
          },
          style: { outline: 'none' },
          loader: isDeleteCardLoading ? 'spinner' : undefined,
        },
      ]}
    >
      <Wrapper>
        <Message>
          <FormattedMessage
            id="SETTINGS_PAYMENTS_REMOVE_PAYMENT_MESSAGE"
            tagName="div"
          />
        </Message>
        <StripeErrors noPadding>
          {deleteError ? (
            <ValidationError>{deleteError}</ValidationError>
          ) : null}
        </StripeErrors>
      </Wrapper>
    </GenericModal>
  )
}

const RemovePaymentMethodModalConnected = injectIntl(RemovePaymentMethodModal)

export const DELETE_PAYMENT_METHOD_MODAL_ID = 'DELETE_PAYMENT_METHOD_MODAL_ID'

export default () => (
  <ModalWrapper modalId={DELETE_PAYMENT_METHOD_MODAL_ID}>
    <RemovePaymentMethodModalConnected />
  </ModalWrapper>
)

const Wrapper = styled.div`
  padding-top: 12px;
  text-align: left;
`

const Message = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text2};
  padding-bottom: 10px;
`
