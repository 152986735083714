import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { flatMap, get, uniq } from 'lodash-es'
import { useSelector } from 'react-redux'
import { PersonalBillingInvoicesApi } from 'api/billing/billingInvoices'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { useBillingInvoicesApi } from 'pages/subscriptions/hooks/useBillingInvoicesApi'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'

export const useBillingInvoiceYearsQuery = (
  api?: PersonalBillingInvoicesApi
) => {
  const isDoneAuthenticating = useSelector(selectIsAuthenticated)

  const billingInvoicesApi = useBillingInvoicesApi()

  const billingApi = api ?? billingInvoicesApi

  const rawInvoiceYears = useQuery({
    queryKey: [QueryKey.BILLING_INVOICE_YEARS],
    queryFn: billingApi.getInvoiceYears,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const invoiceYears = useMemo(() => {
    if (!rawInvoiceYears.data) {
      return []
    }

    const yearsFromAllRegions = flatMap(rawInvoiceYears.data, (region) =>
      get(region, 'availableYears', [])
    )

    const uniqueYears = uniq(yearsFromAllRegions)

    const sortedYears = uniqueYears.sort((a, b) => b - a)

    return sortedYears.map((year) => year.toString())
  }, [rawInvoiceYears.data])

  return { invoiceYears, areInvoiceYearsFetched: rawInvoiceYears.isFetched }
}
