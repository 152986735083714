import React from 'react'
import EmptyResult from '@ubnt/ui-components/EmptyResult/EmptyResult'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import AppViewHeader from 'components/AppViewHeader'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { ErrorPageFourOhFour } from 'core/error-handling/ErrorPages'
import AddPaymentMethodModal from 'features/payment-methods/ui/AddPaymentMethodModal'
import { ProtectedPage } from 'pages/ProtectedPage'
import WrappedInvoiceModal from 'pages/subscriptions/components/invoices/InvoiceModal'
import ChoosePaymentMethodModal from 'pages/subscriptions/components/subscriptionServices/ChoosePaymentMethodModal'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'
import { useBillingSubscriptionsQuery } from 'store/queries/useBillingSubscriptionsQuery'
import { useBillingUpcomingInvoicesQuery } from 'store/queries/useBillingUpcomingInvoicesQuery'
import { HeaderContainer } from './components/subscriptions/Components.styles'
import { WrappedCancelSubscriptionModal } from './components/subscriptionServices/CancelSubscriptionModal'
import { WrappedExternalCancelSubscriptionModal } from './components/subscriptionServices/ExternalCancelSubscriptionModal'
import { SkeletonLoaders } from './components/subscriptionServices/SkeletonLoaders'
import { SubscriptionServicesList } from './components/subscriptionServices/SubscriptionServicesList'
import { getSubscriptionServiceFromUrl } from './components/subscriptionServices/utils'

export const SubscriptionService = () => {
  const { service } = useParams<{ service: string }>()
  const pageUrl = `/subscriptions/${service}`
  const group = getSubscriptionServiceFromUrl(pageUrl)
  const {
    areSubscriptionsLoading,
    areSubscriptionsFetched,
    userSubscriptions,
  } = useBillingSubscriptionsQuery()
  const { areBillingProductsLoading } = useBillingProductsQuery()
  const { areUpcomingInvoicesLoading } = useBillingUpcomingInvoicesQuery()

  if (!group) {
    return <ErrorPageFourOhFour />
  }

  const breadcrumbLinks = [
    {
      url: '/subscriptions',
      label: <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_HEADER" />,
    },
  ]

  const renderContent = () => {
    if (
      areSubscriptionsLoading ||
      !areSubscriptionsFetched ||
      areUpcomingInvoicesLoading ||
      areBillingProductsLoading
    ) {
      return <SkeletonLoaders />
    }

    if (areSubscriptionsFetched && userSubscriptions.length > 0) {
      return <SubscriptionServicesList group={group} />
    }

    return (
      <EmptyResult
        title={<FormattedMessage id={'SETTINGS_SUBSCRIPTIONS_EMPTY_TITLE'} />}
        description={
          <FormattedMessage
            id={'SETTINGS_SUBSCRIPTIONS_SERVICE_EMPTY_MESSAGE'}
          />
        }
        size="large"
      />
    )
  }

  return (
    <ProtectedPage renderMargin={true}>
      <HeaderContainer>
        <AppViewHeader omitMarginBottom />
        <Breadcrumbs links={breadcrumbLinks} currentPage={group} />
      </HeaderContainer>
      {renderContent()}
      <WrappedInvoiceModal />
      <ChoosePaymentMethodModal />
      <AddPaymentMethodModal />
      <WrappedExternalCancelSubscriptionModal />
      <WrappedCancelSubscriptionModal />
    </ProtectedPage>
  )
}
