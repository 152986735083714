import React from 'react'
import { UbiquitiLogo } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage } from 'react-intl'
import { socialMediaLinks, uiLinks } from 'redirectLinks'
import { size, space, typography } from 'theme'
import ConstructionImage from 'assets/images/constructionLight.png'
import Facebook from 'assets/svgs/socialMedia/facebook.svg'
import Instagram from 'assets/svgs/socialMedia/instagram.svg'
import Twitter from 'assets/svgs/socialMedia/twitter.svg'
import Youtube from 'assets/svgs/socialMedia/youtube.svg'
import ExternalLink from 'components/ExternalLink'
import styled from 'theme/styled'
import { useAppView } from 'utils/useAppView'

export const MaintenancePage: React.FC = () => {
  const { facebook, twitter, youtube, instagram } = socialMediaLinks
  const { statusUi, helpUi } = uiLinks

  useAppView()

  return (
    <Wrapper>
      <BodyWrapper>
        <UbiquitiLogo variant="neutral" width={72} height={72} />
        <TextWrapper>
          <Title>
            <FormattedMessage id="COMMON_LABEL_MAINTENANCE_PAGE_TITLE" />
          </Title>
          <Description>
            <FormattedMessage id="COMMON_LABEL_MAINTENANCE_PAGE_DESCRIPTION" />
          </Description>
          <a href="#">
            <StyledButton variant="primary" size="large">
              <FormattedMessage id="COMMON_LABEL_MAINTENANCE_PAGE_BUTTON" />
            </StyledButton>
          </a>
        </TextWrapper>
        <Footer>
          <FormattedMessage
            id="COMMON_LABEL_MAINTENANCE_HELP"
            values={{
              a: (text) => <Link href={helpUi}>{text}</Link>,
            }}
          />
          <FormattedMessage
            id="COMMON_LABEL_MAINTENANCE_STATUS"
            values={{
              a: (text) => <Link href={statusUi}>{text}</Link>,
            }}
          />
          <SocialMediaWrapper>
            <ExternalLink href={facebook}>
              <img src={Facebook} />
            </ExternalLink>
            <ExternalLink href={twitter}>
              <img src={Twitter} />
            </ExternalLink>
            <ExternalLink href={youtube}>
              <img src={Youtube} />
            </ExternalLink>
            <ExternalLink href={instagram}>
              <img src={Instagram} />
            </ExternalLink>
          </SocialMediaWrapper>
        </Footer>
      </BodyWrapper>
      <ImageWrapper>
        <Image src={ConstructionImage} alt="alt" draggable={false} />
      </ImageWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${space['desktop-spacing-base-16']} 0
    ${space['desktop-spacing-base-16']} ${space['desktop-spacing-base-25']};
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #d8dce2;
  @media (max-width: 800px) {
    padding: ${size['desktop-sizing-base-05']};
  }
`

const BodyWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 800px) {
    align-items: center;
  }
`

const Footer = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  gap: ${size['desktop-sizing-base-05']};
  z-index: 1;
  font: ${typography['desktop-typography-heading-large']};
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: ${space['desktop-spacing-base-03']};
  }
`

const SocialMediaWrapper = styled.div`
  display: flex;
  gap: ${space['desktop-spacing-base-05']};
`

const Link = styled(ExternalLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
`

const Title = styled.h1`
  font: ${typography['desktop-typography-heading-xlarge-4']};
  margin: 0;
  @media (max-width: 800px) {
    font: ${typography['desktop-typography-heading-xlarge-2']};
  }
`

const Description = styled.p`
  font: ${typography['desktop-font-size-xlarge-1']};
  margin-top: ${space['desktop-spacing-base-02']};
`

const StyledButton = styled(Button)`
  width: fit-content;
  font: ${typography['desktop-body']};
  margin-top: ${space['desktop-spacing-base-08']};
`

const ImageWrapper = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`
