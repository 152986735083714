import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { LocationDescriptor, LocationDescriptorObject } from 'history'

type Options = {
  replace?: boolean
  state?: LocationDescriptorObject['state']
}

export const useNavigate = () => {
  const history = useHistory()

  const navigate = useCallback(
    (to: LocationDescriptor, options: Options = {}) => {
      const { replace = false, state } = options

      if (replace) {
        history.replace(to, state)
      } else {
        history.push(to, state)
      }
    },
    [history]
  )

  return navigate
}
