import React from 'react'
import { Checkbox, type CheckboxProps } from '@ubnt/ui-components/Checkbox'
import { Text } from '@ubnt/ui-components/Text'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'

type BackupsViewHeaderProps = {
  deviceId: string
  isManageStateActive: boolean
  onClickHeaderCheckbox: () => void
  checkboxState: CheckboxProps['checked']
}

export const BackupsViewHeader: React.FC<BackupsViewHeaderProps> = ({
  isManageStateActive,
  deviceId,
  onClickHeaderCheckbox,
  checkboxState,
}) => {
  if (isManageStateActive) {
    return (
      <StyledCheckbox
        id={deviceId}
        onChange={onClickHeaderCheckbox}
        checked={checkboxState}
      >
        <Header size="body">
          <FormattedMessage id="COMMON_LABEL_DATE_TIME" />
        </Header>
      </StyledCheckbox>
    )
  }

  return (
    <Header size="body" $extraPadding>
      <FormattedMessage id="COMMON_LABEL_DATE_TIME" />
    </Header>
  )
}

const StyledCheckbox = styled(Checkbox)`
  padding: 6px 4px;
`

const Header = styled(Text, {
  shouldForwardProp: (prop) => prop !== '$extraPadding',
})<{ $extraPadding?: boolean }>`
  font-weight: 700;
  color: ${({ theme }) => theme.text1};

  ${({ $extraPadding }) => $extraPadding && '  padding: 6px 4px;'}
`
