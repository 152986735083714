import React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'

interface Props {
  email?: string
}

const TooManyRequestsModal: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl }) => {
  const { resetUpdateProfile } = useUpdateProfile()

  const handleClose = () => {
    resetUpdateProfile()
    onClose?.()
  }

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage
          id="SETTINGS_PROFILE_CHANGE_EMAIL_TOO_MANY_RETRIES_TITLE"
          tagName="div"
        />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_OKAY' }),
          onClick: handleClose,
          variant: 'default',
        },
      ]}
    >
      <FormattedMessage id="SETTINGS_PROFILE_CHANGE_EMAIL_TOO_MANY_RETRIES_DETAILS" />
    </GenericModal>
  )
}

const EmailVerifyCodeModalConnected = injectIntl(TooManyRequestsModal)

export const TOO_MANY_REQUESTS_CODE_MODAL_ID = 'TOO_MANY_REQUESTS_CODE_MODAL_ID'

export default () => (
  <ModalWrapper modalId={TOO_MANY_REQUESTS_CODE_MODAL_ID}>
    <EmailVerifyCodeModalConnected />
  </ModalWrapper>
)
