import React, { useEffect, useRef } from 'react'
import { Bubble } from 'components/support/Bubble'
import { CommunityTile } from 'components/support/CommunityTile'
import { DesignCenterTile } from 'components/support/DesignCenterTile'
import { LargeProjectTile } from 'components/support/LargeProjectTile'
import { PortalTile } from 'components/support/PortalTile'
import styled from 'theme/styled'
import { BubbleProps } from './types'

export const InfoBubble: React.FC<BubbleProps> = ({ shouldScroll }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <Bubble question="SUPPORT_INFO_QUESTION" ref={ref}>
      <Wrapper>
        <VerticalWrapper className="left-wrapper">
          <PortalTile />
          <Container className="large-p-container-small">
            <LargeProjectTile />
          </Container>
        </VerticalWrapper>
        <VerticalWrapper>
          <Row>
            <Container className="community-container">
              <CommunityTile />
            </Container>
            <Container className="large-p-container">
              <LargeProjectTile />
            </Container>
          </Row>
          <Container className="design-c-container">
            <DesignCenterTile />
          </Container>
          <Container className="large-p-container-xsmall">
            <LargeProjectTile />
          </Container>
        </VerticalWrapper>
      </Wrapper>
    </Bubble>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    flex-direction: column;
    align-items: center;
  }
`

const VerticalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.left-wrapper {
    max-width: 330px;
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    &.left-wrapper {
      max-width: 100%;
    }
  }
`

const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
`

const Container = styled.div`
  width: 100%;
  height: 100%;

  &.community-container,
  &.large-p-container {
    width: 50%;
  }

  &.large-p-container-small,
  &.large-p-container-xsmall {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.media.tabletLarge}) {
    &.large-p-container {
      display: none;
    }

    &.large-p-container-small {
      display: flex;
    }

    &.community-container {
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.xSmall}) {
    &.large-p-container-small {
      display: none;
    }

    &.large-p-container-xsmall {
      display: flex;
    }
  }
`
