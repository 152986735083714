import React from 'react'
import theme, { typography } from 'theme'
import styled from 'theme/styled'

interface DropzoneData extends Partial<Pick<HTMLButtonElement, 'disabled'>> {
  id: string
}

export const FileUpload: React.FC<DropzoneData> = ({ id, disabled }) => {
  return (
    <DropzoneWrapper disabled={disabled}>
      <div id={id} />
    </DropzoneWrapper>
  )
}

const DropzoneWrapper = styled.button`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;

  #sendsafely-dropzone {
    img {
      width: 24px;
      height: 24px;
    }
  }

  #sendsafely-attached-file-list {
    width: 440px;
    max-height: 48px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 8px !important;
    margin-bottom: 4px !important;
    overflow: scroll !important;
    overflow-x: hidden !important;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      display: none; /* WebKit (Chrome, Safari, Edge) */
    }

    @media (max-width: ${theme.media.mobileLarge}) {
      width: 380px;
    }

    img {
      display: none;
    }

    li {
      height: 24px !important;
      min-height: 24px !important;
      width: 100%;
      overflow: hidden;
      background: ${({ theme }) => theme.neutral01};
      color: ${({ theme }) => theme.text3} !important;
      padding: 4px !important;
      border-radius: 4px;

      * {
        color: ${({ theme }) => theme.text3} !important;
        font: ${typography['desktop-caption']} !important;
        line-height: 24px;
      }

      [data-upload-remove] {
        font-size: 14px !important;
      }

      div {
        &:first-of-type {
          display: inline-block !important;
          float: left;
          margin-top: 0 !important;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (max-width: ${theme.media.mobileLarge}) {
            max-width: 250px;
          }
        }
        &:last-of-type {
          display: none;
        }
      }
    }
  }
`
