import React from 'react'
import { UbiquitiLogo } from '@ubnt/icons'
import { FormattedMessage } from 'react-intl'
import { Link as ImportedLink } from 'react-router-dom'
import { space } from 'theme'
import styled from 'theme/styled'

const LogoWithSlogan = () => {
  return (
    <Link to="/">
      <StyledUbiquitiLogo height={108} width={108} variant="neutral" />
      <SloganText>
        <FormattedMessage id="COMMON_UI_SLOGAN" />
      </SloganText>
    </Link>
  )
}

export default LogoWithSlogan

const Link = styled(ImportedLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${space['desktop-spacing-base-12']};
`

// Waiting for UIC to update the logos. We can then remove this override.
const StyledUbiquitiLogo = styled(UbiquitiLogo)`
  > path {
    fill: ${({ theme }) => theme.ublue06};
  }
`

// this is a custom solution, not applying to global rules.
const SloganText = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  color: ${({ theme }) => theme.neutral08};
`
