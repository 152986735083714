import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StripeKeys } from 'types/types'

const { STRIPE_PUBLISHABLE_KEYS } = __CONFIG__

interface Props {
  children: React.ReactNode
  region?: string
}

export const StripeElements: React.FC<Props> = ({
  children,
  region = 'us',
}) => {
  const stripePromise = loadStripe(
    STRIPE_PUBLISHABLE_KEYS[region as StripeKeys]
  )

  return <Elements stripe={stripePromise}>{children}</Elements>
}
