import { useQuery } from '@tanstack/react-query'
import { MFAAuthenticatorStatus } from 'enums'
import { useSelector } from 'react-redux'
import { MfaApi } from 'api/sso/mfa'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'

const mfaApi = new MfaApi()

export const useMFAAuthenticators = () => {
  const isDoneAuthenticating = useSelector(selectIsAuthenticated)

  const mfaAuthenticators = useQuery({
    queryKey: [QueryKey.MFA_AUTHENTICATORS],
    queryFn: mfaApi.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const getActiveMfaAuthenticators = () => {
    return (
      mfaAuthenticators.data?.authenticators?.filter(
        (authenticator) =>
          authenticator.status === MFAAuthenticatorStatus.ACTIVE
      ) ?? []
    )
  }

  const getPendingMfaAuthenticators = () => {
    return (
      mfaAuthenticators.data?.authenticators?.filter(
        (authenticator) =>
          authenticator.status === MFAAuthenticatorStatus.PENDING
      ) ?? []
    )
  }

  const getDeactivatedMfaAuthenticators = () => {
    return (
      mfaAuthenticators.data?.authenticators?.filter(
        (authenticator) =>
          authenticator.status === MFAAuthenticatorStatus.DEACTIVATED
      ) ?? []
    )
  }

  return {
    getActiveMfaAuthenticators,
    getPendingMfaAuthenticators,
    getDeactivatedMfaAuthenticators,
    allMfaAuthenticators: mfaAuthenticators?.data?.authenticators ?? [],
    isMfaAuthenticatorsLoading: mfaAuthenticators.isLoading,
    isMfaAuthenticatorsRefetching: mfaAuthenticators.isFetching,
    mfaAuthenticatorsError: mfaAuthenticators.error,
    isMfaAuthenticatorsFetched: mfaAuthenticators.isFetched,
  }
}
