import React, { useEffect, useRef } from 'react'
import { Bubble } from 'components/support/Bubble'
import { QuestionTile } from 'components/support/QuestionTile'
import { TilesWrapper } from 'components/support/Wrappers'
import { userType } from './config/ConfigOptions'
import { BubbleProps, SupportConsole } from './types'
import { getNextSection } from './utils/getNextSection'

export const UserTypeBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.console])

  const handleClick = (value?: string | boolean | SupportConsole) => {
    const section = getNextSection(config, value, 'userType', values)
    handleChange('userType', value, section)
  }

  return (
    <Bubble question={config.userType.fieldTitle} ref={ref}>
      <TilesWrapper>
        {userType.map(({ id, label, icon }) => (
          <QuestionTile
            key={id}
            label={label}
            icon={icon}
            onClick={() => handleClick(id)}
            isActive={values.userType === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
