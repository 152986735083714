import React, {
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { api } from 'api'
import { useFormik } from 'formik'
import { difference } from 'lodash-es'
import { useQueryClient } from '@tanstack/react-query'
import { Bubble } from 'components/support/Bubble'
import { Footer } from 'components/support/Footer'
import { SendSafelyDropzone } from 'components/support/SendSafelyDropzone'
import { SupportFile } from 'components/support/SupportFile'
import { Form } from 'components/support/Wrappers'
import { Console } from 'features/support/unifi/config/types'
import { ChatContext } from 'pages/support/chat/ChatContext'
import {
  CorrectOperatingHours,
  getIsChatOpen,
  initialiseConnection,
} from 'pages/support/chat/utils'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { QueryKey } from 'store/types'
import { useZendeskUserQuery } from 'store/queries/useZendeskUser'
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  ORGANIZATION_ACCOUNT_TAG,
} from '../constants'
import { useSendSafely } from '../useSendSafely'
import { handleCreateRequestError } from '../utils'
import { getConfig } from './config/formConfig'
import { Assistance, TalkVip, UniFiStateValues } from './config/types'
import { UniFiFormValidationSchema } from './config/uniFiFormValidationSchema'
import { FormSections, FormValue, SupportFormState } from './types'
import {
  getChatDepartment,
  getTagsToAdd,
  getTagsToRemove,
} from './utils/chatUtils'
import { createOrUpdateZendeskUser } from './utils/createOrUpdateZendeskUser'
import { getSupportFile } from './utils/getSupportFile'
import { renderUniFiSection } from './utils/nextUniFiSection'
import { useFormatChatMessage } from './utils/useFormatChatMessage'
import { useFormatTicket } from './utils/useFormatTicket'

type Props = {
  dropzoneId: string
  setIsFormSubmitted: React.Dispatch<SetStateAction<boolean>>
  followUpTicketId?: string
}

export const sessionStorageChatKey = 'isInChat'

export const UniFiForm: React.FC<Props> = ({
  dropzoneId,
  setIsFormSubmitted,
  followUpTicketId,
}) => {
  const queryClient = useQueryClient()
  const { hasZendeskUser } = useZendeskUserQuery()
  const { selectValidUcoreDevices: consoles, areNcaDevicesLoading } =
    useNcaDevicesQuery()
  const { accountType } = useOrganizationsQuery()
  const {
    setInitialMessage,
    setSettings,
    setWidgetState,
    widgetState,
    messages,
    isChatOpen,
  } = useContext(ChatContext)

  const config = getConfig(consoles, areNcaDevicesLoading)

  const [visibleSections, setVisibleSections] = useState<FormSections[]>([
    UniFiStateValues.ASSISTANCE,
  ])

  const [isChatLoading, setIsChatLoading] = useState(false)
  const [isSupportFileAllowed, setIsSupportFileAllowed] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false)
  const [isFormReset, setIsFormReset] = useState(false)

  const { handleSendSafelyUpload, handleReinitialize } = useSendSafely(
    dropzoneId,
    setIsAttachmentUploading
  )
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const { formatChatMessage } = useFormatChatMessage(config)
  const { formatRequestedTicket } = useFormatTicket(config)

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    isSubmitting,
    resetForm,
  } = useFormik<SupportFormState>({
    initialValues: {},
    validationSchema: UniFiFormValidationSchema,
    onSubmit: async (values) => {
      let formattedRequest
      try {
        const attachmentsUrl = await handleSendSafelyUpload()
        formattedRequest = await formatRequestedTicket(
          values,
          isSupportFileAllowed,
          selectedConsole,
          attachmentsUrl,
          followUpTicketId,
          hasChatSupport
        )

        if (!formattedRequest) return

        await api.createZendeskRequest(formattedRequest)
        if (!hasZendeskUser) {
          queryClient.invalidateQueries({
            queryKey: [QueryKey.ZENDESK_USER],
          })
        }
        refetchOpenTickets()
        setIsFormSubmitted(true)
      } catch (error) {
        handleCreateRequestError(
          error,
          setErrorMessage,
          formattedRequest,
          setIsFormSubmitted
        )
      }
    },
  })

  const selectedConsole = useMemo(
    () =>
      consoles?.find(
        (console) =>
          console.shadow?.state?.reported?.mac === values.console?.mac
      ),
    [consoles, values.console?.mac]
  )

  useEffect(() => {
    if (widgetState.isEnded) {
      if (!hasZendeskUser) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.ZENDESK_USER],
        })
      }
      refetchOpenTickets()
      resetForm()
      setIsFormReset(true)
      handleReinitialize()
      setVisibleSections([UniFiStateValues.ASSISTANCE])
      setWidgetState((prev) => ({ ...prev, isEnded: false }))
    }
  }, [
    handleReinitialize,
    hasZendeskUser,
    queryClient,
    refetchOpenTickets,
    resetForm,
    setWidgetState,
    widgetState.isEnded,
  ])

  // This is needed because the BE listens for a webhook that takes a few seconds to complete.
  // So we refetch when the component unmounts to fetch latest state
  useEffect(() => {
    return () => {
      if (isFormReset) {
        refetchOpenTickets()
      }
    }
  }, [isFormReset, refetchOpenTickets])

  const clearState = (updatedSections: string[]) => {
    const existingStateValues = Object.keys(values)
    difference(existingStateValues, updatedSections).forEach((key) => {
      if (key === UniFiStateValues.SUPPORT_FILE) {
        return
      }
      return setFieldValue(key, undefined)
    })
  }

  const handleVisibilities = (
    clickedSection: FormSections,
    hasValue = true,
    nextSection?: FormSections
  ) => {
    if (
      visibleSections.includes(clickedSection) &&
      visibleSections[visibleSections.length - 1] !== clickedSection
    ) {
      const indexToRemove = visibleSections.indexOf(clickedSection)
      const updatedArray = visibleSections.slice(0, indexToRemove + 1)
      clearState(updatedArray)
      return hasValue && nextSection
        ? setVisibleSections([...updatedArray, nextSection])
        : setVisibleSections(updatedArray)
    }
    if (!nextSection) return
    return setVisibleSections((prev) => [...prev, nextSection])
  }

  const handleChange = (
    clickedSection: FormSections,
    value: FormValue,
    sectionToRender?: FormSections
  ) => {
    if (!widgetState.isInitialised) {
      initialiseConnection(setWidgetState)
    }

    setFieldValue(clickedSection, value)

    const hasValue = value !== undefined

    handleVisibilities(clickedSection, hasValue, sectionToRender)
  }

  const isFormValid =
    !isSubmitting &&
    !Object.keys(errors).length &&
    !!values.description &&
    values.description.length > DESCRIPTION_MIN_LENGTH &&
    values.description.length < DESCRIPTION_MAX_LENGTH

  const sendChatMessage = async () => {
    setIsChatLoading(true)
    setErrorMessage(undefined)
    try {
      const operatingHours =
        window.zChat.getOperatingHours() as CorrectOperatingHours
      const isOpen = getIsChatOpen(operatingHours)
      if (!isOpen) {
        return setErrorMessage('SUPPORT_CHAT_ERROR')
      }
      await createOrUpdateZendeskUser()
      const attachmentsUrl = await handleSendSafelyUpload()

      const message = formatChatMessage(
        values,
        visibleSections,
        isSupportFileAllowed,
        selectedConsole,
        attachmentsUrl
      )
      const dpt = getChatDepartment(
        values.tech,
        values.app,
        values.model,
        values.trouble,
        values.device,
        values.console?.id as Console
      )
      const tagsToRemove = getTagsToRemove()
      const tagsToAdd = getTagsToAdd(values)

      if (accountType === AccountType.ORG_OWNER) {
        tagsToAdd.push(ORGANIZATION_ACCOUNT_TAG)
      }

      setInitialMessage(message)
      setSettings({
        departmentId: parseInt(dpt.id),
        tagsToAdd,
        tagsToRemove,
        sandboxDepartmentId: parseInt(dpt.sandboxId),
      })
      sessionStorage.setItem(sessionStorageChatKey, 'true')
      setIsChatLoading(false)
    } catch (error) {
      setErrorMessage('SUPPORT_CHAT_ERROR')
    }
  }

  useEffect(() => {
    const getSupportFileUrl = async () => {
      const { downloadUrl } = await getSupportFile(selectedConsole)
      setFieldValue(UniFiStateValues.SUPPORT_FILE, downloadUrl)
    }

    if (!selectedConsole) {
      setFieldValue(UniFiStateValues.SUPPORT_FILE, undefined)
    } else {
      getSupportFileUrl()
    }
  }, [selectedConsole, setFieldValue])

  const hasChatSupport = useMemo(() => {
    if (values.talkVip === TalkVip.vip) {
      return false
    }
    return true
  }, [values.talkVip])

  return (
    <Form onSubmit={handleSubmit}>
      {visibleSections.map((section, i) =>
        renderUniFiSection(section, {
          config,
          values,
          handleChange,
          shouldScroll: i + 1 === visibleSections.length && i > 0,
        })
      )}
      <Bubble
        question={config.attachments.fieldTitle}
        isVisible={visibleSections.includes(UniFiStateValues.DESCRIPTION)}
        extraText
      >
        <SendSafelyDropzone id={dropzoneId} />
        {!!values.supportFile && (
          <SupportFile
            supportFileDevice={selectedConsole?.shadow.state.reported.name}
            isSupportFileAllowed={isSupportFileAllowed}
            setIsSupportFileAllowed={setIsSupportFileAllowed}
          />
        )}
      </Bubble>
      {values.assistance && values.assistance !== Assistance.featureConfig && (
        <Footer
          isFormLoading={isSubmitting}
          startChat={isChatOpen && hasChatSupport ? sendChatMessage : undefined}
          isFormValid={isFormValid}
          isChatLoading={isChatLoading}
          isInActiveChat={widgetState.isConnected && !!messages.length}
          errorMessage={errorMessage}
          hideButton={isChatOpen && hasChatSupport}
          isAttachmentUploading={isAttachmentUploading}
        />
      )}
    </Form>
  )
}
