import React, { useEffect, useRef } from 'react'
import Croppie from 'croppie'
import isMobile from 'is-mobile'
import { FormattedMessage } from 'react-intl'
import 'croppie/croppie.css'
import scrollIcon from 'assets/svgs/profilePictureScrollIcon.svg'
import slideIcon from 'assets/svgs/profilePictureSlideIcon.svg'
import styled from 'theme/styled'

interface Props {
  file: File
  handleCroppieInit?(croppie: Croppie): void
}

const ProfilePictureCrop: React.FC<Props> = ({ file, handleCroppieInit }) => {
  const ref = useRef(null)
  const isMobileDevice = isMobile()

  useEffect(() => {
    if (!ref.current) return
    const url = URL.createObjectURL(file)

    const croppie = new Croppie(ref.current!, {
      url,
      enableExif: true,
      showZoomer: false,
      viewport: {
        width: 300,
        height: 300,
        type: 'circle',
      },
      boundary: {
        width: '100%',
        height: 300,
      },
    } as any)

    handleCroppieInit && handleCroppieInit(croppie)
  }, [file, handleCroppieInit])

  return (
    <Container>
      <CroppieContainer ref={ref} />
      <InstructionsContainer isMobile={isMobileDevice}>
        <div>
          <img src={scrollIcon} alt="Scroll icon" />
          <span>
            <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_MODAL_SCROLL" />
          </span>
        </div>
        <div>
          <img src={slideIcon} alt="Slide icon" />
          <span>
            <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_MODAL_SLIDE" />
          </span>
        </div>
      </InstructionsContainer>
    </Container>
  )
}

export default ProfilePictureCrop

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const CroppieContainer = styled.div`
  height: 300px !important;

  .cr-viewport {
    box-shadow: 0 0 2000px 2000px rgba(255, 255, 255, 0.5) !important;
    border: none !important;
  }
`

const InstructionsContainer = styled.div<{ isMobile: boolean }>`
  margin-top: 20px;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    img {
      margin: 0 2px 0 5px;
    }
    span {
      margin: 0 5px 0 0;
    }
  }
`
