import React, { useContext, useEffect } from 'react'
import { ChatContext } from './ChatContext'
import { ChatWidget } from './ChatWidget'
import { useGetChatHandlers } from './useGetChatHandlers'

export const UniFiChat: React.FC = () => {
  const { messages, widgetState } = useContext(ChatContext)
  const { handleStatusUpdate, handleChatEvents } = useGetChatHandlers()

  useEffect(() => {
    window.zChat.on('connection_update', handleStatusUpdate)
    window.zChat.on('chat', handleChatEvents)

    return () => {
      window.zChat.un('connection_update', handleStatusUpdate)
      window.zChat.un('chat', handleChatEvents)
    }
  }, [
    handleChatEvents,
    handleStatusUpdate,
    widgetState.isConnected,
    widgetState.isInitialised,
  ])

  if (!widgetState.isConnected || !messages.length) {
    return null
  }

  return <ChatWidget />
}
