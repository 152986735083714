import React from 'react'
import { GridRow, ValueCell } from './Backups.styles'
import { MixedDeviceBackups } from './types'
import { getMacLabel, getDeviceTypeLabel } from './utils'

type BackupsGridRowProps = {
  device: MixedDeviceBackups
  selected: boolean
  onClick: (device: string) => void
}

export const BackupGridRow: React.FC<BackupsGridRowProps> = ({
  device,
  selected,
  onClick,
}) => {
  const { name, backups } = device

  const mac = getMacLabel(device)

  const deviceType = getDeviceTypeLabel(device)

  const backupsNumber =
    backups.length === 1
      ? `${backups.length} backup`
      : `${backups.length} backups`

  return (
    <GridRow
      onClick={() => {
        if (!backups.length) {
          return
        }
        onClick(device.deviceId)
      }}
      $selected={selected}
    >
      <ValueCell variant="body-primary">{name}</ValueCell>
      <ValueCell variant="body-primary" className="extraData">
        {mac}
      </ValueCell>
      <ValueCell variant="body-primary">{deviceType}</ValueCell>
      <ValueCell variant="body-primary">{backupsNumber}</ValueCell>
    </GridRow>
  )
}
