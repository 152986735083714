import React, { useMemo } from 'react'
import InfoIcon from '@ubnt/icons/InfoIcon'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button/'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Section, SectionHeader } from 'components/Section.styles'
import { TAX_ID_MODAL_ID } from 'features/payment-methods/ui/tax/TaxIdModal'
import { regionsWithTaxIdSupport } from 'features/stripe/config'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { setVisibleModal } from 'modules/modals'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { useBillingTaxIdQuery } from 'store/queries/useBillingTaxIdQuery'
import { withTooltip } from 'utils/withTooltip'
import {
  StyledInfoContainer,
  StyledInfoIcon,
  TextWrap,
  TaxTitleSection,
} from './Components.styles'
import { TaxId } from './TaxId'

export const Tax = () => {
  const dispatch = useDispatch()
  const { taxId, isTaxIdLoading } = useBillingTaxIdQuery()
  const { customerCards } = useBillingCardsQuery()
  const { billingCustomer } = useBillingCustomerQuery()

  const shouldTaxIdDisplay = useMemo(() => {
    const billingCustomerRegions = billingCustomer
      ? (Object.keys(billingCustomer) as StripeRegionCode[])
      : []

    return billingCustomerRegions.some((region) =>
      regionsWithTaxIdSupport.includes(region)
    )
  }, [billingCustomer])

  if (!shouldTaxIdDisplay || isTaxIdLoading) {
    return null
  }

  return (
    <Section $omitMarginTop>
      <TaxTitleSection>
        <TextWrap>
          <SectionHeader>
            <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS_TAX_ID" />
            <Tooltip
              position="topLeft"
              width={260}
              description={
                <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS_TAX_ID_TOOLTIP" />
              }
            >
              <StyledInfoIcon size={20} variant="outline" isActive />
            </Tooltip>
          </SectionHeader>
        </TextWrap>
        {taxId ? (
          <TaxId taxId={taxId} />
        ) : (
          <>
            {withTooltip(
              {
                description: (
                  <FormattedMessage id="SETTINGS_PAYMENTS_TAX_ADD_DISABLED_TOOLTIP" />
                ),
                position: 'topLeft',
                width: 260,
              },
              <Button
                variant="inline"
                size="medium"
                onClick={() => dispatch(setVisibleModal(TAX_ID_MODAL_ID))}
                disabled={!customerCards.length}
              >
                <FormattedMessage id={'SETTINGS_PAYMENTS_TAX_ID_ADD'} />
              </Button>,
              !customerCards.length
            )}
          </>
        )}
      </TaxTitleSection>
      {taxId && (
        <StyledInfoContainer>
          <InfoIcon variant="fill" isActive size={20} />
          <Text variant="body-primary" color="text-2">
            <FormattedMessage
              id="SETTINGS_PAYMENTS_TAX_ID_INFO"
              values={{
                a: (text) => <Link to="/supportform">{text}</Link>,
              }}
            />
          </Text>
        </StyledInfoContainer>
      )}
    </Section>
  )
}
