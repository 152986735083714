import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { MfaApi } from 'api/sso/mfa'
import { AuthError, checkAuth, selectErrors } from 'features/auth/modules/auth'
import {
  closeVisibleModal,
  selectVisibleModal,
  setVisibleModal,
} from 'modules/modals'
import { MFAType } from 'modules/types'
import { PASSKEY_FAILED_MODAL_ID } from 'pages/security/components/Passkey/FailedModal'
import { PASSKEY_REMOVE_MODAL_ID } from 'pages/security/components/Passkey/RemoveModal'
import { PASSKEY_PROMPT_PRIMARY_MODAL_ID } from 'pages/security/components/Passkey/PromptPrimaryModal'
import { useMfaToasts } from 'pages/MFAToasts'
import { QueryKey } from 'store/types'
import { parseSsoErrors } from 'store/utils'
import { SsoApiError } from 'types/ssoErrors'
import {
  CustomPublicKeyCredentialCreationOptions,
  TransformedAssertion,
  InitiateData,
  TransformedCredential,
  CustomPublicKeyCredentialRequestOptions,
} from 'types/mfa'
import {
  transformCredentialCreateOptions,
  transformNewAssertionForServer,
  transformCredentialRequestOptions,
  transformAssertionForServer,
} from './utils'

type SsoInitiatePasskeyResponse = {
  id: string
  publicKeyCredentialCreationOptions: CustomPublicKeyCredentialCreationOptions
  type: MFAType
}

const mfaApi = new MfaApi()

export const useMfaPasskey = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { visibleModal } = useSelector(selectVisibleModal)
  const error: AuthError = useSelector(selectErrors)
  const { createMfaSuccessToast } = useMfaToasts()

  const removePasskey = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    string
  >({
    mutationFn: (id) => mfaApi.remove({ type: MFAType.webAuthn, id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.MFA_AUTHENTICATORS],
      })
      const modalsToClose = [PASSKEY_REMOVE_MODAL_ID]
      if (visibleModal && modalsToClose.includes(visibleModal)) {
        dispatch(closeVisibleModal())
      }
    },
  })

  const verifyPasskey = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    { id: string; publicKeyCredential: TransformedAssertion }
  >({
    mutationFn: ({ id, publicKeyCredential }) =>
      mfaApi.verify({
        type: MFAType.webAuthn,
        id,
        data: { publicKeyCredential },
      }),
    onSuccess: () => {
      const data = queryClient.getQueryData<SsoInitiatePasskeyResponse>([
        QueryKey.MFA_INITIATED_PASSKEY,
      ])

      dispatch(
        setVisibleModal(PASSKEY_PROMPT_PRIMARY_MODAL_ID, { id: data?.id })
      )
      queryClient.invalidateQueries({
        queryKey: [QueryKey.MFA_AUTHENTICATORS],
      })
      createMfaSuccessToast(MFAType.webAuthn)
    },
    onError: () => {
      const data = queryClient.getQueryData<SsoInitiatePasskeyResponse>([
        QueryKey.MFA_INITIATED_PASSKEY,
      ])
      if (data) {
        removePasskey.mutate(data.id)
      }
      dispatch(setVisibleModal(PASSKEY_FAILED_MODAL_ID))
    },
  })

  const addPasskey = useMutation<
    SsoInitiatePasskeyResponse,
    AxiosError<SsoApiError>,
    InitiateData
  >({
    mutationFn: (data) => mfaApi.initiate({ type: MFAType.webAuthn, data }),
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })

      let { publicKeyCredentialCreationOptions, id } = data

      try {
        const transformedCredential = transformCredentialCreateOptions(
          publicKeyCredentialCreationOptions
        )
        const credential = (await navigator.credentials.create({
          publicKey: transformedCredential,
        })) as TransformedCredential<AuthenticatorAttestationResponse> | null

        if (!credential) {
          throw new Error('No credential created')
        }

        queryClient.setQueryData([QueryKey.MFA_INITIATED_PASSKEY], {
          id,
        })

        verifyPasskey.mutate({
          id,
          publicKeyCredential: transformNewAssertionForServer(credential),
        })
      } catch (error) {
        removePasskey.mutate(id)
        dispatch(setVisibleModal(PASSKEY_FAILED_MODAL_ID, { error }))
      }
    },
    onError: () => {
      dispatch(setVisibleModal(PASSKEY_FAILED_MODAL_ID))
    },
  })

  const updatePasskey = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    { id: string; name: string }
  >({
    mutationFn: ({ id, name }) =>
      mfaApi.update({ type: MFAType.webAuthn, id, data: { name } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      dispatch(closeVisibleModal())
    },
  })

  const loginPasskey = useMutation<AxiosResponse, AxiosError<SsoApiError>>({
    mutationFn: async () => {
      let requestOptions = error?.publicKeyCredentialRequestOptions

      if (!requestOptions) {
        const tmp: any = await mfaApi.webAuthnChallenge()

        requestOptions =
          tmp.publicKeyCredentialRequestOptions as CustomPublicKeyCredentialRequestOptions
      }

      const transformedRequestOptions = transformCredentialRequestOptions(
        requestOptions as CustomPublicKeyCredentialRequestOptions
      )

      const credentials = (await navigator.credentials.get({
        publicKey: transformedRequestOptions,
      })) as TransformedCredential<AuthenticatorAssertionResponse> | null

      if (!credentials) {
        throw new Error('No credential')
      }

      const transformedCredential = transformAssertionForServer(credentials)
      return mfaApi.webAuthnLogin(transformedCredential)
    },
    onSuccess: () => {
      dispatch(checkAuth())
    },
  })

  return {
    addPasskey: addPasskey.mutate,
    addPasskeyError: parseSsoErrors(addPasskey.error),

    removePasskey: removePasskey.mutate,
    removePasskeyError: parseSsoErrors(removePasskey.error),
    resetRemovePasskey: removePasskey.reset,

    updatePasskey: updatePasskey.mutate,
    updatePasskeyError: parseSsoErrors(updatePasskey.error),

    loginPasskey: loginPasskey.mutate,
    loginPasskeyError: parseSsoErrors(loginPasskey.error),
    resetLoginPasskey: loginPasskey.reset,
  }
}
