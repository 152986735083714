import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { BillingTaxIdApi } from 'api/billing/billingTaxId'
import { closeVisibleModal } from 'modules/modals'
import { useToasts } from 'pages/payments/components/useToasts'
import { QueryKey } from 'store/types'
import { getTaxIdMutationErrorMessage } from './utils'

export const useCreateTaxId = (
  source?: 'paymentMethod',
  api: BillingTaxIdApi = new BillingTaxIdApi()
) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { taxIdSuccessToast, taxIdErrorToast } = useToasts()

  const createTaxId = useMutation({
    mutationFn: api.create,
    onSuccess: () => {
      if (source !== 'paymentMethod') {
        dispatch(closeVisibleModal())
      }
      taxIdSuccessToast('added')
    },
    onError: (error) => {
      const errorMessage = getTaxIdMutationErrorMessage(error, intl)

      taxIdErrorToast(errorMessage)
    },
    onSettled: (_data, error) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CUSTOMER],
      })
      if (!error) {
        queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_TAX_ID] })
      }
    },
  })

  return {
    createTaxId: createTaxId.mutate,
    createTaxIdAsync: createTaxId.mutateAsync,
    isCreateTaxIdLoading: createTaxId.isPending,
    isCreateTaxIdError: createTaxId.isError,
  }
}
