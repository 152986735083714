import React, { useContext, useState } from 'react'
import { RadioList } from '@ubnt/ui-components/Radio'
import { useIntl } from 'react-intl'
import {
  MotifContext,
  parseThemeFromSystem,
  ThemeOption,
} from 'motif/MotifProvider'
import { GridWrapper, InputLabel } from './Profile.styles'

const {
  featureFlags: { isDarkModeEnabled },
} = __CONFIG__

export const ThemeSection = () => {
  const intl = useIntl()
  const { motif, setMotif } = useContext(MotifContext)

  const [selectedItem, setSelectedItem] = useState<ThemeOption>(
    (localStorage.getItem('theme') as ThemeOption) ?? motif
  )

  if (!isDarkModeEnabled) {
    return null
  }

  const handleChangeMotif = (value: 'light' | 'dark' | 'system') => {
    setSelectedItem(value)
    localStorage.setItem('theme', value)

    if (value === 'system') {
      return setMotif(parseThemeFromSystem())
    }

    return setMotif(value)
  }

  const items = [
    {
      id: 'light',
      children: intl.formatMessage({ id: 'THEME_OPTION_LIGHT' }),
      checked: selectedItem === 'light',
      onChange: () => handleChangeMotif('light'),
    },
    {
      id: 'dark',
      children: intl.formatMessage({ id: 'THEME_OPTION_DARK' }),
      checked: selectedItem === 'dark',
      onChange: () => handleChangeMotif('dark'),
    },
    {
      id: 'system',
      children: intl.formatMessage({ id: 'THEME_OPTION_SYSTEM' }),
      checked: selectedItem === 'system',
      onChange: () => handleChangeMotif('system'),
    },
  ]

  return (
    <GridWrapper>
      <InputLabel>
        {intl.formatMessage({ id: 'COMMON_LABEL_THEME' })}
      </InputLabel>
      <RadioList items={items} direction="row" />
    </GridWrapper>
  )
}
