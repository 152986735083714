import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { BrazilAutofillData, BrazilPostalCodeApi } from 'api/brazilPostalCode'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'

export const useBrazilPostalCodeQuery = (
  postalCode: string,
  api: BrazilPostalCodeApi = new BrazilPostalCodeApi()
) => {
  const addressInfo = useQuery({
    queryKey: [QueryKey.BRAZIL_ADDRESS_INFO, postalCode], // the addition of the postalCode here is to ensure a refetch on value change
    queryFn: () => api.get(postalCode),
    enabled: !!postalCode.length,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const parsedInfo: BrazilAutofillData = useMemo(
    () => ({
      city: addressInfo.data?.localidade,
      neighborhood: addressInfo.data?.bairro,
      state: addressInfo.data?.estado,
      state_abbr: addressInfo.data?.uf,
    }),
    [
      addressInfo.data?.bairro,
      addressInfo.data?.estado,
      addressInfo.data?.localidade,
      addressInfo.data?.uf,
    ]
  )

  return {
    addressInfo: parsedInfo,
    isAddressInfoFetching: addressInfo.isFetching,
    isAddressInfoFetched: addressInfo.isFetched,
  }
}
