import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { MfaApi } from 'api/sso/mfa'
import {
  closeVisibleModal,
  selectVisibleModal,
  setVisibleModal,
} from 'modules/modals'
import { MFAType } from 'modules/types'
import { EMAIL_VERIFY_CODE_MODAL_ID } from 'pages/security/components/Email'
import { useMfaToasts } from 'pages/MFAToasts'
import { QueryKey } from 'store/types'
import { parseSsoErrors } from 'store/utils'
import { SsoApiError } from 'types/ssoErrors'
import { InitiateData, VerifyData } from 'types/mfa'

type SsoInitiateEmailResponse = {
  id: string
  email: string
  type: MFAType
}

const mfaApi = new MfaApi()

export const useMfaEmail = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { createMfaSuccessToast } = useMfaToasts()
  const { visibleModal } = useSelector(selectVisibleModal)

  const addEmail = useMutation<
    SsoInitiateEmailResponse,
    AxiosError<SsoApiError>,
    InitiateData
  >({
    mutationFn: (data: InitiateData) =>
      mfaApi.initiate({ type: MFAType.email, data }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      queryClient.setQueryData([QueryKey.MFA_INITIATED_METHOD], {
        type: data.type,
        id: data.id,
        email: data.email,
      })
      dispatch(setVisibleModal(EMAIL_VERIFY_CODE_MODAL_ID))
    },
  })

  const sendEmail = useMutation<AxiosResponse, AxiosError<SsoApiError>, string>(
    {
      mutationFn: (id) => mfaApi.send({ type: MFAType.email, id }),
    }
  )

  const verifyEmail = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    { id: string; data: VerifyData }
  >({
    mutationFn: ({ id, data }) =>
      mfaApi.verify({ type: MFAType.email, id, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      if (visibleModal === EMAIL_VERIFY_CODE_MODAL_ID) {
        createMfaSuccessToast(MFAType.email)
      }
      dispatch(closeVisibleModal())
    },
  })

  const removeEmail = useMutation<
    AxiosResponse,
    AxiosError<SsoApiError>,
    string
  >({
    mutationFn: (id) => mfaApi.remove({ type: MFAType.email, id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      dispatch(closeVisibleModal())
    },
  })

  const addEmailData = queryClient.getQueryData<SsoInitiateEmailResponse>([
    QueryKey.MFA_INITIATED_METHOD,
  ])

  return {
    addEmail: addEmail.mutate,
    addEmailData,
    addEmailError: parseSsoErrors(addEmail.error),
    isAddEmailLoading: addEmail.isPending,
    resetAddEmail: addEmail.reset,
    isAddEmailSuccess: addEmail.isSuccess,

    sendEmail: sendEmail.mutate,
    sendEmailError: parseSsoErrors(sendEmail.error),
    isSendEmailLoading: sendEmail.isPending,
    resetSendEmail: sendEmail.reset,
    isSendEmailSuccess: sendEmail.isSuccess,

    verifyEmail: verifyEmail.mutate,
    verifyEmailError: parseSsoErrors(verifyEmail.error),
    isVerifyEmailLoading: verifyEmail.isPending,
    resetVerifyEmail: verifyEmail.reset,
    isVerifyEmailSuccess: verifyEmail.isSuccess,

    removeEmail: removeEmail.mutate,
    removeEmailError: parseSsoErrors(removeEmail.error),
    isRemoveEmailLoading: removeEmail.isPending,
    resetRemoveEmail: removeEmail.reset,
    isRemoveEmailSuccess: removeEmail.isSuccess,
  }
}
