import React, { createContext, useEffect, useState } from 'react'
import { isEqual, uniqWith } from 'lodash-es'
import { ChatMsgChatEventData } from 'zchat-browser'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { sessionStorageChatKey } from './constants'
import { ChatContextType } from './types'
import { initialiseConnection } from './utils'

export const ChatContext = createContext<ChatContextType>({
  widgetState: {
    isMinimised: false,
    isInitialised: false,
    isConnected: false,
    isEnded: false,
  },
  setWidgetState: () => {},
  messages: [],
  setMessages: () => {},
  sendUserMessage: () => {},
  extraInfo: { isAgentTyping: false },
  setExtraInfo: () => {},
  initialMessage: undefined,
  setInitialMessage: () => {},
  review: { rating: null, isOpen: false, isConfirm: false },
  setReview: () => {},
  settings: {
    departmentId: 0,
    tagsToAdd: [],
    tagsToRemove: [],
    sandboxDepartmentId: 0,
  },
  setSettings: () => {},
  endChat: () => {},
  isChatOpen: false,
  setIsChatOpen: () => {},
})

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { profileData } = useSsoProfileQuery()
  const [messages, setMessages] = useState<ChatContextType['messages']>([])
  const [widgetState, setWidgetState] = useState<
    ChatContextType['widgetState']
  >({
    isMinimised: false,
    isInitialised: false,
    isConnected: false,
    isEnded: false,
  })
  const [extraInfo, setExtraInfo] = useState<ChatContextType['extraInfo']>({
    isAgentTyping: false,
  })
  const [initialMessage, setInitialMessage] =
    useState<ChatContextType['initialMessage']>()
  const [review, setReview] = useState<ChatContextType['review']>({
    rating: null,
    isOpen: false,
    isConfirm: false,
  })
  const [settings, setSettings] = useState<ChatContextType['settings']>({
    departmentId: 0,
    tagsToAdd: [],
    tagsToRemove: [],
    sandboxDepartmentId: 0,
  })
  const [isChatOpen, setIsChatOpen] = useState<ChatContextType['isChatOpen']>()

  const sendUserMessage = (message: string) => {
    window.zChat.sendChatMsg(message)
    setMessages((prev) =>
      uniqWith(
        [
          ...prev,
          {
            type: 'chat.msg' as ChatMsgChatEventData['type'],
            nick: 'visitor',
            display_name: profileData?.username || '',
            timestamp: Date.now(),
            msg: message,
          },
        ],
        isEqual
      )
    )
  }

  const resetContext = () => {
    setMessages([])
    setWidgetState({
      isMinimised: false,
      isInitialised: true,
      isConnected: true,
      isEnded: true,
    })
    setExtraInfo({
      isAgentTyping: false,
    })
    setInitialMessage('')
    setReview({
      rating: null,
      isOpen: false,
      isConfirm: false,
    })
    setSettings({
      departmentId: 0,
      tagsToAdd: [],
      tagsToRemove: [],
      sandboxDepartmentId: 0,
    })
  }

  const endChat = () => {
    if (!review.isConfirm && !review.isOpen) {
      setReview((prev) => ({ ...prev, isConfirm: true }))
      return
    }
    if (!review.isOpen) {
      setReview((prev) => ({ ...prev, isOpen: true, isConfirm: false }))
      return
    }
    resetContext()
    window.zChat.endChat()
    sessionStorage.removeItem(sessionStorageChatKey)
  }

  useEffect(() => {
    const sessionStorageChatInfo = sessionStorage.getItem(sessionStorageChatKey)
    if (!widgetState.isConnected && sessionStorageChatInfo) {
      if (!widgetState.isInitialised) {
        initialiseConnection(setWidgetState)
      }
      window.zChat.on('connection_update', (state) => {
        if (state === 'connected') {
          const chatLog = window.zChat.getChatLog()

          const filteredMessages = chatLog.filter(
            ({ type }) => type === 'chat.msg' || type === 'chat.file'
          ) as ChatMsgChatEventData[]

          setMessages(uniqWith(filteredMessages, isEqual))
          setWidgetState((prev) => ({
            ...prev,
            isConnected: true,
          }))
        }
      })
    }
  }, [widgetState.isConnected, widgetState.isInitialised])

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        sendUserMessage,
        widgetState,
        setWidgetState,
        extraInfo,
        setExtraInfo,
        initialMessage,
        setInitialMessage,
        review,
        setReview,
        settings,
        setSettings,
        endChat,
        isChatOpen,
        setIsChatOpen,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
