import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { SubscriptionNameCell } from 'features/subscriptions/ui/cells/SubscriptionNameCell'
import { getProductIdFromInvoice } from 'features/subscriptions/utils'
import { useBillingOpenInvoicesQuery } from 'store/queries/useBillingOpenInvoicesQuery'

export const DeleteNotAllowedModalBody: React.FC = () => {
  const { fullSubscriptions } = useFullSubscriptions()
  const { openInvoices } = useBillingOpenInvoicesQuery()

  if (openInvoices.length) {
    return (
      <>
        <FormattedMessage id="SETTINGS_SECURITY_DELETE_NOT_ALLOWED_INVOICE" />
        <ul>
          {openInvoices.map((inv) => (
            <li>
              <SubscriptionNameCell
                productId={getProductIdFromInvoice(inv)}
                invoice={inv}
                overrideMediaQueries
              />
            </li>
          ))}
        </ul>
      </>
    )
  }

  const activeSubscriptions = fullSubscriptions?.filter(
    ({ isActive, cancelAtPeriodEnd }) => isActive && !cancelAtPeriodEnd
  )

  return (
    <>
      <FormattedMessage id="SETTINGS_SECURITY_DELETE_NOT_ALLOWED_SUBSCRIPTION" />
      <ul>
        {activeSubscriptions.map((sub) => (
          <li>{sub.name}</li>
        ))}
      </ul>
    </>
  )
}
