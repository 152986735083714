import React from 'react'
import OfficeIcon from '@ubnt/icons/OfficeIcon'
import { MY_ORG, MY_SITES } from 'sharedConstants'
import { AccountType } from 'store/queries/useOrganizationsQuery'
import styled from 'theme/styled'

export const hideNavigationItem = (
  accountView: string,
  accountType: AccountType,
  isOwnerAndAdmin: boolean,
  hiddenForAdmins?: boolean
) => {
  const shouldHideForUser =
    accountType === AccountType.ORG_ADMIN ||
    (isOwnerAndAdmin && accountView !== MY_ORG)

  return hiddenForAdmins && accountView !== MY_SITES && shouldHideForUser
}

export const getOrgImage = (logoUrl: string) => {
  return logoUrl ? (
    <StyledImage src={logoUrl} />
  ) : (
    <StyledOfficeIcon variant="fill" size={20} />
  )
}

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`

const StyledOfficeIcon = styled(OfficeIcon)`
  color: ${({ theme }) => theme.neutral08};
`
