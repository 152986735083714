import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { BillingProductsApi } from 'api/billing/billingProducts'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useBillingProductsQuery = (
  api: BillingProductsApi = new BillingProductsApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const billingProducts = useQuery({
    queryKey: [QueryKey.BILLING_PRODUCTS],
    queryFn: api.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const products = useMemo(() => {
    if (!billingProducts.data) return []
    const { data } = billingProducts
    const productRegions = Object.keys(data) as StripeRegionCode[]

    const userProducts = productRegions
      .map((region) => {
        return (
          data[region]?.data.map((product) => {
            return { ...product, region }
          }) ?? []
        )
      })
      .flat()

    return userProducts
  }, [billingProducts])

  const getProduct = useCallback(
    (id: string) => {
      return products.find((product) => product.id === id)
    },
    [products]
  )

  return {
    billingProducts: billingProducts.data,
    areBillingProductsLoading: billingProducts.isFetching,
    products,
    getProduct,
  }
}
