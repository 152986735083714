import React, { useEffect, useState } from 'react'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { Alert } from '@ubnt/ui-components'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button'
import { FormikProps, withFormik } from 'formik'
import { History } from 'history'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Description } from 'components/Description'
import authorizedRedirect from 'components/authorizedRedirect'
import {
  AuthError,
  loginMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import { useMfaPasskey } from 'store/mutations/mfa/useMfaPasskey'
import styled from 'theme/styled'
import { LoginMFAData, RootState } from 'types/types'
import { AuthForm, ErrorAlert } from '../styles'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  apiErrors: AuthError

  handleLogin: typeof loginMFA
  history: History
  handleResend: () => void
  children: React.ReactNode
}

type FormValues = LoginMFAData

const LoginMFAPasskey: React.FC<Props & FormikProps<FormValues>> = ({
  apiErrors,

  children,
  handleResend,
}) => {
  const intl = useIntl()
  const { loginPasskeyError } = useMfaPasskey()
  const [currentError, setCurrentError] = useState<undefined | string>()

  useEffect(() => {
    setCurrentError(apiErrors?.detail)
  }, [apiErrors])

  useEffect(() => {
    setCurrentError(
      loginPasskeyError?.detail
        ? intl.formatMessage({
            id: 'LOGIN_MFA_PASSKEY_ERROR',
          })
        : undefined
    )
  }, [intl, loginPasskeyError])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleResend()
  }

  return (
    <AuthForm id="login-mfa-form">
      <Text variant="heading-large">
        {intl.formatMessage({ id: 'LOGIN_MFA_PASSKEY_TITLE' })}
      </Text>
      <StyledDescription>
        {intl.formatMessage({ id: 'LOGIN_MFA_PASSKEY_DESCRIPTION' })}
        <ReInitiate>
          {intl.formatMessage(
            {
              id: 'SETTINGS_MFA_PASSKEY_RESEND_ACTION',
            },
            {
              link: (
                <Button variant="inline" onClick={handleClick}>
                  {intl.formatMessage({
                    id: 'COMMON_ACTION_TRY_AGAIN',
                  })}
                </Button>
              ),
            }
          )}
        </ReInitiate>
      </StyledDescription>
      <Alert
        type="info"
        text={intl.formatMessage({ id: 'LOGIN_MFA_PASSKEY_INFO' })}
      />
      {currentError ? (
        <ErrorAlert>
          <ExclamationMarkIcon size="original" />
          {currentError}
        </ErrorAlert>
      ) : (
        <></>
      )}
      {children}
    </AuthForm>
  )
}
const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleLogin, history } }) =>
    handleLogin(values, { history }),
  mapPropsToValues: () => ({
    token: '',
  }),
})
const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  isLoading: selectIsLoading(state),
})
const mapDispatchToProps = {
  handleLogin: loginMFA,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(enchance(LoginMFAPasskey))
)

const StyledDescription = styled(Description)`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const ReInitiate = styled.div`
  margin-top: 10px;
  button {
    outline: none;
  }
`
