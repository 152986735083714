import React, { useContext, useMemo } from 'react'
import ArrowLeftSecondaryIcon from '@ubnt/icons/ArrowLeftSecondaryIcon'
import ArrowRightSecondaryIcon from '@ubnt/icons/ArrowRightSecondaryIcon'
import Button from '@ubnt/ui-components/Button/Button'
import Dropdown from '@ubnt/ui-components/Dropdown/Dropdown'
import { FormattedMessage } from 'react-intl'
import { typography } from 'theme'
import styled from 'theme/styled'
import { PAGINATION_OPTIONS, RequestsContext } from './RequestsContext'

export const RequestsPagination = () => {
  const {
    setCurrentPage,
    currentPage,
    ticketsPerPage,
    setTicketsPerPage,
    totalTickets,
    areTicketsLoading,
    isSearchLoading,
  } = useContext(RequestsContext)
  const handlePageBack = () => setCurrentPage?.((prev) => prev - 1)
  const handlePageForward = () => setCurrentPage?.((prev) => prev + 1)

  const ticketStartCount = useMemo<number | string>((): number | string => {
    const firstTicketInPage = 1 + (currentPage - 1) * ticketsPerPage
    const currentPageTicketCount = currentPage * ticketsPerPage
    const lastTicketInPage =
      currentPageTicketCount > totalTickets
        ? totalTickets
        : currentPageTicketCount

    return ticketsPerPage > totalTickets ||
      firstTicketInPage === lastTicketInPage
      ? totalTickets
      : `${firstTicketInPage}-${lastTicketInPage}`
  }, [currentPage, ticketsPerPage, totalTickets])

  if (areTicketsLoading || isSearchLoading) return null

  return (
    <Pagination>
      <Wrapper>
        <ButtonContainer>
          <Button
            className="back"
            Icon={ArrowLeftSecondaryIcon}
            onClick={handlePageBack}
            disabled={currentPage <= 1 || ticketsPerPage > totalTickets}
            variant="tertiary"
          />
          <Button
            Icon={ArrowRightSecondaryIcon}
            onClick={handlePageForward}
            disabled={ticketsPerPage * currentPage >= totalTickets}
            variant="tertiary"
          />
        </ButtonContainer>
        <FormattedMessage
          id="SUPPORT_MY_REQUESTS_PAGINATION_SHOWING"
          values={{
            tickets: ticketStartCount,
            totalTickets: totalTickets,
          }}
        />
      </Wrapper>
      <PaginationSettings>
        <FormattedMessage id="SUPPORT_MY_REQUESTS_RESULTS_PER_PAGE" />
        <Dropdown
          value={ticketsPerPage}
          variant="secondary"
          onChange={({ value }) => setTicketsPerPage?.(value)}
          options={PAGINATION_OPTIONS}
          width="56px"
          inputProps={{ size: 'caption' }}
          portal
        />
      </PaginationSettings>
    </Pagination>
  )
}

const Pagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};
  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  gap: 1px;

  button {
    height: 24px;
    width: 24px;
    padding: 0;
    border: none;
    border-radius: 0 4px 4px 0;
    background-color: ${({ theme }) => theme.neutral02};

    svg {
      height: 14px;
      @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
        height: 20px;
        width: 20px;
      }
    }

    &.back {
      border-radius: 4px 0 0 4px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.neutral03};
    }

    @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
      height: 36px;
      width: 42px;
    }
  }
`

const PaginationSettings = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text3};

  * {
    font: ${typography['desktop-caption']};
  }

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
  }
`
