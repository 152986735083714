import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BackButton } from 'components/BackButton'
import {
  BLACKLISTED_IP_INFO_MODAL,
  WrappedBlacklistedIpInfoModal,
} from 'components/BlacklistedIpInfoModal'
import { Section } from 'components/Section.styles'
import { setVisibleModal } from 'modules/modals'
import { ProtectedPage } from 'pages/ProtectedPage'
import styled from 'theme/styled'
import { isIpBlacklisted } from 'utils/redirectIfIpIsBlacklisted'
import { RequestTicket } from './RequestTicket'

export const Request: React.FC = () => {
  const dispatch = useDispatch()
  const [isCountryBlacklisted, setIsCountryBlacklisted] = useState<
    boolean | null
  >(null)

  useEffect(() => {
    const redirectIfIpIsBlacklisted = async () => {
      const { isBlacklisted, country } = await isIpBlacklisted()
      if (isBlacklisted) {
        setIsCountryBlacklisted(true)
        dispatch(setVisibleModal(BLACKLISTED_IP_INFO_MODAL, { country }))
      }
    }
    redirectIfIpIsBlacklisted()
  }, [dispatch])

  if (isCountryBlacklisted) {
    return (
      <ProtectedPage>
        <WrappedBlacklistedIpInfoModal />
      </ProtectedPage>
    )
  }

  return (
    <ProtectedPage>
      <Container>
        <BackButton to="/requests" messageId="REQUEST_BACK_BUTTON" />
        <StyledSection $omitMarginTop>
          <RequestTicket />
        </StyledSection>
      </Container>
    </ProtectedPage>
  )
}

const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  // Make Request Container fit screen when SideNav disappear
  @media (max-width: 992px) {
    max-width: 100%;
  }
`

const StyledSection = styled(Section)`
  position: relative;
`
