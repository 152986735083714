import React, { useContext, useEffect, useMemo, useState } from 'react'
import { BasicToast, ToastContext } from '@ubnt/ui-components'
import { useFormik } from 'formik'
import { omit } from 'lodash-es'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { ValidationError } from 'components/Input'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import { StripeElements } from 'features/stripe/ui/StripeElements'
import { useUpdateCard } from 'store/mutations/billingCards/useUpdateCard'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { Wrapper } from './Components'
import { CardAddressForm } from './forms/cardAddress/CardAddressForm'
import { CardInfoForm } from './forms/CardInfoForm'
import {
  checkAddress,
  handleCaughtAddressError,
  getErrorMessage,
  getInitialValues,
  getValidationSchema,
  getUpdateCardData,
} from './utils'
import { CardType } from '../modules/types'

interface Props {
  card?: CardType
  cardRegion?: string
}

const EditPaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, card }) => {
  const {
    isUpdateCardLoading,
    updateCardError,
    resetUpdateCardErrors,
    updateCard,
  } = useUpdateCard()
  const [editError, setEditError] = useState<string | null>(null)
  const [expiryDateError, setExpiryDateError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<Record<
    string,
    string
  > | null>(null)
  const { isBillingCustomerLoading } = useBillingCustomerQuery()

  const { createNotification, removeNotification } = useContext(ToastContext)

  const cardData = card?.card

  const isLoading = isUpdateCardLoading || isBillingCustomerLoading

  const addressData = card?.billing_details?.address

  const selectedRegion = card && validStripeRegionMap.get(card.region)

  const initialValues = getInitialValues(
    card?.billing_details.name,
    addressData,
    cardData,
    card?.metadata
  )

  const {
    values,
    touched,
    setFieldTouched,
    isSubmitting,
    handleSubmit,
    handleBlur,
    setValues,
    handleChange,
    errors,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const addressValues = omit(values, 'exp_month', 'exp_year')

      try {
        await checkAddress(addressValues)
      } catch (error) {
        handleCaughtAddressError(error, intl, setAddressError)
        return
      }

      if (card?.id) {
        updateCard(getUpdateCardData(values, card))
      }
    },
    validationSchema: getValidationSchema('edit', initialValues.country),
  })

  useEffect(() => {
    setEditError(null)
  }, [values])

  useEffect(() => {
    const errorMessage = getErrorMessage(
      updateCardError,
      intl,
      selectedRegion?.name
    )
    setEditError(errorMessage)
  }, [selectedRegion?.name, intl, updateCardError])

  useEffect(() => {
    if (editError) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
          details={editError}
        />
      )
    }
  }, [editError, createNotification, removeNotification])

  const isButtonDisabled = useMemo(() => {
    const hasAddressError =
      !!addressError && !!Object.values(addressError).length
    const hasFormikError = !!Object.values(errors).length
    const hasAnyError = hasAddressError || hasFormikError || expiryDateError

    if (isLoading || isSubmitting || hasAnyError) {
      return true
    }
    return false
  }, [addressError, expiryDateError, errors, isSubmitting, isLoading])

  if (!card) return null

  const handleClose = () => {
    onClose?.()
    resetUpdateCardErrors()
  }

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_EDIT"
          tagName="div"
        />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: handleClose,
          variant: 'default',
          disabled: isLoading || isSubmitting,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_APPLY_CHANGES' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleSubmit as any,
          disabled: isButtonDisabled,
          loader: isOpen && (isLoading || isSubmitting) ? 'spinner' : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <CardInfoForm
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            cardRegion={card.region}
            cardData={cardData}
            editError={editError}
            setFieldValue={setFieldValue}
            expiryDateError={expiryDateError}
            setExpiryDateError={setExpiryDateError}
          />
          <CardAddressForm
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setValues={setValues as any}
            touched={touched}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isEditPaymentMethod={true}
            submitError={editError}
            addressError={addressError}
            setAddressError={setAddressError}
            cardRegion={card.region}
            setFieldValue={setFieldValue}
          />
        </Wrapper>
        {addressError?.['detail'] ? (
          <ValidationError>
            <FormattedMessage id="SETTINGS_PAYMENTS_ADDRESS_VALIDATION_FAILED" />
          </ValidationError>
        ) : null}
      </form>
    </GenericModal>
  )
}

const EditPaymentMethodModalConnected = injectIntl(EditPaymentMethodForm)

export const EDIT_PAYMENT_METHOD_MODAL_ID = 'EDIT_PAYMENT_METHOD_MODAL_ID'

const StripeWrappedModal: React.FC<Props & ModalProps> = (props) => {
  return (
    <StripeElements region={props.cardRegion}>
      <EditPaymentMethodModalConnected {...props} />
    </StripeElements>
  )
}

export default () => (
  <ModalWrapper modalId={EDIT_PAYMENT_METHOD_MODAL_ID}>
    <StripeWrappedModal />
  </ModalWrapper>
)
