import React from 'react'
import LogoAppleIcon from '@ubnt/icons/LogoAppleIcon'
import LogoLinuxIcon from '@ubnt/icons/LogoLinuxIcon'
import LogoWindows10Icon from '@ubnt/icons/LogoWindows10Icon'
import { media, typography } from 'theme'
import styled from 'theme/styled'

type Props = {
  icon?: JSX.Element
  imageSrc?: string
  label: string
  hasFooter?: boolean
  onClick: () => void
  isActive?: boolean
}

export const QuestionTile: React.FC<Props> = ({
  icon,
  imageSrc,
  label,
  hasFooter,
  onClick,
  isActive,
}) => {
  return (
    <Wrapper onClick={onClick} $isActive={isActive} type="button">
      {icon}
      {imageSrc && <Image src={imageSrc} />}
      <Label>{label}</Label>

      {hasFooter && (
        <Footer>
          <LogoWindows10Icon size="medium" />
          <LogoAppleIcon size="medium" />
          <LogoLinuxIcon size="medium" />
        </Footer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.button<{
  $isActive?: boolean
}>`
  align-items: center;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.ublue06 : theme.neutral02};
  border-radius: 8px;
  border: none;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.neutral00 : theme.text2};
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 90px;
  justify-content: center;
  padding: 12px;
  transition: background-color 0.2s linear;
  will-change: background-color;
  width: 100%;

  &:hover {
    background-color: ${({ theme, $isActive }) =>
      !$isActive ? theme.neutral03 : theme.ublue06};
    cursor: pointer;
  }

  @media (max-width: ${media.mobileLarge}) {
    height: 60px;
    gap: 8px;
    flex-direction: row;
  }
`

const Label = styled.span`
  font: ${typography['tile-label']};
  text-align: center;
`

const Footer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
`

const Image = styled.img`
  width: 60px;
  height: 60px;
`
