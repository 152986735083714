import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import { Section, SectionBody } from 'components/Section.styles'
import styled from 'theme/styled'
import { ColumnsContainer, RowsContainer } from './Card.styles'

const CardSkeleton: React.FC = () => {
  return (
    <Section $omitMarginBottom $omitMarginTop>
      <SkeletonLoader>
        <ColumnsContainer>
          <RowsContainer>
            <BodyBlock />
            <StyledSectionBody>
              <BodyBlock />
              <BodyBlock />
            </StyledSectionBody>
            <BodyBlock />
          </RowsContainer>
        </ColumnsContainer>
      </SkeletonLoader>
    </Section>
  )
}

const StyledSectionBody = styled(SectionBody)`
  max-width: 250px;
  margin-bottom: 16px;
`

const BodyBlock = styled(Block)`
  width: 150px;
  height: 20px;
`

export default CardSkeleton
