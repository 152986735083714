import { useMemo } from 'react'
import { HeaderTab, SearchData } from 'api/zendesk/types'
import { useCCdTicketsQuery } from 'store/queries/useCCdTicketsQuery'
import { useClosedTicketsQuery } from 'store/queries/useClosedTicketsQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'

export const useGetRequests = (
  searchData: SearchData,
  statusToShow?: HeaderTab
) => {
  const {
    openTickets,
    areOpenTicketsFetched,
    hasMoreOpenTickets,
    openTicketsError,
    totalOpenTickets,
    areOpenTicketsLoading,
  } = useOpenTicketsQuery()

  const {
    closedTickets,
    areClosedTicketsFetched,
    hasMoreClosedTickets,
    closedTicketsError,
    totalClosedTickets,
    areClosedTicketsLoading,
  } = useClosedTicketsQuery()

  const {
    ccdTickets,
    areCcdTicketsFetched,
    ccdTicketsError,
    totalCcdTickets,
    areCcdTicketsLoading,
  } = useCCdTicketsQuery()

  const allTickets = useMemo(() => {
    if (searchData.results.length > 0 || searchData.error) {
      return searchData.results
    }
    if (statusToShow === HeaderTab.OPEN) {
      return openTickets
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return closedTickets
    }
    return ccdTickets
  }, [
    statusToShow,
    ccdTickets,
    closedTickets,
    openTickets,
    searchData.error,
    searchData.results,
  ])

  const hasMore: boolean = useMemo(() => {
    if (statusToShow === HeaderTab.OPEN) {
      return hasMoreOpenTickets
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return hasMoreClosedTickets
    }
    return false
  }, [hasMoreClosedTickets, hasMoreOpenTickets, statusToShow])

  const totalTickets: number = useMemo(() => {
    if (searchData.results.length > 0 || searchData.error) {
      return searchData.results.length
    }
    if (statusToShow === HeaderTab.OPEN) {
      return totalOpenTickets
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return totalClosedTickets
    }
    return totalCcdTickets
  }, [
    searchData.results.length,
    searchData.error,
    totalClosedTickets,
    totalOpenTickets,
    totalCcdTickets,
    statusToShow,
  ])

  const ticketsError: boolean | null = useMemo(() => {
    if (statusToShow === HeaderTab.OPEN) {
      return !!openTicketsError
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return !!closedTicketsError
    }
    if (statusToShow === HeaderTab.CCD) {
      return !!ccdTicketsError
    }

    return null
  }, [ccdTicketsError, closedTicketsError, openTicketsError, statusToShow])

  const hasFetchedOnce: boolean = useMemo(() => {
    if (statusToShow === HeaderTab.OPEN) {
      return areOpenTicketsFetched
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return areClosedTicketsFetched
    }
    return areCcdTicketsFetched
  }, [
    areCcdTicketsFetched,
    areClosedTicketsFetched,
    areOpenTicketsFetched,
    statusToShow,
  ])

  const isFetching: boolean = useMemo(() => {
    if (statusToShow === HeaderTab.OPEN) {
      return areOpenTicketsLoading
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return areClosedTicketsLoading
    }
    return areCcdTicketsLoading
  }, [
    areCcdTicketsLoading,
    areClosedTicketsLoading,
    areOpenTicketsLoading,
    statusToShow,
  ])

  const initialStatusToShow = useMemo((): HeaderTab | undefined => {
    if (
      !areOpenTicketsFetched ||
      !areClosedTicketsFetched ||
      !areCcdTicketsFetched
    ) {
      return undefined
    }
    if (openTickets.length) {
      return HeaderTab.OPEN
    }
    if (closedTickets.length) {
      return HeaderTab.CLOSED
    }
    if (ccdTickets.length) {
      return HeaderTab.CCD
    }
    return undefined
  }, [
    areCcdTicketsFetched,
    areClosedTicketsFetched,
    areOpenTicketsFetched,
    ccdTickets.length,
    closedTickets.length,
    openTickets.length,
  ])

  return {
    allTickets,
    hasMore,
    ticketsError,
    totalTickets,
    hasFetchedOnce,
    isFetching,
    totalFetchedTickets: allTickets.length,
    initialStatusToShow,
  }
}
