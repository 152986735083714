import React, { useEffect } from 'react'
import { InputProps, Input as UbntInput } from '@ubnt/ui-components'
import styled from 'theme/styled'

export const Input: React.FC<
  {
    invalid?: boolean | string | string[]
    focus?: boolean
    focusAfterLoading?: boolean
    bottomMargin?: boolean
  } & InputProps
> = ({
  invalid,
  focus,
  focusAfterLoading,
  bottomMargin = true,
  ...restProps
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    try {
      if (
        ref.current?.matches(':autofill') ||
        ref.current?.matches(':-webkit-autofill')
      ) {
        ref.current?.click()
        ref.current?.focus()
      }
    } catch (e) {
      // Fail silently
    }
  }, [ref])

  useEffect(() => {
    if (focusAfterLoading === false) {
      ref.current?.focus()
    }
  }, [focusAfterLoading])

  useEffect(() => {
    if (focus === true) {
      ref.current?.focus()
    }
  }, [focus, focusAfterLoading])

  const invalidMessage = invalid
    ? Array.isArray(invalid) && invalid.length
      ? invalid[0]
      : invalid
    : undefined

  return (
    <Wrapper bottomMargin={bottomMargin}>
      <InputContainer
        invalid={invalidMessage}
        width={'auto'}
        inputClassName="innerInput"
        ref={ref}
        {...restProps}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  bottomMargin?: boolean
}>`
  margin-bottom: ${({ bottomMargin }) => (bottomMargin ? '22px' : '0px')};
`

const InputContainer = styled(UbntInput)`
  .innerInput {
    color: ${(p) => p.theme.text2};
  }
`

export const ValidationError = styled('span')`
  position: relative;
  font-size: 12px;
  color: ${(p) => p.theme.red06};
`
