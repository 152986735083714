import React, { useEffect, useState } from 'react'
import { useSessionsQuery } from 'store/queries/useSessionsQuery'
import { validStripeCountryMap } from './regions'
import {
  StripeCountryCode,
  StripeRegionCode,
  StripeRegionContextType,
} from './types'

export const StripeRegionContext = React.createContext<StripeRegionContextType>(
  {
    region: StripeRegionCode.US,
    setRegion: undefined,
    country: 'us',
    setCountry: undefined,
  }
)

const getRegion = (countryCode: string | null): StripeRegionCode | null =>
  validStripeCountryMap.get(countryCode as StripeCountryCode)?.region ?? null

const DEFAULT_REGION_CODE = StripeRegionCode.US

export const StripeRegionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentSessionLocation } = useSessionsQuery()

  const [country, setCountry] = useState<string | null>(currentSessionLocation)
  const [region, setRegion] = useState<StripeRegionCode>(
    getRegion(currentSessionLocation) || DEFAULT_REGION_CODE
  )

  useEffect(() => {
    setCountry(currentSessionLocation)
    setRegion(getRegion(currentSessionLocation) || DEFAULT_REGION_CODE)
  }, [currentSessionLocation])

  return (
    <StripeRegionContext.Provider
      value={{ region, setRegion, country, setCountry }}
    >
      {children}
    </StripeRegionContext.Provider>
  )
}
