import React, { useEffect, useMemo, useState } from 'react'
import { Dropdown, Input } from '@ubnt/ui-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { CountryFlag } from 'components/CountryFlag'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import {
  validStripeCountries,
  validStripeCountryMap,
  validStripeRegionMap,
} from 'features/stripe/ui/regions'
import { StripeCountryCode } from 'features/stripe/ui/types'
import { InputWrapper, Title } from '../../Components'
import { checkAddress, handleCaughtAddressError } from '../../utils'
import { FormProps } from '../types'
import { StatesDropdown } from './StatesDropdown'

export const GenericCardAddressForm: React.FC<FormProps> = (props) => {
  const {
    handleBlur,
    values,
    setValues,
    handleChange,
    touched,
    errors,
    submitError,
    addressError,
    setAddressError,
    cardRegion,
    country,
    setFieldValue,
    isEditPaymentMethod = false,
  } = props
  const [cardCountry, setCardCountry] = useState<string | null | undefined>(
    country
  )
  const [addressFocused, setAddressFocused] = useState(false)
  const intl = useIntl()

  const selectedRegion = cardRegion
    ? validStripeRegionMap.get(cardRegion)
    : undefined

  const selectedCountry = validStripeCountryMap.get(
    values.country.toLowerCase() as StripeCountryCode
  )

  const selectedCountryCode = isEditPaymentMethod
    ? selectedCountry?.code
    : cardCountry

  const checkAddressDebounced = useDebouncedCallback(async () => {
    try {
      await checkAddress(values)
      setAddressError(null)
    } catch (error) {
      handleCaughtAddressError(error, intl, setAddressError)
    }
  }, 200)

  useEffect(() => {
    if (addressFocused) {
      checkAddressDebounced()
    }
  }, [checkAddressDebounced, addressFocused, values])

  const hasRegionMultipleCountries = useMemo(() => {
    if (!selectedRegion?.code || (!cardCountry && !selectedCountry)) {
      return false
    }
    return regionsWithMultipleCountries.includes(selectedRegion?.code)
  }, [selectedRegion?.code, cardCountry, selectedCountry])

  return (
    <>
      <Title>
        <FormattedMessage id="SETTINGS_PAYMENTS_BILLING_ADDRESS" />
      </Title>
      {hasRegionMultipleCountries && selectedCountryCode && (
        <InputWrapper>
          <Dropdown
            label="Country"
            value={selectedCountryCode}
            onChange={(option) => {
              !isEditPaymentMethod && setCardCountry(option.value.toString())
              setValues({
                ...values,
                country: option.value.toUpperCase(),
              })
            }}
            options={validStripeCountries
              .filter((country) => country.region === selectedRegion?.code)
              .map((country) => ({
                label: country.name,
                value: country.code,
                image: <CountryFlag countryCode={country.code} />,
              }))}
            searchable
            variant="secondary"
            minHeight={214}
            width="100%"
            optionContainerClassName="change-region__dropdown"
            disabled={isEditPaymentMethod}
          />
        </InputWrapper>
      )}
      <InputWrapper>
        <Input
          name="line1"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STREET_ADDRESS" />}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          invalid={touched.line1 && (errors.line1 || addressError?.['line1'])}
          value={values.line1}
          autoComplete="new-line1"
          full
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="city"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_CITY" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={touched.city && (errors.city || addressError?.['city'])}
          value={values.city}
          autoComplete="new-city"
          full
        />
      </InputWrapper>
      <InputWrapper>
        {values.country === 'CA' ? (
          <StatesDropdown
            state={values.state}
            handleBlur={handleBlur}
            handleChange={setFieldValue}
            country="CA"
            invalid={touched.state && (errors.state || addressError?.['state'])}
          />
        ) : (
          <Input
            name="state"
            variant="secondary"
            label={<FormattedMessage id="COMMON_LABEL_STATE" />}
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={touched.state && (errors.state || addressError?.['state'])}
            value={values.state}
            autoComplete="new-state"
            onFocus={() => setAddressFocused(true)}
            full
          />
        )}
      </InputWrapper>
      <InputWrapper>
        <Input
          name="postal_code"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_POSTAL_CODE" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={
            (touched.postal_code &&
              (errors.postal_code || addressError?.['postal_code'])) ||
            !!submitError
          }
          value={values.postal_code}
          autoComplete="new-postal-code"
          onFocus={() => setAddressFocused(true)}
          full
        />
      </InputWrapper>
    </>
  )
}
