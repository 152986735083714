import { useBillingCardsQuery } from './queries/useBillingCardsQuery'
import { useBillingCustomerQuery } from './queries/useBillingCustomerQuery'
import { useBillingInvoicesQuery } from './queries/useBillingInvoicesQuery'
import { useBillingInvoiceYearsQuery } from './queries/useBillingInvoiceYearsQuery'
import { useBillingOpenInvoicesQuery } from './queries/useBillingOpenInvoicesQuery'
import { useBillingProductsQuery } from './queries/useBillingProductsQuery'
import { useBillingSubscriptionsQuery } from './queries/useBillingSubscriptionsQuery'
import { useBillingTaxIdQuery } from './queries/useBillingTaxIdQuery'
import { useBillingUiBusinessProfileQuery } from './queries/useBillingUiBusinessProfileQuery'
import { useBillingUiTaxIdQuery } from './queries/useBillingUiTaxIdQuery'
import { useBillingUpcomingInvoicesQuery } from './queries/useBillingUpcomingInvoicesQuery'
import { useMFAAuthenticators } from './queries/useMfaAuthenticators'
import { useNcaArchivedBackupsQuery } from './queries/useNcaArchivedBackupsQuery'
import { useNcaBackupsQuery } from './queries/useNcaBackupsQuery'
import { useNcaDevicesQuery } from './queries/useNcaDevicesQuery'
import { useOrganizationsQuery } from './queries/useOrganizationsQuery'
import { useShouldDisplayPasskeyBannerQuery } from './queries/useShouldDisplayPasskeyBannerQuery'
import { useSsoProfileQuery } from './queries/useSsoProfileQuery'
import { useTrustedDevicesQuery } from './queries/useTrustedDevicesQuery'
import { useUmrDeviceQuery } from './queries/useUmrDeviceQuery'
import { useUmrDevicesQuery } from './queries/useUmrDevicesQuery'
import { useZendeskUserQuery } from './queries/useZendeskUser'

export const usePrefetchQueries = () => {
  useBillingCustomerQuery()
  useBillingCardsQuery()
  useBillingTaxIdQuery()
  useUmrDevicesQuery()
  useBillingSubscriptionsQuery()
  useBillingProductsQuery()
  useBillingUpcomingInvoicesQuery()
  useBillingInvoicesQuery()
  useUmrDeviceQuery()
  useNcaDevicesQuery()
  useNcaBackupsQuery()
  useNcaArchivedBackupsQuery()
  useShouldDisplayPasskeyBannerQuery()
  useTrustedDevicesQuery()
  useOrganizationsQuery()
  useBillingInvoiceYearsQuery()
  useBillingOpenInvoicesQuery()
  useBillingUiBusinessProfileQuery()
  useBillingUiTaxIdQuery()
  useSsoProfileQuery()
  useZendeskUserQuery()
  useMFAAuthenticators()
}
