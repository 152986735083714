import React from 'react'
import { Input } from '@ubnt/ui-components/Input'
import { Radio } from '@ubnt/ui-components/Radio'
import { FormikErrors, FormikTouched } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useBillingTaxIdQuery } from 'store/queries/useBillingTaxIdQuery'
import { SectionWrapper, Title, RadioListWrapper } from '../Components'
import { AddCardValues, FormProps } from './types'

type TaxIdFormProps = Pick<
  FormProps,
  'handleBlur' | 'handleChange' | 'setFieldValue' | 'errors'
> & {
  values: AddCardValues
  touched: FormikTouched<AddCardValues>
  errors: FormikErrors<AddCardValues>
  taxIdError?: boolean
}

export const TaxIdForm: React.FC<TaxIdFormProps> = ({
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  errors,
  values,
  taxIdError,
}) => {
  const { isTaxIdFromBrazil, taxId } = useBillingTaxIdQuery()
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('taxIdType', e.target.id)
  }

  if (values.country !== 'BR') {
    return null
  }

  return (
    <SectionWrapper>
      <Title>
        <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS_TAX_ID" />
      </Title>
      <RadioListWrapper>
        <Radio
          id="br_cnpj"
          checked={
            isTaxIdFromBrazil
              ? taxId?.type === 'br_cnpj'
              : values.taxIdType === 'br_cnpj'
          }
          variant="boxed"
          onChange={handleRadioChange}
          disabled={isTaxIdFromBrazil}
        >
          CNPJ
        </Radio>
        <Radio
          id="br_cpf"
          checked={
            isTaxIdFromBrazil
              ? taxId?.type === 'br_cpf'
              : values.taxIdType === 'br_cpf'
          }
          variant="boxed"
          onChange={handleRadioChange}
          disabled={isTaxIdFromBrazil}
        >
          CPF
        </Radio>
      </RadioListWrapper>
      <Input
        name="taxIdValue"
        variant="secondary"
        label={<FormattedMessage id="COMMON_LABEL_ID" />}
        onBlur={handleBlur}
        onChange={handleChange}
        invalid={(touched.taxIdValue && errors.taxIdValue) || taxIdError}
        value={isTaxIdFromBrazil ? taxId?.value : values.taxIdValue}
        autoComplete="taxIdValue"
        disabled={isTaxIdFromBrazil}
      />
    </SectionWrapper>
  )
}
