import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { STATUS_API_SSO_ID, STATUS_IN_PROGRESS } from 'api/constants'
import { Components, StatusApi } from 'api/status'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useStatusQuery = (api: StatusApi = new StatusApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const status = useQuery({
    queryKey: [QueryKey.STATUS],
    queryFn: api.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const filterSSOComponents = (components: Components[]) =>
    components.find(({ id }) => id === STATUS_API_SSO_ID)

  const upcomingMaintenance = useMemo(() => {
    const upcoming = status.data?.upcoming

    const maintenance = upcoming?.scheduled_maintenances?.find(
      ({ components }) => filterSSOComponents(components)
    )

    // hardcoded test values
    // return {
    //   start: '2023-04-13T23:00:00.000-06:00',
    // }

    if (!maintenance) return null

    return {
      start: maintenance.scheduled_for,
      end: maintenance.scheduled_until,
    }
  }, [status.data?.upcoming])

  const activeMaintenance = useMemo(() => {
    const active = status.data?.active

    const maintenance = active?.scheduled_maintenances?.find(({ components }) =>
      filterSSOComponents(components)
    )

    // hardcoded test value
    // return true

    return maintenance?.status === STATUS_IN_PROGRESS
  }, [status.data?.active])

  const uiIncidents = useMemo(() => {
    const incidents = status.data?.uiIncidents?.incidents ?? []

    if (!incidents.length) {
      return null
    }

    return incidents[0].name
  }, [status.data?.uiIncidents?.incidents])

  return { upcomingMaintenance, activeMaintenance, uiIncidents }
}
