import React from 'react'
import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'
import { Text } from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'

export const AccordionLabel = () => {
  return (
    <LabelWrapper>
      <StyledExclamationMarkIcon size={20} variant="twoTone" isActive />
      <Text variant="heading-medium">
        <FormattedMessage id="SETTINGS_INVOICES_UNPAID_ACCORDION_LABEL" />
      </Text>
    </LabelWrapper>
  )
}

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.red06} !important;
  path: first-child {
    fill: ${({ theme }) => theme.red01} !important;
  }
`
