import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CloseWithCircleIcon } from '@ubnt/icons'
import { closeVisibleModal } from 'modules/modals'
import { useMfaPush } from 'store/mutations/mfa/useMfaPush'
import styled from 'theme/styled'
import { GenericMFAModal } from '../GenericMFAModal'

const PUSH_FAILED_MODAL_ID = 'PUSH_FAILED_MODAL_ID'

export const PushFailedModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { addPush } = useMfaPush()

  return (
    <GenericMFAModal
      modalId={PUSH_FAILED_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_PUSH_MODAL_TITLE' })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_TRY_AGAIN' }),
          variant: 'primary',
          onClick: () => addPush({}),
        },
      ]}
      overrideFullScreen
      size="auto"
    >
      <Wrapper>
        <StyledFailIcon />
        <Description>
          {intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE_PLEASE_TRY_AGAIN' })}
        </Description>
      </Wrapper>
    </GenericMFAModal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
  }
`

const Description = styled.div`
  margin: 10px 0 0 0;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text3};
`

const StyledFailIcon = styled(CloseWithCircleIcon)`
  color: ${({ theme }) => theme.red06};
`
