import React from 'react'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { FormattedMessage } from 'react-intl'
import { WarningIcon } from './Components.styles'

export const SubscriptionWarning: React.FC<{ message: string }> = ({
  message,
}) => {
  return (
    <Tooltip
      description={<FormattedMessage id={message} />}
      tooltipClassName="override-index"
      width={260}
    >
      <WarningIcon variant="fill" size={20} />
    </Tooltip>
  )
}
