import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SearchInput_Default } from '@ubnt/ui-components/aria/experimental'
import { Dropdown } from '@ubnt/ui-components/Dropdown'
import { ALL_KEY } from 'sharedConstants'
import { media } from 'theme'
import { useDebouncedCallback } from 'use-debounce'
import { EmptySearchResults } from 'components/EmptySearchResults'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { InvoiceItem, parseTableInvoice } from 'pages/subscriptions/hooks/utils'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import styled from 'theme/styled'
import { GridWrapper, Wrapper } from '../Invoices.styles'
import { createDropdownList, isSubscriptionNameIncluded } from './utils'
import { InvoicesTableHeader } from '../InvoicesTable/InvoicesTableHeader'
import { InvoicesTableRow } from '../InvoicesTable/InvoiceTableRow'

type Props = {
  selectedMonthInvoices?: InvoiceItem
}

export const MonthViewGrid: React.FC<Props> = ({ selectedMonthInvoices }) => {
  const { getProduct } = useBillingProductsQuery()
  const { selectOwnedUcoreDevices } = useNcaDevicesQuery()
  const { isMaxMobileXLarge } = useContext(MediaSizingContext)

  const [subscriptionsList, setSubscriptionsList] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [activeFilter, setActiveFilter] = useState<string>(ALL_KEY)

  useEffect(() => {
    if (!subscriptionsList.length) {
      const subs: string[] = []
      selectedMonthInvoices?.children.forEach((invoice) => {
        const { subscriptionName } = parseTableInvoice(
          invoice,
          getProduct,
          selectOwnedUcoreDevices
        )

        subs.indexOf(subscriptionName) === -1 && subs.push(subscriptionName)
      })
      setSubscriptionsList(subs)
    }
  }, [
    getProduct,
    selectedMonthInvoices,
    selectOwnedUcoreDevices,
    subscriptionsList.length,
  ])

  const invoicesToDisplay = useMemo(() => {
    if (!selectedMonthInvoices) {
      return []
    }

    const parsedInvoices = selectedMonthInvoices?.children.map((invoice) => {
      return {
        invoice,
        parsedData: parseTableInvoice(
          invoice,
          getProduct,
          selectOwnedUcoreDevices
        ),
      }
    })

    if (searchTerm && activeFilter) {
      return parsedInvoices?.filter(
        ({ parsedData: { name, subscriptionName } }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          isSubscriptionNameIncluded(subscriptionName, activeFilter)
      )
    }
    if (searchTerm) {
      return parsedInvoices?.filter(({ parsedData: { name } }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    if (activeFilter) {
      return parsedInvoices?.filter(({ parsedData: { subscriptionName } }) =>
        isSubscriptionNameIncluded(subscriptionName, activeFilter)
      )
    }

    return parsedInvoices
  }, [
    selectedMonthInvoices,
    searchTerm,
    activeFilter,
    getProduct,
    selectOwnedUcoreDevices,
  ])

  const handleDebouncedChange = useDebouncedCallback((value: string) => {
    if (!value) {
      return setSearchTerm(null)
    }

    return setSearchTerm(value.toString())
  }, 300)

  const renderContent = () => {
    if (!invoicesToDisplay?.length) {
      return <EmptySearchResults />
    }

    return invoicesToDisplay?.map(({ invoice, parsedData }) => (
      <InvoicesTableRow key={parsedData.id} {...parsedData} invoice={invoice} />
    ))
  }

  return (
    <Wrapper>
      <GridWrapper>
        <FilterWrapper>
          <SearchField
            aria-label="invoices_search-field"
            placeholder="Search"
            onChange={handleDebouncedChange}
            onClear={() => setSearchTerm(null)}
            height="medium"
          />
          <Dropdown
            variant="secondary"
            width={isMaxMobileXLarge ? '100%' : 200}
            options={createDropdownList(subscriptionsList)}
            onChange={(_options, value) => {
              setActiveFilter(value.toString())
            }}
            value={activeFilter}
          />
        </FilterWrapper>
        <InvoicesTableHeader />
        {renderContent()}
      </GridWrapper>
    </Wrapper>
  )
}

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${media.mobileXLarge}) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`

const SearchField = styled(SearchInput_Default)`
  max-width: 200px;
  margin-bottom: 16px;

  @media (max-width: ${media.mobileXLarge}) {
    max-width: 100%;
  }
`
