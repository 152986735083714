import { useMemo } from 'react'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { NcaBackupsApi } from 'api/nca/ncaBackups'
import { DeviceBackups, DeviceBackupsArchived } from 'api/nca/types'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from '../types'

export const useNcaArchivedBackupsQuery = (
  api: NcaBackupsApi = new NcaBackupsApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const ncaArchivedBackups = useQuery({
    queryKey: [QueryKey.NCA_ARCHIVED_BACKUPS],
    queryFn: api.getAllArchived,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const parsedNcaArchivedBackups = useMemo(() => {
    if (!ncaArchivedBackups.data) {
      return []
    }
    return ncaArchivedBackups.data.reduce(
      (accumulator: DeviceBackupsArchived[], currentValue: DeviceBackups) => {
        const existingObject = accumulator.find(
          (obj) => obj.hardwareId === currentValue.hardwareId
        )
        /*
      BE send duplicate deleted devices every time you adapt the device and delete it again
      but at least they all have the same hardwareId so we match them by that and we flat them to one object
      with adding the different backups to that object, so user don't see the the device twice but rather
      see one device with all of the backups that belongs to that device
      */
        if (existingObject) {
          existingObject.backups = [
            ...existingObject.backups,
            ...currentValue.backups,
          ]
        } else {
          accumulator.push({ ...currentValue, archived: true })
        }

        return accumulator
      },
      []
    )
  }, [ncaArchivedBackups.data])

  return {
    ncaArchivedBackups: ncaArchivedBackups.data,
    areNcaArchivedBackupsLoading: ncaArchivedBackups.isLoading,
    areNcaArchivedBackupsRefetching: ncaArchivedBackups.isFetching,
    ncaArchivedBackupsError: ncaArchivedBackups.error,
    parsedNcaArchivedBackups,
  }
}
