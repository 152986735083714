import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectVisibleModal } from 'modules/modals'
import { MFAType } from 'modules/types'
import { useMfaTotp } from 'store/mutations/mfa/useMfaTotp'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { getErrorMessage } from 'utils/mfa'
import { FormValues, GenericVerifyTokenModal } from '../GenericVerifyTokenModal'

export const TOTP_VERIFY_CODE_MODAL_ID = 'TOTP_VERIFY_CODE_MODAL_ID'

export const TOTPVerifyCodeModal: React.FC = () => {
  const intl = useIntl()
  const {
    verifyTotp,
    verifyTotpError,
    isVerifyTotpLoading,
    resetVerifyTotp,
    addTotpData,
  } = useMfaTotp()
  const { getPendingMfaAuthenticators, isMfaAuthenticatorsLoading } =
    useMFAAuthenticators()

  const visibleModal = useSelector(selectVisibleModal)

  const [apiError, setApiError] = useState<string>('')

  useEffect(() => {
    setApiError(
      getErrorMessage({
        apiError: verifyTotpError,
        defaultMessage: intl.formatMessage({
          id: 'GENERIC_ERROR_BOUNDARY_TITLE',
        }),
        fields: ['detail'],
      }) || ''
    )
  }, [verifyTotpError, intl])

  useEffect(() => setApiError(''), [visibleModal])

  const handleOnSubmit = useCallback(
    ({ token }: FormValues) => {
      if (!token) {
        return
      }

      const id =
        addTotpData?.id ||
        getPendingMfaAuthenticators()?.find(
          (authenticator) => authenticator.type === MFAType.totp
        )?.id

      if (!id) {
        return
      }

      verifyTotp({ id, data: { oneTimePassword: token } })
    },
    [verifyTotp, addTotpData, getPendingMfaAuthenticators]
  )

  return (
    <GenericVerifyTokenModal
      modalId={TOTP_VERIFY_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_TOTP_MODAL_TITLE' })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_VERIFY_CODE_DESCRIPTION',
      })}
      handleOnSubmit={handleOnSubmit}
      isLoading={isVerifyTotpLoading || isMfaAuthenticatorsLoading}
      errorMessage={apiError}
      onAfterClose={() => {
        resetVerifyTotp()
      }}
    />
  )
}
