import React, { useEffect, useState } from 'react'
import UbiquitiLogo from '@ubnt/icons/UbiquitiLogo'
import { ProgressIndicator_Spinner, Text } from '@ubnt/ui-components/aria'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import {
  get3DSDataFromQuery,
  getSubscriptionFromId,
  initiate3DSFlow,
} from './utils'

const redirectUserBack = (url: string) => {
  return setTimeout(() => {
    window.location.href = url
  }, 2000)
}

export const Validate3DS = () => {
  const [error, setError] = useState<string | null>(null)
  const { subscriptionId, url, region } = get3DSDataFromQuery()

  useEffect(() => {
    if ((!subscriptionId || !region || !url) && !error) {
      setError('GENERIC_3DS_ERROR')
    }
  }, [error, region, subscriptionId, url])

  useEffect(() => {
    const initiate3DS = async () => {
      try {
        if (!subscriptionId || !region || !url) {
          return
        }
        const subscription = await getSubscriptionFromId(subscriptionId, region)

        const subscriptionPaymentIntent =
          subscription[region].latest_invoice?.payment_intent

        if (subscriptionPaymentIntent?.status === 'requires_action') {
          const clientSecret = subscriptionPaymentIntent?.client_secret
          const paymentMethod =
            subscriptionPaymentIntent?.payment_method ??
            subscriptionPaymentIntent?.source

          if (!clientSecret || !paymentMethod) {
            return setError('GENERIC_3DS_ERROR')
          }

          await initiate3DSFlow(region, url, clientSecret, paymentMethod)
          return
        }
        if (subscriptionPaymentIntent?.status === 'succeeded') {
          window.location.href = url
          return
        }
        setError('GENERIC_3DS_ERROR')
      } catch (e) {
        return setError('GENERIC_3DS_ERROR')
      }
    }

    initiate3DS()
  }, [region, subscriptionId, url])

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined = undefined
    if (url && error) {
      timerId = redirectUserBack(url)
    }

    return () => timerId && clearTimeout(timerId)
  }, [error, url])

  return (
    <Container>
      {error ? (
        <>
          <UbiquitiLogo variant="flat" size={72} />
          <Text color="text-1" variant="heading-large">
            <FormattedMessage id={error} />
          </Text>
          <Text color="text-1" variant="body-secondary">
            <FormattedMessage id="3DS_REDIRECT_ERROR_MESSAGE" />
          </Text>
          <ProgressIndicator_Spinner
            size={28}
            thickness={2}
            variant="loading"
          />
        </>
      ) : (
        <>
          <UbiquitiLogo variant="aqua" size={72} />
          <Text color="text-1" variant="heading-large">
            <FormattedMessage id="3DS_REDIRECT_LOADING" />
          </Text>
          <ProgressIndicator_Spinner
            size={28}
            thickness={2}
            variant="loading"
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 8px;
`
