import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LockerIcon } from '@ubnt/icons'
import { Button, ToastContext } from '@ubnt/ui-components'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import AppViewHeader from 'components/AppViewHeader'
import StyledEntityToast from 'components/EntityToast'
import { FooterButtons } from 'components/FooterButtons'
import { InputGroup, InputGroups } from 'components/form'
import { Section, SectionHeader } from 'components/Section.styles'
import {
  extendAssuranceLevel,
  getAssuranceLevel,
  selectAssuranceLevel,
} from 'features/auth/modules/assuranceLevel'
import { checkAuth } from 'features/auth/modules/auth'
import { WrappedConfirmDeleteAccountModal } from 'features/delete-account/ui/ConfirmDeleteAccountModal'
import { WrappedDeleteAccountModal } from 'features/delete-account/ui/DeleteAccountModal'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { InputLabel } from 'pages/profile/Profile.styles'
import { ProtectedPage } from 'pages/ProtectedPage'
import {
  CHANGE_EMAIL_MODAL_ID,
  WrappedChangeEmailModal,
} from 'pages/security/ChangeEmailModal'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { LastMethodPushLocalStorage } from 'types/types'
import parseLocalStorage from 'utils/localStorage'
import { useAddMfaQuickFlow } from 'utils/useAddMfaQuickFlow'
import AssuranceLevelTimer from './AssuranceLevelTimer'
import {
  CHANGE_PASSWORD_MODAL_ID,
  WrappedChangePasswordModal,
} from './ChangePasswordModal'
import DeleteAccountButton from './DeleteAccountButton'
import LockHeader from './LockHeader'
import { LockTooltip } from './LockTooltip'
import { MFASection } from './MFASection'
import { SessionSection } from './SessionSection'
import { SUBMIT_PASSWORD_MODAL_ID } from './SubmitPasswordModal'
import TooManyRequestsModal, {
  TOO_MANY_REQUESTS_CODE_MODAL_ID,
} from './TooManyRequestsModal'
import VerifyEmailModal from './VerifyEmailModal'
import { WebSessionTimeout } from './WebSessionTimeout'

const Security = () => {
  const { createNotification, removeNotification } = useContext(ToastContext)
  const intl = useIntl()
  const dispatch = useDispatch()
  const { profileData, isProfileDataFetching } = useSsoProfileQuery()
  const { updateProfile } = useUpdateProfile()
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const [level, setLevel] = useState<number>(assuranceLevel?.data.level)
  const [sessionLength, setSessionLength] = useState<undefined | number>(
    profileData?.session_ttl_seconds
  )

  useEffect(() => {
    dispatch(getAssuranceLevel())
  }, [dispatch])

  const handleChangePassword = useCallback(
    () => dispatch(setVisibleModal(CHANGE_PASSWORD_MODAL_ID)),
    [dispatch]
  )

  const handleChangeEmail = useCallback(() => {
    const currentTimeInSeconds = Date.now() / 1000
    const emailChangeBan = parseLocalStorage<number>(
      `emailChangeBan-${profileData?.username}`
    )
    const isEmailChangeBanned =
      emailChangeBan && currentTimeInSeconds - emailChangeBan < 600

    if (isEmailChangeBanned) {
      return dispatch(setVisibleModal(TOO_MANY_REQUESTS_CODE_MODAL_ID))
    }
    localStorage.removeItem(`emailChangeBan-${profileData?.username}`)
    return dispatch(setVisibleModal(CHANGE_EMAIL_MODAL_ID))
  }, [dispatch, profileData?.username])

  useEffect(() => {
    const deactivatedData =
      parseLocalStorage<LastMethodPushLocalStorage>('lastMethodPush')
    deactivatedData?.deactivated && dispatch(checkAuth())
  }, [dispatch])

  useEffect(() => {
    const newLevel = assuranceLevel?.data.level

    if (!assuranceLevel?.isLoading && level !== newLevel) {
      setLevel(newLevel)
      if (newLevel === 1) {
        dispatch(closeVisibleModal())
        createNotification(
          <StyledEntityToast
            icon={<LockerIcon />}
            stateIndicator="warning"
            title={intl.formatMessage({ id: 'SETTINGS_LOCK_TOAST_HEADER' })}
            duration={5000}
            details={intl.formatMessage({
              id: 'SETTINGS_LOCK_TOAST_DESCRIPTION',
            })}
            primaryButton={{
              label: intl.formatMessage({ id: 'SETTINGS_LOCK_TOAST_BUTTON' }),
              onClick: () => {
                dispatch(setVisibleModal(SUBMIT_PASSWORD_MODAL_ID))
              },
            }}
            onClose={(_e, id) => {
              if (id) {
                removeNotification(id)
              }
            }}
          />
        )
      }
    }
  }, [
    assuranceLevel,
    createNotification,
    dispatch,
    intl,
    level,
    removeNotification,
  ])

  useAddMfaQuickFlow()

  const shouldDisplayFooter =
    !!sessionLength && sessionLength !== profileData?.session_ttl_seconds

  const handleUpdateSessionLength = () => {
    updateProfile({ session_ttl_seconds: sessionLength })
  }

  return (
    <ProtectedPage>
      <AppViewHeader headerId="COMMON_SIDE_NAV_SECURITY" />
      <LockHeader />
      <LockPage $isLocked={assuranceLevel.data.level !== 2}>
        <MFASection />
        <SessionSection />
        <Section $marginBottomDesktop={'100px'}>
          <SectionHeader>
            <FormattedMessage id={'COMMON_LABEL_ACCOUNT_SETTINGS'} />
          </SectionHeader>

          <InputGroups>
            <InputGroup full>
              <OuterWrapper>
                <InputLabel>
                  <FormattedMessage id="COMMON_LABEL_EMAIL" />
                </InputLabel>
                <InnerWrapper>
                  {profileData?.email && <Text>{profileData.email}</Text>}
                  <LockTooltip inline>
                    <ChangeButton
                      size="medium"
                      variant="inline"
                      onClick={() => {
                        dispatch(extendAssuranceLevel())
                        handleChangeEmail()
                      }}
                    >
                      <FormattedMessage id="COMMON_LABEL_CHANGE_EMAIL" />
                    </ChangeButton>
                  </LockTooltip>
                </InnerWrapper>
              </OuterWrapper>
            </InputGroup>
          </InputGroups>
          <InputGroups>
            <InputGroup full>
              <OuterWrapper>
                <InputLabel>
                  <FormattedMessage id="COMMON_LABEL_PASSWORD" />
                </InputLabel>
                <InnerWrapper>
                  <Text>••••••••••</Text>
                  <LockTooltip inline>
                    <ChangeButton
                      size="medium"
                      variant="inline"
                      onClick={() => {
                        dispatch(extendAssuranceLevel())
                        handleChangePassword()
                      }}
                    >
                      <FormattedMessage id="COMMON_LABEL_CHANGE_PASSWORD" />
                    </ChangeButton>
                  </LockTooltip>
                </InnerWrapper>
              </OuterWrapper>
            </InputGroup>
          </InputGroups>

          <InputGroups>
            <InputGroup full>
              <LockTooltip inline>
                <WebSessionTimeout
                  sessionLength={sessionLength}
                  setSessionLength={setSessionLength}
                />
              </LockTooltip>
            </InputGroup>
          </InputGroups>

          <InputGroups>
            <InputGroup full>
              <OuterWrapper $marginTop={16} $isLastField>
                <TitleWrapper>
                  <InputLabel>
                    <FormattedMessage id="SETTINGS_SECURITY_DELETE_BUTTON" />
                  </InputLabel>
                </TitleWrapper>
                <InnerWrapper>
                  <LockTooltip inline={true}>
                    <DeleteAccountButton />
                  </LockTooltip>
                </InnerWrapper>
              </OuterWrapper>
            </InputGroup>
          </InputGroups>
        </Section>
        <FooterButtons
          isActive={shouldDisplayFooter}
          isLoading={isProfileDataFetching}
          onSubmit={handleUpdateSessionLength}
          onCancel={() => setSessionLength(profileData?.session_ttl_seconds)}
        />
      </LockPage>
      <AssuranceLevelTimer isLocked={assuranceLevel.data.level !== 2} />
      <WrappedChangePasswordModal />
      <WrappedDeleteAccountModal />
      <WrappedConfirmDeleteAccountModal />
      <WrappedChangeEmailModal />
      <VerifyEmailModal />
      <TooManyRequestsModal />
    </ProtectedPage>
  )
}

export default Security

const ChangeButton = styled(Button)`
  letter-spacing: 0;
  span {
    justify-content: left;
  }
`
const LockPage = styled.div<{ $isLocked: boolean }>`
  position: relative;
  ${({ $isLocked }) =>
    $isLocked &&
    `
    opacity: 0.4;
    user-select: none;
    pointer-events: none;
  `}
`

const OuterWrapper = styled.div<{
  $marginTop?: number
  $isLastField?: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ $isLastField }) => ($isLastField ? 0 : '16px')};
  margin-top: ${({ $marginTop }) => $marginTop}px;
  max-width: 650px;
  @media (max-width: ${({ theme }) => theme.media.small}) {
    margin-right: 0px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 360px;
  @media (max-width: ${({ theme }) => theme.media.small}) {
    align-items: right;
    justify-content: flex-end;
    width: auto;
  }
  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-right: 20px;
`

const Text = styled.div`
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.text2};
  margin-right: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 350px;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    max-width: 200px;
  }
`
