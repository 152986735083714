import React, { useCallback, useContext } from 'react'
import EntityToast from '@ubnt/ui-components/Toast/EntityToast'
import { ToastContext } from '@ubnt/ui-components/Toast/ToastContext'
import { FormattedMessage } from 'react-intl'

export const useToasts = () => {
  const toast = useContext(ToastContext)

  const handleCloseToast = useCallback(
    (_e: React.MouseEvent, id?: string) => {
      if (id) {
        toast.removeNotification(id)
      }
    },
    [toast]
  )

  const emailUpdatedSuccessToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon="success"
        title={<FormattedMessage id="SETTINGS_SECURITY_EMAIL_UPDATED" />}
        onClose={handleCloseToast}
      />
    )
  }, [handleCloseToast, toast])

  return {
    emailUpdatedSuccessToast,
  }
}
