import { useContext } from 'react'
import { omit, reduce } from 'lodash-es'
import { UcoreDevice } from 'api/nca/types'
import { ZDCustomFields } from 'features/support/types'
import { CustomFields, SupportFormState } from 'features/support/unifi/types'
import { getTags } from 'features/support/utils'
import { ChatContext } from 'pages/support/chat/ChatContext'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { createSubject } from './createSubject'
import { getConsoleValues } from './getConsoleValues'
import { getTotalNumberOfDevices } from './getTotalNumberOfDevices'
import { parseQueryValues } from './parseQueryData'
import { getRequester } from './requestUtils'
import { FIELD_FALLBACK_VALUE_NA } from '../../constants'
import { FormConfig, UniFiStateValues } from '../config/types'

const { ZENDESK_UNIFI_FORM_KEY } = __CONFIG__

export const useFormatTicket = (config: FormConfig) => {
  const { profileData } = useSsoProfileQuery()

  const { selectValidUcoreDevices: consoles } = useNcaDevicesQuery()
  const { accountType } = useOrganizationsQuery()
  const { isChatOpen } = useContext(ChatContext)

  const { customTags, sentryUserId, sentryEventId } = parseQueryValues(
    window.location.href
  )

  const formatRequestedTicket = async (
    values: SupportFormState,
    isSupportFileAllowed: boolean,
    selectedConsole?: UcoreDevice,
    sendsafelyAttachmentUrl?: string,
    followup_source_id?: string,
    hasChatSupport?: boolean
  ) => {
    if (!profileData) return
    const requester = getRequester(profileData)
    const {
      assistance,
      trouble,
      device,
      model,
      tech,
      talkVip,
      supportFile,
      description,
      userType,
      adoptionTrouble,
      app,
    } = values

    const totalNumberOfDevices = await getTotalNumberOfDevices()

    const consoleData = getConsoleValues(
      values,
      selectedConsole?.shadow.state.reported
    )

    const initialValues: CustomFields = {
      assistance,
      trouble,
      adoptionTrouble,
      device,
      console: values.console?.id,
      model,
      app,
      tech,
      talkVip,
      userType,
      attachments: sendsafelyAttachmentUrl,
      supportFile: isSupportFileAllowed ? supportFile : undefined,
      totalDevices: totalNumberOfDevices,
      totalConsoles: consoles.length.toString(),
      mac: values.console?.mac,
      ...consoleData,
    }

    const rawData = JSON.stringify(initialValues, null, 2)

    const allValues = { ...initialValues, sentryUserId, sentryEventId, rawData }

    if (allValues.adoptionTrouble) {
      /* Since both trouble and adoption trouble map to the same Zd field, 
      if adoption trouble exists trouble needs to be removed to not cause mapping issues */
      allValues.trouble = undefined
    }

    const ticketFields = omit(config, UniFiStateValues.DESCRIPTION)

    const custom_fields = reduce<typeof ticketFields, ZDCustomFields>(
      ticketFields,
      (result, field, key) => {
        const id: number = field.fieldId
        const value: string | null | undefined =
          allValues[key as keyof CustomFields]
        if (!value) return result
        return [
          ...result,
          {
            id,
            value,
          },
        ]
      },
      []
    )

    return {
      followup_source_id: followup_source_id || FIELD_FALLBACK_VALUE_NA,
      ticket_form_id: ZENDESK_UNIFI_FORM_KEY,
      requester,
      tags: getTags({
        isFollowUp: !!followup_source_id,
        isTalkVip: !!talkVip,
        isOrgAccount: accountType === AccountType.ORG_OWNER,
        queryTags: customTags,
        isChatClosed: !isChatOpen && hasChatSupport,
      }),

      subject: createSubject(values, description),
      comment: { body: description },
      custom_fields,
    }
  }

  return { formatRequestedTicket }
}
