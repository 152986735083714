import { api } from 'api'
import axios, { AxiosResponse } from 'axios'
import { all, call, put, spawn, takeEvery } from 'redux-saga/effects'
import { closeVisibleModal } from 'modules/modals'
import { ReduxAction, RootState } from 'types/types'

export const dataKey = 'assuranceLevel'

interface AssuranceLevel {
  level: number
  time_left_seconds: number
}

export interface ErrorData {
  detail?: string
  ERRCODE?: string
}

export interface AssuranceLevelState {
  data: AssuranceLevel
  error: ErrorData | null | string
  isLoading: boolean
}

const GET_ASSURANCE_LEVEL = `${dataKey}/GET_ASSURANCE_LEVEL`
export const getAssuranceLevel = () => {
  return {
    type: GET_ASSURANCE_LEVEL,
  }
}

const GET_ASSURANCE_LEVEL_DONE = `${dataKey}/GET_ASSURANCE_LEVEL_DONE`
export const getAssuranceLevelDone = (data: AxiosResponse<AssuranceLevel>) => ({
  type: GET_ASSURANCE_LEVEL_DONE,
  payload: data,
})

const GET_ASSURANCE_LEVEL_FAILED = `${dataKey}/GET_ASSURANCE_LEVEL_FAILED`
const getAssuranceLevelFailed = (
  data: AxiosResponse<AssuranceLevel> | string
) => ({
  type: GET_ASSURANCE_LEVEL_FAILED,
  payload: data,
})

const UPGRADE_ASSURANCE_LEVEL = `${dataKey}/UPGRADE_ASSURANCE_LEVEL`
export const upgradeAssuranceLevel = (password: string) => {
  return {
    type: UPGRADE_ASSURANCE_LEVEL,
    payload: password,
  }
}

const UPGRADE_ASSURANCE_LEVEL_DONE = `${dataKey}/UPGRADE_ASSURANCE_LEVEL_DONE`
const upgradeAssuranceLevelDone = (data: AxiosResponse<AssuranceLevel>) => {
  return {
    type: UPGRADE_ASSURANCE_LEVEL_DONE,
    payload: data,
  }
}

const UPGRADE_ASSURANCE_LEVEL_FAILED = `${dataKey}/UPGRADE_ASSURANCE_LEVEL_FAILED`
const upgradeAssuranceLevelFailed = (
  data: AxiosResponse<AssuranceLevel> | string
) => {
  return {
    type: UPGRADE_ASSURANCE_LEVEL_FAILED,
    payload: data,
  }
}

const EXTEND_ASSURANCE_LEVEL = `${dataKey}/EXTEND_ASSURANCE_LEVEL`
export const extendAssuranceLevel = () => {
  return {
    type: EXTEND_ASSURANCE_LEVEL,
  }
}

const EXTEND_ASSURANCE_LEVEL_DONE = `${dataKey}/EXTEND_ASSURANCE_LEVEL_DONE`
const extendAssuranceLevelDone = (data: AxiosResponse<AssuranceLevel>) => {
  return {
    type: EXTEND_ASSURANCE_LEVEL_DONE,
    payload: data,
  }
}

const EXTEND_ASSURANCE_LEVEL_FAILED = `${dataKey}/EXTEND_ASSURANCE_LEVEL_FAILED`

const DOWNGRADE_ASSURANCE_LEVEL = `${dataKey}/DOWNGRADE_ASSURANCE_LEVEL`
export const downgradeAssuranceLevel = () => {
  return {
    type: DOWNGRADE_ASSURANCE_LEVEL,
  }
}

const DOWNGRADE_ASSURANCE_LEVEL_DONE = `${dataKey}/DOWNGRADE_ASSURANCE_LEVEL_DONE`
const downgradeAssuranceLevelDone = (data: AxiosResponse<AssuranceLevel>) => {
  return {
    type: DOWNGRADE_ASSURANCE_LEVEL_DONE,
    payload: data,
  }
}

const DOWNGRADE_ASSURANCE_LEVEL_FAILED = `${dataKey}/DOWNGRADE_ASSURANCE_LEVEL_FAILED`

const ASSURANCE_LEVEL_RESET_ERRORS = `${dataKey}/ASSURANCE_LEVEL_RESET_ERRORS`
export const assuranceLevelResetErrors = () => ({
  type: ASSURANCE_LEVEL_RESET_ERRORS,
})

const selectData = (state: RootState) => state[dataKey]

export const selectAssuranceLevel = (state: RootState) => selectData(state)

export const selectErrors = (state: RootState) => selectData(state).error

export const selectIsLoading = (state: RootState) => selectData(state).isLoading

function* assuranceLevelSaga() {
  let assuranceLevelRes: AxiosResponse

  try {
    assuranceLevelRes = yield call(api.getAssuranceLevel)

    yield put(getAssuranceLevelDone(assuranceLevelRes))
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      yield put(getAssuranceLevelFailed(e.response.statusText))
    }
  }
}

function* subscribeToGetAssuranceLevel() {
  yield takeEvery(GET_ASSURANCE_LEVEL, assuranceLevelSaga)
}

function* upgradeAssuranceLevelSaga({ payload }: ReduxAction<string>) {
  let upgradeAssuranceLevelRes: AxiosResponse

  try {
    upgradeAssuranceLevelRes = yield call(api.upgradeAssuranceLevel, payload)
    yield put(upgradeAssuranceLevelDone(upgradeAssuranceLevelRes))
    yield put(closeVisibleModal())
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      (e.response?.status === 403 || e.response?.status === 400)
    ) {
      yield put(upgradeAssuranceLevelFailed(e.response.data))
      return
    } else {
      if (axios.isAxiosError(e) && e.response) {
        yield put(upgradeAssuranceLevelFailed(e.response.statusText))
      }
      return
    }
  }
}

function* subscribeToUpgradeAssuranceLevel() {
  yield takeEvery(UPGRADE_ASSURANCE_LEVEL, upgradeAssuranceLevelSaga)
}

function* extendAssuranceLevelSaga() {
  let extendAssuranceLevelRes: AxiosResponse
  try {
    extendAssuranceLevelRes = yield call(api.extendAssuranceLevel)
    yield put(extendAssuranceLevelDone(extendAssuranceLevelRes))
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      (e.response?.status === 400 ||
        e.response?.status === 499 ||
        e.response?.status === 403)
    ) {
      if (e.response.status === 499) {
        yield put(downgradeAssuranceLevel())
      }
      yield put(upgradeAssuranceLevelFailed(e.response?.data))
      return
    } else {
      if (axios.isAxiosError(e) && e.response) {
        yield put(upgradeAssuranceLevelFailed(e.response.statusText))
      }
      return
    }
  }
}

function* subscribeToExtendAssuranceLevel() {
  yield takeEvery(EXTEND_ASSURANCE_LEVEL, extendAssuranceLevelSaga)
}

function* downgradeAssuranceLevelSaga() {
  let downgradeAssuranceLevelRes: AxiosResponse

  try {
    downgradeAssuranceLevelRes = yield call(api.downgradeAssuranceLevel)
    yield put(downgradeAssuranceLevelDone(downgradeAssuranceLevelRes))
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      (e.response?.status === 400 || e.response?.status === 403)
    ) {
      yield put(upgradeAssuranceLevelFailed(e.response?.data))
      return
    } else {
      if (axios.isAxiosError(e) && e.response) {
        yield put(upgradeAssuranceLevelFailed(e.response.statusText))
      }
      return
    }
  }
}

function* subscribeToDowngradeAssuranceLevel() {
  yield takeEvery(DOWNGRADE_ASSURANCE_LEVEL, downgradeAssuranceLevelSaga)
}

export function* assuranceLevelRootSaga() {
  yield all([
    spawn(subscribeToGetAssuranceLevel),
    spawn(subscribeToUpgradeAssuranceLevel),
    spawn(subscribeToDowngradeAssuranceLevel),
    spawn(subscribeToExtendAssuranceLevel),
  ])
}

const initialState: AssuranceLevelState = {
  data: {
    level: 1,
    time_left_seconds: 0,
  },
  isLoading: false,
  error: null,
}

export const assuranceLevelReducer = (
  state = initialState,
  action: ReduxAction
) => {
  const { type, payload } = action

  switch (type) {
    case GET_ASSURANCE_LEVEL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case GET_ASSURANCE_LEVEL_DONE:
      return {
        data: { ...payload },
        isLoading: false,
        error: null,
      }
    case GET_ASSURANCE_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case UPGRADE_ASSURANCE_LEVEL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case UPGRADE_ASSURANCE_LEVEL_DONE:
      return {
        data: {
          ...payload,
        },
        isLoading: false,
        error: null,
      }
    case UPGRADE_ASSURANCE_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case EXTEND_ASSURANCE_LEVEL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case EXTEND_ASSURANCE_LEVEL_DONE:
      return {
        data: {
          ...payload,
        },
        isLoading: false,
        error: null,
      }
    case EXTEND_ASSURANCE_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case DOWNGRADE_ASSURANCE_LEVEL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case DOWNGRADE_ASSURANCE_LEVEL_DONE:
      return {
        data: {
          ...payload,
        },
        isLoading: false,
        error: null,
      }
    case DOWNGRADE_ASSURANCE_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case ASSURANCE_LEVEL_RESET_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}
