import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { MfaApi } from 'api/sso/mfa'
import { setVisibleModal } from 'modules/modals'
import { BACKUP_CODES_FAILED_MODAL_ID } from 'pages/security/components/BackupCodes/BackupCodesFailedModal'
import { BACKUP_CODES_MODAL_ID } from 'pages/security/components/BackupCodes/BackupCodesModal'
import { QueryKey } from 'store/types'
import { parseSsoErrors } from 'store/utils'
import { SsoApiError } from 'types/ssoErrors'

type SsoGenerateBackupCodesResponse = {
  backup_codes?: string[]
}

const mfaApi = new MfaApi()

export const useMfaBackupCodes = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const generateBackupCodes = useMutation<
    SsoGenerateBackupCodesResponse,
    AxiosError<SsoApiError>
  >({
    mutationFn: () => mfaApi.generateBackupCodes(),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MFA_AUTHENTICATORS] })
      queryClient.setQueryData([QueryKey.MFA_BACKUP_CODES], {
        backup_codes: data.backup_codes ?? [],
      })
      dispatch(setVisibleModal(BACKUP_CODES_MODAL_ID))
    },
    onError: () => {
      dispatch(setVisibleModal(BACKUP_CODES_FAILED_MODAL_ID))
    },
  })

  const backupCodes =
    queryClient.getQueryData<SsoGenerateBackupCodesResponse>([
      QueryKey.MFA_BACKUP_CODES,
    ])?.backup_codes ?? []

  return {
    generateBackupCodes: generateBackupCodes.mutate,
    isGeneratingBackupCodes: generateBackupCodes.isPending,
    generateBackupCodesError: parseSsoErrors(generateBackupCodes.error),
    backupCodes,
  }
}
