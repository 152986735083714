import React from 'react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { ReCaptchaProviderConnected } from 'components/ReCaptcha'
import { MediaSizingProvider } from 'components/mediaSizing/MediaSizingContext'
import { OrganizationProvider } from 'core/contexts/OrganizationsContext'
import '@ubnt/ui-components/styles/normalize.css'
import '@ubnt/ui-components/styles/reset.css'
import GlobalErrorBoundry from 'core/error-handling/GlobalErrorBoundry'
import { authRootSaga } from 'features/auth/modules/auth'
import { assuranceLevelRootSaga } from 'features/auth/modules/assuranceLevel'
import { rolesRootSaga } from 'features/early-access/module/roles'
import { deleteAccountRootSaga } from 'features/delete-account/module/deleteAccount'
import { LocaleProvider } from 'modules/LocaleContext'
import { MotifProvider } from 'motif/MotifProvider'
import { changeEmailRootSaga } from 'pages/security/changeEmail'
import { ChatProvider } from 'pages/support/chat/ChatContext'
import GlobalStyles from 'theme/GlobalStyles'
import packageJSON from '../package.json'
import storeConfigs from './store'
import { AppIntlProvider } from './pages/IntlProvider'
import App from './pages/App'

const queryClient = new QueryClient()

const { store, sagaMiddleware } = storeConfigs

sagaMiddleware.run(authRootSaga)
sagaMiddleware.run(deleteAccountRootSaga)
sagaMiddleware.run(rolesRootSaga)
sagaMiddleware.run(assuranceLevelRootSaga)
sagaMiddleware.run(changeEmailRootSaga)

window.__RELEASE__ = __RELEASE__

if (!__DEV__) {
  const tagManagerArgs = {
    gtmId: 'GTM-P5VBRWW',
  }

  TagManager.initialize(tagManagerArgs)

  Sentry.init({
    dsn: 'https://b185b3ad1240a5ed1a7199d1e49e105a@o21767.ingest.sentry.io/4506058886676480',
    tracesSampleRate: 0,
    release: `account@${packageJSON.version}`,
    // TODO: Improve. Now setting on hour, not minutes, seconds or milliseconds so within hour they will interfere
    dist: new Date().setMinutes(0, 0, 0).toString(),
  })
}

const load = () => (
  <Provider store={store}>
    <MotifProvider>
      <GlobalStyles />
      <MediaSizingProvider>
        <LocaleProvider>
          <AppIntlProvider>
            <GlobalErrorBoundry>
              <ReCaptchaProviderConnected>
                <QueryClientProvider client={queryClient}>
                  <OrganizationProvider>
                    <ChatProvider>
                      <App />
                    </ChatProvider>
                  </OrganizationProvider>
                </QueryClientProvider>
              </ReCaptchaProviderConnected>
            </GlobalErrorBoundry>
          </AppIntlProvider>
        </LocaleProvider>
      </MediaSizingProvider>
    </MotifProvider>
  </Provider>
)

const root = createRoot(document.getElementById('root')!)
root.render(load())
