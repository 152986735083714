import {
  TransformedCredential,
  TransformedAssertion,
  CustomPublicKeyCredentialCreationOptions,
  CustomPublicKeyCredentialLoginOptions,
  CustomPublicKeyCredentialRequestOptions,
} from 'types/mfa'

const b64encode = (buf: Uint8Array) => {
  return btoa(
    new Uint8Array(buf).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

const b64decode = (string: string) => {
  return Uint8Array.from(
    atob(string.replace(/_/g, '/').replace(/-/g, '+')),
    (c) => c.charCodeAt(0)
  )
}

// create
export const transformNewAssertionForServer = (
  newAssertion: TransformedCredential<AuthenticatorAttestationResponse>
): TransformedAssertion => {
  const attObj = new Uint8Array(newAssertion.response.attestationObject)
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON)
  const authenticatorAttachment = newAssertion.authenticatorAttachment
  const transports = newAssertion.response.getTransports()

  return {
    id: newAssertion.id,
    rawId: newAssertion.id,
    type: newAssertion.type,
    response: {
      attestationObject: b64encode(attObj),
      clientDataJSON: b64encode(clientDataJSON),
      authenticatorAttachment,
      transports,
    },
  }
}

export const transformCredentialCreateOptions = (
  credentialCreateOptionsFromServer: CustomPublicKeyCredentialCreationOptions
): PublicKeyCredentialCreationOptions => {
  const { challenge, user, excludeCredentials, ...rest } =
    credentialCreateOptionsFromServer

  const transformedCredentialCreateOptions = {
    challenge: b64decode(challenge),
    user: {
      ...user,
      id: b64decode(user.id),
    },
    excludeCredentials: excludeCredentials?.map(
      ({ id, ...rest }: { id: string; type: 'public-key' }) => ({
        id: b64decode(id),
        ...rest,
      })
    ),
    ...rest,
  }

  return transformedCredentialCreateOptions
}

// login
export const transformAssertionForServer = (
  newAssertion: TransformedCredential<AuthenticatorAssertionResponse>
): CustomPublicKeyCredentialLoginOptions => {
  const authData = new Uint8Array(newAssertion.response.authenticatorData)
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON)
  const sig = new Uint8Array(newAssertion.response.signature)
  return {
    id: newAssertion.id,
    rawId: newAssertion.id,
    type: newAssertion.type,
    response: {
      authenticatorData: b64encode(authData),
      clientDataJSON: b64encode(clientDataJSON),
      signature: b64encode(sig),
    },
  }
}

export const transformCredentialRequestOptions = (
  credentialRequestOptionsFromServer: CustomPublicKeyCredentialRequestOptions
): PublicKeyCredentialRequestOptions => {
  const { challenge, allowCredentials } = credentialRequestOptionsFromServer
  const challengeDecoded = b64decode(challenge)
  const allowCredentialsDecoded = allowCredentials.map(
    (credentialDescriptor: any) => {
      let { id } = credentialDescriptor
      id = b64decode(id)
      return Object.assign({}, credentialDescriptor, { id })
    }
  )
  const transformedCredentialRequestOptions = Object.assign(
    {},
    credentialRequestOptionsFromServer,
    { challenge: challengeDecoded, allowCredentials: allowCredentialsDecoded }
  )
  return transformedCredentialRequestOptions
}
