import React from 'react'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { SubscriptionServiceTableItem } from '../Components.styles'
import { getFormattedDate } from '../utils'

interface Props {
  isSuspended: boolean
  periodEnd: number
  upcomingInvoice?: StripeInvoice
}

export const BillingDate: React.FC<Props> = ({
  isSuspended,
  periodEnd,
  upcomingInvoice,
}) => {
  const billingDate =
    isSuspended && upcomingInvoice ? upcomingInvoice.period_end : periodEnd

  return (
    <SubscriptionServiceTableItem className="extra-data">
      {getFormattedDate(billingDate)}
    </SubscriptionServiceTableItem>
  )
}
