import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@ubnt/ui-components'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import { typography } from 'theme'
import { AddMFAOption } from './types'

export const Option: React.FC<AddMFAOption & { isFeatured?: boolean }> = ({
  icon,
  title,
  description,
  url,
  isFeatured,
}) => {
  const history = useHistory()

  return (
    <OptionRow isFeatured={isFeatured}>
      {icon}
      <TextWrapper>
        <OptionTitle>
          <FormattedMessage id={title} />
        </OptionTitle>
        <FormattedMessage id={description} />
      </TextWrapper>
      <StyledButton
        wrapClassName="buttonWrapper"
        onClick={() => history.push(url)}
        variant="primary"
        isFeatured={isFeatured}
      >
        <FormattedMessage id="COMMON_ACTION_ADD" />
      </StyledButton>
    </OptionRow>
  )
}

const OptionRow = styled.div<{ isFeatured?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 46px 1fr 58px;
  gap: 12px;
  margin-bottom: 16px;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    grid-template-columns: fit-content(100%) fit-content(1fr) 1fr;
    align-items: start;
  }
  .buttonWrapper {
    @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
      grid-column: ${({ isFeatured }) => (isFeatured ? '1/4' : '2')};
    }
  }
`

const TextWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    grid-column: 2/4;
  }
`

const OptionTitle = styled.div`
  font: ${typography['desktop-typography-heading-medium']};
`

const StyledButton = styled(Button)<{ isFeatured?: boolean }>`
  max-width: 100%;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    border-radius: 34px;
    font-weight: bold;
    padding-top: 0px;
  }
`
