import React from 'react'
import { Popover } from '@ubnt/ui-components/Popover'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CardType } from 'features/payment-methods/modules/types'
import { EDIT_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/EditPaymentMethodModal'
import { DELETE_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/RemovePaymentMethodModal'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { setVisibleModal } from 'modules/modals'
import { useCreateCard } from 'store/mutations/billingCards/useCreateCard'
import { withTooltip } from 'utils/withTooltip'
import { PopoverButton, PopoverIcon } from './Components.styles'

interface Props {
  card: CardType
  handleSetDefault: (cardId: string, cardRegion: StripeRegionCode) => void
}

export const CardListPopover: React.FC<Props> = ({
  card,
  handleSetDefault,
}) => {
  const dispatch = useDispatch()
  const { resetCreateCardErrors } = useCreateCard()

  const handleEditPayment = (card: CardType) => {
    resetCreateCardErrors()
    dispatch(
      setVisibleModal(EDIT_PAYMENT_METHOD_MODAL_ID, {
        card,
        cardRegion: card.region,
      })
    )
  }

  const handleRemovePayment = (card: CardType) => {
    dispatch(
      setVisibleModal(DELETE_PAYMENT_METHOD_MODAL_ID, {
        card,
      })
    )
  }

  return (
    <Popover
      align="bottomLeft"
      renderToggle={() => <PopoverIcon size="large" />}
      size="small"
      classNameCard="payments-popover__card"
    >
      <PopoverButton variant="tertiary" onClick={() => handleEditPayment(card)}>
        <FormattedMessage id="COMMON_ACTION_EDIT" />
      </PopoverButton>
      {!card.is_default && (
        <PopoverButton
          variant="tertiary"
          onClick={() => handleSetDefault(card.id, card.region)}
        >
          <FormattedMessage id="SETTINGS_PAYMENTS_SET_DEFAULT" />
        </PopoverButton>
      )}
      {withTooltip(
        {
          description: (
            <FormattedMessage id="STRIPE_PAYMENT_ERROR_CANNOT_DELETE" />
          ),
          portal: true,
        },
        <PopoverButton
          variant="tertiary"
          color="danger"
          onClick={() => handleRemovePayment(card)}
          disabled={card.isInUse}
        >
          <FormattedMessage id="COMMON_ACTION_REMOVE" />
        </PopoverButton>,
        card.isInUse
      )}
    </Popover>
  )
}
