import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { typography } from 'theme'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'

const CONTENT = [
  {
    key: 'SETTINGS_EARLY_ACCESS_BENEFITS_COMMUNITY',
    url: 'https://community.ui.com/questions',
  },
  {
    key: 'SETTINGS_EARLY_ACCESS_BENEFITS_RELEASES',
    url: 'https://community.ui.com/releases',
  },
]

const LearnMoreModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_EARLY_ACCESS_BENEFITS_TITLE" />}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
          variant: 'primary',
        },
      ]}
    >
      <Wrapper>
        <StyledList>
          {CONTENT.map(({ key, url }) => (
            <a key={key} href={url} target="_blank" rel="noreferrer">
              <FormattedMessage id={key} />
            </a>
          ))}
        </StyledList>
        <Descriptions>
          <FormattedMessage id="SETTINGS_EARLY_ACCESS_BENEFITS_DESCRIPTION" />
        </Descriptions>
      </Wrapper>
    </GenericModal>
  )
}

export const EARLY_ACCESS_BENEFITS_MODAL_ID = 'EARLY_ACCESS_BENEFITS_MODAL_ID'

export const WrappedBenefitsMoreModal = connect()(() => (
  <ModalWrapper modalId={EARLY_ACCESS_BENEFITS_MODAL_ID}>
    <LearnMoreModal />
  </ModalWrapper>
))

const Wrapper = styled.div`
  height: 100%;
`

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 0;
  margin-top: 0;
`

const Descriptions = styled.div`
  margin-top: 12px;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
`
