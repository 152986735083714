import React from 'react'
import { SubscriptionWarning } from '../../subscriptions/SubscriptionWarning'
import { SubscriptionServiceTableItem } from '../Components.styles'

interface Props {
  plan?: string | null
  isFailed: boolean
  cardNotFound: boolean
  isCanceled: boolean
}

export const Plan: React.FC<Props> = ({
  plan,
  isFailed,
  cardNotFound,
  isCanceled,
}) => {
  const displayWarningIcon = (isFailed || cardNotFound) && !isCanceled

  return (
    <SubscriptionServiceTableItem>
      {displayWarningIcon && (
        <SubscriptionWarning
          message={
            isFailed
              ? 'SETTINGS_SUBSCRIPTIONS_TOOLTIP_WARNING'
              : 'SETTINGS_SUBSCRIPTIONS_PAYMENT_CARD_NOT_FOUND'
          }
        />
      )}
      {plan ?? '-'}
    </SubscriptionServiceTableItem>
  )
}
