import UbiquitiLogo from '@ubnt/icons/UbiquitiLogo'
import { Button } from '@ubnt/ui-components/Button'
import { PopoverCard } from '@ubnt/ui-components/Popover'
import { TextArea } from '@ubnt/ui-components/TextArea'
import theme, { typography } from 'theme'
import styled from 'theme/styled'

export const ChatPopover = styled(PopoverCard)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 450px;
  height: 600px;
  z-index: 250;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  gap: 8px;
  border-radius: 12px;

  @media (max-width: ${theme.media.mobileLarge}) {
    width: 100%;
    height: calc(100% - 50px);
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
`

export const ChatButtonContainer = styled.div`
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 25;
`

export const ChatButton = styled(Button)`
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
`

export const HeaderContainer = styled.div`
  position: relative;
  display: grid;
  height: 56px;
  grid-template-columns: 64px 1fr 64px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.ublue06};
`

export const HeaderUI = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ theme }) => theme.neutral00};
  font: ${typography['desktop-heading-large']};
`

export const HeaderButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

export const IconButton = styled(Button)`
  width: 24px;
  padding: 0;
  svg {
    color: ${({ theme }) => theme.neutral00};
  }
  &:hover {
    background: transparent;
  }
  &:focus {
    box-shadow: none !important;
  }
`

export const ConfirmationPopup = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  height: 96px;
  width: 100%;
  padding: 16px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow:
    0px 0px 1px 0px rgba(33, 35, 39, 0.08),
    0px 12px 48px 0px rgba(33, 35, 39, 0.12);
  background: ${({ theme }) => theme.neutral00};
`

export const FooterContainer = styled.div`
  height: 96px;
  padding: 0 8px 8px;
`

export const FooterWrapper = styled.div`
  width: 100%;
  diplay: flex;
  flex-direction: column;
  gap: 8px;
`

export const NewMessageWrapper = styled.div<{ $isFocused: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 80px;
  width: 100%;
  align-items: center;
  border: ${({ theme, $isFocused }) =>
    $isFocused ? `1px solid ${theme.ublue06}` : `1px solid ${theme.neutral04}`};
  border-radius: 8px;
  padding: 8px;
`

export const StyledTextArea = styled(TextArea)`
  height: 32px;
  textarea {
    border: none;
    padding: 0;
    resize: none;
  }
`

export const FooterButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 32px;
`

export const MessageIconButton = styled(IconButton)<{ $isActive: boolean }>`
  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.ublue06 : theme.neutral06};
    height: 20px;
    width: 20px;
  }
`

export const MessagesContainer = styled.div`
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const OverflowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
`

export const Message = styled.div<{ alignRight?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};
`

export const MessageWrapper = styled.div`
  max-width: 80%;
  width: max-content;
  display: flex;
  gap: 8px;
`

export const UIAvatar = styled(UbiquitiLogo)`
  border-radius: 50%;
`

export const TextWrapper = styled.div<{ $extraMargin?: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;
  max-width: 320px;

  ${({ $extraMargin }) => {
    return $extraMargin && `margin: ${$extraMargin};`
  }}
`

export const UserTextWrapper = styled(TextWrapper)`
  align-items: flex-end;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.neutral02};
  border-radius: 8px;
`

export const Agent = styled.div`
  font: ${typography['desktop-heading-medium']};
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.ublue06};
`

export const ImageMessage = styled.img`
  display: contain;
  max-width: 300px;
  max-height: 300px;
`

export const MessageContent = styled.div`
  width: 100%;
  font: ${typography['desktop-body']};
  word-break: break-word;
  text-wrap: wrap;
  white-space: pre-line;
  min-height: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 64px 48px 16px;
`

export const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
`

export const ReviewTextArea = styled(TextArea)`
  padding-top: 8px;
  textarea {
    min-height: 120px;
    max-height: 280px;
    resize: vertical;
  }
`

export const HelperMessage = styled.div<{ $isInvalid: boolean }>`
  font: ${typography['desktop-caption']};
  color: ${({ $isInvalid, theme }) => ($isInvalid ? theme.red06 : theme.text3)};
`

export const ReviewButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`

export const TypingWrapper = styled(InfoWrapper)`
  justify-content: flex-start;
  gap: 8px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 4px;
`

export const Dots = styled.div`
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat
    radial-gradient(
      circle closest-side,
      ${({ theme }) => theme.ublue05} 90%,
      #0000
    );
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: d3 1s infinite linear;

  @keyframes d3 {
    20% {
      background-position:
        0% 0%,
        50% 50%,
        100% 50%;
    }
    40% {
      background-position:
        0% 100%,
        50% 0%,
        100% 50%;
    }
    60% {
      background-position:
        0% 50%,
        50% 100%,
        100% 0%;
    }
    80% {
      background-position:
        0% 50%,
        50% 50%,
        100% 100%;
    }
  }
`
