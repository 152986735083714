import React, { PureComponent } from 'react'
import { api } from 'api'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'

interface PathParamsType {
  uuid?: string
}

type Props = RouteComponentProps<PathParamsType>

interface State {
  isValid: null | boolean
}

class VerifyEmail extends PureComponent<Props, State> {
  state = {
    isValid: null,
  }

  async componentDidMount() {
    const {
      match: {
        params: { uuid },
      },
    } = this.props

    try {
      if (uuid) {
        await api.verifyRegistration(uuid)
      }
    } catch {
      this.setState({ isValid: false })
      return
    }

    this.setState({ isValid: true })
  }

  render() {
    const { isValid } = this.state
    if (isValid === false) return <Redirect to="/" />
    if (isValid) return <Redirect to="/verify/success" />
    return null
  }
}

export default withRouter(VerifyEmail)
