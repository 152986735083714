import React from 'react'
import { ConsoleBubble } from '../Console'
import { DescriptionBubble } from '../Description'
import { BubbleProps } from '../types'
import { UmrIssueBubble } from '../UmrIssue'
import { UserTypeBubble } from '../UserType'

export const renderUmrSection = (
  sectionID: string,
  props: BubbleProps
): React.ReactElement => {
  const section = {
    umrSpecificIssue: <UmrIssueBubble key={sectionID} {...props} />,
    console: <ConsoleBubble key={sectionID} {...props} />,
    userType: <UserTypeBubble key={sectionID} {...props} />,
    description: <DescriptionBubble key={sectionID} {...props} />,
  }[sectionID]

  return section ?? <></>
}
