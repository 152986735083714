import React, { useCallback } from 'react'
import { InfoIcon } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ProfileData } from 'api/sso/profile'
import { FooterButtons } from 'components/FooterButtons'
import { PROFILE_PICTURE_MODAL_ID } from 'features/profile-picture/ui/ProfilePictureModal'
import { setVisibleModal } from 'modules/modals'
import {
  EditIcon,
  FormContainer,
  GridWrapper,
  ImageValueWrapper,
  InputLabel,
  PictureLabel,
  StyledAvatar,
  StyledInput,
  StyledText,
  StyledTooltip,
  ValueWrapper,
} from 'pages/profile/Profile.styles'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import Yup from 'validators/yupLocaleConfig'
import { CHANGE_USERNAME_MODAL_ID } from './ChangeUsernameModal'
import EarlyAccessSection from './EarlyAccessSection'
import { MailingListSection } from './MailingListSection'
import SelectLanguage from './SelectLanguage'
import { ThemeSection } from './ThemeSection'

export const ProfileInfoForm: React.FC = () => {
  const dispatch = useDispatch()
  const { profileData, isProfileDataFetching } = useSsoProfileQuery()
  const { updateProfile, isUpdateProfileLoading, updateProfileError } =
    useUpdateProfile()

  const isLoading = isProfileDataFetching || isUpdateProfileLoading

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    handleReset,
    dirty: hasChanges,
  } = useFormik<ProfileData>({
    initialValues: {
      username: profileData?.username || '',
      email: profileData?.email || '',
      first_name: profileData?.first_name || '',
      last_name: profileData?.last_name || '',
    },
    onSubmit: (values) => {
      const dirtyValues: ProfileData = {}

      if (profileData?.username !== values?.username) {
        dirtyValues.username = values.username
      }
      if (profileData?.email !== values?.email) {
        dirtyValues.email = values.email
      }
      if (profileData?.first_name !== values?.first_name) {
        dirtyValues.first_name = values.first_name
      }
      if (profileData?.last_name !== values?.last_name) {
        dirtyValues.last_name = values.last_name
      }

      updateProfile(dirtyValues)
    },
    enableReinitialize: true,
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().required().email().label('COMMON_LABEL_EMAIL'),
        first_name: Yup.string()
          .max(30)
          .trim()
          .label('COMMON_LABEL_FIRST_NAME'),
        last_name: Yup.string().max(30).trim().label('COMMON_LABEL_LAST_NAME'),
      }),
  })

  const hasErrors = !!Object.keys(errors).length

  const onOpenChangeUsernameModal = useCallback(
    () => dispatch(setVisibleModal(CHANGE_USERNAME_MODAL_ID)),
    [dispatch]
  )

  const changeProfilePicture = () =>
    dispatch(setVisibleModal(PROFILE_PICTURE_MODAL_ID))

  const handleFormReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleReset(e)
  }

  return (
    <FormContainer>
      <GridWrapper>
        <PictureLabel>
          <FormattedMessage id="SETTINGS_PICTURE_LABEL" />
        </PictureLabel>
        <ImageValueWrapper>
          <StyledAvatar isClickable={false} />
          <Button
            variant="link"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              changeProfilePicture()
            }}
          >
            <EditIcon size="medium" />
            <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_BUTTON" />
          </Button>
        </ImageValueWrapper>
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_USERNAME" />
        </InputLabel>
        <ValueWrapper>
          <StyledText>{values.username}</StyledText>
          <Button
            variant="link"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              onOpenChangeUsernameModal()
            }}
          >
            <EditIcon size="medium" />
            <FormattedMessage id="COMMON_LABEL_CHANGE_USERNAME" />
          </Button>
        </ValueWrapper>
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_EMAIL" />
          <StyledTooltip
            description={
              <FormattedMessage
                id="SETTINGS_PROFILE_CHANGE_EMAIL_TOOLTIP"
                values={{
                  a: (text) => <Link to="/security">{text}</Link>,
                }}
              />
            }
            portal
            isInteractive
          >
            <InfoIcon size="original" isActive variant="twoTone" />
          </StyledTooltip>
        </InputLabel>
        <ValueWrapper>
          <StyledText>{values.email}</StyledText>
        </ValueWrapper>
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_FIRST_NAME" />
        </InputLabel>
        <StyledInput
          variant="secondary"
          name="first_name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.first_name}
          invalid={errors?.first_name || updateProfileError?.first_name}
          maxLength={30}
          width="100%"
        />
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_LAST_NAME" />
        </InputLabel>
        <StyledInput
          variant="secondary"
          name="last_name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.last_name}
          invalid={errors?.last_name || updateProfileError?.last_name}
          maxLength={30}
          width="100%"
        />
      </GridWrapper>

      <SelectLanguage />

      <ThemeSection />

      <EarlyAccessSection />

      <MailingListSection />

      <FooterButtons
        isActive={hasChanges}
        isDisabled={hasErrors}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={handleFormReset}
      />
    </FormContainer>
  )
}
