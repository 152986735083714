import React, { useState } from 'react'
import Button from '@ubnt/ui-components/Button/Button'
import InlineDialog from '@ubnt/ui-components/InlineDialog/InlineDialog'
import { FormattedMessage, useIntl } from 'react-intl'
import theme from 'theme'
import { useRemoveTrustedDevices } from 'store/mutations/trustedDevices/useRemoveTrustedDevices'
import styled from 'theme/styled'

interface Props {
  selectedIds: string[]
  isCurrentDeviceSelected?: boolean
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const RemoveTrustedDeviceDialog: React.FC<Props> = ({
  selectedIds,
  isCurrentDeviceSelected,
  setSelectedIds,
}) => {
  const intl = useIntl()
  const { removeMultipleTrustedDevices, isRemoveLoading } =
    useRemoveTrustedDevices()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const getMessageIds = () => {
    if (selectedIds.length > 1 && isCurrentDeviceSelected) {
      return {
        title: 'SETTINGS_TRUSTED_DEVICES_REMOVE_MULTIPLE_AND_CURRENT_TITLE',
        desc: 'SETTINGS_TRUSTED_DEVICES_REMOVE_MULTIPLE_AND_CURRENT',
      }
    }
    if (isCurrentDeviceSelected) {
      return {
        title: 'SETTINGS_TRUSTED_DEVICES_REMOVE_CURRENT_TITLE',
        desc: 'SETTINGS_TRUSTED_DEVICES_REMOVE_CURRENT',
      }
    }
    if (selectedIds.length > 1) {
      return {
        title: 'SETTINGS_TRUSTED_DEVICES_REMOVE_MULTIPLE_TITLE',
        desc: 'SETTINGS_TRUSTED_DEVICES_REMOVE_MULTIPLE',
      }
    }
    return {
      title: 'SETTINGS_TRUSTED_DEVICES_REMOVE_ONE_TITLE',
      desc: 'SETTINGS_TRUSTED_DEVICES_REMOVE_ONE',
    }
  }

  return (
    <StyledInlineDialog
      isOpen={isOpen}
      position="bottomLeft"
      actions={[
        {
          onClick: () => setIsOpen(false),
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          variant: 'tertiary',
          disabled: isRemoveLoading,
        },
        {
          onClick: () => {
            removeMultipleTrustedDevices({ ids: selectedIds })
            setIsOpen(false)
            setSelectedIds([])
          },
          text: intl.formatMessage({ id: 'COMMON_ACTION_REMOVE' }),
          variant: 'primary',
          loader: isRemoveLoading ? 'spinner' : undefined,
          color: 'danger',
        },
      ]}
      title={<FormattedMessage id={getMessageIds().title} />}
      body={<FormattedMessage id={getMessageIds().desc} />}
      onRequestClose={() => setIsOpen(false)}
    >
      <Button
        variant="inline"
        size="medium"
        color="danger"
        disabled={!selectedIds.length}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <FormattedMessage
          id="SETTINGS_TRUSTED_DEVICES_REMOVE_DEVICES"
          values={{ number: selectedIds.length }}
        />
      </Button>
    </StyledInlineDialog>
  )
}

const StyledInlineDialog = styled(InlineDialog)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    `
  @media (max-width: ${theme.media.mobileXLarge}) {
    > div:last-child {
      width: 300px !important;
      max-width: 300px !important;
    }
  }`}
`
