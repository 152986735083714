import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { NcaBackupsApi } from 'api/nca/ncaBackups'
import { BackupItem } from 'api/nca/types'
import { closeVisibleModal } from 'modules/modals'
import { useToasts } from 'pages/backups/components/useToasts'
import { QueryKey } from 'store/types'

export const useDeleteBackups = (api: NcaBackupsApi = new NcaBackupsApi()) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { backupsDeleteSuccess } = useToasts()
  const [noOfDeletedBackups, setNoOfDeletedBackups] = useState(0)

  const deleteBackups = useMutation({
    mutationFn: api.deleteMany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.NCA_BACKUPS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.NCA_ARCHIVED_BACKUPS],
      })
      backupsDeleteSuccess(noOfDeletedBackups)
      dispatch(closeVisibleModal())
    },
  })

  const handleDeleteBackups = (backups: { backups: BackupItem[] }) => {
    setNoOfDeletedBackups(backups.backups.length)
    return deleteBackups.mutate(backups)
  }

  return {
    deleteBackups: handleDeleteBackups,
    areDeleteBackupsLoading: deleteBackups.isPending,
  }
}
