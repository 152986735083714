import React from 'react'
import ArrowDownPrimaryIcon from '@ubnt/icons/ArrowDownPrimaryIcon'
import ArrowUpPrimaryIcon from '@ubnt/icons/ArrowUpPrimaryIcon'
import { Checkbox } from '@ubnt/ui-components'
import { FormattedMessage } from 'react-intl'
import theme, { typography } from 'theme'
import styled from 'theme/styled'
import { DevicesGrid } from './TrustedDevicesList'
import { DeviceSortingOptions } from './utils'

interface Props {
  isManaging: boolean
  sorting: DeviceSortingOptions
  areAllIdsSelected: boolean
  toggleSelectAllDeviceIds: () => void
  toggleSorting: (type: 'name' | 'date') => void
}

export const TrustedDevicesListHeader: React.FC<Props> = ({
  isManaging,
  sorting,
  areAllIdsSelected,
  toggleSelectAllDeviceIds,
  toggleSorting,
}) => {
  return (
    <DevicesGrid $isManaging={isManaging}>
      {isManaging && (
        <HeaderCell>
          <Checkbox
            checked={areAllIdsSelected}
            id="selectAllDevices"
            onChange={toggleSelectAllDeviceIds}
          />
        </HeaderCell>
      )}
      <HeaderCell>
        <FormattedMessage id="SETTINGS_TRUSTED_DEVICES_LABEL_DEVICES" />
        <IconButton onClick={() => toggleSorting('name')}>
          {sorting === DeviceSortingOptions.NAME_ASC ? (
            <ArrowUpPrimaryIcon
              isActive={sorting === DeviceSortingOptions.NAME_ASC}
              transparent={sorting != DeviceSortingOptions.NAME_ASC}
            />
          ) : (
            <ArrowDownPrimaryIcon
              isActive={sorting === DeviceSortingOptions.NAME_DESC}
              transparent={sorting != DeviceSortingOptions.NAME_DESC}
            />
          )}
        </IconButton>
      </HeaderCell>
      <HeaderCell className="date">
        <FormattedMessage id="SETTINGS_TRUSTED_DEVICES_LABEL_DATE" />
        <IconButton onClick={() => toggleSorting('date')}>
          {sorting === DeviceSortingOptions.DATE_ASC ? (
            <ArrowUpPrimaryIcon
              isActive={sorting === DeviceSortingOptions.DATE_ASC}
              transparent={sorting != DeviceSortingOptions.DATE_ASC}
            />
          ) : (
            <ArrowDownPrimaryIcon
              isActive={sorting === DeviceSortingOptions.DATE_DESC}
              transparent={sorting != DeviceSortingOptions.DATE_DESC}
            />
          )}
        </IconButton>
      </HeaderCell>
    </DevicesGrid>
  )
}

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  font: ${typography['desktop-typography-heading-medium']};
  color: ${({ theme }) => theme.text2};
  padding: 4px 0;
  gap: 4px;

  @media (max-width: ${theme.media.mobileXLarge}) {
    &.date {
      display: none;
    }
  }
`

const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`
