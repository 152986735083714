import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { PasskeyBannerApi } from 'api/passkeyBanner'
import { MFAType } from 'modules/types'
import { QueryKey } from 'store/types'
import { useMFAAuthenticators } from './useMfaAuthenticators'

export const useShouldDisplayPasskeyBannerQuery = (
  api: PasskeyBannerApi = new PasskeyBannerApi()
) => {
  const { isMfaAuthenticatorsFetched, getActiveMfaAuthenticators } =
    useMFAAuthenticators()
  const authenticators = getActiveMfaAuthenticators()

  const hasPasskey = useMemo(() => {
    return authenticators.some(({ type }) => type === MFAType.webAuthn)
  }, [authenticators])

  const shouldDisplayPasskeyBanner = useQuery({
    queryKey: [QueryKey.SHOULD_DISPLAY_PASSKEY_BANNER],
    queryFn: api.get,
    staleTime: Infinity,
    enabled: isMfaAuthenticatorsFetched && !hasPasskey,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const shouldDisplayBanner = useMemo(() => {
    return !hasPasskey && !!shouldDisplayPasskeyBanner.data?.showPasskeyPrompt
  }, [shouldDisplayPasskeyBanner.data, hasPasskey])

  return { shouldDisplayBanner }
}
