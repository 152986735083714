import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ADD_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/AddPaymentMethodModal'
import type { StripeRegionCode } from 'features/stripe/ui/types'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { setVisibleModal } from 'modules/modals'
import { CHOOSE_PAYMENT_METHOD_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/ChoosePaymentMethodModal'
import { usePayInvoice } from 'store/mutations/billingInvoices/usePayInvoice'
import { useUpdateSubscription } from 'store/mutations/billingSubscriptions/useUpdateSubscription'
import { useBillingOpenInvoicesQuery } from 'store/queries/useBillingOpenInvoicesQuery'

export const useSubscriptionPaymentMethods = () => {
  const dispatch = useDispatch()
  const { updateSubscription, isSubscriptionUpdateLoading } =
    useUpdateSubscription()
  const { openInvoices } = useBillingOpenInvoicesQuery()
  const { payInvoice } = usePayInvoice()

  const handleCardChange = useCallback(
    (value: string, fullSubscription: FullSubscription) => {
      const { id, region, default_payment_method } = fullSubscription

      const payload = default_payment_method
        ? { default_payment_method: value }
        : { default_source: value }

      updateSubscription({ id, region, ...payload })
    },
    [updateSubscription]
  )

  const addPaymentMethod = useCallback(
    (region: StripeRegionCode) =>
      dispatch(
        setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
          subscriptionRegion: region,
        })
      ),
    [dispatch]
  )

  const openChoosePaymentMethodModal = useCallback(
    (fullSubscription: FullSubscription) =>
      dispatch(
        setVisibleModal(CHOOSE_PAYMENT_METHOD_MODAL_ID, {
          fullSubscription,
        })
      ),
    [dispatch]
  )

  const handlePayInvoice = useCallback(
    (fullSubscription: FullSubscription, cardId?: string) => {
      const { region, default_source, id, default_payment_method } =
        fullSubscription

      const openSubscriptionInvoices = openInvoices.filter(
        (invoice) => invoice.subscription === id
      )

      const payload = default_payment_method
        ? { payment_method: cardId || default_payment_method }
        : { source: cardId || default_source }

      openSubscriptionInvoices?.forEach((invoice) => {
        payInvoice({
          id: invoice.id,
          region: region,
          ...payload,
        })
      })
    },
    [openInvoices, payInvoice]
  )

  return {
    handleCardChange,
    addPaymentMethod,
    handlePayInvoice,
    openChoosePaymentMethodModal,
    isSubscriptionUpdateLoading,
  }
}
