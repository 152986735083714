import React, { useMemo } from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { useIntl } from 'react-intl'
import styled from 'theme/styled'

export const RequestSubject: React.FC<{
  subject?: string
  id: string
}> = ({ subject, id }) => {
  const intl = useIntl()

  const { dynamicText, parsedText } = useMemo(() => {
    if (!subject) {
      return {
        dynamicText: intl.formatMessage({ id: 'REQUESTS_SUBJECT_FALLBACK' }),
        parsedText: '',
      }
    }

    const parsedSubject = subject.split(': ')

    const dynamicText = parsedSubject[0]
      .replace('undefined | ', '')
      .replace(' | undefined', '')

    if (parsedSubject.length < 2) {
      return { dynamicText, parsedText: '' }
    }

    let parsedText = parsedSubject[1]

    if (parsedText === id) {
      parsedText = ''
    }

    const timestampPattern = /\((0[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9] (AM|PM)\)/

    if (timestampPattern.test(parsedText)) {
      parsedText = parsedText.split(timestampPattern)[0].trim()
    }

    return {
      dynamicText,
      parsedText,
    }
  }, [id, intl, subject])

  return (
    <Wrapper>
      <TruncatedText variant="heading-medium" title={dynamicText}>
        {dynamicText}
      </TruncatedText>
      <TruncatedText variant="body-primary" title={parsedText}>
        {parsedText}
      </TruncatedText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const TruncatedText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
