import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalForm } from 'components/ModalForm'
import styled from 'theme/styled'

export const DeleteAccountWarning: React.FC = () => {
  return (
    <ModalForm style={{ height: '100%' }}>
      <Wrapper>
        <Descriptions>
          <TextWrapper>
            <FormattedMessage
              id="SETTINGS_SECURITY_DELETE_MODAL_DESC"
              values={{
                link_1: (
                  <a href="/" target="_blank" rel="noreferrer">
                    <FormattedMessage id="SETTINGS_SECURITY_DELETE_ACCOUNT_LINK" />
                  </a>
                ),
                link_2: (
                  <a
                    href="https://community.ui.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="SETTINGS_SECURITY_DELETE_COMMUNITY_LINK" />
                  </a>
                ),
                link_3: (
                  <a
                    href="https://unifi.ui.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="SETTINGS_SECURITY_DELETE_UNIFI_LINK" />
                  </a>
                ),
                link_4: (
                  <a
                    href="https://uisp.ui.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="SETTINGS_SECURITY_DELETE_UISP_LINK" />
                  </a>
                ),
              }}
            />
          </TextWrapper>
          <TextWrapper>
            <FormattedMessage id="SETTINGS_SECURITY_DELETE_MODAL_SURE" />
          </TextWrapper>
        </Descriptions>
      </Wrapper>
    </ModalForm>
  )
}

const Wrapper = styled.div`
  height: 100%;
`

const TextWrapper = styled.div`
  margin-bottom: 10px;
`

const Descriptions = styled.div`
  margin-top: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  text-align: left;
  line-height: 24px;
`
