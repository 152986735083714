import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import ConfirmationCode from '@ubnt/ui-components/ConfirmationCode/ConfirmationCode'
import { typography } from 'theme'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { useToasts } from './useToasts'
import { TOO_MANY_REQUESTS_CODE_MODAL_ID } from './TooManyRequestsModal'

interface Props {
  email?: string
}

const EmailVerifyCodeModal: React.FC<Props & ModalProps> = ({
  isOpen,
  onClose,
  email,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { profileData } = useSsoProfileQuery()
  const {
    updateProfile,
    resetUpdateProfile,
    isProfileUpdated,
    updateProfileError,
    isUpdateProfileLoading,
  } = useUpdateProfile()
  const [value, setValue] = useState<string>('')
  const { emailUpdatedSuccessToast } = useToasts()
  const { getActiveMfaAuthenticators } = useMFAAuthenticators()

  const authenticators = getActiveMfaAuthenticators()

  const errorMessage = useMemo(() => {
    if (!updateProfileError) return undefined
    if (updateProfileError.email) {
      // Error if there was a problem with the update profile (email) itself
      return intl.formatMessage({
        id: 'GENERIC_ERROR_MESSAGE_PLEASE_TRY_AGAIN',
      })
    }
    if (updateProfileError[0] === 'Email change request not found') {
      // Error if the code expired or the request was not correctly submited
      return intl.formatMessage({
        id: 'SETTINGS_PROFILE_CHANGE_EMAIL_REQUEST_ERROR',
      })
    }
    if (
      updateProfileError[0] === 'The email change code you entered is incorrect'
    ) {
      return intl.formatMessage({
        id: 'SETTINGS_PROFILE_CHANGE_EMAIL_TOKEN_ERROR',
      })
    }

    if (updateProfileError?.detail === 'Too many requests') {
      const emailChangeBan = Math.floor(Date.now() / 1000).toString()

      localStorage.setItem(
        `emailChangeBan-${profileData?.username}`,
        emailChangeBan
      )
      dispatch(setVisibleModal(TOO_MANY_REQUESTS_CODE_MODAL_ID))
      return
    }
  }, [dispatch, intl, profileData?.username, updateProfileError])

  const handleOnSubmit = useCallback(() => {
    if (value.length !== 6) return
    localStorage.removeItem(`emailChangeBan-${profileData?.username}`)
    updateProfile({
      email,
      email_change_verification_code: value,
    })
  }, [email, profileData?.username, updateProfile, value])

  const handleClose = () => {
    resetUpdateProfile()
    onClose?.()
  }

  useEffect(() => {
    if (value.length === 6) {
      handleOnSubmit()
    }
  }, [value, handleOnSubmit])

  useEffect(() => {
    if (
      isProfileUpdated &&
      authenticators.some((mfa) => mfa.type === 'email')
    ) {
      emailUpdatedSuccessToast()
    }
    if (isProfileUpdated) {
      dispatch(closeVisibleModal())
    }
  }, [authenticators, dispatch, emailUpdatedSuccessToast, isProfileUpdated])

  if (!email) return <></>

  const getLoader = () => {
    if (isUpdateProfileLoading) {
      return 'dots'
    }
    if (isProfileUpdated) {
      return 'success'
    }
    return
  }

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage id="SETTINGS_PROFILE_CHANGE_EMAIL" tagName="div" />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: handleClose,
          variant: 'default',
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CHANGE' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleOnSubmit,
          style: { outline: 'none' },
          disabled: value.length !== 6,
          loader: getLoader(),
        },
      ]}
    >
      <FormattedMessage
        id="SETTINGS_PROFILE_CHANGE_EMAIL_DESCRIPTION"
        values={{
          email,
        }}
        tagName="div"
      />
      <Wrapper>
        <div>
          <ConfirmationCode
            tokens={6}
            type="number"
            onChange={(changedToken: string) => {
              setValue(changedToken)
            }}
            className="tokenInput"
            value={value}
          />
          {errorMessage && <ValidationError>{errorMessage}</ValidationError>}
        </div>
      </Wrapper>
    </GenericModal>
  )
}

export const VERIFY_EMAIL_CODE_MODAL_ID = 'VERIFY_EMAIL_CODE_MODAL_ID'

export default () => (
  <ModalWrapper modalId={VERIFY_EMAIL_CODE_MODAL_ID}>
    <EmailVerifyCodeModal />
  </ModalWrapper>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`

const ValidationError = styled.div`
  position: relative;
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.red06};
  margin-top: -16px;
`
