import { IntlShape } from 'react-intl'
import { DAY_IN_SECONDS } from 'sharedConstants'
import { ActivityItem, ActivityItemLocation } from './module/types'

export const getFormattedTime = (
  timestamp: number,
  intl: IntlShape,
  shortVersion?: boolean
) => {
  const date = new Date(timestamp)
  const now = new Date()

  const minutesAgo = Math.floor((now.getTime() - date.getTime()) / 60000)

  if (minutesAgo < 1) {
    return intl.formatMessage({ id: 'COMMON_TIMES_LESS_THAN_A_MINUTE_AGO' })
  }

  if (minutesAgo === 1) {
    return intl.formatMessage({ id: 'COMMON_TIMES_ONE_MINUTE_AGO' })
  }

  if (minutesAgo < 60) {
    return `${minutesAgo} ${intl.formatMessage({
      id: 'COMMON_TIMES_MINUTES_AGO',
    })}`
  }

  if (date.toDateString() === now.toDateString()) {
    return `${intl.formatMessage({
      id: 'COMMON_TIMES_TODAY_AT',
    })} ${intl.formatTime(timestamp)}`
  }

  return `${intl.formatDate(timestamp, {
    year: 'numeric',
    month: shortVersion ? 'short' : 'long',
    day: 'numeric',
  })}, ${intl.formatTime(timestamp)}`
}

export const getTTLString = (sessionLength: number, intl: IntlShape) => {
  if (sessionLength === DAY_IN_SECONDS) {
    return intl.formatMessage({ id: 'ACTIVITY_TTL_ONE_DAY' })
  }

  const days = sessionLength / DAY_IN_SECONDS

  // Check if time is whole days
  if (days % 1 === 0) {
    return `${days} ${intl.formatMessage({ id: 'ACTIVITY_TTL_DAYS' })}`
  }

  return `${sessionLength} ${intl.formatMessage({
    id: 'ACTIVITY_TTL_SECONDS',
  })}`
}

export const getLocationString = (
  location: ActivityItemLocation,
  intl: IntlShape
) => {
  if (!location.countryCode) return

  if (intl.locale !== 'en') return `${location.countryCode}`

  if (location.city) return `${location.city}, ${location.countryCode}`
  if (location.state) return `${location.state}, ${location.countryCode}`

  return location.countryCode
}

export const formatUserAgent = (item: ActivityItem) => {
  if (!item.browser && !item.os) return ''

  if (item.browser && item.os) return `${item.browser}, ${item.os}`

  return item.browser || item.os
}
