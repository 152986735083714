import React, { useEffect } from 'react'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { useUpdateProfile } from 'store/mutations/ssoProfile/useUpdateProfile'
import styled from 'theme/styled'
import { DownloadUIVerify } from './DownloadUIVerify'
import { MultiFactorMethods } from './MultiFactorMethods'

const Wrapper = styled.div`
  margin-bottom: 16px;
`

export const MultiFactorContent: React.FC = () => {
  const { profileData, isProfileDataFetched } = useSsoProfileQuery()
  const { updateProfile, isUpdateProfileLoading } = useUpdateProfile()

  const { getPendingMfaAuthenticators, getActiveMfaAuthenticators } =
    useMFAAuthenticators()

  const hasPendingAutenticators = getPendingMfaAuthenticators().length
  const activeMfaAuthenticators = getActiveMfaAuthenticators()

  useEffect(() => {
    if (
      !profileData?.default_mfa &&
      activeMfaAuthenticators.length === 1 &&
      !isUpdateProfileLoading &&
      isProfileDataFetched
    ) {
      updateProfile({
        default_mfa: activeMfaAuthenticators[0].id,
      })
    }
  }, [
    activeMfaAuthenticators,
    isProfileDataFetched,
    isUpdateProfileLoading,
    profileData?.default_mfa,
    updateProfile,
  ])

  return (
    <>
      {activeMfaAuthenticators?.length ? (
        <MultiFactorMethods activeMFAAuthenticators={activeMfaAuthenticators} />
      ) : (
        <>
          {hasPendingAutenticators ? (
            <Wrapper>
              <MultiFactorMethods
                activeMFAAuthenticators={activeMfaAuthenticators}
              />
            </Wrapper>
          ) : null}
          <DownloadUIVerify />
        </>
      )}
    </>
  )
}
