import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { AxiosResponse, AxiosError } from 'axios'
import { MfaApi } from 'api/sso/mfa'
import { closeVisibleModal } from 'modules/modals'
import { MFAType } from 'modules/types'
import { QueryKey } from 'store/types'
import { parseSsoErrors } from 'store/utils'
import { SsoApiError } from 'types/ssoErrors'

const mfaApi = new MfaApi()

export const useMfaSms = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const sendSms = useMutation<AxiosResponse, AxiosError<SsoApiError>, string>({
    mutationFn: (id) => mfaApi.send({ type: MFAType.sms, id }),
  })

  const removeSms = useMutation<AxiosResponse, AxiosError<SsoApiError>, string>(
    {
      mutationFn: (id) => mfaApi.remove({ type: MFAType.sms, id }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.MFA_AUTHENTICATORS],
        })
        dispatch(closeVisibleModal())
      },
    }
  )
  return {
    sendSms: sendSms.mutate,
    sendSmsError: parseSsoErrors(sendSms.error),
    isSendSmsLoading: sendSms.isPending,
    resetSendSms: sendSms.reset,
    isSendSmsSuccess: sendSms.isSuccess,

    removeSms: removeSms.mutate,
    removeSmsError: parseSsoErrors(removeSms.error),
    isRemoveSmsLoading: removeSms.isPending,
    resetRemoveSms: removeSms.reset,
    isRemoveSmsSuccess: removeSms.isSuccess,
  }
}
