import React from 'react'
import { BubbleProps, FormSections } from 'features/support/unifi/types'
import { AdoptionTroubleBubble } from '../AdoptionTrouble'
import { ApplicationBubble } from '../Application'
import { AssistanceBubble } from '../Assistance'
import { ConsoleBubble } from '../Console'
import { DescriptionBubble } from '../Description'
import { DevicesBubble } from '../Devices'
import { InfoBubble } from '../Info'
import { ModelBubble } from '../Model'
import { TalkBubble } from '../Talk'
import { TechBubble } from '../Tech'
import { TroubleBubble } from '../Trouble'
import { UserTypeBubble } from '../UserType'

export const renderUniFiSection = (
  sectionID: FormSections,
  props: BubbleProps
): React.ReactElement => {
  const allSections: Record<FormSections, React.ReactElement> = {
    assistance: <AssistanceBubble key={sectionID} {...props} />,
    trouble: <TroubleBubble key={sectionID} {...props} />,
    device: <DevicesBubble key={sectionID} {...props} />,
    console: <ConsoleBubble key={sectionID} {...props} />,
    model: <ModelBubble key={sectionID} {...props} />,
    tech: <TechBubble key={sectionID} {...props} />,
    talkVip: <TalkBubble key={sectionID} {...props} />,
    app: <ApplicationBubble key={sectionID} {...props} />,
    adoptionTrouble: <AdoptionTroubleBubble key={sectionID} {...props} />,
    userType: <UserTypeBubble key={sectionID} {...props} />,
    description: <DescriptionBubble key={sectionID} {...props} />,
    info: <InfoBubble key={sectionID} {...props} />,
  }

  return allSections[sectionID] || <></>
}
