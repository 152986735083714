import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { MFAType } from 'modules/types'
import { GenericConfirmRemoveMFAModal } from '../RemoveModal/GenericConfirmRemoveMFAModal'

export const EMAIL_REMOVE_MODAL_ID = 'EMAIL_REMOVE_MODAL_ID'

const EmailRemoveModal: React.FC<
  ModalProps & WrappedComponentProps & { removeId?: string }
> = ({ intl, removeId, ...restProps }) => {
  return (
    <GenericConfirmRemoveMFAModal
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_EMAIL_REMOVE_MODAL_TITLE',
      })}
      removeId={removeId}
      type={MFAType.email}
      {...restProps}
    />
  )
}

const EmailRemoveModalConnected = injectIntl(EmailRemoveModal)

export const WrappedEmailRemoveModal = () => (
  <ModalWrapper modalId={EMAIL_REMOVE_MODAL_ID}>
    <EmailRemoveModalConnected />
  </ModalWrapper>
)
