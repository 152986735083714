import React from 'react'
import { connect } from 'react-redux'
import { closeVisibleModal, selectVisibleModal } from 'modules/modals'
import { RootState } from 'types/types'

export interface ModalProps {
  onClose?(): void
  isOpen?: boolean
}

interface Props {
  modalId: string
  onClose?(): void
  rootNodeSelector?(): HTMLElement
  isOpen?: boolean
  children: JSX.Element
}

const ModalWrapper: React.FC<Props> = (props) => {
  if (!props.isOpen) {
    return null
  }
  const { modalId, rootNodeSelector, children, ...restProps } = props
  const modalContent = React.cloneElement(
    React.Children.only(children),
    restProps
  )

  return <div data-role={modalId}>{modalContent}</div>
}

interface OwnProps {
  modalId: string
  children: JSX.Element
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const modalData = selectVisibleModal(state)
  const { modalId } = ownProps
  if (!modalData || !modalId) {
    return { isOpen: false, modalId }
  }

  const isOpen = modalData.visibleModal === modalId

  const modalProps = modalData.props
  const additionalProps = modalProps ? modalProps : {}

  return {
    modalId,
    isOpen,
    ...additionalProps,
  }
}

const mapDispatchToProps = {
  onClose: closeVisibleModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper)
