import { MFAAuthenticator, MFAType } from 'modules/types'
import {
  InitiateData,
  VerifyData,
  UpdateData,
  CustomPublicKeyCredentialLoginOptions,
} from 'types/mfa'
import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  postRequestWithCookies,
  putRequestWithCookies,
} from '../axios'

const {
  api: { sso, ssoUi },
} = __CONFIG__

export class MfaApi {
  public getAll = async () => {
    const url = new URL(sso.paths.mfa, ssoUi.base)
    const { data } = await fetchRequestWithCookies<{
      authenticators: MFAAuthenticator[]
    }>(url.href)
    return data
  }

  public initiate = async <T>({
    type,
    data,
  }: {
    type: MFAType
    data: InitiateData
  }) => {
    const url = new URL(`${sso.paths.mfa}/${type}`, ssoUi.base)
    const { data: response } = await postRequestWithCookies<InitiateData, T>(
      url.href,
      data
    )

    return response
  }

  public verify = async ({
    type,
    id,
    data,
  }: {
    type: MFAType
    id: string
    data: VerifyData
  }) => {
    const url = new URL(`${sso.paths.mfa}/${type}/${id}/verify`, ssoUi.base)
    return await postRequestWithCookies(url.href, data)
  }

  public send = async ({ type, id }: { type: MFAType; id?: string }) => {
    let url = new URL(`${sso.paths.mfa}/${type}/${id}/send`, ssoUi.base)
    if (!id) {
      url = new URL(`${sso.paths.mfa}/${type}/send`, ssoUi.base)
    }
    return await postRequestWithCookies(url.href)
  }

  public update = async ({
    type,
    id,
    data,
  }: {
    type: MFAType
    id: string
    data: UpdateData
  }) => {
    const url = new URL(`${sso.paths.mfa}/${type}/${id}`, ssoUi.base)
    return await putRequestWithCookies(url.href, data)
  }

  public remove = async ({ type, id }: { type: MFAType; id: string }) => {
    const url = new URL(`${sso.paths.mfa}/${type}/${id}`, ssoUi.base)
    return await deleteRequestWithCookies(url.href)
  }

  public webAuthnChallenge = async () => {
    const url = new URL(`${sso.paths.mfa}/webauthn/challenge`, ssoUi.base)
    const { data: response } = await postRequestWithCookies(url.href)
    return response
  }

  public webAuthnLogin = async (
    data: CustomPublicKeyCredentialLoginOptions
  ) => {
    const url = new URL(`${sso.paths.mfa}/webauthn/login`, ssoUi.base)
    const { data: response } = await postRequestWithCookies(url.href, {
      publicKeyCredential: data,
    })
    return response
  }

  public generateBackupCodes = async <T>() => {
    const url = new URL(sso.paths.twoFaBackup, ssoUi.base)
    const { data: response } = await postRequestWithCookies<undefined, T>(
      url.href
    )
    return response
  }

  public pollSetupPush = async ({
    id,
    signal,
  }: {
    id: string
    signal: AbortSignal
  }) => {
    const url = new URL(`${sso.paths.mfa}/push/${id}/poll-setup`, ssoUi.base)
    return await fetchRequestWithCookies(url.href, { signal })
  }

  public pollLoginPush = async (signal: AbortSignal) => {
    const url = new URL(`${sso.paths.mfa}/push/poll-login`, ssoUi.base)
    return await fetchRequestWithCookies(url.href, { signal })
  }
}
