import { useState } from 'react'
import { api } from 'api'
import { useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'

export const useVerifyUsername = () => {
  const intl = useIntl()
  const [verifyUsernameError, setVerifyUsernameError] = useState<string | null>(
    null
  )
  const [isVerifyUsernameLoading, setIsVerifyUsernameLoading] =
    useState<boolean>(false)

  const verifyUsername = useDebouncedCallback(async (username: string) => {
    setIsVerifyUsernameLoading(true)
    try {
      await api.checkUsername(username)
      setVerifyUsernameError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_USERNAME_TAKEN',
        })
      )
    } catch (e) {
      setVerifyUsernameError(null)
    }
    setIsVerifyUsernameLoading(false)
  }, 200)

  return { verifyUsername, isVerifyUsernameLoading, verifyUsernameError }
}
