import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import {
  OrganizationBillingInvoicesApi,
  PersonalBillingInvoicesApi,
} from 'api/billing/billingInvoices'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { useBillingInvoicesApi } from 'pages/subscriptions/hooks/useBillingInvoicesApi'
import { QueryKey } from '../types'
import { useBillingSubscriptionsQuery } from './useBillingSubscriptionsQuery'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'

export const useBillingUpcomingInvoicesQuery = (
  api?: PersonalBillingInvoicesApi | OrganizationBillingInvoicesApi
) => {
  const { subscriptionsWithUpcomingInvoices } = useBillingSubscriptionsQuery()
  const billingInvoicesApi = useBillingInvoicesApi()

  const billingApi = api ?? billingInvoicesApi

  const billingUpcomingInvoices = useQueries({
    queries:
      subscriptionsWithUpcomingInvoices?.map(({ id, region }) => {
        return {
          queryKey: [QueryKey.BILLING_UPCOMING_INVOICES, id],
          queryFn: () => billingApi.getUpcoming(id, region),
          staleTime: FIVE_MINUTES_IN_MILLISECONDS,
          retry: false,
          retryOnMount: false,
          refetchOnWindowFocus: false,
        }
      }) ?? [],
  })

  const upcomingInvoices: StripeInvoice[] = useMemo(() => {
    return billingUpcomingInvoices.flatMap(({ data }) => {
      if (!data) {
        return []
      }

      const upcomingInvoiceRegions = Object.keys(data) as StripeRegionCode[]

      if (!upcomingInvoiceRegions.length) {
        return []
      }

      return upcomingInvoiceRegions.map((region) => {
        return { ...data[region], region: region }
      })
    })
  }, [billingUpcomingInvoices])

  return {
    upcomingInvoices,
    areUpcomingInvoicesLoading: billingUpcomingInvoices.some(
      (query) => query.isFetching
    ),
  }
}
