import React, { useState } from 'react'
import { RadioList } from '@ubnt/ui-components'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import type Stripe from 'stripe'
import { space } from 'theme'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { getPaymentMethodIcon } from 'pages/payments/utils'
import { useSubscriptionPaymentMethods } from 'pages/subscriptions/hooks/useSubscriptionPaymentMethod'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { useBillingSubscriptionsQuery } from 'store/queries/useBillingSubscriptionsQuery'
import styled from 'theme/styled'

interface Props {
  addPaymentView?: boolean
  fullSubscription?: FullSubscription
}

const ChoosePaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, fullSubscription }) => {
  const { customerCards } = useBillingCardsQuery()
  const { areSubscriptionsRefetching } = useBillingSubscriptionsQuery()
  const {
    handleCardChange,
    handlePayInvoice,
    addPaymentMethod,
    isSubscriptionUpdateLoading,
  } = useSubscriptionPaymentMethods()
  const [selectedCard, setSelectedCard] = useState<
    Stripe.PaymentMethod | undefined
  >(() =>
    customerCards.find(({ id }) => {
      if (!fullSubscription?.default_payment_method) {
        return id === fullSubscription?.default_source
      }
      return id === fullSubscription?.default_payment_method
    })
  )
  const isLoading = areSubscriptionsRefetching || isSubscriptionUpdateLoading

  if (!fullSubscription) return null

  const {
    isSuspended,
    default_source,
    default_payment_method,
    region,
    status,
  } = fullSubscription

  const customDisabledStyle = { opacity: isLoading ? 0.3 : 1 }

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_PAYMENTS_MODAL_TITLE_EDIT" />}
      actions={[
        {
          text: intl.formatMessage({ id: 'SETTINGS_PAYMENTS_ADD_ACTION' }),
          onClick: () => addPaymentMethod(region),
          variant: 'secondary',
          disabled: isLoading,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_UPDATE' }),
          type: 'submit',
          variant: 'primary',
          onClick: () => {
            if (
              status === 'past_due' ||
              isSuspended ||
              (!default_source && !default_payment_method)
            ) {
              handlePayInvoice(fullSubscription, selectedCard?.id)
            }
            handleCardChange(selectedCard?.id ?? '', fullSubscription)
          },
          disabled:
            isLoading ||
            !selectedCard ||
            selectedCard.id === default_source ||
            selectedCard.id === default_payment_method,
          loader: isLoading ? 'spinner' : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      <StyledRadioList
        direction="row"
        items={customerCards
          .filter((card) => card.region === region)
          .map((card) => ({
            checked: card.id === selectedCard?.id,
            id: card.id,
            disabled: isLoading,
            style: { background: 'transparent' },
            children: (
              <FlexWrapper>
                <OptionContainer>
                  <StyledImg
                    src={getPaymentMethodIcon(card.card?.brand || '')}
                    style={customDisabledStyle}
                  />
                  <>**** {card.card?.last4}</>
                </OptionContainer>
                {(default_payment_method === card.id ||
                  default_source === card.id) && (
                  <SubText style={customDisabledStyle}>
                    <FormattedMessage id="COMMON_LABEL_CURRENT" />
                  </SubText>
                )}
              </FlexWrapper>
            ),
            onChange: () => setSelectedCard(card),
          }))}
        variant="boxed"
        full
      />
    </GenericModal>
  )
}

const ChoosePaymentMethodModalConnected = injectIntl(ChoosePaymentMethodForm)

export const CHOOSE_PAYMENT_METHOD_MODAL_ID = 'CHOOSE_PAYMENT_METHOD_MODAL_ID'

export default () => (
  <ModalWrapper modalId={CHOOSE_PAYMENT_METHOD_MODAL_ID}>
    <ChoosePaymentMethodModalConnected />
  </ModalWrapper>
)

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
`

const StyledImg = styled.img`
  height: 13px;
  margin-right: ${space['desktop-spacing-base-02']};
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const SubText = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
`

const StyledRadioList = styled(RadioList)`
  label {
    margin-right: 0px !important;
    height: 40px;
    display: flex;
    align-items: center;
  }
`
