import React from 'react'
import { Link } from '@ubnt/ui-components/aria/experimental'
import { DateTime } from 'luxon'
import { Schedule } from 'zchat-browser'
import { MessageContent } from './styles'
import { ChatContextType } from './types'

const { PROD_ZENDESK_PUBLIC_KEY } = __CONFIG__

export const initialiseConnection = (
  setWidgetState: ChatContextType['setWidgetState']
) => {
  window.zChat.init({
    // Change to sandbox to test locally
    account_key: PROD_ZENDESK_PUBLIC_KEY,
  })

  setWidgetState((prev) => ({ ...prev, isInitialised: true }))
}

export const parseInitialMessage = (text: string) => {
  const parts = text.split(' \n')

  const shouldHaveExtraSpace = parts.some(
    (part) =>
      part.startsWith('- https://secure-uploads') ||
      part.startsWith('- https://uos-support-files')
  )

  return (
    <>
      {parts.map((textPart) => {
        if (!textPart.length && !shouldHaveExtraSpace) {
          return null
        }

        if (!textPart.startsWith('- ')) {
          return <MessageContent>{textPart}</MessageContent>
        }

        const trimmedText = textPart.replace('- ', '')
        const shouldHaveLink =
          trimmedText.startsWith('https://secure-uploads') ||
          trimmedText.startsWith('https://uos-support-files')

        return (
          <MessageContent>
            <li>
              {shouldHaveLink ? (
                <Link href={trimmedText} target="blank">
                  Download
                </Link>
              ) : (
                trimmedText
              )}
            </li>
          </MessageContent>
        )
      })}
    </>
  )
}

// This type is needed because the Zendesk type definition has a type where account_schedule is spelled as acount_schedule
export type CorrectOperatingHours = { enabled: boolean; timezone: string } & (
  | { type: 'account'; account_schedule: Schedule }
  | { type: 'department'; department_schedule: { [id: number]: Schedule } }
)

export const getIsChatOpen = (
  operatingHours?: CorrectOperatingHours
): boolean | undefined => {
  if (!operatingHours || operatingHours.type !== 'account') {
    return
  }
  // Position 0 of the array is Sunday and position 6 Saturday according to https://api.zopim.com/web-sdk/#zchat-getoperatinghours
  const chatSchedule = operatingHours.account_schedule

  const currentDate = DateTime.now().setZone(operatingHours.timezone)

  const weekday = currentDate.weekday
  const hourInMinutes = currentDate.hour * 60
  const minutes = currentDate.minute

  const minutesOfTheDay = hourInMinutes + minutes

  if (weekday >= 1 && weekday < 7) {
    const daySchedule = chatSchedule[weekday as keyof Schedule][0]
    const isOpen =
      !!daySchedule &&
      minutesOfTheDay >= daySchedule.start &&
      minutesOfTheDay <= daySchedule.end
    return isOpen
  }
  const daySchedule = chatSchedule[0][0]
  return (
    !!daySchedule &&
    minutesOfTheDay >= daySchedule.start &&
    minutesOfTheDay <= daySchedule.end
  )
}
