import React from 'react'
import ArrowRightMicroIcon from '@ubnt/icons/ArrowRightMicroIcon'
import { Text } from '@ubnt/ui-components/aria'
import { Link } from 'react-router-dom'
import { FlexWrapper } from './SharedComponents'

interface Props {
  links: { url: string; label: React.ReactNode; onClick?: () => void }[]
  currentPage: React.ReactNode
}

export const Breadcrumbs: React.FC<Props> = ({ links, currentPage }) => {
  return (
    <FlexWrapper gap={4} alignItems="center">
      {links.map(({ url, label, onClick }, i) => (
        <FlexWrapper gap={4} alignItems="center" key={`breadcrumbs-${i}`}>
          <Link to={url} onClick={onClick}>
            <Text color="ublue-06" variant="body-primary">
              {label}
            </Text>
          </Link>
          <ArrowRightMicroIcon />
        </FlexWrapper>
      ))}
      <Text color="text-2" variant="body-primary">
        {currentPage}
      </Text>
    </FlexWrapper>
  )
}
