import React, { useCallback, useContext } from 'react'
import { ThreatManagementIcon } from '@ubnt/icons'
import { ToastContext } from '@ubnt/ui-components'
import { useIntl } from 'react-intl'
import StyledEntityToast from 'components/EntityToast'
import { MFAType } from 'modules/types'
import { methodTitle } from 'utils/mfa'

export const useMfaToasts = () => {
  const { createNotification } = useContext(ToastContext)

  const intl = useIntl()

  const createMfaSuccessToast = useCallback(
    (type: MFAType) => {
      createNotification(
        <StyledEntityToast
          icon={<ThreatManagementIcon />}
          stateIndicator="success"
          title={intl.formatMessage(
            { id: 'SETTINGS_MFA_TOAST' },
            { method: methodTitle(type) }
          )}
          duration={5000}
        />
      )
    },
    [createNotification, intl]
  )

  return { createMfaSuccessToast }
}
