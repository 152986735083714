import { useCallback, useContext, useEffect, useState } from 'react'
import { useMotif } from '@ubnt/ui-components'
import { useIntl } from 'react-intl'
import { motifPalette } from 'theme'
import DropzoneAttachment from 'assets/images/dropzoneAttachment.png'
import PlusIconDropzone from 'assets/images/PlusIconDropzone.png'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { isString } from 'utils/typeGuards'
import observeDOM from './observeDOM'
import { SSWidget } from './unifi/types'

const { SEND_SAFELY_DZ_ID, SEND_SAFELY_HOST } = __CONFIG__

export const useSendSafely = (
  placeholderDivId: string,
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  height = 200,
  isChat = false
) => {
  const { profileData } = useSsoProfileQuery()

  const [widget, setWidget] = useState<SSWidget | undefined>(undefined)
  const { isMaxMobileLarge } = useContext(MediaSizingContext)
  const intl = useIntl()
  const { motif } = useMotif()

  const dropzonePlaceholderText = intl.formatMessage({
    id: isMaxMobileLarge
      ? 'SETTINGS_SUPPORT_DROPZONE_MOBILE'
      : 'SETTINGS_SUPPORT_DROPZONE',
  })

  useEffect(() => {
    //jQuery is used because SendSafelyDropzone expect jQuery DOM Element to be passed as second arg.
    //jQuery library is included as cdn in index.html since it needs to be available globally for SendSafelyDropzone to work.
    const placeholderElement = $(`#${placeholderDivId}`)
    if (!widget && placeholderElement) {
      try {
        const newWidget = new window.SendSafelyDropzone(
          SEND_SAFELY_DZ_ID,
          placeholderElement
        )

        newWidget.url = SEND_SAFELY_HOST
        newWidget.disableAutoSubmit = true
        newWidget.DROPZONE_TEXT = isChat
          ? 'Add Attachments'
          : dropzonePlaceholderText
        newWidget.DROPZONE_TEXT_MOBILE = isChat ? '' : dropzonePlaceholderText
        newWidget.DROP_TEXT_COLOR = motifPalette[motif].neutral08
        newWidget.DROPZONE_STYLE = isChat
          ? `font-size: 14px; text-align: center; padding: 4px; display: flex; align-items: center;`
          : `font-size: 14px; text-align: center; padding: 16px; border-radius: 8px; height: ${height}px; display: flex; align-items: center;`
        newWidget.PROGRESS_STYLE = ''
        newWidget.FILE_ROW_STYLE = isChat
          ? `border: none; font-size: 12px; margin: 0px;`
          : `border: 1px solid ${motifPalette[motif].neutral03}; border-radius: 6px; font-size: 14px; margin: 0px; padding: 16px; overflow: hidden;`
        newWidget.logoPath = isChat ? DropzoneAttachment : PlusIconDropzone

        newWidget.initialize()
        setWidget(newWidget)
      } catch (e) {
        console.error(e)
      }
    }
  }, [dropzonePlaceholderText, placeholderDivId, widget, height, motif, isChat])

  const checkIfUploadingComplete = useCallback(() => {
    const placeholderElement = $(`#${placeholderDivId}`)
    const uploadedDocuments = placeholderElement.find('li')

    if (uploadedDocuments && uploadedDocuments.length > 0) {
      setIsUploading(true)

      const addedDocumentsArray = Array.from(uploadedDocuments)
      const uploadCompleted = addedDocumentsArray?.every((upload) =>
        upload?.innerHTML?.toLowerCase().includes('completed')
      )
      setIsUploading(!uploadCompleted)
    } else {
      setIsUploading(false)
    }
  }, [placeholderDivId, setIsUploading])

  useEffect(() => {
    if (placeholderDivId && widget) {
      observeDOM(placeholderDivId, checkIfUploadingComplete)
    }
  }, [checkIfUploadingComplete, placeholderDivId, widget])

  const finalizeSendSafelyUpload = useCallback(
    (cb: (url: string | undefined) => void, email: string | undefined) => {
      if (widget && widget.nbrOfFilesAttached > 0) {
        widget.setUnconfirmedSender(email)
        widget.finalizePackage(function (url) {
          cb(url)
        })
      } else {
        cb(undefined)
      }
    },
    [widget]
  )

  const uploadAttachments = () => {
    return new Promise((resolve, reject) => {
      try {
        finalizeSendSafelyUpload((url) => {
          resolve(url)
        }, profileData?.email)
      } catch (e) {
        reject('Problem uploading file.')
      }
    })
  }

  const handleSendSafelyUpload = async () => {
    let sendsafelyAttachmentUrl: string | undefined
    try {
      const uploadedFileUrl = await uploadAttachments()
      if (isString(uploadedFileUrl)) {
        sendsafelyAttachmentUrl = uploadedFileUrl
      }
    } catch (e) {
      sendsafelyAttachmentUrl = 'SEND_SAFELY_UPLOAD_FAILED'
      console.error(e)
    }
    return sendsafelyAttachmentUrl
  }

  const handleReinitialize = () => setWidget(undefined)

  return {
    handleSendSafelyUpload,
    handleReinitialize,
    hasUploads: !!widget?.nbrOfFilesAttached,
  }
}
