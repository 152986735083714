import React, { useEffect, useState } from 'react'
import Button from '@ubnt/ui-components/Button/Button'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'

interface Props {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  errors: {
    [key: string]: string
  }
  input?: 'prompt' | 'manual'
}

export const ResendButton: React.FC<Props> = ({
  handleClick,
  errors,
  input,
}) => {
  const [resendTimeLeft, setResendTimeLeft] = useState<number>(10)
  const [resendTimer, setResendTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()

  useEffect(() => {
    return () => resendTimer && clearTimeout(resendTimer)
  }, [resendTimer])

  useEffect(() => {
    if (resendTimeLeft > 0) {
      const timer = setTimeout(
        () => setResendTimeLeft(resendTimeLeft - 1),
        1000
      )
      setResendTimer(timer)
    }
  }, [resendTimeLeft])

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleClick(e)
    setResendTimeLeft(10)
  }

  useEffect(() => {
    if (input === 'manual' && resendTimeLeft <= 0) {
      resendTimer && clearTimeout(resendTimer)
      setResendTimeLeft(10)
    }
  }, [input, resendTimeLeft, resendTimer])

  return (
    <StyledButton
      variant="primary"
      onClick={onClick}
      disabled={resendTimeLeft > 0 || errors?.ERRCODE === 'BAD_REQUEST'}
    >
      {resendTimeLeft > 0 ? (
        <FormattedMessage
          id="SETTINGS_MFA_OPTIONS_RESEND_PUSH_TIMER"
          values={{ seconds: resendTimeLeft }}
        />
      ) : (
        <FormattedMessage id="SETTINGS_MFA_OPTIONS_RESEND_PUSH" />
      )}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  height: 40px;
  margin-top: 10px;
`
