import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { ActivityApi } from 'api/activity'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useActivityQuery = (api: ActivityApi = new ActivityApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const activity = useQuery({
    queryKey: [QueryKey.ACTIVITY],
    queryFn: api.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    activity: activity.data,
    isActivityFetched: activity.isFetched,
    error: activity.error,
    isLoading: activity.isFetching,
  }
}
