import React from 'react'
import { Loader } from '@ubnt/ui-components'
import { useIntl } from 'react-intl'
import { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import { GenericMFAModal } from '../GenericMFAModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const PASSKEY_LOADING_MODAL_ID = 'PASSKEY_LOADING_MODAL_ID'

interface Props extends ModalProps {
  isLoading?: boolean
}

export const PasskeyLoadingModal: React.FC<Props> = ({ isLoading }) => {
  const intl = useIntl()

  return (
    <GenericMFAModal
      modalId={PASSKEY_LOADING_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_TITLE',
      })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_DESCRIPTION',
      })}
      actions={[]}
      isLoading={isLoading}
      size="small"
      overrideFullScreen
    >
      <Wrapper>
        <Loader size="medium" />
      </Wrapper>
    </GenericMFAModal>
  )
}
