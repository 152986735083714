import React from 'react'
import { useIntl } from 'react-intl'
import { Button } from '@ubnt/ui-components'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { useDispatch } from 'react-redux'
import { closeVisibleModal } from 'modules/modals'
import { useMfaBackupCodes } from 'store/mutations/mfa/useMfaBackupCodes'
import styled from 'theme/styled'
import { GenericMFAModal } from '../GenericMFAModal'

export const BACKUP_CODES_FAILED_MODAL_ID = 'BACKUP_CODES_FAILED_MODAL_ID'

export const BackupCodesFailedModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { generateBackupCodesError, generateBackupCodes } = useMfaBackupCodes()

  return (
    <GenericMFAModal
      modalId={BACKUP_CODES_FAILED_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_SETUP_BACKUP_CODES_MODAL_TITLE',
      })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'secondary',
          onClick: () => dispatch(closeVisibleModal()),
        },
      ]}
    >
      <Wrapper>
        <ExclamationMarkIcon width={30} height={30} variant="fill" />
        <Description>
          {intl.formatMessage({
            id:
              generateBackupCodesError.details === '2fa token error'
                ? 'SETTINGS_MFA_BACKUP_CODE_INVALID_TOKEN'
                : 'SETTINGS_MFA_BACKUP_CODE_GENERIC_ERROR_MESSAGE',
          })}
        </Description>
        <Button
          variant="primary"
          onClick={() => {
            generateBackupCodes()
          }}
        >
          {intl.formatMessage({
            id: 'COMMON_ACTION_TRY_AGAIN',
          })}
        </Button>
      </Wrapper>
    </GenericMFAModal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const Description = styled.div`
  margin: 16px 0;
`
