import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AnnouncementsApi } from 'api/announcements'
import { QueryKey } from 'store/types'

export const useMarkAnnouncementAsRead = (
  api: AnnouncementsApi = new AnnouncementsApi()
) => {
  const queryClient = useQueryClient()

  const markAnnouncementAsRead = useMutation({
    mutationFn: api.markAsRead,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKey.ANNOUNCEMENTS] }),
  })

  return {
    markAnnouncementAsRead: markAnnouncementAsRead.mutate,
  }
}
