import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { LockTooltip } from 'pages/security/LockTooltip'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { withTooltip } from 'utils/withTooltip'
import { MFAHeaderActionButton } from './MFAHeaderActionButton'

const MAX_AMOUNT_OF_AUTHENTICATORS = 10

export const MultiFactorHeader: React.FC = () => {
  const intl = useIntl()
  const { profileData } = useSsoProfileQuery()
  const { allMfaAuthenticators } = useMFAAuthenticators()

  const hasReachedMaxAmountOfAuthenticators = useMemo(
    () => allMfaAuthenticators.length >= MAX_AMOUNT_OF_AUTHENTICATORS,
    [allMfaAuthenticators.length]
  )

  return (
    <Wrapper>
      <TextWrapper>
        <Text>
          {profileData?.twofa_enabled && <Circle isOn />}
          {intl.formatMessage({ id: 'SETTINGS_MFA_HEADER_TITLE' })}
        </Text>
        <Description isMFAEnabled={profileData?.twofa_enabled}>
          {intl.formatMessage({ id: 'SETTINGS_MFA_HEADER_DESCRIPTION' })}
          {intl.formatMessage({ id: 'SETTINGS_MFA_HEADER_EXTRA_DESCRIPTION' })}
        </Description>
      </TextWrapper>
      <ButtonSectionWrapper>
        <LockTooltip inline={true}>
          {withTooltip(
            {
              description: intl.formatMessage({
                id: 'SETTINGS_MFA_ADD_BACKUP_ACTION_LIMIT_REACHED_TOOLTIP_MESSAGE',
              }),
              portal: true,
            },
            <MFAHeaderActionButton
              disabled={hasReachedMaxAmountOfAuthenticators}
            />,
            hasReachedMaxAmountOfAuthenticators
          )}
        </LockTooltip>
      </ButtonSectionWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: ${(p) => p.theme.media.small}) {
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    padding-right: 0;
    padding-left: 0;
  }
`

const Text = styled.div`
  font-weight: bold;
  font: ${typography['desktop-typography-heading-large']};
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
`
const Description = styled.div<{ isMFAEnabled?: boolean }>`
  font: ${typography['desktop-typography-body']} !important;
  margin-top: 4px;
  color: ${({ theme }) => theme.text2};
  margin-left: ${({ isMFAEnabled }) => (isMFAEnabled ? '16px' : 0)};
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  max-width: calc(100% - 280px);
  @media (max-width: ${({ theme }) => theme.media.small}) {
    max-width: 100%;
  }
`

const Circle = styled.div<{ isOn?: boolean }>`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: ${({ isOn, theme }) => (isOn ? theme.green06 : '#B6B9C4')};
  border-radius: 50%;
  margin-right: 10px;
`

const ButtonSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.media.small}) {
    flex-direction: column-reverse;
    width: 100%;
  }
`
