import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert } from '@ubnt/ui-components/Alert'
import { History } from 'history'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import 'utils/inputAutoFillFix.css'
import { space, typography } from 'theme'
import { ShowOtherButton } from 'components/showOtherButton/ShowOtherButton'
import { HideOptions } from 'components/hideOptions/HideOptions'
import { Description } from 'components/Description'
import authorizedRedirect from 'components/authorizedRedirect'
import {
  getAssuranceLevel,
  selectAssuranceLevel,
  selectErrors as selectAssuranceLevelErrors,
} from 'features/auth/modules/assuranceLevel'
import {
  checkAuth,
  loginAuthUser,
  selectErrors,
} from 'features/auth/modules/auth'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import PublicPage from 'pages/PublicPage'
import {
  EmailCompletedModal,
  EmailInputEmailModal,
  EmailVerifyCodeModal,
} from 'pages/security/components/Email'
import { WrappedPasskeyFailedModal } from 'pages/security/components/Passkey/FailedModal'
import { PasskeyLoadingModal } from 'pages/security/components/Passkey/LoadingModal'
import { MFAOption } from 'pages/security/components/SetupMFAModal/MFAOption'
import { SMSCompletedModal } from 'pages/security/components/SMS/CompletedModal'
import {
  TOTPCompletedModal,
  TOTPQRCodeModal,
  TOTPSecretCodeModal,
  TOTPVerifyCodeModal,
} from 'pages/security/components/TOTP'
import { SUBMIT_PASSWORD_MODAL_ID } from 'pages/security/SubmitPasswordModal'
import { PushCompletedModal } from 'pages/security/components/Push/CompletedModal'
import { PushFailedModal } from 'pages/security/components/Push/FailedModal'
import { PushQRCodeModal } from 'pages/security/components/Push/QRCodeModal'
import { WrappedPushRemoveModal } from 'pages/security/components/Push/RemoveModal'
import { useMfaPush } from 'store/mutations/mfa/useMfaPush'
import { useMfaEmail } from 'store/mutations/mfa/useMfaEmail'
import { useMfaPasskey } from 'store/mutations/mfa/useMfaPasskey'
import { useMfaTotp } from 'store/mutations/mfa/useMfaTotp'
import styled from 'theme/styled'
import { useGetMFAOptions } from './useGetMFAOptions'
import AssuranceLevelTimer from './AssuranceLevelTimer'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  isNotVerified: boolean
  history: History
}

const SetMFAFirstLogin: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const mfaOptions = useGetMFAOptions()
  const { addEmailError, verifyEmailError } = useMfaEmail()
  const { addPushError, verifyPushError } = useMfaPush()
  const { addPasskeyError } = useMfaPasskey()
  const { addTotpError, verifyTotpError } = useMfaTotp()

  const [showOtherMethods, setShowOtherMethods] = useState(false)

  const assuranceLevel = useSelector(selectAssuranceLevel)
  const [isCaptchaLoading, setCaptchaLoading] = useState(false)
  const [assuranceCheckDone, setAssuranceCheckDone] = useState<boolean | null>(
    null
  )

  const generalErrors = useSelector(selectErrors)
  const assuranceLevelErrors = useSelector(selectAssuranceLevelErrors)

  const mfaErrors = useMemo(
    () => [
      addTotpError,
      verifyTotpError,
      addEmailError,
      verifyEmailError,
      addPushError,
      verifyPushError,
      addPasskeyError,
    ],
    [
      addEmailError,
      addTotpError,
      verifyEmailError,
      verifyTotpError,
      verifyPushError,
      addPushError,
      addPasskeyError,
    ]
  )

  useEffect(() => {
    dispatch(getAssuranceLevel())
    dispatch(loginAuthUser(window.location.search))
  }, [dispatch])

  const emailUpdatedItem = localStorage?.getItem('emailUpdated')
  const emailUpdated: boolean | undefined =
    emailUpdatedItem && JSON.parse(emailUpdatedItem)

  const redirect = useCallback(
    (pathname = '/') => {
      dispatch(closeVisibleModal())
      if (pathname === '/security/')
        dispatch(setVisibleModal(SUBMIT_PASSWORD_MODAL_ID))
      dispatch(checkAuth())
      props.history.push({ pathname })
    },
    [dispatch, props.history]
  )

  if (emailUpdated) {
    localStorage.removeItem('emailUpdated')
    redirect()
  }

  useEffect(() => {
    if (generalErrors || assuranceLevelErrors) {
      redirect()
    } else {
      mfaErrors?.find((error) => {
        if (typeof error === 'string' && error === 'is not allowed') {
          redirect('/login/')
        } else if (
          typeof error !== 'string' &&
          error?.ERRCODE &&
          error.ERRCODE === 'L2_REQUIRED'
        ) {
          redirect('/security/')
        }
      })
    }
  }, [mfaErrors, generalErrors, assuranceLevelErrors, redirect])

  useEffect(() => {
    if (assuranceLevel.isLoading === true) return setAssuranceCheckDone(false)
    if (assuranceCheckDone === false)
      setAssuranceCheckDone(!assuranceLevel.isLoading)
  }, [assuranceCheckDone, assuranceLevel.isLoading])

  useEffect(() => {
    if (
      assuranceCheckDone === true &&
      assuranceLevel?.data?.level &&
      assuranceLevel.data.level === 1
    ) {
      redirect('/security/')
      setAssuranceCheckDone(null)
    }
  }, [assuranceCheckDone, assuranceLevel, redirect])

  useEffect(() => {
    isCaptchaLoading &&
      // make sure that captcha stops loading in case the challenge window has been closed
      setTimeout(() => isCaptchaLoading && setCaptchaLoading(false), 1000)
  }, [isCaptchaLoading, setCaptchaLoading])

  return emailUpdated ? null : (
    <PublicPage>
      <Wrapper>
        <StyledDescription>
          {intl.formatMessage({ id: 'LOGIN_MFA_DESCRIPTION' })}
        </StyledDescription>
        <StyledAlert
          text={intl.formatMessage({ id: 'LOGIN_MFA_EMAIL_INFO' })}
        />
        {mfaOptions.featured}
        <HideOptions isShown={showOtherMethods}>
          {mfaOptions.other}
          <MFAOption
            description={intl.formatMessage({
              id: 'COMMON_ACTION_SKIP_FOR_NOW',
            })}
            onClick={() => redirect()}
          />
        </HideOptions>
        <ButtonWrapper>
          <ShowOtherButton
            handleClick={() => setShowOtherMethods(!showOtherMethods)}
            isOpen={showOtherMethods}
          />
        </ButtonWrapper>
      </Wrapper>
      <PasskeyLoadingModal />
      <WrappedPasskeyFailedModal />
      <TOTPQRCodeModal />
      <TOTPSecretCodeModal />
      <TOTPVerifyCodeModal />
      <TOTPCompletedModal />
      <SMSCompletedModal />
      <EmailInputEmailModal />
      <EmailCompletedModal />
      <EmailVerifyCodeModal />
      <PushQRCodeModal />
      <PushCompletedModal />
      <PushFailedModal />
      <WrappedPushRemoveModal />
      <AssuranceLevelTimer />
    </PublicPage>
  )
}

export default compose<React.FC<Record<string, unknown>>>(authorizedRedirect)(
  SetMFAFirstLogin
)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 36px;
`

const StyledDescription = styled(Description)`
  margin-bottom: 16px;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text3};
  padding: 0;
`

const ButtonWrapper = styled.div`
  text-align: left;
  margin-top: ${space['desktop-spacing-base-04']};
`

const StyledAlert = styled(Alert)`
  margin-bottom: 8px;
`
