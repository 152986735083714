import { useMfaPasskey } from 'store/mutations/mfa/useMfaPasskey'
import { useMfaEmail } from 'store/mutations/mfa/useMfaEmail'
import { useMfaPush } from 'store/mutations/mfa/useMfaPush'
import { useMfaSms } from 'store/mutations/mfa/useMfaSms'
import { useMfaTotp } from 'store/mutations/mfa/useMfaTotp'

export const useGetErrorMessage = () => {
  const { removeEmailError, resetRemoveEmail } = useMfaEmail()
  const { removePushError, resetRemovePush } = useMfaPush()
  const { removeSmsError, resetRemoveSms } = useMfaSms()
  const { removePasskeyError, resetRemovePasskey } = useMfaPasskey()

  const { removeTotpError, resetRemoveTotp } = useMfaTotp()

  const errorMessage =
    removeEmailError?.detail ||
    removeTotpError?.detail ||
    removePushError?.detail ||
    removeSmsError?.detail ||
    removePasskeyError?.detail ||
    null

  const resetErrors = () => {
    if (removeEmailError?.detail) {
      resetRemoveEmail()
    }
    if (removeTotpError?.detail) {
      resetRemoveTotp()
    }
    if (removePushError?.detail) {
      resetRemovePush()
    }
    if (removeSmsError?.detail) {
      resetRemoveSms()
    }
    if (removePasskeyError?.detail) {
      resetRemovePasskey()
    }
  }

  return {
    errorMessage,
    resetErrors,
  }
}
