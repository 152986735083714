import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'
import { Section } from 'components/Section.styles'
import styled from 'theme/styled'

export const SkeletonLoaders: React.FC = () => {
  return (
    <Section $omitMarginTop>
      <Container>
        <SkeletonLoader>
          <Table rowCounts={6} colCounts={1} rowHeight={32} />
        </SkeletonLoader>
      </Container>
    </Section>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  tbody {
    * {
      width: 100% !important;
    }
  }

  thead {
    * {
      width: 50% !important;
    }
  }
`
