import React from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ROUTES, Route } from 'types/routes'
import {
  EmptyStateContainer,
  EmptyStateContent,
  StyledExclamationMarkIcon,
  WarningIconContainer,
} from './Backups.styles'

export const BackupsErrorPlaceholder = () => {
  return (
    <EmptyStateContainer>
      <WarningIconContainer>
        <StyledExclamationMarkIcon variant="fill" />
      </WarningIconContainer>

      <EmptyStateContent>
        <FormattedMessage id="SETTINGS_BACKUPS_FETCH_ERROR" />
        <br />
        <FormattedMessage
          id="SETTINGS_BACKUPS_FETCH_ERROR_SUPPORT"
          values={{
            link: (
              <Link to={ROUTES[Route.SupportForm]}>
                <FormattedMessage id="SETTINGS_ADVANCED_CLOUD_CONSOLE_ERROR_CONTACT_SUPPORT" />
              </Link>
            ),
          }}
        />
      </EmptyStateContent>

      <Button variant="secondary" onClick={() => window.location.reload()}>
        <FormattedMessage id="COMMON_ACTION_REFRESH" />
      </Button>
    </EmptyStateContainer>
  )
}
