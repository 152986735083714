import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { UmrDeviceApi } from 'api/umrDevice'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

export const useUmrDeviceQuery = (api: UmrDeviceApi = new UmrDeviceApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const umrDevice = useQuery({
    queryKey: [QueryKey.UMR_DEVICE],
    queryFn: api.get,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return { umrDeviceInfo: umrDevice.data }
}
