import { validStripeCountryMap } from 'features/stripe/ui/regions'
import Yup from 'validators/yupLocaleConfig'

const VALIDATOR_CITY_MAX_LENGTH = 32
const VALIDATOR_STREET_ADDRESS_MAX_LENGTH = 64

const checkTaxIdPattern = (
  type: 'br_cnpj' | 'br_cpf',
  schema: Yup.StringSchema<string | undefined, Yup.AnyObject, undefined, ''>
) => {
  const pattern = validStripeCountryMap
    .get('br')
    ?.taxId?.find(({ value }) => value === type)?.taxFormat

  if (!pattern) {
    return schema.required('Something went wrong')
  }

  return schema.matches(pattern, 'Invalid tax id format')
}

const cardInformationValidationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required()
    .matches(/^complete$/, 'Your card number is invalid')
    .label('SETTINGS_PAYMENTS_LABEL_CARD_NUMBER'),
  cardExpiry: Yup.string()
    .required()
    .matches(/^complete$/, 'Your Exipration date is invalid')
    .label('SETTINGS_PAYMENTS_LABEL_EXPIRATION_DATE'),
  cardCvc: Yup.string()
    .required()
    .matches(/^complete$/, 'Your CVC is invalid')
    .label('SETTINGS_PAYMENTS_LABEL_SECURITY_CODE'),
})

export const defaultValidationSchema = Yup.object().shape({
  name: Yup.string().required().label('SETTINGS_PAYMENTS_NAME_ON_CARD'),
  country: Yup.string().required().label('COMMON_LABEL_COUNTRY'),
  line1: Yup.string()
    .required()
    .max(VALIDATOR_STREET_ADDRESS_MAX_LENGTH)
    .label('COMMON_LABEL_STREET_ADDRESS'),
  city: Yup.string()
    .required()
    .max(VALIDATOR_CITY_MAX_LENGTH)
    .label('COMMON_LABEL_CITY'),
  state: Yup.string().required().label('COMMON_LABEL_STATE'),
  postal_code: Yup.string().required().label('COMMON_LABEL_POSTAL_CODE'),
})

export const brazilValidationSchema = Yup.object().shape({
  name: Yup.string().required().label('SETTINGS_PAYMENTS_NAME_ON_CARD'),
  country: Yup.string().required().label('COMMON_LABEL_COUNTRY'),
  postal_code: Yup.string().required().label('COMMON_LABEL_POSTAL_CODE'),
  bill_addr_street: Yup.string()
    .required()
    .label('COMMON_LABEL_STREET_ADDRESS'),
  number: Yup.string().required().label('COMMON_LABEL_STREET_NUMBER'),
  neighborhood: Yup.string().required().label('COMMON_LABEL_NEIGHBORHOOD'),
  city: Yup.string().required().label('COMMON_LABEL_CITY'),
  state: Yup.string().min(2).max(2).label('COMMON_LABEL_STATE'),
})

const brazilTaxIdSchema = Yup.object().shape({
  taxIdType: Yup.mixed()
    .oneOf(['br_cnpj', 'br_cpf'])
    .required()
    .label('COMMON_LABEL_FIELD'),
  taxIdValue: Yup.string()
    .required()
    .when('taxIdType', {
      is: 'br_cpf',
      then: (schema) => checkTaxIdPattern('br_cpf', schema),
      otherwise: (schema) => checkTaxIdPattern('br_cnpj', schema),
    })
    .label('COMMON_LABEL_FIELD'),
})

export const defaultValidationSchemaWithCardInformation =
  defaultValidationSchema.concat(cardInformationValidationSchema)

export const brazilValidationSchemaWithCardInformation =
  brazilValidationSchema.concat(cardInformationValidationSchema)

export const brazilValidationSchemaWithCardInformationAndTaxId =
  brazilValidationSchemaWithCardInformation.concat(brazilTaxIdSchema)
