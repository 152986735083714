import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import type Stripe from 'stripe'
import {
  BillingCustomerApi,
  GlobalCustomerData,
} from 'api/billing/billingCustomer'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from '../constants'
import { QueryKey } from '../types'

type CustomerDefaults = {
  [key in StripeRegionCode]?: string | Stripe.CustomerSource
}

export const useBillingCustomerQuery = (
  api: BillingCustomerApi = new BillingCustomerApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const billingCustomer = useQuery({
    queryKey: [QueryKey.BILLING_CUSTOMER],
    queryFn: api.get,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const customerDefaultCards = useMemo(() => {
    if (!billingCustomer.data) return null
    const customerRegions = Object.keys(billingCustomer.data)

    if (!customerRegions.length) return null

    const userDefaults: CustomerDefaults = {}

    customerRegions.forEach(
      (region) =>
        (userDefaults[region as keyof CustomerDefaults] =
          billingCustomer.data[region as keyof GlobalCustomerData]?.stripeData
            ?.default_source ?? undefined)
    )

    return userDefaults
  }, [billingCustomer.data])

  return {
    billingCustomer: billingCustomer.data,
    customerDefaultCards,
    isBillingCustomerLoading: billingCustomer.isFetching,
  }
}
