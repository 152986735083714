import React, { useMemo } from 'react'
import { DownloadIcon } from '@ubnt/icons'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { Text } from '@ubnt/ui-components/Text'
import { useDispatch } from 'react-redux'
import { media } from 'theme'
import { BackupDetail } from 'api/nca/types'
import { setVisibleModal } from 'modules/modals'
import styled from 'theme/styled'
import { BackupsViewHeader } from './BackupsViewHeader'
import { ParsedBackup } from './types'
import { prepareData } from './utils'
import { PASSWORD_MODAL_ID } from '../SubmitPasswordModal'

type BackupsViewProps = {
  backups: BackupDetail[]
  deviceId: string
  isManageStateActive: boolean
  onClickCheckbox: (backup: ParsedBackup) => void
  onClickHeaderCheckbox: () => void
  selectedBackups: ParsedBackup[]
}

export const BackupsView: React.FC<BackupsViewProps> = ({
  backups,
  deviceId,
  isManageStateActive,
  onClickCheckbox,
  onClickHeaderCheckbox,
  selectedBackups,
}) => {
  const dispatch = useDispatch()

  const handleDownloadBackup = (item: ParsedBackup) => {
    dispatch(
      setVisibleModal(PASSWORD_MODAL_ID, { item: { ...item, deviceId } })
    )
  }

  const headerCheckboxState = useMemo(() => {
    if (!selectedBackups.length) {
      return false
    }
    if (selectedBackups.length !== backups.length) {
      return 'mixed'
    }
    return true
  }, [backups, selectedBackups.length])

  return (
    <StyledWrapper>
      <BackupsViewHeader
        isManageStateActive={isManageStateActive}
        deviceId={deviceId}
        onClickHeaderCheckbox={onClickHeaderCheckbox}
        checkboxState={headerCheckboxState}
      />
      {backups.map((backup) => {
        const parsedBackup = prepareData({ ...backup, deviceId })
        return (
          <BackupRow key={parsedBackup.id}>
            {isManageStateActive ? (
              <Checkbox
                id={parsedBackup.id}
                onChange={() => onClickCheckbox(parsedBackup)}
                checked={selectedBackups.some(
                  ({ id }) => id === parsedBackup.id
                )}
              >
                <BackupTime size="body">{parsedBackup.time}</BackupTime>
              </Checkbox>
            ) : (
              <BackupTime size="body">{parsedBackup.time}</BackupTime>
            )}
            <StyledDownloadIcon
              size="original"
              isActive
              onClick={() => handleDownloadBackup(parsedBackup)}
            />
          </BackupRow>
        )
      })}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.neutral00};
  border-radius: 4px;
  @media (max-width: ${media.mobileLarge}) {
    margin-left: 8px;
  }
`

const BackupRow = styled.div`
  padding: 6px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-top: 1px solid ${({ theme }) => theme.neutral03};
`

const BackupTime = styled(Text)`
  color: ${({ theme }) => theme.text2};
`

const StyledDownloadIcon = styled(DownloadIcon)`
  &:hover {
    cursor: pointer;
  }
`
