import React, { useContext, useEffect, useRef } from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { ChatContext } from './ChatContext'
import {
  Agent,
  Dots,
  InfoWrapper,
  LoaderWrapper,
  TextWrapper,
  TypingWrapper,
  UIAvatar,
} from './styles'

export const ExtraInfo: React.FC = () => {
  const {
    extraInfo: { isAgentTyping, queuePosition, agentName },
  } = useContext(ChatContext)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isAgentTyping) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isAgentTyping])

  if (!isAgentTyping && !queuePosition) {
    return null
  }

  if (queuePosition) {
    return (
      <InfoWrapper>
        <Text variant="caption">
          Your position in queue is: {queuePosition}
        </Text>
      </InfoWrapper>
    )
  }

  return (
    <TypingWrapper ref={ref}>
      <UIAvatar size={24} />
      <TextWrapper>
        {agentName && <Agent>{agentName}</Agent>}
        <LoaderWrapper>
          <Dots />
        </LoaderWrapper>
      </TextWrapper>
    </TypingWrapper>
  )
}
