import React, { useCallback, useEffect, useMemo } from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { BubbleContainer } from '@ubnt/ui-components/BubbleContainer'
import { Button } from '@ubnt/ui-components/Button'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import { isAxiosError } from 'axios'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  innerRedirect,
  outerRedirect,
  storedInnerRedirectKey,
  storedTrustDeviceRedirectKey,
} from 'features/redirect-after-register'
import PublicPage from 'pages/PublicPage'
import {
  getTrustedDeviceImage,
  getTrustedDeviceNameForDevicePage,
} from 'pages/security/components/MultiFactor/utils'
import { useAddTrustedDevice } from 'store/mutations/trustedDevices/useAddTrustedDevice'
import { useTrustDeviceQuery } from 'store/queries/useTrustDeviceQuery'
import { useTrustedDevicesQuery } from 'store/queries/useTrustedDevicesQuery'
import styled from 'theme/styled'
import { selectIsAuthenticated } from '../modules/auth'

export const TrustDevice = () => {
  const history = useHistory()
  const { currentTrustedDevice } = useTrustedDevicesQuery()
  const {
    trustDeviceInfo,
    isTrustDeviceInfoLoading,
    hasFetchedDeviceInfoOnce,
    trustDeviceInfoErrors,
  } = useTrustDeviceQuery()
  const { addTrustedDevice } = useAddTrustedDevice()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const navigate = useCallback(() => {
    localStorage.removeItem(storedTrustDeviceRedirectKey)
    // Sometimes due to a bug or the cache being cleared we don't get an innerRedirect, so we need
    // to check for it here to make sure the user does not get "stuck" on this page
    const storedInnerRedirectItem = localStorage?.getItem(
      storedInnerRedirectKey
    )
    if (outerRedirect()) {
      return <></>
    }
    if (storedInnerRedirectItem) {
      innerRedirect(history)
    } else {
      history.replace('/')
    }
  }, [history])

  useEffect(() => {
    if (
      isAxiosError(trustDeviceInfoErrors) &&
      !!trustDeviceInfoErrors.response
    ) {
      // For now if information on the device cannot be retrieved we skip the trust device step
      navigate()
    }
  }, [navigate, trustDeviceInfoErrors])

  const trustDevice = () => {
    if (!trustDeviceInfo) return
    const { device_id } = trustDeviceInfo
    addTrustedDevice({ device_id })
  }

  const deviceName = useMemo(() => {
    if (
      isTrustDeviceInfoLoading ||
      !hasFetchedDeviceInfoOnce ||
      !trustDeviceInfo
    ) {
      return undefined
    }
    const name = getTrustedDeviceNameForDevicePage({
      model: trustDeviceInfo.device_model,
      name: trustDeviceInfo.device_name,
      isUiProduct: !!trustDeviceInfo.simplified_agent?.is_ui_product,
    })
    const fallbackName = '-'
    const deviceName = name ?? fallbackName
    return deviceName
  }, [hasFetchedDeviceInfoOnce, isTrustDeviceInfoLoading, trustDeviceInfo])

  const deviceImageSrc = useMemo(() => {
    if (deviceName) {
      return getTrustedDeviceImage(deviceName)
    }
    return undefined
  }, [deviceName])

  useEffect(() => {
    if (currentTrustedDevice) {
      navigate()
    }
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [currentTrustedDevice, history, isAuthenticated, navigate])

  return (
    <PublicPage showLogo={false} formHeight="468px">
      <Wrapper>
        <Wrapper $smallGap>
          <Text variant="heading-large">
            <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_TITLE" />
          </Text>
          <Text variant="body-primary">
            <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_DESCRIPTION" />
          </Text>
        </Wrapper>
        <StyledBubbleContainer headerTitle={null} withBackground>
          <Device>
            <ImageContainer>
              {(isTrustDeviceInfoLoading || !hasFetchedDeviceInfoOnce) && (
                <SkeletonLoader>
                  <Block height="36px" width="100%" />
                </SkeletonLoader>
              )}
              {deviceImageSrc && (
                <DeviceImage src={deviceImageSrc} alt="Device" />
              )}
            </ImageContainer>
            <DeviceInfo>
              {(isTrustDeviceInfoLoading || !hasFetchedDeviceInfoOnce) && (
                <SkeletonLoader>
                  <Block height="20px" width="100%" />
                </SkeletonLoader>
              )}
              {deviceName && <Text variant="heading-medium">{deviceName}</Text>}
              <Text variant="caption">
                <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_MANAGE_INFO" />
              </Text>
            </DeviceInfo>
          </Device>
        </StyledBubbleContainer>
        <Wrapper $smallGap>
          <Button
            variant="primary"
            onClick={trustDevice}
            disabled={!trustDeviceInfo?.device_id || isTrustDeviceInfoLoading}
          >
            <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_BUTTON" />
          </Button>
          <Button onClick={navigate}>
            <FormattedMessage id="COMMON_ACTION_NOT_NOW" />
          </Button>
        </Wrapper>
      </Wrapper>
    </PublicPage>
  )
}

const Wrapper = styled.div<{ $smallGap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $smallGap }) => ($smallGap ? '16px' : '32px')};
`

const StyledBubbleContainer = styled(BubbleContainer)`
  padding: 16px 20px;
`

const Device = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 48px;
`

const DeviceImage = styled.img`
  width: 48px;
  object-fit: contain;
`

const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
