import { useEffect, useState } from 'react'
import { MFAType } from 'modules/types'
import { useMarkPasskeyPromptAsSeen } from 'store/mutations/account/useMarkPasskeyPromptAsSeen'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { usePasskeyAvailableQuery } from 'store/queries/usePasskeyAvailableQuery'
import { useShouldDisplayPasskeyBannerQuery } from 'store/queries/useShouldDisplayPasskeyBannerQuery'

export const useShowMfaBanner = () => {
  const [displayPasskeyBanner, setDisplayPasskeyBanner] = useState(false)
  const { isPasskeyAvailable } = usePasskeyAvailableQuery()
  const { shouldDisplayBanner } = useShouldDisplayPasskeyBannerQuery()
  const { markPasskeyPromptAsSeen } = useMarkPasskeyPromptAsSeen()

  const { getActiveMfaAuthenticators } = useMFAAuthenticators()

  const activeMfaAuthenticators = getActiveMfaAuthenticators()

  const onlyHasOneEmailMfa =
    activeMfaAuthenticators.length === 1 &&
    activeMfaAuthenticators[0].type === MFAType.email

  useEffect(() => {
    if (shouldDisplayBanner && isPasskeyAvailable && !onlyHasOneEmailMfa) {
      setDisplayPasskeyBanner(true)
    }
  }, [shouldDisplayBanner, isPasskeyAvailable, onlyHasOneEmailMfa])

  const handleCloseBanner = () => {
    markPasskeyPromptAsSeen()
    setDisplayPasskeyBanner(false)
  }

  return { displayPasskeyBanner, handleCloseBanner, onlyHasOneEmailMfa }
}
