import React from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'

type FooterProps = {
  isManageStateActive: boolean
  onClick: (isActive: boolean) => void
  onClickRemove: () => void
  hasSelectedBackups: boolean
}

export const Footer: React.FC<FooterProps> = ({
  onClick,
  onClickRemove,
  isManageStateActive,
  hasSelectedBackups,
}) => {
  if (isManageStateActive) {
    return (
      <Wrapper>
        <RemoveButton
          variant="tertiary"
          onClick={onClickRemove}
          disabled={!hasSelectedBackups}
        >
          <FormattedMessage id="COMMON_ACTION_REMOVE" />
        </RemoveButton>
        <Button variant="tertiary" onClick={() => onClick(false)}>
          <FormattedMessage id="COMMON_ACTION_DONE" />
        </Button>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Button variant="link" onClick={() => onClick(true)}>
        <FormattedMessage id="COMMON_ACTION_MANAGE" />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;

  padding: 16px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: linear-gradient(
    transparent,
    ${({ theme }) => theme.neutral01},
    ${({ theme }) => theme.neutral00}
  );
`

const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.red06};
`
