import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { checkAddress, handleCaughtAddressError } from '../../utils'
import { FormProps } from '../types'
import { BrazilCardAddressForm } from './BrazilCardAddressForm'
import { GenericCardAddressForm } from './GenericCardAddressForm'
import { UsCardAddressForm } from './UsCardAddressForm'

export const CardAddressForm: React.FC<FormProps> = (props) => {
  const { values, setAddressError } = props

  const [addressFocused, setAddressFocused] = useState(false)
  const intl = useIntl()

  const checkAddressDebounced = useDebouncedCallback(async () => {
    try {
      await checkAddress(values)
      setAddressError(null)
    } catch (error) {
      handleCaughtAddressError(error, intl, setAddressError)
    }
  }, 200)

  useEffect(() => {
    if (addressFocused) {
      checkAddressDebounced()
    }
  }, [checkAddressDebounced, addressFocused, values])

  if (values.country === 'BR') {
    return (
      <BrazilCardAddressForm {...props} setAddressFocused={setAddressFocused} />
    )
  }

  if (values.country === 'US') {
    return <UsCardAddressForm {...props} />
  }

  return <GenericCardAddressForm {...props} />
}
