import React from 'react'
import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'
import PasskeyIcon from '@ubnt/icons/PasskeyIcon'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { closeVisibleModal } from 'modules/modals'
import { useMfaPasskey } from 'store/mutations/mfa/useMfaPasskey'
import styled from 'theme/styled'
import { DomException } from 'types/enums'

export const PASSKEY_FAILED_MODAL_ID = 'PASSKEY_FAILED_MODAL_ID'

const getErrorMessage = (error?: unknown) => {
  if (
    error instanceof DOMException &&
    error.name === DomException.INVALID_STATE_ERROR
  ) {
    return 'SETTINGS_MFA_PASSKEY_FAILED_MODAL_DESCRIPTION_INVALID_STATE'
  }
  if (
    error instanceof DOMException &&
    (error.name === DomException.NOT_ALLOWED_ERROR ||
      // Firefox triggers a different error here
      error.name === DomException.ABORT_ERROR)
  ) {
    return 'SETTINGS_MFA_PASSKEY_FAILED_MODAL_DESCRIPTION_NOT_ALLOWED'
  }
  return 'SETTINGS_MFA_PASSKEY_FAILED_MODAL_DESCRIPTION_GENERIC'
}

const PasskeyFailedModal: React.FC<
  ModalProps & { error?: DOMException | null }
> = ({ error, isOpen, onClose }) => {
  const intl = useIntl()
  const { addPasskey } = useMfaPasskey()
  const dispatch = useDispatch()

  return (
    <GenericModal
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_TITLE',
      })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_TRY_AGAIN' }),
          variant: 'primary',
          onClick: () => addPasskey({}),
        },
      ]}
      size="small"
      overrideFullScreen
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Wrapper>
        <Container>
          <PasskeyIcon variant="twoTone" isActive size={32} />
          <WarningIcon variant="fill" size="original" />
        </Container>
        <Description>
          {intl.formatMessage({
            id: getErrorMessage(error),
          })}
        </Description>
      </Wrapper>
    </GenericModal>
  )
}

export const WrappedPasskeyFailedModal: React.FC = () => {
  return (
    <ModalWrapper modalId={PASSKEY_FAILED_MODAL_ID}>
      <PasskeyFailedModal />
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const Container = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.neutral04}`};
  border-radius: 8px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.neutral08};
  margin: 16px 0;
`

const WarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.orange06};
  position: absolute;
  bottom: -4px;
  right: -6px;
`
