import { parseDescription } from 'features/support/utils'
import { country, orderIssue } from '../config/ConfigOptions'
import { SupportFormState } from '../types'

const parseField = (type: 'country' | 'orderIssue', valueId?: string) => {
  if (type === 'country') {
    return country.find(({ id }) => id === valueId)?.label
  }

  return orderIssue.find(({ id }) => id === valueId)?.label
}

export const createSubject = ({
  country,
  orderIssue,
  description,
}: SupportFormState) => {
  const parsedCountry = parseField('country', country)
  const parsedIssue = parseField('orderIssue', orderIssue)

  const parsedDescription = parseDescription(description || '')

  if (parsedCountry && parsedIssue) {
    return `${parsedCountry} Store | ${parsedIssue}: ${parsedDescription}...`
  }

  if (parsedCountry && !parsedIssue) {
    return `${parsedCountry} Store: ${parsedDescription}...`
  }

  if (!parsedCountry && parsedIssue) {
    return `Store | ${parsedIssue}: ${parsedDescription}...`
  }

  return `${parsedDescription}...`
}
