import { useContext } from 'react'
import {
  OrganizationBillingInvoicesApi,
  PersonalBillingInvoicesApi,
} from 'api/billing/billingInvoices'
import { OrganizationContext } from 'core/contexts/OrganizationsContext'

export const useBillingInvoicesApi = () => {
  const { orgToFetch } = useContext(OrganizationContext)

  if (orgToFetch) {
    return new OrganizationBillingInvoicesApi(orgToFetch)
  }

  return new PersonalBillingInvoicesApi()
}
