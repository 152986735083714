import React, { useMemo, useState } from 'react'
import { FooterProps } from '@ubnt/ui-components/Modal/Footer'
import { useIntl } from 'react-intl'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { MFAType } from 'modules/types'
import { useMFAAuthenticators } from 'store/queries/useMfaAuthenticators'
import { useMfaEmail } from 'store/mutations/mfa/useMfaEmail'
import { useMfaPush } from 'store/mutations/mfa/useMfaPush'
import { useMfaSms } from 'store/mutations/mfa/useMfaSms'
import { useMfaPasskey } from 'store/mutations/mfa/useMfaPasskey'
import { useMfaTotp } from 'store/mutations/mfa/useMfaTotp'
import ModalContent from './ModalContent'
import { isActive } from '../Utils'

type Props = {
  title?: string
  isOpen?: boolean
  onClose?: () => void
  removeId?: string
  type: MFAType
}

export const GenericConfirmRemoveMFAModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  removeId = '',
  type,
}) => {
  const intl = useIntl()
  const { allMfaAuthenticators } = useMFAAuthenticators()
  const { removePasskey } = useMfaPasskey()
  const { removeEmail, isRemoveEmailLoading } = useMfaEmail()
  const { removePush, isRemovePushLoading } = useMfaPush()
  const { removeSms, isRemoveSmsLoading } = useMfaSms()
  const { removeTotp, isRemoveTotpLoading } = useMfaTotp()

  const userHasTwoMFAMethodsAndIsActive = useMemo(
    () =>
      allMfaAuthenticators.length === 2 &&
      isActive(removeId, allMfaAuthenticators),
    [allMfaAuthenticators, removeId]
  )

  const userWillOnlyHaveWebAuthnActive = useMemo(() => {
    const authenticatorsAfterRemove = allMfaAuthenticators.filter(
      (auth) => auth.id !== removeId && auth.status === 'active'
    )

    return authenticatorsAfterRemove.every(
      (auth) => auth.type === MFAType.webAuthn
    )
  }, [allMfaAuthenticators, removeId])

  const [checked, setChecked] = useState(false)

  const isEmail = type === MFAType.email

  const handleOnSubmit = () => {
    if (!removeId) {
      console.error('Missing id to remove')
      return
    }

    switch (type) {
      case MFAType.sms:
        return removeSms(removeId)
      case MFAType.totp:
        return removeTotp(removeId)
      case MFAType.webAuthn:
        return removePasskey(removeId)
      case MFAType.push:
        return removePush(removeId)
      default:
        return removeEmail(removeId)
    }
  }

  const isLoading =
    isRemoveTotpLoading ||
    isRemoveSmsLoading ||
    isRemovePushLoading ||
    isRemoveEmailLoading

  const handleCheckbox = () => setChecked(!checked)

  const actions: FooterProps['actions'] = [
    {
      text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
      variant: 'tertiary',
      onClick: onClose,
      tabIndex: 1,
    },
    {
      text: intl.formatMessage({
        id: 'COMMON_ACTION_REMOVE',
      }),
      onClick: handleOnSubmit,
      variant: 'danger',
      disabled: (isEmail && !checked) || isLoading,
      loader: isLoading ? 'dots' : undefined,
    },
  ]

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      title={title}
      onRequestClose={onClose}
      onAfterClose={() => setChecked(false)}
      actions={actions}
    >
      <ModalContent
        userHasTwoMFAMethodsAndIsActive={userHasTwoMFAMethodsAndIsActive}
        userWillOnlyHaveWebAuthnActive={userWillOnlyHaveWebAuthnActive}
        isMFATypeEmail={isEmail}
        checked={checked}
        handleCheckbox={handleCheckbox}
      />
    </GenericModal>
  )
}
