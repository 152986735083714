import { IntlShape } from 'react-intl'

export const getFormattedTime = (
  timestamp: Date,
  intl: IntlShape,
  shortVersion?: boolean
) => {
  const date = new Date(timestamp)
  const now = new Date()

  if (date.toDateString() === now.toDateString()) {
    return `${intl.formatMessage({
      id: 'COMMON_TIMES_TODAY_AT',
    })} ${intl.formatTime(timestamp)}`
  }

  return `${intl.formatDate(timestamp, {
    year: 'numeric',
    month: shortVersion ? 'short' : 'long',
    day: 'numeric',
  })}`
}

export const getFormattedCloseTicketMessage = (
  timestamp: Date,
  intl: IntlShape
) => {
  const date = new Date(timestamp)
  const now = new Date()

  if (date.toDateString() === now.toDateString()) {
    return intl.formatMessage(
      {
        id: 'REQUEST_CLOSED_TODAY',
      },
      { date: intl.formatTime(timestamp) }
    )
  }

  const closedDate = intl.formatDate(timestamp, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  return intl.formatMessage(
    {
      id: 'REQUEST_CLOSED_MESSAGE',
    },
    { date: closedDate }
  )
}
