import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import { DeleteAccountWarning } from './DeleteAccountWarning'
import { DeleteNotAllowedModalBody } from './DeleteNotAllowedModalBody'

type DeleteAccountModalBodyProps = {
  isLoading: boolean
  actionNotAllowed: boolean
}

export const DeleteAccountModalBody: React.FC<DeleteAccountModalBodyProps> = ({
  isLoading,
  actionNotAllowed,
}) => {
  if (isLoading) {
    return (
      <SkeletonLoader>
        <Text width={300} />
        <Text width={350} />
        <Text width={250} />
      </SkeletonLoader>
    )
  }

  if (actionNotAllowed) {
    return <DeleteNotAllowedModalBody />
  }

  return <DeleteAccountWarning />
}
