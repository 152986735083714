import React from 'react'
import { Section } from 'components/Section.styles'
import { useTrustedDevicesQuery } from 'store/queries/useTrustedDevicesQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import {
  EmailCompletedModal,
  EmailInputEmailModal,
  EmailVerifyCodeModal,
  WrappedEmailRemoveModal,
} from './components/Email'
import { BackupCodesFailedModal } from './components/BackupCodes/BackupCodesFailedModal'
import { BackupCodesModal } from './components/BackupCodes/BackupCodesModal'
import { BackupCodesPromptModal } from './components/BackupCodes/BackupCodesPromptModal'
import {
  MultiFactorContent,
  MultiFactorHeader,
  RecoveryCodes,
} from './components/MultiFactor'
import { WrappedEditMFANameModal } from './components/MultiFactor/EditMFANameModal'
import { TrustedDevices } from './components/MultiFactor/TrustedDevices'
import { WrappedPasskeyFailedModal } from './components/Passkey/FailedModal'
import { PasskeyLoadingModal } from './components/Passkey/LoadingModal'
import PasskeyPromptPrimaryModalWrapped from './components/Passkey/PromptPrimaryModal'
import { WrappedPasskeyRemoveModal } from './components/Passkey/RemoveModal'
import { SetupMFAModal } from './components/SetupMFAModal'
import { SMSCompletedModal } from './components/SMS/CompletedModal'
import { WrappedSMSRemoveModal } from './components/SMS/RemoveModal'
import {
  TOTPCompletedModal,
  TOTPQRCodeModal,
  TOTPSecretCodeModal,
  TOTPVerifyCodeModal,
  WrappedTOTPRemoveModal,
} from './components/TOTP'
import { WrappedSubmitPasswordModal } from './SubmitPasswordModal'
import { PushCompletedModal } from './components/Push/CompletedModal'
import { PushFailedModal } from './components/Push/FailedModal'
import { PushQRCodeModal } from './components/Push/QRCodeModal'
import { WrappedPushRemoveModal } from './components/Push/RemoveModal'

export const MFASection: React.FC = () => {
  const { profileData } = useSsoProfileQuery()

  const { hasTrustedDevices } = useTrustedDevicesQuery()

  return (
    <>
      <Section>
        <MultiFactorHeader />
        <MultiFactorContent />
        {profileData?.twofa_enabled ? <RecoveryCodes /> : null}
        {hasTrustedDevices ? <TrustedDevices /> : null}
      </Section>
      <SetupMFAModal />
      <PasskeyLoadingModal />
      <WrappedPasskeyFailedModal />
      <WrappedPasskeyRemoveModal />
      <TOTPQRCodeModal />
      <TOTPSecretCodeModal />
      <TOTPVerifyCodeModal />
      <TOTPCompletedModal />
      <WrappedTOTPRemoveModal />
      <SMSCompletedModal />
      <WrappedSMSRemoveModal />
      <BackupCodesModal />
      <BackupCodesPromptModal />
      <BackupCodesFailedModal />
      <EmailInputEmailModal />
      <EmailCompletedModal />
      <EmailVerifyCodeModal />
      <WrappedEmailRemoveModal />
      <PushQRCodeModal />
      <PushCompletedModal />
      <PushFailedModal />
      <WrappedPushRemoveModal />
      <WrappedSubmitPasswordModal />
      <WrappedEditMFANameModal />
      <PasskeyPromptPrimaryModalWrapped />
    </>
  )
}
