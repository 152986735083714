import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SessionsApi } from 'api/sessions'
import { QueryKey } from 'store/types'

export const useRevokeSession = (api: SessionsApi = new SessionsApi()) => {
  const queryClient = useQueryClient()

  const revokeSession = useMutation({
    mutationFn: api.revokeOne,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKey.SESSIONS] }),
  })

  return {
    revokeSession: revokeSession.mutate,
  }
}
