import { useIntl } from 'react-intl'
import { UcoreDevice } from 'api/nca/types'
import { SupportFormState } from 'features/support/unifi/types'
import { getFormattedMessage } from './chatUtils'
import { FormConfig } from '../config/types'

export const useFormatChatMessage = (config: FormConfig) => {
  const intl = useIntl()

  const formatChatMessage = (
    values: SupportFormState,
    visibleSections: string[],
    isSupportFileAllowed: boolean,
    selectedConsole?: UcoreDevice,
    sendsafelyAttachmentUrl?: string
  ) => {
    const consoleFWVersion =
      selectedConsole?.shadow?.state?.reported?.hardware?.firmwareVersion

    const consoleName = selectedConsole?.shadow?.state?.reported?.name

    const message = getFormattedMessage(
      config,
      values,
      visibleSections,
      intl,
      isSupportFileAllowed,
      consoleName,
      consoleFWVersion,
      sendsafelyAttachmentUrl
    )

    return message
  }

  return { formatChatMessage }
}
