import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_PAYMENT_VIEW_QUERY, ALL_KEY } from 'sharedConstants'
import { Section, SectionHeader } from 'components/Section.styles'
import { ADD_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/AddPaymentMethodModal'
import { CHANGE_REGION_MODAL_ID } from 'features/payment-methods/ui/ChangeRegionModal'
import { StripeRegionContext } from 'features/stripe/ui/Region'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import { selectVisibleModal, setVisibleModal } from 'modules/modals'
import { useCreateCard } from 'store/mutations/billingCards/useCreateCard'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { BrowserUtils } from 'utils/browserUtils'
import { CardFilter } from './CardFilter'
import { CardList } from './CardList'
import { TextWrap, TitleSection } from './Components.styles'

export const Cards = () => {
  const { isCardsLoading, customerCards, areCardsFetched } =
    useBillingCardsQuery()
  const { resetCreateCardErrors } = useCreateCard()
  const [filter, setFilter] = useState(ALL_KEY)
  const { region } = useContext(StripeRegionContext)
  const visibleModal = useSelector(selectVisibleModal)
  const stripeRegion = validStripeRegionMap.get(region)?.code
  const dispatch = useDispatch()

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter)
  }

  const isAddPaymentView = !!BrowserUtils.getQueryValue(
    window.location.toString(),
    ADD_PAYMENT_VIEW_QUERY
  )

  const handleAddPayment = useCallback(() => {
    if (!stripeRegion) {
      dispatch(
        setVisibleModal(CHANGE_REGION_MODAL_ID, {
          notSupported: true,
        })
      )
    }
    if (
      visibleModal.visibleModal !== 'ADD_PAYMENT_METHOD_MODAL_ID' &&
      stripeRegion
    ) {
      resetCreateCardErrors()
      dispatch(setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID))
    }
  }, [dispatch, resetCreateCardErrors, stripeRegion, visibleModal.visibleModal])

  useEffect(() => {
    if (
      isAddPaymentView &&
      visibleModal.visibleModal !== ADD_PAYMENT_METHOD_MODAL_ID
    ) {
      handleAddPayment()
    }
  }, [handleAddPayment, isAddPaymentView, visibleModal.visibleModal])

  const renderHeaderContent = () => {
    if (isCardsLoading || !areCardsFetched) {
      return null
    }
    if (!isCardsLoading && !customerCards.length && areCardsFetched) {
      return (
        <Button variant="link" onClick={handleAddPayment}>
          <FormattedMessage id="SETTINGS_PAYMENTS_MODAL_TITLE_ADD" />
        </Button>
      )
    }
    return <CardFilter onChange={handleFilterChange} filter={filter} />
  }

  const renderContent = () => {
    if (isCardsLoading || !areCardsFetched) {
      return (
        <SkeletonLoader>
          <Table rowCounts={4} colCounts={4} rowHeight={32} />
        </SkeletonLoader>
      )
    }
    if (customerCards.length > 0) {
      return <CardList handleAddPayment={handleAddPayment} filter={filter} />
    }
    return null
  }

  return (
    <Section $omitMarginTop>
      <TitleSection>
        <TextWrap>
          <SectionHeader>
            <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS" />
          </SectionHeader>
        </TextWrap>
        {renderHeaderContent()}
      </TitleSection>
      {renderContent()}
    </Section>
  )
}
