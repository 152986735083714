import { typography } from '@ubnt/ui-components/styles/designToken'
import ProfilePicture from 'features/profile-picture/ui/ProfilePicture'
import styled from 'theme/styled'

const ProfileWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  min-height: 50px;
  padding: 0 8px;
  margin: 0 16px 16px 16px;
`

const StyledAvatar = styled(ProfilePicture)`
  box-shadow: none;
  flex-shrink: 0;
  height: 50px;
  margin: 0;
  width: 50px;

  img {
    height: 100%;
    width: 100%;
  }
`

const TextContainer = styled.div``

const Title = styled.p`
  color: ${({ theme }) => theme.text1};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.media.large}) {
    max-width: 148px;
  }

  @media (max-width: ${({ theme }) => theme.media.mediumV2}) {
    max-width: calc(100vw - 120px);
  }
`

const Subtitle = styled.p`
  color: ${({ theme }) => theme.text3};
  font: ${typography['desktop-body']};
  margin: 0;
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.media.large}) {
    max-width: 148px;
  }

  @media (max-width: ${({ theme }) => theme.media.mediumV2}) {
    max-width: calc(100vw - 120px);
  }
`

export { ProfileWrapper, StyledAvatar, Title, Subtitle, TextContainer }
