import awaitHand from 'assets/images/pushHandAwait.png'
import deniedHand from 'assets/images/pushHandDenied.png'

export const getLoginPushDescription = (errors: { [key: string]: string }) => {
  switch (errors?.ERRCODE) {
    case 'MFA_PUSH_LOGIN_APPROVAL_DENIED':
      return `LOGIN_MFA_PUSH_APPROVAL_DENIED`
    case 'NOT_FOUND':
      return `LOGIN_MFA_PUSH_NOT_FOUND`
    case 'BAD_REQUEST':
      return `LOGIN_MFA_PUSH_BAD_REQUEST`
    default:
      return 'LOGIN_MFA_PUSH_DESCRIPTION_PROMPT'
  }
}

export const getLoginPushImageSrc = (errors: { [key: string]: string }) => {
  switch (errors?.ERRCODE) {
    case 'MFA_PUSH_LOGIN_APPROVAL_DENIED':
    case 'NOT_FOUND':
    case 'BAD_REQUEST':
      return deniedHand
    default:
      return awaitHand
  }
}

export const getLoginPushError = (errors: { [key: string]: string }) => {
  switch (errors?.ERRCODE) {
    case 'MFA_PUSH_LOGIN_APPROVAL_DENIED':
    case 'INVALID_CREDENTIAL':
      return 'MFA_PUSH_LOGIN_APPROVAL_DENIED'
    case 'BAD_REQUEST':
      return 'LOGIN_MFA_PUSH_BAD_REQUEST'
    default:
      return 'LOGIN_MFA_PUSH_DESCRIPTION_MANUAL'
  }
}
