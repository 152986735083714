import type { DeviceBackups } from 'api/nca/types'
import { formatMac } from 'utils/formatMac'
import { isActiveDevice, MixedDeviceBackups, SortState } from './types'

const KEY_LENGTH = 128

export const getSortingFunctionForBackupDevices = (sorting: SortState) => {
  switch (sorting) {
    case SortState.ASC_NAME:
      return (a: DeviceBackups, b: DeviceBackups) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    case SortState.DESC_NAME:
      return (a: DeviceBackups, b: DeviceBackups) =>
        b.name.localeCompare(a.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    default:
      return (a: DeviceBackups, b: DeviceBackups) =>
        b.latestBackupTimestamp - a.latestBackupTimestamp
  }
}

export const makePasswordHash = async (
  password: string,
  salt: string
): Promise<string> => {
  if (!password) throw new Error('Password is empty')
  if (!salt) throw new Error('Salt is empty')

  const enc = new TextEncoder()
  const passwordBuffer = enc.encode(password)
  const saltBuffer = enc.encode(salt)

  const passwordKey = await window.crypto.subtle.importKey(
    'raw',
    passwordBuffer,
    { name: 'PBKDF2' },
    false,
    ['deriveBits']
  )

  const derivedKey = await window.crypto.subtle.deriveBits(
    {
      name: 'PBKDF2',
      salt: saltBuffer,
      iterations: 100000,
      hash: 'SHA-512',
    },
    passwordKey,
    KEY_LENGTH
  )

  let hex = ''
  new Uint8Array(derivedKey).forEach((byte) => {
    hex += byte.toString(16).padStart(2, '0')
  })

  return hex
}

export const parseFileName = (encryptedFileName: string): string => {
  const encryptedFileNameFormat = /^.*\.tar\.crypted$/i
  if (encryptedFileNameFormat.test(encryptedFileName) === false) {
    throw new Error('Input filename string is invalid format')
  }

  return encryptedFileName.replace('.tar.crypted', '.unifi')
}

export const getMacLabel = (device: MixedDeviceBackups | null) => {
  if (device && isActiveDevice(device)) {
    return formatMac(device?.shadow?.state?.reported?.mac)
  }
  return '-'
}

export const getDeviceTypeLabel = (device: MixedDeviceBackups | null) => {
  if (device && isActiveDevice(device)) {
    return device?.shadow?.state?.reported?.hardware?.name ?? 'Uncategorized'
  }
  return 'Uncategorized'
}
