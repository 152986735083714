import React, { useState } from 'react'
import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'
import Input from '@ubnt/ui-components/Input/Input'
import Tooltip from '@ubnt/ui-components/Tooltip/Tooltip'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import { TaxIdState } from '../types'

type Props = {
  taxIdState: TaxIdState
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => void
  isTaxIdFormatValid: boolean
}

const TaxIdInput: React.FC<Props> = ({
  taxIdState,
  onChange,
  isTaxIdFormatValid,
}) => {
  const { value, placeholder } = taxIdState

  const [renderTooltip, setRenderTooltip] = useState(false)

  const isTaxIdInvalid =
    renderTooltip && !isTaxIdFormatValid && value.length > 0

  return (
    <Input
      onBlur={() => setRenderTooltip(true)}
      onFocus={() => setRenderTooltip(false)}
      variant="secondary"
      label={<FormattedMessage id="COMMON_LABEL_ID" />}
      placeholder={placeholder}
      value={value}
      full
      iconAfter={
        isTaxIdInvalid ? (
          <Tooltip
            description={
              <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS_TAX_ID_ERROR_TOOLTIP" />
            }
            isOpen
            portal
            offset={20}
            width={168}
          >
            <StyledExclamationMarkIcon
              variant="twoTone"
              isActive
              size="original"
            />
          </Tooltip>
        ) : null
      }
      onChange={onChange}
    />
  )
}

export default TaxIdInput

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.red06} !important;
  path:first-child {
    color: ${({ theme }) => theme.red01} !important;
  }
`
