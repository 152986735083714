import React from 'react'
import { CountryFlag } from 'components/CountryFlag'
import { CustomTaxId } from 'features/payment-methods/modules/types'
import { validStripeTaxIdCountries } from 'features/stripe/ui/regions'
import { StripeCountryDropdownOption } from 'features/stripe/ui/types'
import { RegionDropdownOption, TaxIdState } from '../types'

const STRIPE_TESTING_VALUES = {
  successful: '000000000',
  failing: '111111111',
  pending: '222222222',
}

export const disableAddOrUpdatedButton = (
  taxId: CustomTaxId | null | undefined,
  { value, country }: TaxIdState,
  isTaxIdFormatValid: boolean
) => {
  const isAddDisabled = !isTaxIdFormatValid
  const isUpdateDisabled =
    value === taxId?.value && country === taxId?.country?.toLowerCase()

  return isAddDisabled || isUpdateDisabled
}

const parseLabel = (
  { name, value }: StripeCountryDropdownOption,
  hasCountryMultipleTaxIdFormats?: boolean
) => {
  let parsedValue = name
  if (value === 'eu_vat' && hasCountryMultipleTaxIdFormats) {
    parsedValue += ' (EU)'
  }
  if (value === 'ch_uid' && hasCountryMultipleTaxIdFormats) {
    parsedValue += ' (HR)'
  }
  if (value === 'no_voec' && hasCountryMultipleTaxIdFormats) {
    parsedValue += ' (VOEC)'
  }
  if (value === 'br_cnpj' && hasCountryMultipleTaxIdFormats) {
    parsedValue += ' (CNPJ)'
  }
  if (value === 'br_cpf' && hasCountryMultipleTaxIdFormats) {
    parsedValue += ' (CPF)'
  }

  return parsedValue
}

const renderDropdownOption = (
  fieldsForDropdownOption: StripeCountryDropdownOption,
  hasCountryMultipleTaxIdFormats?: boolean
) => {
  const { code, value, taxFormat, taxPlaceholder, region } =
    fieldsForDropdownOption

  const parsedLabel = parseLabel(
    fieldsForDropdownOption,
    hasCountryMultipleTaxIdFormats
  )

  return {
    value: `${code}-${value}`,
    label: parsedLabel,
    image: <CountryFlag countryCode={code} size="small" />,
    type: value,
    taxFormat: taxFormat,
    taxPlaceholder: taxPlaceholder,
    country: code,
    region,
  }
}

export const countryDropdownOptions = validStripeTaxIdCountries
  .flatMap((country) => {
    if ('taxId' in country && country.taxId) {
      const { taxId, code, name, region } = country
      if (taxId.length > 1) {
        return taxId.map((tax) =>
          renderDropdownOption({ ...tax, code, name, region }, true)
        )
      }
      return renderDropdownOption({ ...taxId[0], code, name, region }, false)
    }
  })
  .filter((region) => region !== undefined) as RegionDropdownOption[]

export const getValidationRegex = (dropdownValue: string) => {
  return countryDropdownOptions.find((region) => region.value === dropdownValue)
    ?.taxFormat
}

export const deriveCountryFromValue = (
  value: TaxIdState['value'],
  type: TaxIdState['type']
): string => {
  return (
    countryDropdownOptions
      .filter((country) => country.type === type)
      .find((country) => country.taxFormat?.test(value))?.value || ''
  )
}

export const deriveRegionFromValue = (
  value: TaxIdState['value'],
  type: TaxIdState['type']
): string => {
  return (
    countryDropdownOptions
      .filter((country) => country.type === type)
      .find((country) => country.taxFormat?.test(value))?.region || ''
  )
}

const getIsValidForEmployee = (value: string) => {
  const { successful, failing, pending } = STRIPE_TESTING_VALUES
  return value === successful || value === failing || value === pending
}

export const assertIfTaxIdIsValid = (value: string, regex?: RegExp) => {
  const isTaxIdValid = !!regex?.test(value)
  const isValidForEmployee = isTaxIdValid || getIsValidForEmployee(value)
  return { isValidForEmployee, isTaxIdValid }
}
