import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import { TilesWrapper } from './Wrappers'

export const TilesLoader: React.FC<{ numberOfTiles: number }> = ({
  numberOfTiles,
}) => {
  return (
    <TilesWrapper>
      {Array.from(Array(numberOfTiles), (_item, index) => (
        <SkeletonLoader key={index}>
          <Block key={index} height="120px" width="100%" />
        </SkeletonLoader>
      ))}
    </TilesWrapper>
  )
}
