import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Alert from '@ubnt/ui-components/Alert/Alert'
import { Text } from '@ubnt/ui-components/aria'
import Button from '@ubnt/ui-components/Button/Button'
import designToken from '@ubnt/ui-components/styles/designToken'
import { FormikProps, Form as ImportedForm, withFormik } from 'formik'
import { History } from 'history'
import { isEmpty } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import { size, space } from 'theme'
import authorizedRedirect from 'components/authorizedRedirect'
import { Input } from 'components/Input'
import { useReCaptcha } from 'components/ReCaptcha'
import {
  login,
  loginAuthUser,
  selectErrors,
  selectIsAuthenticated,
  selectIsLoading,
  selectIsNotVerified,
  selectUnverifiedEmail,
} from 'features/auth/modules/auth'
import {
  resetApiErrors,
  selectErrors as selectResetApiErrors,
} from 'features/auth/modules/resetPassword'
import { CheckboxReCaptcha } from 'features/RecaptchaCheckbox/CheckboxReCaptcha'
import { SsoErrorCode } from 'features/RecaptchaCheckbox/types'
import DeleteAccountToast from 'pages/DeleteAccountToast'
import PublicPage from 'pages/PublicPage'
import styled from 'theme/styled'
import { RootState } from 'types/types'
import { BrowserUtils } from 'utils/browserUtils'
import Yup from 'validators/yupLocaleConfig'
import 'utils/inputAutoFillFix.css'
import {
  resendVerificationEmail,
  resetErrors,
  selectErrors as selectVerificationEmailErrors,
  selectIsCompleted as selectVerificationEmailIsCompleted,
  selectIsLoading as selectVerificationEmailIsLoading,
} from '../modules/resendVerification'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  apiErrors: ReturnType<typeof selectErrors>
  isNotVerified: boolean
  unverifiedEmail?: string
  handleSubmit: typeof login
  history: History
  location?: Location
  handleResendVerification: typeof resendVerificationEmail
  verificationEmailIsLoading?: boolean
  verificationEmailIsComplete?: boolean
  verificationEmailErrors?: ReturnType<typeof selectVerificationEmailErrors>
  resetResendVerificationEmailErrors: typeof resetErrors
}

interface FormValues {
  username?: string
  password?: string
  captcha?: string
  site_key?: string
}

const { GOOGLE_RECAPTCHA_SECRET_SCORE, GOOGLE_RECAPTCHA_SECRET_CHALLENGE } =
  __CONFIG__

const LoginForm: React.FC<
  RouteComponentProps & Props & FormikProps<FormValues>
> = ({
  isLoading,
  apiErrors,
  isNotVerified,
  errors,
  values,
  touched,
  handleChange,
  handleSubmit,
  setFieldValue,
  location,
  history,
  handleResendVerification,
  verificationEmailIsLoading,
  verificationEmailIsComplete,
  verificationEmailErrors,
  resetResendVerificationEmailErrors,
  unverifiedEmail,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const MS_UNTIL_SESSION_EXPIRED = 600000

  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [wasAnyChange, setWasAnyChange] = useState(false)
  const resetErrors = useSelector(selectResetApiErrors)
  const [isSuppressed, setIsSuppressed] = useState(false)

  useEffect(() => {
    if (Object.keys(resetErrors).length) {
      dispatch(resetApiErrors())
    }
  }, [dispatch, resetErrors])

  const isResetPasswordRedirect = BrowserUtils.getQueryValue(
    window.location.href,
    'passwordReset'
  )

  const checkBoxCaptchaIsVisible = useMemo(
    () =>
      verificationEmailErrors?.data?.error_code ===
      SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED,
    [verificationEmailErrors]
  )

  useEffect(() => {
    if (apiErrors?.detail === 'Invalid credential') {
      return setErrorMessage(
        intl.formatMessage({
          id: 'LOGIN_ERROR_CREDENTIALS',
        })
      )
    }

    if (apiErrors?.detail?.includes('Your email address has been suppressed')) {
      setIsSuppressed(true)
      return setErrorMessage(undefined)
    }

    if (apiErrors?.detail) {
      return setErrorMessage(apiErrors.detail)
    }

    if (
      !isEmpty(verificationEmailErrors) &&
      verificationEmailErrors?.data?.error_code !==
        SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED
    ) {
      return setErrorMessage(
        intl.formatMessage({
          id: 'COMMON_AUTH_ACTION_RESEND_VERFICATION_EMAIL_RESEND_GENERIC_ERROR',
        })
      )
    }

    if (apiErrors !== null && isEmpty(apiErrors)) {
      return setErrorMessage(intl.formatMessage({ id: 'LOGIN_FALLBACK_ERROR' }))
    }
  }, [apiErrors, intl, verificationEmailErrors])

  const locationState = useMemo(
    () => location.state as { isSessionExpired?: boolean },
    [location.state]
  )

  useEffect(() => {
    if (!wasAnyChange && locationState?.isSessionExpired) {
      setErrorMessage(intl.formatMessage({ id: 'LOGIN_SESSION_EXPIRED_ERROR' }))
    }
  }, [intl, locationState, wasAnyChange])

  const [isCaptchaLoading, setCaptchaLoading] = useState(false)

  useEffect(() => {
    if (verificationEmailIsComplete) {
      window.location.href = `/resend-verification/success${
        values.username?.includes('@')
          ? `#email=${encodeURIComponent(values.username)}`
          : ''
      }`
    }
  }, [values.username, verificationEmailIsComplete])

  useEffect(() => {
    !isNotVerified && setHideVerificationMessage(false)
  }, [isNotVerified])

  const { ref: recaptchaRef } = useReCaptcha()

  const [hideVerificationMessage, setHideVerificationMessage] = useState(false)

  const handleResendVerificationWithRecaptcha = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (
        (Object.keys(errors).length > 1 && !errors.captcha) ||
        !values.username
      ) {
        return
      }

      setCaptchaLoading(true)
      const token = await recaptchaRef?.executeAsync()
      setFieldValue('captcha', token)
      setFieldValue('site_key', GOOGLE_RECAPTCHA_SECRET_SCORE)
      setCaptchaLoading(false)
      if (token) {
        handleResendVerification({
          usernameOrEmail: values.username,
          captcha: token,
          site_key: GOOGLE_RECAPTCHA_SECRET_SCORE,
        })
      }
      return
    },
    [
      errors,
      setFieldValue,
      values.username,
      handleResendVerification,
      recaptchaRef,
    ]
  )

  const handleSubmitWithRecaptcha = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setHideVerificationMessage(false)

      if (
        (Object.keys(errors).length && !errors.captcha) ||
        !values.password ||
        !values.username
      ) {
        handleSubmit(e)
        return
      }

      handleSubmit()
      return
    },
    [errors, handleSubmit, values, setFieldValue] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleOnChange = (event: ChangeEvent) => {
    isNotVerified && setHideVerificationMessage(true)
    resetResendVerificationEmailErrors()
    setWasAnyChange(true)
    setErrorMessage(undefined)
    setIsSuppressed(false)
    handleChange(event)
  }

  useEffect(() => {
    dispatch(loginAuthUser(window.location.search))
  }, [dispatch])

  useEffect(() => {
    return () =>
      history.push({
        state: {
          isSessionExpired: false,
          sessionTimeout: Date.now() + MS_UNTIL_SESSION_EXPIRED,
        },
      })
  }, [history])

  useEffect(() => {
    isCaptchaLoading &&
      // make sure that captcha stops loading in case the challenge window has been closed
      setTimeout(() => isCaptchaLoading && setCaptchaLoading(false), 1000)
  }, [isCaptchaLoading, setCaptchaLoading])

  const handleCheckboxRecaptcha = (token: string) => {
    if (values?.username) {
      handleResendVerification({
        usernameOrEmail: values?.username,
        captcha: token,
        site_key: GOOGLE_RECAPTCHA_SECRET_CHALLENGE,
      })
    }
  }

  useEffect(() => {
    if (
      !values.username &&
      isNotVerified &&
      !hideVerificationMessage &&
      unverifiedEmail
    ) {
      setFieldValue('username', unverifiedEmail)
    }
  }, [
    hideVerificationMessage,
    isNotVerified,
    setFieldValue,
    unverifiedEmail,
    values.username,
  ])

  return (
    <PublicPage
      message={
        isResetPasswordRedirect
          ? intl.formatMessage({
              id: 'COMMON_SSO_LABEL_PASSWORD_RESET_REDIRECT',
            })
          : undefined
      }
    >
      <Form
        id="login-form"
        onSubmit={handleSubmitWithRecaptcha}
        autoComplete="on"
      >
        {isNotVerified && !hideVerificationMessage && (
          <>
            <Alert
              type="danger"
              text={
                <VerificationMessageContainer>
                  <FormattedMessage
                    id="COMMON_AUTH_ACTION_RESEND_VERFICATION_EMAIL_LOGIN_ERROR"
                    values={{
                      resendLink: (
                        <Button
                          variant="inline"
                          loader={
                            verificationEmailIsLoading ? 'dots' : undefined
                          }
                          onClick={handleResendVerificationWithRecaptcha}
                        >
                          {intl.formatMessage({
                            id: 'COMMON_AUTH_ACTION_RESEND_VERFICATION_EMAIL_LOGIN_ERROR_HERE',
                          })}
                        </Button>
                      ),
                    }}
                  />
                </VerificationMessageContainer>
              }
            />
            <AddedMargin />
          </>
        )}
        <StyledInput
          data-testid="username"
          type="text"
          value={values.username!}
          label={intl.formatMessage({
            id: 'COMMON_SSO_LABEL_EMAIL_OR_USERNAME',
          })}
          name="username"
          onChange={handleOnChange}
          invalid={
            (touched.username && errors.username) ||
            (typeof apiErrors?.user === 'string' && apiErrors.user) ||
            !!errorMessage ||
            !!isSuppressed
          }
          size="body"
          autoCapitalize="none"
          autoCorrect="off"
          full
          focusAfterLoading={isLoading || verificationEmailIsLoading}
          autoComplete="username"
          variant="secondary"
        />
        <StyledInput
          data-testid="password"
          type="password"
          value={values.password!}
          label={intl.formatMessage({ id: 'COMMON_SSO_LABEL_PASSWORD' })}
          name="password"
          onChange={handleOnChange}
          invalid={
            (touched.password && errors.password) ||
            errorMessage ||
            !!isSuppressed
          }
          size="body"
          full
          autoComplete="current-password"
          passwordToggle
          variant="secondary"
        />
        {!isResetPasswordRedirect && (
          <ForgotPasswordLink to="/reset-password">
            {intl.formatMessage({ id: 'COMMON_SSO_LABEL_FORGOT_PASSWORD' })}
          </ForgotPasswordLink>
        )}

        <LoginButton
          data-testid="loginButton"
          $isResetPasswordRedirect={!!isResetPasswordRedirect}
          disabled={
            isLoading ||
            !values.username ||
            !values.password ||
            isCaptchaLoading ||
            checkBoxCaptchaIsVisible
          }
          loader={isLoading || isCaptchaLoading ? 'dots' : undefined}
          size="medium"
          type="submit"
          variant="primary"
          full
        >
          {intl.formatMessage({ id: 'COMMON_AUTH_ACTION_SIGN_IN' })}
        </LoginButton>
        {checkBoxCaptchaIsVisible && values?.username && (
          <CheckboxReCaptcha onVerify={handleCheckboxRecaptcha} />
        )}
      </Form>
      {!isResetPasswordRedirect && (
        <CreateAccountWrapper>
          <CreateAccountText>
            {`${intl.formatMessage({
              id: 'COMMON_SSO_LABEL_NOT_A_MEMBER',
            })} `}
            <CreateAccountLink to="/register">
              {intl.formatMessage({
                id: 'COMMON_AUTH_ACTION_CREATE_ACCOUNT',
              })}
            </CreateAccountLink>
            {'.'}
          </CreateAccountText>
        </CreateAccountWrapper>
      )}
      {isSuppressed && (
        <Text variant="caption" color="red-06">
          {intl.formatMessage({
            id: 'LOGIN_ERROR_SUPPRESSED_EMAIL',
          })}
        </Text>
      )}
      <DeleteAccountToast />
    </PublicPage>
  )
}

const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleSubmit, history } }) =>
    handleSubmit(values, { history }),
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string()
      .required()
      .label('COMMON_SSO_LABEL_EMAIL_OR_USERNAME'),
    password: Yup.string().required().label('COMMON_LABEL_PASSWORD'),
    captcha: Yup.string().recaptcha(),
  }),
})

const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  isAuthenticated: selectIsAuthenticated(state),
  isLoading: selectIsLoading(state),
  isNotVerified: selectIsNotVerified(state),
  unverifiedEmail: selectUnverifiedEmail(state),
  verificationEmailIsComplete: selectVerificationEmailIsCompleted(state),
  verificationEmailIsLoading: selectVerificationEmailIsLoading(state),
  verificationEmailErrors: selectVerificationEmailErrors(state),
})

const mapDispatchToProps = {
  handleSubmit: login,
  handleResendVerification: resendVerificationEmail,
  resetResendVerificationEmailErrors: resetErrors,
}

export default compose<React.FC<Record<string, unknown>>>(
  connect(mapStateToProps, mapDispatchToProps),
  authorizedRedirect,
  enchance
)(LoginForm)

const ForgotPasswordLink = styled(Link)`
  width: fit-content;
  margin-bottom: ${space['desktop-spacing-base-06']};
  font: ${designToken['desktop-caption']};
  color: ${({ theme }) => theme.ublue06};
`

const CreateAccountLink = styled(Link)`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.ublue06};
`

const CreateAccountText = styled('p')`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  text-align: center;
`

const Form = styled(ImportedForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    margin-bottom: 0px;
  }
`

const StyledInput = styled(Input)`
  height: 58px;
`
const LoginButton = styled(Button, {
  shouldForwardProp: (props) => props !== '$isResetPasswordRedirect',
})<{ $isResetPasswordRedirect?: boolean }>`
  height: 40px;
  ${({ $isResetPasswordRedirect }) =>
    $isResetPasswordRedirect && 'margin-top: 24px'};
`

const CreateAccountWrapper = styled.div`
  width: 100%;
  height: ${size['desktop-sizing-base-09']};
  background-color: ${({ theme }) => theme.neutral02};
  border-radius: ${designToken['desktop-radius-base']};
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 8px;
`

const AddedMargin = styled.div`
  margin-bottom: 16px;
`

const VerificationMessageContainer = styled.div`
  font-size: 12px;
  line-height: 1.6;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
`
