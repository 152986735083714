import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import Tooltip from '@ubnt/ui-components/Tooltip/Tooltip'
import { GenericModal } from 'components/generic-modal/GenericModal'
import styled from 'theme/styled'

export const StyledModal = styled(GenericModal)`
  footer {
    padding: 16px 20px 20px 20px;
  }
`

export const InputWrapper = styled.div`
  padding-bottom: 10px;
`

export const MultipleInputsWrapper = styled(InputWrapper)`
  display: flex;
  gap: 8px;
`

export const Wrapper = styled.div`
  padding-top: 12px;
  text-align: left;

  .StripeElement {
    height: 32px;
  }
`

export const SectionWrapper = styled.div`
  margin-bottom: 32px;
`

export const StripeInputWrapper = styled.div`
  margin-bottom: 10px;
`

/* Stripe elements cannot use the theme since Stripe does not support 
hsla colour values and the UIC palette is built using them */
export const StyledCardNumberElement = styled(CardNumberElement)<{
  disabled?: boolean
  $invalid?: boolean
}>`
  height: 30px;
  border-radius: 4px;
  padding-left: 7px;
  padding-top: 7px;
  background-color: ${(p) =>
    p.disabled ? p.theme.neutral01 : p.theme.neutral02};
  &:hover {
    background-color: ${(p) =>
      p.disabled ? p.theme.neutral01 : p.theme.neutral03};
  }
  ${({ $invalid, theme }) => $invalid && `border: 1px solid ${theme.red06};`}
`
export const StyledCardExpiryElement = styled(CardExpiryElement)<{
  disabled?: boolean
  $invalid?: boolean
}>`
  height: 30px;
  border-radius: 4px;
  padding-left: 7px;
  padding-top: 7px;
  background-color: ${(p) =>
    p.disabled ? p.theme.neutral01 : p.theme.neutral02};
  &:hover {
    background-color: ${(p) =>
      p.disabled ? p.theme.neutral01 : p.theme.neutral03};
  }
  ${({ $invalid, theme }) => $invalid && `border: 1px solid ${theme.red06};`}
`
export const StyledCardCVCElement = styled(CardCvcElement)<{
  disabled?: boolean
  $invalid?: boolean
}>`
  height: 30px;
  border-radius: 4px;
  padding-left: 7px;
  padding-top: 7px;
  background-color: ${(p) =>
    p.disabled ? p.theme.neutral01 : p.theme.neutral02};
  &:hover {
    background-color: ${(p) =>
      p.disabled ? p.theme.neutral01 : p.theme.neutral03};
  }
  ${({ $invalid, theme }) => $invalid && `border: 1px solid ${theme.red06};`}
`

export const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text2};
  padding-bottom: 10px;
`

export const HalfFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
`

export const AddressResultsContainer = styled.div<{ visible: boolean }>`
  position: relative;
  width: 100%;
  display: ${(p) => (p.visible ? 'block' : 'none')};
`

export const AddressResultsInnerContainer = styled.div`
  position: absolute;
  z-index: 100;
  background-color: ${(p) => p.theme.neutral00};
  width: 100%;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px 0;
`

export const AddressResult = styled.div<{ active: boolean }>`
  font-size: 14px;
  font-family: Lato, sans-serif;
  color: ${({ theme }) => theme.text1};
  padding: 4px 8px;
  border: ${(p) =>
    p.active
      ? `1px solid ${p.theme.neutral03}`
      : `1px solid ${p.theme.neutral00}`};
  :hover {
    background-color: ${(p) => p.theme.neutral02};
    cursor: pointer;
  }
`

export const Country = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ disabled, theme }) =>
    `1px solid ${disabled ? theme.neutral02 : theme.ublue06}`};
  background: ${(p) => (p.disabled ? 'transparent' : p.theme.neutral02)};
  border-radius: 8px;
  padding: 12px;
  cursor: default;
  div {
    display: flex;
    align-items: center;
    .country-name {
      margin-left: 10px;
      color: ${({ disabled, theme }) => (disabled ? theme.text3 : theme.text1)};
    }
  }
`

export const StripeErrors = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: ${({ noPadding }) => (noPadding ? '0' : '8px')};
  line-height: 20px;
`

export const Label = styled.label<{
  focus?: boolean
  error?: boolean | undefined
}>`
  font-size: 12px;
  line-height: 22px;
  color: ${({ error, focus, theme }) => {
    if (error) return theme.red06
    if (focus) return theme.ublue06
    return theme.text3
  }};
`

export const ElementWrapper = styled.div<{
  hasError?: boolean
  focus?: boolean
}>`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.neutral02};
  border: solid 1px
    ${(p) =>
      p.hasError
        ? p.theme.red06
        : p.focus
          ? p.theme.ublue06
          : p.theme.neutral00};
  &:hover {
    background-color: ${({ theme }) => theme.neutral02};
    border: solid 1px
      ${(p) =>
        p.hasError
          ? p.theme.red06
          : p.focus
            ? p.theme.ublue06
            : p.theme.neutral01};
  }
`

export const CountryTooltip = styled(Tooltip)`
  .country-toolip {
    &::before {
      right: 38px !important;
    }
  }
`

export const RadioListWrapper = styled.div`
  padding-bottom: 10px;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
`
