import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { FormikProps, withFormik } from 'formik'
import { History } from 'history'
import Button from '@ubnt/ui-components/Button/Button'
import ButtonLink from '@ubnt/ui-components/Button/ButtonLink'
import { Link as ImportedLink } from 'react-router-dom'
import { size, space, typography } from 'theme'
import logo from 'assets/svgs/uiIconRoundedNew.svg'
import authorizedRedirect from 'components/authorizedRedirect'
import { TokenInput } from 'components/TokenInput'
import {
  AuthError,
  loginMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import styled from 'theme/styled'
import { LoginMFAData, RootState } from 'types/types'
import { AuthForm } from '../styles'
import { ResendButton } from './ResendButton'
import {
  getLoginPushDescription,
  getLoginPushError,
  getLoginPushImageSrc,
} from './utils'

interface Props {
  apiErrors?: AuthError
  handleLogin: typeof loginMFA
  history: History
  handleResend: () => void
  isLoading: boolean
  children: React.ReactNode
  // Due to the way our mfa forms are structured we can't use the useMfaPush hook here due to re-renders
  // that override the error states.
  loginPushCancel: () => void
  resetLoginPush: () => void
  loginPushError: {
    [key: string]: string
  }
  resetSendPush: () => void
  sendPushError: {
    [key: string]: string
  }
}

type FormValues = LoginMFAData
const LoginMFAPush: React.FC<Props & FormikProps<FormValues>> = ({
  children,
  handleResend,
  values,
  setFieldValue,
  submitForm,
  apiErrors,
  isLoading,
  loginPushCancel,
  resetLoginPush,
  loginPushError,
  resetSendPush,
  sendPushError,
}) => {
  const [input, setInput] = useState<'prompt' | 'manual'>('prompt')
  const [manualError, setManualError] = useState<undefined | string>()

  const pushErrors = loginPushError || sendPushError

  useEffect(() => {
    setInput('prompt')
    resetSendPush()
    resetLoginPush()
  }, [resetLoginPush, resetSendPush])

  useEffect(() => {
    return () => {
      loginPushCancel()
    }
  }, [loginPushCancel])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    loginPushCancel()
    handleResend()
  }

  const toggleInput = () => {
    if (input === 'manual') {
      loginPushCancel()
      handleResend()
    }
    setInput(input === 'prompt' ? 'manual' : 'prompt')
  }

  useEffect(() => {
    if (!manualError && apiErrors?.detail) {
      setManualError(apiErrors.detail)
    }
  }, [apiErrors?.detail, manualError])

  useEffect(() => {
    if (input === 'manual' && values.token.length === 6) {
      submitForm()
    }
  }, [input, submitForm, values.token])

  return (
    <>
      <AuthForm id="login-mfa-form" buttonAlign>
        {input === 'prompt' ? (
          <>
            <Description>
              <FormattedMessage id={getLoginPushDescription(pushErrors)} />
            </Description>
            <ImageWrapper>
              <img
                src={getLoginPushImageSrc(pushErrors)}
                alt="UI Verify app"
                draggable="false"
              />
            </ImageWrapper>
            <ResendButton
              handleClick={handleClick}
              input={input}
              errors={pushErrors}
            />
          </>
        ) : (
          <>
            <Link to="/">
              <UiLogo src={logo} />
            </Link>
            <Description style={{ marginBottom: '40px' }}>
              <FormattedMessage id={getLoginPushError(pushErrors)} />
            </Description>
            <TokenInput
              currentError={manualError}
              setCurrentError={setManualError}
              disabled={values.token.length === 6}
              setFieldValue={setFieldValue}
            />
            <StyledButton
              variant="primary"
              loader={isLoading ? 'dots' : undefined}
              disabled={values.token.length < 6}
            >
              <FormattedMessage id="COMMON_ACTION_CONFIRM" />
            </StyledButton>
          </>
        )}

        {children}
      </AuthForm>
      <StyledButtonLink onClick={toggleInput}>
        {input === 'prompt' ? (
          <FormattedMessage id="LOGIN_MFA_PUSH_MANUAL" />
        ) : (
          <FormattedMessage id="LOGIN_MFA_PUSH_PROMPT" />
        )}
      </StyledButtonLink>
    </>
  )
}
const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleLogin, history } }) =>
    handleLogin(values, { history }),
  mapPropsToValues: () => ({
    token: '',
  }),
})
const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  isLoading: selectIsLoading(state),
})
const mapDispatchToProps = {
  handleLogin: loginMFA,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(enchance(LoginMFAPush))
)

const ImageWrapper = styled.div`
  width: 144px;
  height: 266px;
  display: flex;
  justify-content: center;
  margin: ${space['desktop-spacing-base-06']} auto 0;
`

const Link = styled(ImportedLink)`
  display: flex;
  justify-content: center;
  margin-bottom: ${space['desktop-spacing-base-12']};
`

const UiLogo = styled.img`
  height: ${size['desktop-sizing-base-16']};
  width: ${size['desktop-sizing-base-16']};
`

const Description = styled.span`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
`

const StyledButtonLink = styled(ButtonLink)`
  margin: ${space['desktop-spacing-base-06']} 0
    ${space['desktop-spacing-base-02']};
  font: ${typography['desktop-body']} !important;
`

const StyledButton = styled(Button)`
  height: ${size['desktop-sizing-base-10']};
  margin-top: 10px;
`
