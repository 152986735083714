import { api } from 'api'
import { handle } from 'redux-pack'
import { ReduxAction, RootState } from 'types/types'

export const dataKey = 'authResendVerification'

export interface AuthResendVerificationState {
  errors: { [key: string]: any }
  isLoading: boolean
  isCompleted: boolean
}

const initialState: AuthResendVerificationState = {
  errors: {},
  isLoading: false,
  isCompleted: false,
}

const RESEND_VERIFICATION_EMAIL = `${dataKey}/RESEND_VERIFICATION_EMAIL`
export const resendVerificationEmail = (data: {
  usernameOrEmail: string
  captcha: string
  site_key: string
}) => ({
  type: RESEND_VERIFICATION_EMAIL,
  promise: api.resendVerificationEmail(data),
})

const RESET_ERRORS = `${dataKey}/RESET_ERRORS`
export const resetErrors = () => ({
  type: RESET_ERRORS,
})

export const selectIsCompleted = (state: RootState) =>
  state[dataKey].isCompleted

export const selectIsLoading = (state: RootState) => state[dataKey].isLoading

export const selectErrors = (state: RootState) => state[dataKey].errors

export const authResendVerificationReducer = (
  state = initialState,
  action: ReduxAction
) => {
  const { type } = action
  switch (type) {
    case RESEND_VERIFICATION_EMAIL: {
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          errors: {},
          isLoading: true,
          isCompleted: false,
        }),
        failure: (prevState) => ({
          ...prevState,
          errors: action.payload.response,
        }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        success: (prevState) => ({
          ...prevState,
          isCompleted: true,
          errors: {},
        }),
      })
    }
    case RESET_ERRORS: {
      return { ...initialState }
    }
    default:
      return state
  }
}
