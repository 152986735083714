import React from 'react'
import { CategoryBubble } from '../Category'
import { CloudHostnameBubble } from '../CloudHostname'
import { BubbleProps } from '../config/types'
import { DescriptionBubble } from '../Description'
import { ProductLineBubble } from '../ProductLine'

export const renderUispSection = (
  sectionID: string,
  props: BubbleProps
): React.ReactElement => {
  const section = {
    productLine: <ProductLineBubble key={sectionID} {...props} />,
    category: <CategoryBubble key={sectionID} {...props} />,
    cloudHostname: <CloudHostnameBubble key={sectionID} {...props} />,
    description: <DescriptionBubble key={sectionID} {...props} />,
  }[sectionID]

  return section ?? <></>
}
