import { useCallback, useContext, useEffect, useState } from 'react'
import { isBoolean, isEqual, uniqWith } from 'lodash-es'
import { ChatEventData, ConnectionUpdateEventData } from 'zchat-browser'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { ChatContext } from './ChatContext'
import { CorrectOperatingHours, getIsChatOpen } from './utils'

export const useGetChatHandlers = () => {
  const { profileData } = useSsoProfileQuery()
  const {
    initialMessage,
    sendUserMessage,
    setExtraInfo,
    extraInfo,
    setMessages,
    settings,
    setReview,
    widgetState,
    setWidgetState,
    isChatOpen,
    setIsChatOpen,
  } = useContext(ChatContext)

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(false)

  useEffect(() => {
    if (widgetState.isEnded) {
      setHasSentInitialMessage(false)
    }
  }, [setWidgetState, widgetState.isEnded])

  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      widgetState.isConnected &&
      initialMessage?.length
    ) {
      window.zChat.setVisitorInfo({
        display_name: profileData?.username,
        email: profileData?.email,
      })

      window.zChat.setVisitorDefaultDepartment(
        // Change to sandboxDepartmentId to test locally
        settings.departmentId,
        () => {
          sendUserMessage(initialMessage)
          window.zChat.removeTags(settings.tagsToRemove)
          // Adding tag here like this since it's *only* for audit purposes of the prod trials
          // should be removed completelly before final prod deployment
          window.zChat.addTags([...settings.tagsToAdd, 'new-chat-2025'])
          setHasSentInitialMessage(true)
        }
      )
    }
  }, [
    hasSentInitialMessage,
    initialMessage,
    profileData?.email,
    profileData?.username,
    sendUserMessage,
    settings.departmentId,
    settings.sandboxDepartmentId,
    settings.tagsToAdd,
    settings.tagsToRemove,
    widgetState.isConnected,
  ])

  const handleStatusUpdate = useCallback(
    (status: ConnectionUpdateEventData) => {
      if (status === 'connected') {
        if (!isBoolean(isChatOpen)) {
          const operatingHours =
            window.zChat.getOperatingHours() as CorrectOperatingHours
          const isOpen = getIsChatOpen(operatingHours)
          setIsChatOpen(isOpen)
        }
        if (!widgetState.isConnected) {
          setWidgetState((prev) => ({ ...prev, isConnected: true }))
        }
      }
      if (status === 'closed') {
        window.zChat.reconnect()
      }
    },
    [isChatOpen, widgetState.isConnected, setIsChatOpen, setWidgetState]
  )

  const handleChatEvents = useCallback(
    (data: ChatEventData) => {
      if (data.type === 'typing') {
        const agentNick = data.nick
        const agentsInChat = window.zChat.getServingAgentsInfo()
        const agentName = agentsInChat.find(
          (agent) => agent.nick === agentNick
        )?.display_name
        setExtraInfo((prev) => ({
          ...prev,
          isAgentTyping: data.typing,
          agentName,
        }))
      }
      if (data.type === 'chat.queue_position') {
        setExtraInfo((prev) => ({
          ...prev,
          queuePosition: data.queue_position,
        }))
      }
      if (data.type === 'chat.msg' || data.type === 'chat.file') {
        setMessages((prev) => uniqWith([...prev, data], isEqual))
      }

      if (data.type === 'chat.memberleave' && data.nick !== 'visitor') {
        const agents = window.zChat.getServingAgentsInfo()

        // It's important to look for queue position when departments change from the agents. If so, the queue position will be higher than 0.
        if (!agents.length && !extraInfo.queuePosition) {
          setReview((prev) => ({ ...prev, isOpen: true }))
        }
      }
    },
    [extraInfo.queuePosition, setExtraInfo, setMessages, setReview]
  )

  return {
    handleStatusUpdate,
    handleChatEvents,
  }
}
