import React, { useContext, useEffect, useState } from 'react'
import SendIcon from '@ubnt/icons/SendIcon'
import { useSendSafely } from 'features/support/useSendSafely'
import { Dropzone } from 'pages/supportForm/types'
import { ChatContext } from './ChatContext'
import { FileUpload } from './FileUpload'
import {
  FooterButtonWrapper,
  FooterContainer,
  FooterWrapper,
  MessageIconButton,
  NewMessageWrapper,
  StyledTextArea,
} from './styles'

const dropzoneId = Dropzone.CHAT

export const Footer: React.FC = () => {
  const { sendUserMessage, messages } = useContext(ChatContext)

  const [newMessage, setNewMessage] = useState('')
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const { handleSendSafelyUpload, handleReinitialize, hasUploads } =
    useSendSafely(dropzoneId, setIsAttachmentUploading, 20, true)

  const sendMessage = async () => {
    let attachmentUrl
    if (!isAttachmentUploading) {
      attachmentUrl = await handleSendSafelyUpload()
    }

    if (attachmentUrl) {
      sendUserMessage(`Attachments: ${attachmentUrl}`)
      handleReinitialize()
    }

    if (newMessage.length) {
      sendUserMessage(newMessage)
      setNewMessage('')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevents new line insertion
      sendMessage()
    }
  }

  useEffect(() => {
    const lastMessage = messages[messages.length - 1]
    if (isFocused && lastMessage.nick !== 'visitor') {
      window.zChat.markAsRead()
    }
  }, [isFocused, messages])

  return (
    <FooterWrapper>
      <FooterContainer>
        <NewMessageWrapper
          $isFocused={isFocused}
          onClick={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          <StyledTextArea
            value={newMessage}
            onChange={(_e, value) => setNewMessage(value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter message here"
            width="100%"
          />
          <FooterButtonWrapper>
            <MessageIconButton
              $isActive={hasUploads || !!newMessage.length}
              Icon={SendIcon({ variant: 'fill' })}
              onClick={sendMessage}
              type="button"
              size="medium"
            />
          </FooterButtonWrapper>
        </NewMessageWrapper>
      </FooterContainer>
      <FileUpload id={dropzoneId} />
    </FooterWrapper>
  )
}
