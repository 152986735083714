import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { api } from 'api'
import { FormikProps, withFormik } from 'formik'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { InputGroup } from 'components/form'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { Input, ValidationError } from 'components/Input'
import { ModalForm } from 'components/ModalForm'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { RootState } from 'types/types'
import Yup from 'validators/yupLocaleConfig'
import {
  requestEmailVerification,
  requestEmailVerificationReset,
  selectEmailRequestErrors,
  selectEmailRequestIsLoading,
} from './changeEmail'

interface Props extends ModalProps {
  requestEmailVerification: typeof requestEmailVerification
  emailRequestError: unknown
}

interface FormValues {
  email?: string
}

const ChangeEmailModal: React.FC<
  Props & FormikProps<FormValues> & WrappedComponentProps
> = ({
  isOpen,
  onClose,
  handleSubmit,
  intl,
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  emailRequestError,
}) => {
  const dispatch = useDispatch()
  const [emailChecking, setEmailChecking] = useState(false)
  const [emailError, setEmailError] = useState<string | null>(null)
  const emailRequestIsLoading = useSelector(selectEmailRequestIsLoading)
  const { profileData } = useSsoProfileQuery()

  const checkEmail = useDebouncedCallback(async () => {
    const validator = Yup.string().email()
    if (!values.email || !validator.isValidSync(values.email)) return
    setEmailChecking(true)
    try {
      await api.checkEmail(encodeURIComponent(values.email))
      // on successfull responses the email was found on our db, therefore it is not available and we display a error message
      setEmailError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_EMAIL_TAKEN',
        })
      )
      return false
    } catch (e) {
      // on error responses the email is available
      setEmailError(null)
      return true
    } finally {
      setEmailChecking(false)
    }
  }, 200)

  useEffect(() => {
    checkEmail()
  }, [checkEmail, values?.email])

  const isFormInvalid = emailChecking || emailRequestIsLoading || !!emailError

  const handleOnSubmit = (e: KeyboardEvent<HTMLFormElement>) => {
    if (isFormInvalid) {
      return e.preventDefault()
    } else {
      handleSubmit()
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_LABEL_CHANGE_EMAIL" />}
      size="small"
      onRequestClose={() => {
        dispatch(requestEmailVerificationReset())
        onClose?.()
      }}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          disabled: emailChecking || emailRequestIsLoading,
          onClick: () => {
            dispatch(requestEmailVerificationReset())
            onClose?.()
          },
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_CHANGE" />,
          variant: 'primary',
          disabled: isFormInvalid,
          type: 'submit',
          onClick: () => {
            handleSubmit()
          },
          loader: emailRequestIsLoading ? 'dots' : undefined,
        },
      ]}
    >
      <Wrapper>
        <StyledModalForm onSubmit={handleOnSubmit}>
          {profileData?.email && (
            <TextWrapper>
              <Label>
                <FormattedMessage id="COMMON_LABEL_CURRENT_EMAIL" />
              </Label>
              <Text>{profileData.email}</Text>
            </TextWrapper>
          )}

          <InputGroupStyled full>
            <Input
              name="email"
              type="email"
              variant="secondary"
              label={intl.formatMessage({
                id: 'COMMON_LABEL_NEW_EMAIL',
              })}
              value={values.email}
              invalid={
                (touched.email && errors.email) ||
                (!emailChecking && emailError) ||
                undefined
              }
              bottomMargin={false}
              onChange={(e: ChangeEvent) => {
                if (emailRequestError) dispatch(requestEmailVerificationReset())
                handleChange(e)
              }}
              onBlur={handleBlur}
              full
            />
          </InputGroupStyled>
        </StyledModalForm>
        {!!emailRequestError && (
          <ValidationError>
            <FormattedMessage id="GENERIC_ERROR_MESSAGE" />
          </ValidationError>
        )}
      </Wrapper>
    </GenericModal>
  )
}

const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { requestEmailVerification } }) =>
    values.email && requestEmailVerification(values.email),
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required().label('COMMON_LABEL_NEW_EMAIL'),
  }),
})

const ChangeEmailModalEnchanced = enchance(injectIntl(ChangeEmailModal))

export const CHANGE_EMAIL_MODAL_ID = 'CHANGE_EMAIL_MODAL_ID'

const mapStateToProps = (state: RootState) => ({
  emailRequestError: selectEmailRequestErrors(state),
})

const mapDispatchToProps = {
  requestEmailVerification,
}

const ModalToConnect: React.FC<{
  requestEmailVerification: typeof requestEmailVerification
  emailRequestError: unknown
}> = ({ requestEmailVerification, emailRequestError }) => (
  <ModalWrapper modalId={CHANGE_EMAIL_MODAL_ID}>
    <ChangeEmailModalEnchanced
      requestEmailVerification={requestEmailVerification}
      emailRequestError={emailRequestError}
    />
  </ModalWrapper>
)
export const WrappedChangeEmailModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalToConnect)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: left;
  min-width: 0;
`

const Label = styled.div`
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text3};
  height: 20px;
  display: flex;
  align-items: center;
`

const Text = styled.div`
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.text2};
  height: 32px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StyledModalForm = styled(ModalForm)`
  width: 100%;
`

const InputGroupStyled = styled(InputGroup)`
  span {
    top: 4px;
  }
`
