import { fetchRequestWithCookies } from 'api/axios'
import { ZendeskUserResponse } from './types'

const {
  api: { accountBE },
} = __CONFIG__

export class ZendeskUserApi {
  public get = async () => {
    const url = new URL(accountBE.paths.zendeskUser, accountBE.base)

    const { data } = await fetchRequestWithCookies<ZendeskUserResponse>(
      url.href
    )
    return data
  }
}
