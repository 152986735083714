import { orderBy } from 'lodash-es'
import { IntlShape } from 'react-intl'
import { UAParser } from 'ua-parser-js'
import androidLogo from 'assets/images/sessionDevices/android.png'
import tvLogo from 'assets/images/sessionDevices/appleTv.png'
import ipadLogo from 'assets/images/sessionDevices/ipad.png'
import iphoneLogo from 'assets/images/sessionDevices/iphone.png'
import macbookLogo from 'assets/images/sessionDevices/macbook.png'
import unifiLogo from 'assets/images/sessionDevices/unifi.png'
import windowsLogo from 'assets/images/sessionDevices/windows.png'
import { MFAAuthenticator, MFAType } from 'modules/types'
import { SortedDevice } from './TrustedDevice'

// we consider that if device_model has a length until 20, it means it's an actually device model and not a string that needs parsing
const DEVICE_MODEL_LENGTH = 20

type DeviceData = {
  model: string | null
  name: string | null
  isUiProduct: boolean
}

const getTrustedDeviceName = (deviceData: DeviceData) => {
  const { model, name, isUiProduct } = deviceData

  if (model && name) {
    // If we have both the device name and model available it means the name is user given and
    // therefore we should display it
    return name
  }

  if (model && model.length <= DEVICE_MODEL_LENGTH) {
    return model
  }

  const parsedModel = model ? UAParser(model) : null
  const browser = (parsedModel?.browser.name ?? '')
    .replace('Mobile ', '')
    .replace(' WebView', '')

  if (parsedModel?.device.vendor && parsedModel?.device.model) {
    const deviceModel = /mac/i.test(parsedModel.device.model)
      ? 'Macbook'
      : parsedModel.device.model

    const deviceBrand = parsedModel?.device.vendor

    const fullModelName =
      deviceBrand !== 'Apple' ? `${deviceBrand} ${deviceModel}` : deviceModel

    return browser ? { browser, fullModelName } : fullModelName
  }

  if (parsedModel?.os.name && browser) {
    return `${parsedModel.os.name} - ${browser}`
  }

  if (name && isUiProduct) {
    return name
  }

  return undefined
}

export const getTrustedDeviceNameForList = (deviceData: DeviceData) => {
  const deviceName = getTrustedDeviceName(deviceData)

  if (typeof deviceName === 'object') {
    return `${deviceName.fullModelName} - ${deviceName.browser}`
  }

  return deviceName
}

export const getTrustedDeviceNameForDevicePage = (deviceData: DeviceData) => {
  const deviceName = getTrustedDeviceName(deviceData)

  if (typeof deviceName === 'object') {
    return `${deviceName.browser} on ${deviceName.fullModelName}`
  }

  return deviceName
}

export const getTrustedDeviceImage = (deviceName: string, model?: string) => {
  if (/mac/i.test(deviceName)) {
    return macbookLogo
  }
  if (/windows/i.test(deviceName) || /linux/i.test(deviceName)) {
    return windowsLogo
  }
  if (/tv/i.test(deviceName)) {
    return tvLogo
  }
  if (/iphone/i.test(deviceName) || (model && /iphone/i.test(model))) {
    return iphoneLogo
  }
  if (/ipad/i.test(deviceName) || (model && /ipad/i.test(model))) {
    return ipadLogo
  }
  const devicesWithAndroidIconRegex =
    /\b(google|samsung|lg|motorola|nokia|android|xiaomi)\b/i
  if (
    devicesWithAndroidIconRegex.test(deviceName) ||
    (model && devicesWithAndroidIconRegex.test(model))
  ) {
    return androidLogo
  }

  return unifiLogo
}

export const getFormattedTime = (timestamp: number, intl: IntlShape) => {
  return `${intl.formatDate(timestamp, {
    dateStyle: 'short',
  })} at ${intl.formatTime(timestamp)}`
}

export enum DeviceSortingOptions {
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_desc',
}

export const sortDevices = (
  deviceList: SortedDevice[],
  sorting: DeviceSortingOptions
) => {
  if (sorting === DeviceSortingOptions.NAME_ASC) {
    return orderBy(deviceList, ['name'], ['asc'])
  }
  if (sorting === DeviceSortingOptions.NAME_DESC) {
    return orderBy(deviceList, ['name'], ['desc'])
  }
  if (sorting === DeviceSortingOptions.DATE_ASC) {
    return orderBy(deviceList, ['date'], ['asc'])
  }
  return orderBy(deviceList, ['date'], ['desc'])
}

export const shouldButtonBeDisabled = (
  authenticators: MFAAuthenticator[],
  type: MFAType,
  isActive: boolean
) => {
  const hasMoreThanOneEmailMfa =
    authenticators.filter((mfa) => mfa.type === MFAType.email).length > 1

  if (hasMoreThanOneEmailMfa) {
    return false
  }

  return authenticators.length < 3 && type === MFAType.email && isActive
}
