import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Bubble } from 'components/support/Bubble'
import { FormInput } from 'components/support/FormInput'
import { config } from './config/formConfig'
import { BubbleProps } from './config/types'

export const CloudHostnameBubble: React.FC<BubbleProps> = ({
  handleChange,
  shouldScroll,
  touched,
  errors,
  setFieldTouched,
  values,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.category])

  const onChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value?: string | number
  ) => {
    handleChange('cloudHostname', value?.toString(), 'description')
  }

  return (
    <Bubble question={config.cloudHostname.fieldTitle} ref={ref}>
      <FormInput
        size="body"
        variant="secondary"
        placeholder={intl.formatMessage({
          id: 'SUPPORT_DESCRIPTION_PLACEHOLDER',
        })}
        onChange={onChange}
        invalid={touched?.cloudHostname && errors?.cloudHostname}
        onBlur={() => setFieldTouched('cloudHostname', true)}
        value={values.cloudHostname}
      />
    </Bubble>
  )
}
