import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { SelfData, SsoProfileApi } from 'api/sso/profile'
import { useToasts } from 'features/profile-picture/ui/useToasts'
import { closeVisibleModal } from 'modules/modals'
import { QueryKey } from 'store/types'
import { parseSsoProfileErrors } from 'store/utils'
import { SsoApiError } from 'types/ssoErrors'

export const useUpdateProfile = (api: SsoProfileApi = new SsoProfileApi()) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { profilePictureUpdatedSuccessToast } = useToasts()

  const updateProfile = useMutation<
    SelfData,
    AxiosError<SsoApiError>,
    Partial<SelfData>
  >({
    mutationFn: api.update,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SSO_PROFILE],
      })
    },
  })

  const updateProfilePicture = useMutation<
    { picture: string },
    AxiosError<SsoApiError>,
    File
  >({
    mutationFn: api.updatePicture,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SSO_PROFILE],
      })
      profilePictureUpdatedSuccessToast()
      dispatch(closeVisibleModal())
    },
  })

  return {
    updateProfile: updateProfile.mutate,
    updateProfileError: parseSsoProfileErrors(updateProfile.error),
    isUpdateProfileLoading: updateProfile.isPending,
    resetUpdateProfile: updateProfile.reset,
    isProfileUpdated: updateProfile.isSuccess,

    updateProfilePicture: updateProfilePicture.mutate,
    updateProfilePictureError: parseSsoProfileErrors(
      updateProfilePicture.error
    ),
    isUpdateProfilePictureLoading: updateProfilePicture.isPending,
    resetUpdateProfilePicture: updateProfilePicture.reset,
  }
}
