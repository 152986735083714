import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SessionsApi } from 'api/sessions'
import { QueryKey } from 'store/types'

export const useRevokeAllNonCurrentSessions = (
  api: SessionsApi = new SessionsApi()
) => {
  const queryClient = useQueryClient()

  const revokeNonCurrentSessions = useMutation({
    mutationFn: api.revokeNonCurrent,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QueryKey.SESSIONS] }),
  })

  return {
    revokeNonCurrentSessions: revokeNonCurrentSessions.mutate,
  }
}
