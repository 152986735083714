import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PasskeyBannerApi } from 'api/passkeyBanner'
import { QueryKey } from 'store/types'

export const useMarkPasskeyPromptAsSeen = (
  api: PasskeyBannerApi = new PasskeyBannerApi()
) => {
  const queryClient = useQueryClient()

  const markPasskeyPromptAsSeen = useMutation({
    mutationFn: api.update,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SHOULD_DISPLAY_PASSKEY_BANNER],
      }),
  })

  return {
    markPasskeyPromptAsSeen: markPasskeyPromptAsSeen.mutate,
  }
}
