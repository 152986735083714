import React, { useCallback, useContext } from 'react'
import { QuoteIcon } from '@ubnt/icons'
import EntityToast from '@ubnt/ui-components/Toast/EntityToast'
import { ToastContext } from '@ubnt/ui-components/Toast/ToastContext'
import { useIntl } from 'react-intl'
import BackupCloud from 'assets/svgs/BackupCloud.svg?react'
import StyledEntityToast from 'components/EntityToast'
import { isString } from 'utils/typeGuards'

export const useToasts = () => {
  const toast = useContext(ToastContext)
  const intl = useIntl()

  const backupDownloadSuccessToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon={<QuoteIcon />}
        stateIndicator="success"
        title={intl.formatMessage({
          id: 'SETTINGS_BACKUPS_DOWNLOAD_SUCCESS',
        })}
        duration={5000}
        onClose={(_e, id: string | undefined) => {
          if (isString(id)) {
            toast.removeNotification(id)
          }
        }}
      />
    )
  }, [intl, toast])

  const backupsDeleteSuccess = useCallback(
    (deletedBackups: number) => {
      toast.createNotification(
        <StyledEntityToast
          id="profilePictureUpdated"
          icon={<BackupCloud />}
          stateIndicator="success"
          onClose={() => {
            toast.removeNotification('profilePictureUpdated')
          }}
          title={intl.formatMessage({
            id: 'COMMON_MESSAGE_SUCCESS',
          })}
          details={intl.formatMessage(
            { id: 'SETTINGS_BACKUPS_DELETE_BACKUPS_SUCCESS_COUNT' },
            {
              count: deletedBackups,
              backup: deletedBackups < 2 ? 'backup has' : 'backups have',
            }
          )}
          duration={5000}
        />
      )
    },
    [intl, toast]
  )

  return {
    backupDownloadSuccessToast,
    backupsDeleteSuccess,
  }
}
