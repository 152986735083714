import React, { useRef } from 'react'
import { FormikErrors } from 'formik'
import styled from 'theme/styled'
import { parseYear } from './utils'
import { ExpiryDateValueState } from '../modules/types'

type ExpiryDateProps = {
  value: ExpiryDateValueState
  onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>
  placeholder?: ExpiryDateValueState
  expiryDateError?: boolean
  setExpiryDateError?: React.Dispatch<React.SetStateAction<boolean>>
}

const MAX_INPUT_CHARS = 2
const MONTHS_IN_YEAR = 12
const MAX_YEAR = 99

const checkIfDateIsPast = ({
  expMonth,
  expYear,
}: {
  expMonth: string
  expYear: string
}): boolean => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  const parsedYear = parseInt(`20${expYear}`)
  const parsedMonth = parseInt(expMonth)

  const isPastYear = parsedYear < currentYear
  const isPastDate = parsedYear === currentYear && parsedMonth <= currentMonth

  return isPastYear || isPastDate
}

export const ExpiryDate: React.FC<ExpiryDateProps> = ({
  value,
  onChange,
  placeholder,
  expiryDateError,
  setExpiryDateError,
}) => {
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const handleMonthChange = (val: string) => {
    if (parseInt(val) > MONTHS_IN_YEAR) {
      return
    }
    if (val.length === MAX_INPUT_CHARS) {
      yearRef.current?.focus()
    }
    onChange('exp_month', val)
  }

  const handleYearChange = (val: string) => {
    const parsedVal = parseYear(val)

    if (parseInt(parsedVal) > MAX_YEAR) {
      return
    }

    if (parsedVal.length === MAX_INPUT_CHARS) {
      const isDateInPast = checkIfDateIsPast({
        expMonth: value.expMonth!,
        expYear: parsedVal,
      })
      setExpiryDateError?.(isDateInPast)
    }
    onChange('exp_year', parsedVal)
  }

  const handleKeyDown = ({ key }: React.KeyboardEvent) => {
    if (key === 'Backspace' && !value.expYear?.length) {
      monthRef.current?.focus()
    }
  }

  const handleFocus = () => {
    if (value.expMonth?.length === MAX_INPUT_CHARS) {
      yearRef.current?.focus()
    } else {
      monthRef.current?.focus()
    }
  }

  return (
    <Wrapper onClick={handleFocus}>
      <NumericInput
        type="number"
        ref={monthRef}
        value={value.expMonth}
        onChange={(e) => {
          handleMonthChange(e.target.value)
        }}
        placeholder={placeholder?.expMonth}
        $isInvalid={!!expiryDateError}
      />
      <Divider $isInvalid={!!expiryDateError}> / </Divider>
      <NumericInput
        type="number"
        ref={yearRef}
        value={parseYear(value.expYear)}
        onChange={(e) => handleYearChange(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder?.expYear}
        $isInvalid={!!expiryDateError}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.neutral01};
  padding: 0 8px;

  &:has(input:focus) {
    outline: 1px solid ${({ theme }) => theme.ublue06};
  }

  &:hover {
    background-color: ${({ theme }) => theme.neutral02};
    cursor: text;
  }
`

const NumericInput = styled.input<{
  $isInvalid: boolean
}>`
  width: 24px;
  border: none;
  background-color: inherit;
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }

  // this resets native iOS input styling
  padding-inline-start: 0;
  padding-inline-end: 0;

  color: ${({ theme }) => `${theme.text1} !important`};

  ${({ $isInvalid, theme }) =>
    $isInvalid &&
    `
      color: ${theme.red06} !important;
    `}
`

const Divider = styled.span<{
  $isInvalid: boolean
}>`
  padding-right: 4px;

  ${({ $isInvalid, theme }) =>
    $isInvalid &&
    `
      color: ${theme.red06} !important;
    `}
`
