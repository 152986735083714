import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { Loader } from '@ubnt/ui-components/Loader'
import { ValidationMessage } from '@ubnt/ui-components/ValidationMessage'
import { isAxiosError } from 'axios'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { typography } from 'theme'
import authorizedRedirect from 'components/authorizedRedirect'
import { Input, ValidationError } from 'components/Input'
import { useReCaptcha } from 'components/ReCaptcha'
import { CheckboxReCaptcha } from 'features/RecaptchaCheckbox/CheckboxReCaptcha'
import { SsoErrorCode } from 'features/RecaptchaCheckbox/types'
import PublicPage from 'pages/PublicPage'
import { useCreateAccount } from 'store/mutations/ssoAccount/useCreateAccount'
import { useLegalQuery } from 'store/queries/useLegalQuery'
import styled from 'theme/styled'
import { PasswordFields } from '../PasswordFields'
import {
  RegisterFormSchema,
  registerFormValidationSchema,
} from './registerFormSchema'
import { useVerifyEmail } from './useVerifyEmail'
import { useVerifyPassword } from './useVerifyPassword'
import { useVerifyUsername } from './useVerifyUsername'

const { GOOGLE_RECAPTCHA_SECRET_SCORE, GOOGLE_RECAPTCHA_SECRET_CHALLENGE } =
  __CONFIG__

const RegistrationForm = () => {
  const intl = useIntl()
  const { ref: recaptchaRef } = useReCaptcha()
  const { legalInfo, isLegalInfoLoading } = useLegalQuery()
  const { createAccount, createAccountError, isCreateAccountLoading } =
    useCreateAccount()
  const { verifyUsername, verifyUsernameError, isVerifyUsernameLoading } =
    useVerifyUsername()
  const { verifyEmail, verifyEmailError, isVerifyEmailLoading } =
    useVerifyEmail()
  const {
    verifyPassword,
    isVerifyPasswordLoading,
    isPasswordLongEnough,
    passwordData,
  } = useVerifyPassword()

  const [isCaptchaLoading, setCaptchaLoading] = useState(false)
  const [passwordConfirmError, setPasswordConfirmError] = useState(false)
  const [passwordFocused, setPasswordFocused] = useState(false)

  const {
    setFieldValue,
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
  } = useFormik<RegisterFormSchema>({
    initialValues: {
      email: '',
      username: '',
      password: '',
      passwordConfirm: '',
      mailinglist: false,
      agreedToTerms: false,
      site_key: GOOGLE_RECAPTCHA_SECRET_SCORE,
    },
    validationSchema: registerFormValidationSchema,
    onSubmit: async (values) => {
      setCaptchaLoading(true)
      const token = await recaptchaRef?.executeAsync()
      setCaptchaLoading(false)
      if (token) {
        return createAccount({ ...values, captcha: token })
      }
    },
  })

  useEffect(() => {
    if (
      legalInfo &&
      (!values.curr_privacy_rev ||
        !values.curr_terms_aircrm_rev ||
        !values.curr_terms_rev)
    ) {
      setFieldValue('curr_terms_rev', legalInfo.rev_terms)
      setFieldValue('curr_privacy_rev', legalInfo.rev_privacy)
      setFieldValue('curr_terms_aircrm_rev', legalInfo.rev_terms_aircrm)
    }
  }, [legalInfo, setFieldValue, values])

  const isLoading =
    isCreateAccountLoading || isLegalInfoLoading || isCaptchaLoading

  const apiErrors = useMemo(() => {
    if (isAxiosError(createAccountError) && createAccountError.response?.data) {
      return createAccountError.response.data.error_code ===
        SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED
        ? ''
        : createAccountError.response.data
    }
  }, [createAccountError])

  const errorMessage = useMemo(() => {
    if (
      !isEmpty(apiErrors) &&
      !apiErrors.username &&
      !apiErrors.email &&
      !apiErrors.password
    ) {
      let errorMessage = intl.formatMessage({ id: 'REGISTER_FALLBACK_ERROR' })
      if (apiErrors.detail === 'invalid captcha') {
        errorMessage = intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_RECAPTCHA_ERROR',
        })
      }
      if (apiErrors.fields_non_unique?.length) {
        errorMessage = intl.formatMessage(
          {
            id: 'COMMON_AUTH_REGISTER_FIELDS_ERROR',
          },
          { field: apiErrors.fields_non_unique[0] }
        ) as string
      }
      return (
        <ValidationErrorWrapper>
          <ValidationError>{errorMessage}</ValidationError>
        </ValidationErrorWrapper>
      )
    }
  }, [apiErrors, intl])

  const confirmPasswordSame = useMemo(() => {
    return (
      (touched.passwordConfirm || !!values.passwordConfirm?.length) &&
      !errors.passwordConfirm &&
      values.passwordConfirm === values.password
    )
  }, [touched, values, errors])

  const checkBoxCaptchaIsVisible = useMemo(
    () =>
      isAxiosError(createAccountError) &&
      createAccountError.response?.data.error_code ===
        SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED,
    [createAccountError]
  )

  const handleCheckboxRecaptcha = (token: string) => {
    createAccount({
      ...values,
      site_key: GOOGLE_RECAPTCHA_SECRET_CHALLENGE,
      captcha: token,
    })
  }

  const termsNotChecked = useMemo(
    () => !!(touched.agreedToTerms && errors.agreedToTerms),
    [touched, errors]
  )

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (isCaptchaLoading) {
      // make sure that captcha stops loading in case the challenge window has been closed
      timer = setTimeout(
        () => isCaptchaLoading && setCaptchaLoading(false),
        1000
      )
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isCaptchaLoading, setCaptchaLoading])

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    verifyPassword(e.target.value, values.username, values.email)
  }

  return (
    <PublicPage
      title={intl.formatMessage({
        id: 'COMMON_AUTH_REGISTER_HEADER',
      })}
      registerPage
    >
      <Form
        data-testid="registerForm"
        id="register-form"
        onSubmit={handleSubmit}
      >
        <StyledInput
          data-testid="username"
          label={intl.formatMessage({ id: 'COMMON_LABEL_USERNAME' })}
          name="username"
          value={values.username}
          onChange={(e) => {
            handleChange(e)
            verifyUsername(e.target.value)
          }}
          onBlur={handleBlur}
          invalid={
            (touched.username && errors.username) ||
            (!isVerifyUsernameLoading && verifyUsernameError) ||
            undefined
          }
          iconAfter={
            isVerifyUsernameLoading ? (
              <Loader size="tiny" type="spinner" />
            ) : undefined
          }
          autoCapitalize="none"
          autoCorrect="off"
          full
          variant="secondary"
        />
        <StyledInput
          data-testid="email"
          label={intl.formatMessage({ id: 'COMMON_LABEL_EMAIL' })}
          name="email"
          value={values.email}
          onChange={(e) => {
            handleChange(e)
            verifyEmail(e.target.value)
          }}
          onBlur={handleBlur}
          invalid={
            (touched.email && errors.email) ||
            (!isVerifyEmailLoading && verifyEmailError) ||
            undefined
          }
          iconAfter={
            isVerifyEmailLoading ? (
              <Loader size="tiny" type="spinner" />
            ) : undefined
          }
          full
          variant="secondary"
        />
        <PasswordFields
          passwordFocused={passwordFocused}
          setPasswordFocused={setPasswordFocused}
          handleChange={handleChange}
          handlePasswordChange={handlePasswordChange}
          handleBlur={handleBlur}
          password={values.password}
          passwordErrors={errors.password}
          passwordLongEnough={isPasswordLongEnough}
          apiErrors={apiErrors}
          passwordTouched={touched.password}
          passwordData={passwordData}
          passwordCheckLoading={isVerifyPasswordLoading}
          confirmPassword={values.passwordConfirm}
          setPasswordConfirmError={setPasswordConfirmError}
          confirmPasswordErrors={errors.passwordConfirm}
          confirmPasswordTouched={touched.passwordConfirm}
          passwordConfirmError={passwordConfirmError}
          confirmPasswordSame={confirmPasswordSame}
        />
        <StyledCheckbox
          data-testid="agreeToTerms"
          id="agreedToTerms"
          checked={!!values.agreedToTerms}
          onChange={handleChange}
        >
          <CheckDescription>
            <FormattedMessage
              id="COMMON_AUTH_REGISTER_LABEL_TERMS"
              values={{
                termsOfConditions: (
                  <a
                    href="https://www.ui.com/legal/termsofservice/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="COMMON_LABEL_TERMS_OF_SERVICE" />
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href="https://www.ui.com/legal/privacypolicy/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="COMMON_LABEL_PRIVACY_POLICY" />
                  </a>
                ),
              }}
            />
          </CheckDescription>
        </StyledCheckbox>
        <StyledCheckbox
          data-testid="subscribeToMailingList"
          id="mailinglist"
          checked={!!values.mailinglist}
          onChange={handleChange}
        >
          <CheckDescription>
            <FormattedMessage id="COMMON_AUTH_REGISTER_LABEL_NEWSLETTER" />
          </CheckDescription>
        </StyledCheckbox>
        {errorMessage}
        {termsNotChecked && (
          <ValidationMessage>{errors.agreedToTerms}</ValidationMessage>
        )}
        <StyledButton
          data-testid="createAccountButton"
          disabled={
            isLoading ||
            !!Object.keys(errors).length ||
            !!verifyUsernameError ||
            !!verifyEmailError ||
            isVerifyPasswordLoading ||
            !passwordData?.score ||
            passwordData.score < 3 ||
            passwordConfirmError ||
            !confirmPasswordSame ||
            !isPasswordLongEnough ||
            checkBoxCaptchaIsVisible
          }
          type="submit"
          variant="primary"
          loader={isLoading ? 'dots' : undefined}
          full
        >
          {intl.formatMessage({
            id: 'COMMON_AUTH_REGISTER_LABEL_REGISTER',
          })}
        </StyledButton>
        {checkBoxCaptchaIsVisible && (
          <CheckboxReCaptcha onVerify={handleCheckboxRecaptcha} />
        )}
      </Form>
      <LoginWrapper>
        <LoginText>
          {intl.formatMessage({
            id: 'COMMON_SSO_LABEL_ALREADY_HAVE_ACCOUNT',
          })}
          &nbsp;
          <LoginLink to="/login">
            {intl.formatMessage({ id: 'COMMON_AUTH_ACTION_SIGN_IN' })}
          </LoginLink>
        </LoginText>
      </LoginWrapper>
    </PublicPage>
  )
}

export const RegisterForm = authorizedRedirect(RegistrationForm)

const ValidationErrorWrapper = styled.div`
  margin: 20px 0;
`

export const StyledInput = styled(Input)`
  height: 48px;
  margin-bottom: 12px;
  > div {
    > span {
      font: ${typography['desktop-caption']};
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  > div {
    margin: 0;
  }
`

const CheckDescription = styled.div`
  font-size: 12px;
  line-height: 20px;
`

const StyledButton = styled(Button)`
  height: 40px;
`

const LoginWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.neutral01};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  justify-self: flex-end;
`

const LoginLink = styled(Link)`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.ublue06};
`

const LoginText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  text-align: center;
`

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 12px;
`
