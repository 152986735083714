import React, { useContext, useCallback } from 'react'
import { useIntl } from 'react-intl'
import UserAccountIcon from '@ubnt/icons/UserAccountIcon'
import { ToastContext } from '@ubnt/ui-components/Toast'
import StyledEntityToast from 'components/EntityToast'

export const useToasts = () => {
  const toast = useContext(ToastContext)
  const intl = useIntl()

  const profilePictureUpdatedSuccessToast = useCallback(() => {
    toast.createNotification(
      <StyledEntityToast
        id="profilePictureUpdated"
        centerText={true}
        icon={<UserAccountIcon />}
        stateIndicator="success"
        onClose={() => {
          toast.removeNotification('profilePictureUpdated')
        }}
        title={intl.formatMessage({
          id: 'SETTINGS_PICTURE_UPLOAD_SUCCESS_TOAST',
        })}
        duration={5000}
      />
    )
  }, [intl, toast])

  return {
    profilePictureUpdatedSuccessToast,
  }
}
