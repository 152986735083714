import { AxiosError } from 'axios'
import { SsoApiError } from 'types/ssoErrors'

export const parseSsoErrors = (error: AxiosError<SsoApiError> | null) => {
  if (!error || !error.response) return {}

  //handle case when username is already taken
  if (error.status === 409) {
    return {
      username: 'Sorry, this username is unavailable.',
    }
  }

  //handle case when profile picture file is unsupported
  if (error.status === 415) {
    return {
      picture: 'Sorry, this is not a supported image format.',
    }
  }

  const errors = Object.entries(error.response.data).reduce<{
    [key: string]: string
  }>((acc, [key, value]) => {
    acc[key] = value && typeof value === 'string' ? value : value[0]
    return acc
  }, {})

  if (!errors.detail) {
    return errors
  } else {
    if (errors.detail.match(/Wrong reset uuid for user/)) {
      return {
        ...errors,
        detail: 'You entered the wrong password. Please try again.',
      }
    }
  }

  return errors
}

export const parseSsoProfileErrors = (
  error: AxiosError<SsoApiError> | null
) => {
  if (!error || !error.response) return {}

  //handle case when username is already taken
  if (error.status === 409) {
    return {
      username: 'Sorry, this username is unavailable.',
    }
  }

  return parseSsoErrors(error)
}
